import React, { useState, useEffect } from 'react';
import Axios from '../../../Axios/Axios';

import {
  Row,
  Button,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Alert,
  Form,
  Container,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CirclePicker } from 'react-color';
import UploadHistory from '../../UploadMeterData/MeterData/UploadHistory/UploadHistory';
import swal from 'sweetalert';
import './MeterItem.css';

const MeterItem = props => {
  const [meter, SetMeter] = useState([]);
  const [building, setBuilding] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [allSubMeter, SetAllSubMeter] = useState([]);
  const [allUploadConsumptions, setUploadConsumptions] = useState([]);
  const [MeterName, setMeterName] = useState('');
  const [MeterType, setMeterType] = useState('Consumption');
  const [MeterUnit, setMeterUnit] = useState('');
  const [SecMeterIcon, setSecMeterIcon] = useState('');
  const [SecColor, setSecColor] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subMeterNumber, SetSubMeterNumber] = useState('');
  const [parentMeterId, SetParentMeterId] = useState('');
  const [showEditMeter, setShowEditMeter] = useState(false);
  const [allParameterUnit, setAllParameterUnit] = useState([]);
  const [allConsumptionUnit, setAllConsumptionUnit] = useState([]);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    Axios.get('/api/v1/Meter/' + props.ItemID, config).then(meter => {
      SetMeter(meter.data.data.data);
      setMeterType(meter.data.data.data.unit.type);
      Axios.get(
        '/api/v1/organization/' + meter.data.data.data.organization._id,
        config
      ).then(org => {
        setOrganization(org.data.data.data);
      });
      Axios.get(
        '/api/v1/building/' + meter.data.data.data.building._id,
        config
      ).then(building => {
        setBuilding(building.data.data.data);
      });
      Axios.get('/api/v1/unit?type=Consumption', config).then(units => {
        setAllConsumptionUnit(units.data.data.data);
      });
      Axios.get('/api/v1/unit?type=Parameter', config).then(units => {
        setAllParameterUnit(units.data.data.data);
      });
      //Upload History
      Axios.get(`/api/v1/file?meter=${props.ItemID}`, config).then(res => {
        setUploadConsumptions(res.data.data.data);
        // console.log(res.data.data.data.length);
      });

      if (meter.data.data.data.hasChild) {
        Axios.get(
          '/api/v1/Meter?hasChild=false&parentMeter=' + props.ItemID,
          config
        ).then(subMeter => {
          SetAllSubMeter(subMeter.data.data.data);
        });
      } else {
        if (meter.data.data.data.parentMeter) {
          Axios.get(
            '/api/v1/Meter?parentMeter=' + meter.data.data.data.parentMeter._id,
            config
          ).then(parentMeter => {
            // console.log(parentMeter.data.data.data.length);
            SetSubMeterNumber(parentMeter.data.data.data.length);
            SetParentMeterId(meter.data.data.data.parentMeter._id);
          });
        }
      }
    });
  }, [props.ItemID]);

  const DeleteItem = async () => {
    swal({
      title: ` ایا می خواهید کنتور  "` + meter.name + `" را حذف نمایید   `,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(async willDelete => {
      if (willDelete) {
        const body = JSON.stringify({
          active: false
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        let currentMeter = await Axios.get(
          '/api/v1/Meter/' + props.ItemID,
          config
        );
        console.log(currentMeter);
        if (currentMeter.data.data.data.parentMeter) {
          let childMeter = await Axios.get(
            '/api/v1/Meter?parentMeter=' +
              currentMeter.data.data.data.parentMeter._id,
            config
          );

          if (childMeter.data.data.data.length === 1) {
            const updatemeterParent = JSON.stringify({
              hasChild: false
            });
            await Axios.patch(
              `/api/v1/meter/` + currentMeter.data.data.data.parentMeter._id,
              updatemeterParent,
              config
            );
            props.setIsRenderNavbar(true);
          }
        }

        Axios.delete(`/api/v1/meter/` + props.ItemID, config).then(res => {
          props.setIsRenderNavbar(true);
          props.SetShowPage('d-none');
          swal('کنتور مورد نظر حذف شد.', {
            icon: 'success'
          });
        });
      }
    });
  };
  const [CirclePickerColors, setCirclePickerColors] = useState([
    '#8bc34a',
    '#ffc107',
    '#2196f3',
    '#f44336',
    '#ffeb3b',
    '#B3B3B3',
    '#333'
  ]);
  const handleChangeComplete = color => {
    setSecColor(color.hex);
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const handleShowAdd = () => setShowAdd(true);

  const updateMeter = () => {
    const meterNameTemp = MeterName ? MeterName : meter.name;
    const meterTypeTemp = MeterType ? MeterType : meter.type;
    const meterUnitTemp = MeterUnit ? MeterUnit : meter.unit;
    const meterIconTemp = SecMeterIcon ? SecMeterIcon : meter.icon;
    const meterColorTemp = SecColor ? SecColor : meter.color;
    const updateMeterJSON = JSON.stringify({
      name: meterNameTemp,
      type: meterTypeTemp,
      unit: meterUnitTemp,
      icon: meterIconTemp,
      color: meterColorTemp
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    Axios.patch(`/api/v1/meter/` + meter._id, updateMeterJSON, config).then(
      org => {
        props.setIsRenderNavbar(true);
        toast.success('کنتور "' + meterNameTemp + '" ویرایش شد .');
        handleCloseEdit();
        setMeterName('');
        setMeterType('');
        setMeterUnit('');
        setSecMeterIcon('');
        setSecColor('');
      }
    );
  };
  const AddSubMeter = () => {
    const SubMeterNewItem = JSON.stringify({
      name: MeterName,
      hasChild: 'false',
      type: MeterType,
      unit: MeterUnit,
      organization: { _id: organization._id },
      building: { _id: building._id },
      icon: SecMeterIcon,
      color: SecColor,
      parentMeter: { _id: props.ItemID }
    });
    if (MeterName && MeterType && MeterUnit && SecMeterIcon && SecColor) {
      Axios.post(`/api/v1/Meter/`, SubMeterNewItem, config).then(org => {
        setLoading(false);
        setShowAdd(false);

        setMeterType('');
        setMeterName('');
        setMeterUnit('');
        setMeterUnit('');
        setSecMeterIcon('');
        setSecColor('');
        const updatemeterParent = JSON.stringify({
          hasChild: true
        });
        Axios.patch(
          `/api/v1/meter/` + props.ItemID,
          updatemeterParent,
          config
        ).then(meterParent => {
          props.setIsRenderNavbar(true);
        });

        toast.success('کنتور "' + MeterName + '" ایجاد شد .');
      });
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 6000 });
      setLoading(false);
    }
  };
  const handleShowEditMeter = () => {
    setShowEditMeter(true);
  };
  const handleCloseEdit = () => {
    setShowEditMeter(false);
  };
  const handleShowEdit = () => setShowAdd(true);
  const changeUnit = e => {
    let unit = null;
    allConsumptionUnit.forEach(element => {
      if (element._id === e.target.value) {
        unit = element;
      }
    });
    allParameterUnit.forEach(element => {
      if (element._id === e.target.value) {
        unit = element;
      }
    });
    setMeterUnit(unit);
  };
  return (
    <div>
      <div className="Actionbar">
        <div className="row">
          <div className="col-md-6 ">
            <Button
              hidden={meter.parentMeter}
              variant="btn btn-success float-left mr-2"
              onClick={handleShowAdd}
            >
              <i class="fa fa-plus"></i>
              کنتور / متغیر زیر مجموعه
            </Button>
            <Modal
              hidden={meter.parentMeter}
              show={showAdd}
              onHide={handleCloseAdd}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  افزودن {MeterType === 'Consumption' ? 'کنتور' : 'متغیر'} زیر
                  مجموعه
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <InputGroup className="mb-1">
                    <Form.Control
                      as="select"
                      custom
                      onChange={e => setMeterType(e.target.value)}
                      value={MeterType}
                    >
                      <option value="Consumption">کنتور مصرفی</option>
                      <option value="Parameter">پارامترها</option>
                    </Form.Control>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder={
                        MeterType === 'Consumption' ? 'نام کنتور' : 'نام متغیر'
                      }
                      aria-label="MeterName"
                      aria-describedby="MeterName"
                      value={MeterName}
                      onChange={e => setMeterName(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <Form.Control
                      as="select"
                      custom
                      value={MeterUnit ? MeterUnit._id : null}
                      onChange={e => changeUnit(e)}
                    >
                      <option value="ChooseUnit">
                        {MeterType === 'Consumption'
                          ? 'واحد کنتور'
                          : 'واحد متغیر'}
                        را انتخاب نمایید
                      </option>
                      {MeterType === 'Consumption'
                        ? allConsumptionUnit.map((element, index) => {
                            return (
                              <option
                                index={index}
                                value={element._id}
                              >{`${element.name}(${element.unit})`}</option>
                            );
                          })
                        : allParameterUnit.map((element, index) => {
                            return (
                              <option
                                index={index}
                                value={element._id}
                              >{`${element.name}(${element.unit})`}</option>
                            );
                          })}
                    </Form.Control>
                  </InputGroup>
                  <InputGroup className="mb-1 ">
                    <Form.Control
                      as="select"
                      multiple
                      custom
                      className="IconSelect"
                      onChange={e => setSecMeterIcon(e.target.value)}
                    >
                      <option value="fa-sun-o">Sun &#xf185;</option>
                      <option value="fa-snowflake-o">Snow &#xf2dc;</option>
                      <option value="fa-battery-full">battery &#xf240;</option>
                      <option value="fa fa-lightbulb-o">
                        lightbulb &#xf0eb;
                      </option>
                    </Form.Control>
                  </InputGroup>

                  <CirclePicker
                    colors={CirclePickerColors}
                    color={SecColor}
                    onChangeComplete={handleChangeComplete}
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={AddSubMeter}>
                  ذخیره
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="col-md-6">
            {/* Edit Meter */}
            <Button
              variant="btn btn-success float-right"
              onClick={handleShowEditMeter}
            >
              <i class="feather icon-edit"></i>
            </Button>
            <Modal show={showEditMeter} onHide={handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  ویرایش {MeterType === 'Consumption' ? 'کنتور' : 'متغیر'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <InputGroup className="mb-1">
                    <Form.Control
                      as="select"
                      custom
                      value={MeterType ? MeterType : meter.type}
                      onChange={e => setMeterType(e.target.value)}
                    >
                      <option value="Consumption">کنتور مصرفی</option>
                      <option value="Parameter">پارامترها</option>
                    </Form.Control>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder={
                        MeterType === 'Consumption' ? 'نام کنتور' : 'نام متغیر'
                      }
                      aria-label="MeterName"
                      aria-describedby="MeterName"
                      value={MeterName ? MeterName : meter.name}
                      onChange={e => setMeterName(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <Form.Control
                      as="select"
                      custom
                      value={
                        meter.unit
                          ? MeterUnit
                            ? MeterUnit._id
                            : meter.unit._id
                          : null
                      }
                      onChange={e => changeUnit(e)}
                    >
                      <option value="ChooseUnit">
                        {MeterType === 'Consumption'
                          ? 'واحد کنتور'
                          : 'واحد متغیر'}
                        را انتخاب نمایید
                      </option>
                      {MeterType === 'Consumption'
                        ? allConsumptionUnit.map((element, index) => {
                            return (
                              <option
                                index={index}
                                value={element._id}
                              >{`${element.name}(${element.unit})`}</option>
                            );
                          })
                        : allParameterUnit.map((element, index) => {
                            return (
                              <option
                                index={index}
                                value={element._id}
                              >{`${element.name}(${element.unit})`}</option>
                            );
                          })}
                    </Form.Control>
                  </InputGroup>
                  <InputGroup className="mb-1 ">
                    <Form.Control
                      as="select"
                      multiple
                      custom
                      className="IconSelect"
                      onChange={e => setSecMeterIcon(e.target.value)}
                    >
                      <option value="fa-sun-o">Sun &#xf185;</option>
                      <option value="fa-snowflake-o">Snow &#xf2dc;</option>
                      <option value="fa-battery-full">battery &#xf240;</option>
                      <option value="fa fa-lightbulb-o">
                        lightbulb &#xf0eb;
                      </option>
                    </Form.Control>
                  </InputGroup>

                  <CirclePicker
                    colors={CirclePickerColors}
                    color={SecColor ? SecColor : meter.color}
                    onChangeComplete={handleChangeComplete}
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={updateMeter}>
                  ذخیره
                </Button>
              </Modal.Footer>
            </Modal>

            {/* End Edit Meter */}

            <Button
              variant="btn btn-danger float-right mr-2"
              onClick={DeleteItem}
            >
              <i class="feather icon-trash"></i>
            </Button>
          </div>
        </div>
      </div>
      <div className="Orginfo">
        <Row>
          <Col xs={12} md={12}>
            <h3> اطلاعات کنتور</h3>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="grayLbl mb-1">
                <i class="fa fa-building"></i>
                <span className="lblBold"> نام :</span>
                <span className="lblNormal">{meter.name}</span>
              </div>
              <div className="grayLbl mb-1">
                <i class="fa fa-tag"></i>
                <span className="lblBold"> نوع :</span>
                <span className="lblNormal">
                  {meter.type === 'Consumption' ? 'کنتور مصرفی' : 'پارامترها'}
                </span>
              </div>
              <div className="grayLbl mb-1">
                <i class="fas fa-ruler-combined"></i>
                <span className="lblBold"> واحد :</span>
                <span className="lblNormal">
                  {meter.unit ? `${meter.unit.name}(${meter.unit.unit})` : ''}
                </span>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="grayLbl mb-1">
                <i class="fa fa-university"></i>
                <span className="lblBold"> نام سازمان:</span>
                <span className="lblNormal">{organization.name}</span>
              </div>
              <div className="grayLbl mb-1">
                <i class="fa fa-building"></i>
                <span className="lblBold">نام ساختمان:</span>
                <span className="lblNormal">{building.name}</span>
              </div>
            </Col>
          </Row>
        </Container>
        <h3 className="mt-3">کنتورها </h3>
        <div className="table-responsive">
          <table class="table table-striped tblOrg">
            <thead>
              <tr>
                <th scope="col">نام </th>
                <th scope="col">نوع</th>
                <th scope="col">واحد</th>
              </tr>
            </thead>
            <tbody>
              {allSubMeter.map(subMeter => {
                return (
                  <tr
                    class="clickable "
                    onclick="window.location='http://google.com'"
                  >
                    <td> {subMeter.name}</td>
                    <td>
                      {subMeter.type === 'Consumption'
                        ? 'کنتور مصرفی'
                        : 'پارامترها'}
                    </td>
                    <td>
                      {subMeter.unit
                        ? `${subMeter.unit.name} (${subMeter.unit.unit})`
                        : ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div
          className={'mt-2 mb-2'}
          hidden={allUploadConsumptions.length === 0}
        >
          <h3 className="mt-3">سوابق آپلود</h3>

          <UploadHistory historyConsumption={allUploadConsumptions} />
        </div>
      </div>
    </div>
  );
};
export default MeterItem;

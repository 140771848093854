import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

const ChartCompareYear = props => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  defaults.normalized = true;
  defaults.font.size = 11;
  defaults.font.family = 'Iranian Sans';
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  const option = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true
      },
      title: {
        display: true,
        text: ' نمودار مقایسه ای سالیانه',
        padding: {
          bottom: 40
        },
        font: {
          size: 18
        }
      }
    },
    

    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: props.dataset.currentYear[0].unit
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'ریال'
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      },
      x: {
        grid: {
          display: true
        }
      }
    }
  };
  const currentYearLabel = props.dataset.currentYear.length > 0 ? props.dataset.currentYear[0]._id.dateOf : '';
  const lastYearLabel = props.dataset.lastYear.length > 0 ? props.dataset.lastYear[0]._id.dateOf : '';
  const twoYearAgoLabel = props.dataset.twoYearAgo.length > 0 ? props.dataset.twoYearAgo[0]._id.dateOf + ' ' : '';
  const data = {
    datasets: [
      {
        label: 'مصرف',
        yAxisID: 'y',
        backgroundColor: '#ff9e64',
        borderColor: '#fa7e33',
        data: [
          props.dataset.currentYear.length > 0 ? props.dataset.currentYear[0].total : '',
          props.dataset.lastYear.length > 0 ? props.dataset.lastYear[0].total : '',
          props.dataset.twoYearAgo.length > 0 ? props.dataset.twoYearAgo[0].total : ''
        ]
      },
      {
        label: 'هزینه',
        yAxisID: 'y1',
        backgroundColor: '#305dfa',
        borderColor: '#3fd58a',
        data: [
          props.dataset.currentYear.length > 0 ? props.dataset.currentYear[0].cost : '',
          props.dataset.lastYear.length > 0 ? props.dataset.lastYear[0].cost : '',
          props.dataset.twoYearAgo.length > 0 ? props.dataset.twoYearAgo[0].cost : ''
        ]
      }
    ],

    labels: [currentYearLabel, lastYearLabel, twoYearAgoLabel]
  };

  return <Bar data={data} options={option} redraw={true}/>;
};
export default ChartCompareYear;

import React, { Fragment, useEffect } from 'react';
import Axios from '../../../Axios/Axios';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Navbar from './Navbar/Navbar';
import { logout } from '../../../actions/auth';

//for check if user token is invalid
const getUser = async logout => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  try {
    await Axios.get('/api/v1/users/me', config);
  } catch (error) {
    logout();
  }
};

const Header = props => {
  // useEffect(() => {
  //  getUser(props.logout);
  // });
  if (!props.user) {
    return <Redirect to="/login" />;
  }
  return (
    <Fragment>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <Navbar user={props.user} />
    </Fragment>
  );
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps, { logout })(Header);

import React, { Fragment, useState, useEffect } from 'react';
import { Row, Container, Button, Col, Spinner, Form } from 'react-bootstrap';
import { Calendar } from 'react-modern-calendar-datepicker';
import Axios from '../../../Axios/Axios';
import Axie from '../../../components/UI/Axie/Axie';

const TrendFormulaBuilder = (props) => {
  const [allDataNode, setAllDataNode] = useState([]);
  const [needGetData, setNeedGetData] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [needGetYear, setNeedGetYear] = useState(false);

  const [yAxieFormula, setYAxieFormula] = useState(null);
  const [validYAxie, setValidYAxie] = useState(true);

  const [x1AxieFormula, setX1AxieFormula] = useState(null);
  const [validX1Axie, setValidX1Axie] = useState(true);

  const [x2AxieFormula, setX2AxieFormula] = useState(null);
  const [validX2Axie, setValidX2Axie] = useState(true);

  const [x3AxieFormula, setX3AxieFormula] = useState(null);
  const [validX3Axie, setValidX3Axie] = useState(true);

  const [x4AxieFormula, setX4AxieFormula] = useState(null);
  const [validX4Axie, setValidX4Axie] = useState(true);

  const [x5AxieFormula, setX5AxieFormula] = useState(null);
  const [validX5Axie, setValidX5Axie] = useState(true);


  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const getAllDataNode = async () => {
    let temp = [...allDataNode];
    const org = await Axios.get('/api/v1/organization', config);
    let orgData = org.data.data.data;

    orgData.forEach((item, index) => {
      temp.push({
        _id: item._id,
        name: item.name + '(برق)',
        label: item.name,
        abbr: 'org' + (index * 1 + 1) + ' (Electricy)',
        navType: 'organization',
        type: 'برق'
      });
      temp.push({
        _id: item._id,
        name: item.name + '(آب)',
        label: item.name,
        abbr: 'org' + (index * 1 + 1) + ' (Water)',
        navType: 'organization',
        type: 'آب'
      });
      temp.push({
        _id: item._id,
        name: item.name + '(گاز)',
        label: item.name,
        abbr: 'org' + (index * 1 + 1) + ' (Gas)',
        navType: 'organization',
        type: 'گاز'
      });
    });
    const buildings = await Axios.get('/api/v1/building', config);
    let buildingsData = buildings.data.data.data;
    buildingsData.forEach((item, index) => {
      const orgName = orgData.find(el => {
        return el._id === item.organization;
      });
      temp.push({
        _id: item._id,
        name: orgName.name + ' > ' + item.name + '(برق)',
        label: item.name,
        abbr: 'building' + (index * 1 + 1) + ' (Electricy)',
        navType: 'building',
        type: 'برق'
      });
      temp.push({
        _id: item._id,
        name: orgName.name + ' > ' + item.name + '(آب)',
        label: item.name,
        abbr: 'building' + (index * 1 + 1) + ' (Water)',
        navType: 'building',
        type: 'آب'
      });
      temp.push({
        _id: item._id,
        name: orgName.name + ' > ' + item.name + '(گاز)',
        label: item.name,
        abbr: 'building' + (index * 1 + 1) + ' (Gas)',
        navType: 'building',
        type: 'گاز'
      });
    });
    const meters = await Axios.get('/api/v1/meter', config);
    meters.data.data.data.forEach((item, index) => {
      temp.push({
        _id: item._id,
        name:
          item.organization.name +
          ' > ' +
          item.building.name +
          ' > ' +
          item.name,
        label: item.name,
        abbr: 'meter' + (index * 1 + 1),
        navType: 'meter',
        type: item.type
      });
    });
    setAllDataNode(temp);
  };
  const checkBTNValid = () => {
    if (props.regressionParam === '1') {
      return props.x1AxieFormulaSynon.length > 0 && props.yAxieFormulaSynon.length > 0;
    } else if (props.regressionParam === '2') {
      return (
        props.yAxieFormulaSynon.length > 0 &&
        props.x1AxieFormulaSynon.length > 0 &&
        props.x2AxieFormulaSynon.length > 0
      );
    } else if (props.regressionParam === '3') {
      return (
        props.yAxieFormulaSynon.length > 0 &&
        props.x1AxieFormulaSynon.length > 0 &&
        props.x2AxieFormulaSynon.length > 0 &&
        props.x3AxieFormulaSynon.length > 0
      );
    } else if (props.regressionParam === '4') {
      return (
        props.yAxieFormulaSynon.length > 0 &&
        props.x1AxieFormulaSynon.length > 0 &&
        props.x2AxieFormulaSynon.length > 0 &&
        props.x3AxieFormulaSynon.length > 0 &&
        props.x4AxieFormulaSynon.length > 0
      );
    } else if (props.regressionParam === '5') {
      return (
        props.yAxieFormulaSynon.length > 0 &&
        props.x1AxieFormulaSynon.length > 0 &&
        props.x2AxieFormulaSynon.length > 0 &&
        props.x3AxieFormulaSynon.length > 0 &&
        props.x4AxieFormulaSynon.length > 0 &&
        props.x5AxieFormulaSynon.length > 0
      );
    }
  };
  useEffect(() => {
    if (needGetData) {
      setNeedGetData(false);
      getAllDataNode();
    }
  });
  return (
    <Fragment>
      <Row className={'pt-3 mb-2 dpRtl'}>
        <Col md={{ span: 8, offset: 2 }}>
          <Row>
            <Col md={{ span: 6 }} className="text-right">
              <label className={'pr-2 pl-1'}>تعداد متغیرهای مرتبط</label>
            </Col>
            <Col md={6}>
              <select
                className="regressionOrderSelect"
                value={props.regressionParam}
                onChange={e => {
                  props.setRegressionParam(e.target.value);
                  props.setPreviewRegression(null);
                }}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </Col>
            <Col
              md={{ span: 6 }}
              className="text-right mt-2 mb-2"
              hidden={props.regressionParam !== '1'}
            >
              <label className={'pr-2 pl-1'}>درجه رگرسیون (Order)</label>
            </Col>
            <Col
              md={6}
              hidden={props.regressionParam !== '1'}
              className="mt-2 mb-2"
            >
              <select
                className="regressionOrderSelect"
                value={props.regressionOrder}
                onChange={e => {
                  props.setRegressionOrder(e.target.value);
                  props.setPreviewRegression(null);
                }}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={'mb-1 '}>
        <Col md={{ span: 12 }}>
          <Col className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text dpInputGroupText">Y-Axies</div>
            </div>
            <Axie
              allDataNode={allDataNode}
              axieFormulaSynon={props.yAxieFormulaSynon}
              setAxieFormulaSynon={props.setYAxieFormulaSynon}
              axieFormula={yAxieFormula}
              setAxieFormula={setYAxieFormula}
              setNeedGetYear={setNeedGetYear}
              placehodler={
                "برای درج فرمول از کلیدهای '@' ، '$' ، '#' استفاده نمایید"
              }
              validAxie={validYAxie}
              setValidAxie={setValidYAxie}
              setAxieFormulaText={props.setYAxieFormulaText}
              setChartData={setChartData}
            />
          </Col>
          <Col className="input-group mb-2">
            <div className="input-group-prepend">
              <div className="input-group-text dpInputGroupText">X1-Axies</div>
            </div>
            <Axie
              allDataNode={allDataNode}
              axieFormulaSynon={props.x1AxieFormulaSynon}
              setAxieFormulaSynon={props.setX1AxieFormulaSynon}
              axieFormula={x1AxieFormula}
              setAxieFormula={setX1AxieFormula}
              setNeedGetYear={setNeedGetYear}
              placehodler={
                "برای درج فرمول از کلیدهای '@' ، '$' ، '#' استفاده نمایید"
              }
              validAxie={validX1Axie}
              setValidAxie={setValidX1Axie}
              setAxieFormulaText={props.setX1AxieFormulaText}
              setChartData={setChartData}
            />
          </Col>
          <Col className="input-group mb-2" hidden={props.regressionParam < 2}>
            <div className="input-group-prepend">
              <div className="input-group-text dpInputGroupText">X2-Axies</div>
            </div>
            <Axie
              allDataNode={allDataNode}
              axieFormulaSynon={props.x2AxieFormulaSynon}
              setAxieFormulaSynon={props.setX2AxieFormulaSynon}
              axieFormula={x2AxieFormula}
              setAxieFormula={setX2AxieFormula}
              setNeedGetYear={setNeedGetYear}
              placehodler={
                "برای درج فرمول از کلیدهای '@' ، '$' ، '#' استفاده نمایید"
              }
              validAxie={validX2Axie}
              setValidAxie={setValidX2Axie}
              setAxieFormulaText={props.setX2AxieFormulaText}
              setChartData={setChartData}
            />
          </Col>
          <Col className="input-group mb-2" hidden={props.regressionParam < 3}>
            <div className="input-group-prepend">
              <div className="input-group-text dpInputGroupText">X3-Axies</div>
            </div>
            <Axie
              allDataNode={allDataNode}
              axieFormulaSynon={props.x3AxieFormulaSynon}
              setAxieFormulaSynon={props.setX3AxieFormulaSynon}
              axieFormula={x3AxieFormula}
              setAxieFormula={setX3AxieFormula}
              setNeedGetYear={setNeedGetYear}
              placehodler={
                "برای درج فرمول از کلیدهای '@' ، '$' ، '#' استفاده نمایید"
              }
              validAxie={validX3Axie}
              setValidAxie={setValidX3Axie}
              setAxieFormulaText={props.setX3AxieFormulaText}
              setChartData={setChartData}
            />
          </Col>
          <Col className="input-group mb-2" hidden={props.regressionParam < 4}>
            <div className="input-group-prepend">
              <div className="input-group-text dpInputGroupText">X4-Axies</div>
            </div>
            <Axie
              allDataNode={allDataNode}
              axieFormulaSynon={props.x4AxieFormulaSynon}
              setAxieFormulaSynon={props.setX4AxieFormulaSynon}
              axieFormula={x4AxieFormula}
              setAxieFormula={setX4AxieFormula}
              setNeedGetYear={setNeedGetYear}
              placehodler={
                "برای درج فرمول از کلیدهای '@' ، '$' ، '#' استفاده نمایید"
              }
              validAxie={validX4Axie}
              setValidAxie={setValidX4Axie}
              setAxieFormulaText={props.setX4AxieFormulaText}
              setChartData={setChartData}
            />
          </Col>
          <Col className="input-group mb-2" hidden={props.regressionParam < 5}>
            <div className="input-group-prepend">
              <div className="input-group-text dpInputGroupText">X5-Axies</div>
            </div>
            <Axie
              allDataNode={allDataNode}
              axieFormulaSynon={props.x5AxieFormulaSynon}
              setAxieFormulaSynon={props.setX5AxieFormulaSynon}
              axieFormula={x5AxieFormula}
              setAxieFormula={setX5AxieFormula}
              setNeedGetYear={setNeedGetYear}
              placehodler={
                "برای درج فرمول از کلیدهای '@' ، '$' ، '#' استفاده نمایید"
              }
              validAxie={validX5Axie}
              setValidAxie={setValidX5Axie}
              setAxieFormulaText={props.setX5AxieFormulaText}
              setChartData={setChartData}
            />
          </Col>
        </Col>
      </Row>

      <Row className={'mb-1 dpRtl pt-2 text-center'}>
        <Col md={{ span: 6, offset: 3 }}>
          <h3 className="mt-3 mb-2 ">تعیین دوره خط مبنا</h3>
          <div className="w-100"></div>
          <Calendar
            value={props.chartRangeDate}
            onChange={props.setChartRangeDate}
            locale="fa"
            calendarClassName={'calendarWidth'}
          />
          <div className="w-100"></div>
          <Button
          className="mt-2"
            onClick={() => { props.calculateTrend(2) }}
            disabled={
              !validX1Axie ||
              !validX2Axie ||
              !validX3Axie ||
              !validX4Axie ||
              !validX5Axie ||
              !validYAxie ||
              !checkBTNValid()
            }
          >
            محاسبه خط مبنا
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TrendFormulaBuilder;

import React, { Fragment, useRef, useState } from 'react';

import './Chart.css';

import { Bar, Line, Pie, Doughnut, defaults, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import Axios from '../../../Axios/Axios';

import { Modal, Form, Button } from 'react-bootstrap';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
Chart.register(annotationPlugin);

const Charts = React.forwardRef((props, ref) => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.scales.linear.beginAtZero = true;
  defaults.plugins.legend.position = 'bottom';
  defaults.plugins.legend.rtl = true;
  defaults.plugins.legend.labels.padding = 30;
  defaults.plugins.legend.labels.usePointStyle = true;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.elements.line.tension = '0.5';
  defaults.plugins.legend.labels.color = '#121212';
  defaults.plugins.title.color = '#121212';

  const chartRef = useRef();

  const [lineVal, setLineVal] = useState('');
  const [lineTitle, setLineTitle] = useState('');
  const [lineAxies, setLineAxies] = useState('');
  const [allLineAnon, setAllLineAnon] = useState([]);
  const [topMarginChart, setTopMarginChart] = useState('mt-0');
  const [avgShow, setAvgShow] = useState(false);
  const [maxShow, setMaxShow] = useState(false);
  const [minShow, setMinShow] = useState(false);

  const downloadPNG = async () => {
    window.open(chartRef.current.toBase64Image());
  };
  const toggleShowTitle = () => {
    if (props.showTitle) {
      props.setShowTitle(false);
      setTopMarginChart('mt-3');
    } else {
      setTopMarginChart('mt-0');
      props.setShowTitle(true);
    }
  };
  const handleClose = () => props.setShow(false);
  const handleAdd = () => {
    if (lineAxies !== '' && lineVal !== '' && lineTitle !== '') {
      let tempLine = allLineAnon;
      tempLine.push({ val: lineVal, axies: lineAxies, title: lineTitle });
      setAllLineAnon(tempLine);
      setLineVal('');
      setLineTitle('');
      setLineAxies('');
      props.setShow(false);
    }
  };
  const handleShow = () => props.setShow(true);
 /* // todo
  const toggleLogaritm = () => {
    chartYScaleText.forEach((item, index) => {
      if (index === 0) {
        if (chartRef.current.options.scales.y === 'linear') {
          chartRef.current.options.scales.y = {
            type: 'logarithmic'
          };
        } else {
          chartRef.current.options.scales.y = {
            type: 'linear'
          };
        }
      } else {
        if (chartRef.current.options.scales[`y${index}`] === 'linear') {
          chartRef.current.options.scales[`y${index}`] = {
            type: 'logarithmic'
          };
        } else {
          chartRef.current.options.scales[`y${index}`] = {
            type: 'linear'
          };
        }
      }
    });

    chartRef.current.update();
  };
  //*/

  const ChartTempColor = ['#353c45', '#6b737d', '#a1a7ad', '#c4c49b'];
  let ChartColor = [];
  props.ChartColor
    ? (ChartColor = props.ChartColor)
    : (ChartColor = [
      '#6e61ed',
      '#00E396',
      '#fd8e2d',
      '#FF4560',
      '#afe831',
      '#F86624',
      '#A300D6',
      '#807b74',
      '#4ccce6',
      '#199c1d',
      '#4dc9f6',
      '#f67019',
      '#f53794',
      '#537bc4',
      '#acc236',
      '#166a8f',
      '#00a950',
      '#58595b',
      '#8549ba'
    ]);
  const ChartBorderColor = [
    '#8c7fff',
    '#7ef2cb',
    '#a18c7a',
    '#f095a2',
    '#cde892',
    '#f5b598',
    '#c798d6',
    '#d1c9bc',
    '#b1dbe3',
    '#86c487',
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
  ];
  const ChartColorCompare = [
    '#3c3582',
    '#006b47',
    '#915119',
    '#80212f',
    '#688a1d',
    '#853714',
    '#5a0175',
    '#3d3b37',
    '#2a7180',
    '#0e5911',

    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
  ];
  let chartYScaleText = [];
  const chartData = props.chartData.data;
  const chartDataCompare = props.chartData.dataCompare;

  const formula = props.chartData.formula;
  const formulaData = props.chartData.formulaData;

  chartData.forEach(datasets => {
    datasets.forEach(dataset => {
      if (!chartYScaleText.includes(`${dataset._id} (${dataset.AggregateData[0].unit})`)) {
        chartYScaleText.push(`${dataset._id} (${dataset.AggregateData[0].unit})`);
      }
    });
  });
  const footer = tooltipItems => {
    let sum = 0;
    tooltipItems.forEach(function (tooltipItem) {
      tooltipItem.dataset.data.forEach(item => {
        sum += item.y;
      });
    });
    if (isNaN(sum)) {
      return null;
    } else {
      return (
        'جمع کل : ' +
        (sum.toFixed(2) * 1).toLocaleString() +
        ' (%' +
        ((tooltipItems[0].raw.y / sum) * 100).toFixed(1) +
        ')'
      );
    }
  };
  const getScales = () => {
    if (props.chartQuery.cost) {
      options.scales.y = {
        title: {
          display: true,
          text: 'تومان'
        },
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          display: false
        }
      };
    } else {
      if (chartYScaleText.length > 1) {
        chartYScaleText.forEach((item, index) => {
          if (index > 0) {
            options.scales[`y${index}`] = {
              title: {
                display: true,
                text: chartYScaleText[index],
                color: '#121212'
              },
              type: 'linear',
              display: true,
              position: 'left',
              grid: {
                display: false
              }
            };
          }
        });
      }
    }
    if (chartData.length === 0) {
      options.scales['y'] = {
        display: false
      };
    }
    if (formulaData) {
      if (formulaData.length > 0) {
        options.scales.y999 = {
          title: {
            display: true,
            text: 'مقادیر فرمول'
          },
          type: 'linear',
          display: true,
          position: 'left',
          grid: {
            display: false
          }
        };
      }
    }
  };
  const getLabels = () => {
    let labels = [];
    if (
      props.chartQuery.kind === 'week' &&
      (props.chartType === 'Stacked' || props.chartType === 'Pie' || props.chartType === 'Doughnut')
    ) {
      return ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنجشنبه', 'جمعه'];
    }
    if (props.chartQuery.kind === 'year') {
      return ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];
    }
    if (chartData.length > 0) {
      chartData.forEach(navData => {
        navData.forEach(dataset => {
          dataset.AggregateData.forEach(element => {
            if (!labels.includes(element.dateOf)) {
              labels.push(element.dateOf);
            }
          });
        });
      });
    }
    if (chartDataCompare.length > 0) {
      chartData.forEach(navData => {
        navData.forEach(dataset => {
          dataset.AggregateData.forEach(element => {
            if (!labels.includes(element.dateOf)) {
              labels.push(element.dateOf);
            }
          });
        });
      });
    }
    if (formulaData) {
      if (formulaData.length > 0) {
        formulaData.forEach(formul => {
          formul.forEach(item => {
            if (!labels.includes(item.x)) {
              labels.push(item.x);
            }
          });
        });
      }
    }
    return labels.sort();
  };

  const getDataset = () => {
    let i = 0;
    let datasets = [];
    //add Dataset Temprature
    if (props.chartTempData.length > 0) {
      options.scales['y1T'] = {
        type: 'linear',
        title: {
          display: true,
          text: `(°C)`,
          color: '#121212'
        },
        position: 'right',
        grid: {
          display: false
        }
      };
      props.chartTempData.forEach((cities, index) => {
        datasets.push({
          type: 'line',
          label: `دما ${props.city[index].name}`,
          data: cities.map(el => {
            return el.avgTemp;
          }),
          backgroundColor: ChartTempColor[index],
          borderColor: ChartTempColor[index],
          yAxisID: 'y1T',
          plugins: false,
          id: 'temp'
        });
      });
    }
    // chart Dataset Cost
    if (props.chartQuery.cost) {
      chartData.forEach((navData, index) => {
        let cost = [];
        navData.forEach(dataset => {
          dataset.AggregateData.map(el => {
            var indexx = cost.findIndex(costEl => el.dateOf === costEl.x);
            indexx === -1 ? cost.push({ x: el.dateOf, y: el.cost }) : (cost[indexx].y += el.cost);
          });
        });
        datasets.push({
          label: `${props.chartData.nav[index].navName}`,
          data: cost,
          backgroundColor: ChartColor[i],
          borderColor: ChartBorderColor[i],
          yAxisID: 'y'
        });
        i++;
      });
      if (chartDataCompare.length > 0) {
        i = 0;
        chartDataCompare.forEach((navData, index) => {
          let cost = [];
          navData.forEach(dataset => {
            dataset.AggregateData.map(el => {
              var indexx = cost.findIndex(costEl => el.dateOf === costEl.x);
              indexx === -1 ? cost.push({ x: el.dateOf, y: el.cost }) : (cost[indexx].y += el.cost);
            });
          });
          datasets.push({
            label: `${props.chartData.nav[index].navName} (مقایسه)`,
            data: cost,
            backgroundColor: ChartColorCompare[i],
            borderColor: ChartBorderColor[i],
            yAxisID: 'y'
          });
          i++;
        });
      }
    }
    //chart Dataset Value
    else {
      chartData.forEach((navData, index) => {
        navData.forEach(dataset => {
          datasets.push({
            label: `${props.chartData.nav[index].navName} (${dataset._id})`,
            data: dataset.AggregateData.map(el => {
              return { x: el.dateOf, y: el.total };
            }),
            backgroundColor: ChartColor[i],
            borderColor: ChartBorderColor[i],
            yAxisID:
              chartYScaleText.indexOf(`${dataset._id} (${dataset.AggregateData[0].unit})`) === 0
                ? 'y'
                : 'y' + chartYScaleText.indexOf(`${dataset._id} (${dataset.AggregateData[0].unit})`)
          });
          i++;
        });
      });
      if (chartDataCompare.length > 0) {
        i = 0;
        chartDataCompare.forEach((navData, index) => {
          navData.forEach(dataset => {
            datasets.push({
              label: `${props.chartData.nav[index].navName} (${dataset._id})(مقایسه)`,
              data: dataset.AggregateData.map(el => {
                return { x: el.dateOf, y: el.total };
              }),
              backgroundColor: ChartColorCompare[i],
              borderColor: ChartBorderColor[i],
              yAxisID:
                chartYScaleText.indexOf(`${dataset._id} (${dataset.AggregateData[0].unit})`) === 0
                  ? 'y'
                  : 'y' + chartYScaleText.indexOf(`${dataset._id} (${dataset.AggregateData[0].unit})`)
            });
            i++;
          });
        });
      }
    }
    if (formulaData) {
      if (formulaData.length > 0) {
        formulaData.forEach((item, index) => {
          datasets.push({
            label: formula[index].name,
            data: item,
            backgroundColor: ChartColor[i],
            borderColor: ChartBorderColor[i],
            yAxisID: 'y999'
          });
          i++;
        });
      }
    }
    return datasets;
  };
  const getStackedDataset = () => {
    let i = 0;
    let j = 0;
    let datasets = [];
    let tempArrayLabel = getLabels();
    //add Dataset Temprature
    if (props.chartTempData.length > 0) {
      props.chartTempData.forEach((cities, index) => {
        datasets.push({
          type: 'line',
          label: `دما ${props.city[index].name}`,
          data: tempArrayLabel.map(item => {
            const ii = cities.findIndex(city => city._id === item);
            if (ii >= 0) {
              return cities[ii].avgTemp;
            } else return 0;
          }),
          backgroundColor: ChartTempColor[index],
          borderColor: ChartTempColor[index],
          yAxisID: 'y1T',
          plugins: false,
          id: 'temp'
        });
      });
    }
    // chart Dataset Cost
    if (props.chartQuery.cost) {
      chartData.forEach((navData, index) => {
        navData.forEach(dataset => {
          datasets.push({
            label: `${props.chartData.nav[index].navName} (${dataset._id})`,
            data: tempArrayLabel.map(item => {
              const ii = dataset.AggregateData.findIndex(dataItem => dataItem.dateOf === item);
              if (ii >= 0) {
                return dataset.AggregateData[ii].cost;
              } else return 0;
            }),
            backgroundColor: ChartColor[i],
            borderColor: ChartBorderColor[i]
          });
          i++;
        });
      });
      if (chartDataCompare.length > 0) {
        chartDataCompare.forEach((navData, index) => {
          navData.forEach(dataset => {
            datasets.push({
              label: `${props.chartData.nav[index].navName} (${dataset._id})`,
              data: tempArrayLabel.map(item => {
                const ii = dataset.AggregateData.findIndex(dataItem => dataItem.dateOf === item);
                if (ii >= 0) {
                  return dataset.AggregateData[ii].cost;
                } else return 0;
              }),
              backgroundColor: ChartColorCompare[j],
              borderColor: ChartBorderColor[j]
            });
            j++;
          });
        });
      }
    }
    //chart Dataset Value
    else {
      chartData.forEach((navData, index) => {
        navData.forEach(dataset => {
          datasets.push({
            label: `${props.chartData.nav[index].navName} (${dataset._id})`,
            data: tempArrayLabel.map(item => {
              const ii = dataset.AggregateData.findIndex(dataItem => dataItem.dateOf === item);
              if (ii !== -1) {
                return dataset.AggregateData[ii].total;
              } else return 0;
            }),
            backgroundColor: ChartColor[i],
            borderColor: ChartBorderColor[i]
          });
          i++;
        });
      });
      if (chartDataCompare.length > 0) {
        chartDataCompare.forEach((navData, index) => {
          navData.forEach(dataset => {
            datasets.push({
              label: `${props.chartData.nav[index].navName} (${dataset._id})(مقایسه)`,
              data: tempArrayLabel.map(item => {
                const ii = dataset.AggregateData.findIndex(dataItem => dataItem.dateOf === item);
                if (ii >= 0) {
                  return dataset.AggregateData[ii].total;
                } else return 0;
              }),
              backgroundColor: ChartColorCompare[j],
              borderColor: ChartBorderColor[j]
            });
            j++;
          });
        });
      }
    }
    return datasets;
  };
  const getPieDataset = () => {
    let datasets = [];
    //add Dataset Temprature
    //Its dosent mean

    // chart Dataset Cost
    if (props.chartQuery.cost) {
      let cost = [];
      chartData.forEach((navData, index) => {
        navData.forEach(dataset => {
          dataset.AggregateData.map(function(el){
            var indexx = cost.findIndex(costEl => el.dateOf === costEl.x);
            indexx === -1 ? cost.push({ x: el.dateOf, y: el.cost }) : (cost[indexx].y += el.cost);
          });
        });
      });
      datasets.push({
        label: `${props.chartData.nav[0].navName}`,
        data: cost.map(el => el.y),
        backgroundColor: ChartColor,
        borderColor: ChartBorderColor
      });
      if (chartDataCompare.length > 0) {
        cost = [];
        chartDataCompare.forEach((navData, index) => {
          navData.forEach(dataset => {
            dataset.AggregateData.map(el => {
              var indexx = cost.findIndex(costEl => el.dateOf === costEl.x);
              indexx === -1 ? cost.push({ x: el.dateOf, y: el.cost }) : (cost[indexx].y += el.cost);
            });
          });
        });
        datasets.push({
          label: `${props.chartData.nav[0].navName}`,
          data: cost.map(el => el.y),
          backgroundColor: ChartColor,
          borderColor: ChartBorderColor
        });
      }
    }
    //chart Dataset Value
    else {
      chartData.forEach((navData, index) => {
        navData.forEach(dataset => {
          datasets.push({
            label: `${props.chartData.nav[index].navName} (${dataset._id})`,
            data: dataset.AggregateData.map(el => {
              return el.total;
            }),
            backgroundColor: ChartColor,
            borderColor: ChartBorderColor
          });
        });
      });
      if (chartDataCompare.length > 0) {
        chartDataCompare.forEach((navData, index) => {
          navData.forEach(dataset => {
            datasets.push({
              label: `${props.chartData.nav[index].navName} (${dataset._id})(مقایسه)`,
              data: dataset.AggregateData.map(el => {
                return el.total;
              }),
              backgroundColor: ChartColorCompare,
              borderColor: ChartBorderColor
            });
          });
        });
      }
    }
    return datasets;
  };
  const getAnonations = () => {
    let anon = {
      annotations: {}
    };
    if (chartData.length === 1) {
      if (avgShow) {
        chartData[0].forEach((item, index) => {
          let lineNum = `line${100 - index}`;
          anon.annotations[lineNum] = {
            type: 'line',
            drawTime: 'afterDatasetsDraw',
            id: 'a-line-1',
            mode: 'horizontal',
            value: item.avgConsumption,
            scaleID:
              chartYScaleText.indexOf(`${item._id} (${item.AggregateData[0].unit})`) === 0
                ? 'y'
                : 'y' + chartYScaleText.indexOf(`${item._id} (${item.AggregateData[0].unit})`),
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
            borderDash: [5, 10],
            label: {
              content: 'میانگین ' + item._id + ' (' + item.avgConsumption.toFixed(2) + ')',
              enabled: true,
              font: { style: 'bold' },
              position: 'right'
            }
          };
        });
      }
      if (minShow) {
        chartData[0].forEach((item, index) => {
          let lineNum = `line${200 - index}`;
          anon.annotations[lineNum] = {
            type: 'line',
            drawTime: 'afterDatasetsDraw',
            id: 'a-line-1',
            mode: 'horizontal',
            value: item.minConsumption,
            scaleID:
              chartYScaleText.indexOf(`${item._id} (${item.AggregateData[0].unit})`) === 0
                ? 'y'
                : 'y' + chartYScaleText.indexOf(`${item._id} (${item.AggregateData[0].unit})`),
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
            borderDash: [5, 10],
            label: {
              content: 'مینیمم ' + item._id + ' (' + item.minConsumption.toFixed(2) + ')',
              enabled: true,
              font: { style: 'bold' },
              position: 'right'
            }
          };
        });
      }
      if (maxShow) {
        chartData[0].forEach((item, index) => {
          let lineNum = `line${300 - index}`;
          anon.annotations[lineNum] = {
            type: 'line',
            drawTime: 'afterDatasetsDraw',
            id: 'a-line-1',
            mode: 'horizontal',
            value: item.maxConsumption,
            scaleID:
              chartYScaleText.indexOf(`${item._id} (${item.AggregateData[0].unit})`) === 0
                ? 'y'
                : 'y' + chartYScaleText.indexOf(`${item._id} (${item.AggregateData[0].unit})`),
            borderColor: 'rgb(0, 0, 0)',
            borderWidth: 2,
            borderDash: [5, 10],
            label: {
              content: 'ماکسیمم ' + item._id + ' (' + item.maxConsumption.toFixed(2) + ')',
              enabled: true,
              font: { style: 'bold' },
              position: 'right'
            }
          };
        });
      }
    }
    allLineAnon.forEach((item, index) => {
      let lineNum = 'line' + index + 3;
      anon.annotations[lineNum] = {
        type: 'line',
        drawTime: 'afterDatasetsDraw',
        id: `a-line-${index + 3}`,
        mode: 'horizontal',
        value: item.val,
        scaleID: `y${item.axies !== '0' ? item.axies : ''}`,
        borderColor: 'rgb(0, 0, 0)',
        borderWidth: 2,
        borderDash: [5, 10],
        label: {
          content: item.title,
          enabled: true,
          font: { style: 'bold' },
          position: 'right'
        }
      };
    });
    options.plugins.annotation = anon;
  };

  const getChartTitle = () => {
    let chartTitle = [];
    let meterDetail = [];
    props.meter.forEach(async item => {
      if (item.navType === 'Meter') {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        meterDetail.push(Axios.get('/api/v1/Meter/' + item.navID, config));
      } else {
        chartTitle.push(item.navName);
      }
    });
    if (meterDetail.length > 0) {
      const metersName = Promise.all(meterDetail).then(metersName => {
        metersName.forEach(element => {
          chartTitle.push(`${element.data.data.data.name} (${element.data.data.data.building.name})`);
        });
        defaults.plugins.title.text = chartTitle.join(' - ');
        if (chartRef.current) {
          chartRef.current.update();
        }
      });
    } else {
      defaults.plugins.title.text = chartTitle.join(' - ');
    }
  };

  const options = {
    barPercentage: 0.3,
    borderRadius: 5,
    animation: {
      y: {
        easing: 'linear',
        duration: 2000
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: chartYScaleText[0]
        },
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          display: true
        }
      }
    },
    plugins: {
      annotation: {},
      title: {
        display: props.showTitle,
        margin: {
          top: 30
        },

        padding: {
          top: 10,
          bottom: 50
        },
        font: {
          weight: 'bold',
          size: 13
        },
        color: '#121212'
      },
      tooltip: {
        callbacks: {
          footer: footer
        }
      },
      legend: {
        display: props.showLegend,
        labels: {
          color: '#000'
        }
      }
    }
  };

  const data = {
    labels: getLabels(),
    datasets: getDataset()
  };
  getAnonations();
  getScales();
  getChartTitle();

  //
  let htmlChart = <div></div>;
  switch (props.chartType) {
    case 'Bar':
      htmlChart = (
        <Bar
          data={data}
          options={options}
          // height="100"
          redraw={true}
          ref={chartRef}
        />
      );
      break;
    case 'Stacked':
      let optionStacked = { ...options };
      optionStacked.animation = {
        y: {
          easing: 'easeOutQuint',
          duration: 3000
        }
      };
      optionStacked.scales = {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: `${chartYScaleText.join('  ')}`
          }
        }
      };
      if (props.chartTempData.length > 0) {
        optionStacked.scales['y1T'] = {
          type: 'linear',
          title: {
            display: true,
            text: `(°C)`
          },
          position: 'right',
          grid: {
            display: false
          }
        };
      }
      const stackedData = {
        labels: getLabels(),
        datasets: getStackedDataset()
      };
      htmlChart = (
        <Bar
          data={stackedData}
          options={optionStacked}
          // height="100"
          redraw={true}
          ref={chartRef}
        />
      );
      break;
    case 'Line':
      let optionLine = { ...options };
      htmlChart = (
        <Line
          data={data}
          options={optionLine}
          // height="100"
          redraw={true}
          ref={chartRef}
        />
      );
      break;
    case 'Area':
      let dataArea = { ...data };
      dataArea.datasets.forEach((el, index) => {
        dataArea.datasets[index]['fill'] = true;
      });
      htmlChart = (
        <Line
          data={dataArea}
          options={options}
          // height="100"
          redraw={true}
          ref={chartRef}
        />
      );
      break;
    case 'Pie':
      const pieData = {
        labels: getLabels(),
        datasets: getPieDataset()
      };
      let optionPie = { ...options };
      optionPie.scales = {};
      optionPie.plugins.tooltip = {
        callbacks: {
          label: function (item) {
            return `${item.formattedValue} - ${item.dataset.label}`;
          }
        }
      };

      htmlChart = (
        <Pie
          data={pieData}
          options={optionPie}
          // height="10"
          redraw={true}
          ref={chartRef}
        />
      );
      break;
    case 'Doughnut':
      const doughnutData = {
        labels: getLabels(),
        datasets: getPieDataset()
      };
      let optionDoughnut = { ...options };
      optionDoughnut.scales = {};
      optionDoughnut.plugins.tooltip = {
        callbacks: {
          label: function (item) {
            return `${item.formattedValue} - ${item.dataset.label}`;
          }
        }
      };
      htmlChart = (
        <Doughnut
          data={doughnutData}
          options={optionDoughnut}
          // height="10"
          redraw={true}
          ref={chartRef}
        />
      );
      break;
  }
  let avgContextMenu = '';
  if (chartData.length === 1) {
    avgContextMenu = avgShow ? (
      <MenuItem
        className={'contextItem'}
        onClick={() => {
          setAvgShow(false);
        }}
        key={5}
      >
        عدم نمایش میانگین
      </MenuItem>
    ) : (
      <MenuItem
        className={'contextItem'}
        onClick={() => {
          setAvgShow(true);
        }}
        key={6}
      >
        نمایش میانگین
      </MenuItem>
    );
  }
  let maxContextMenu = '';
  if (chartData.length === 1) {
    maxContextMenu = maxShow ? (
      <MenuItem
        className={'contextItem'}
        onClick={() => {
          setMaxShow(false);
        }}
        key={15}
      >
        عدم نمایش ماکسیمم
      </MenuItem>
    ) : (
      <MenuItem
        className={'contextItem'}
        onClick={() => {
          setMaxShow(true);
        }}
        key={16}
      >
        نمایش ماکسیمم
      </MenuItem>
    );
  }
  let minContextMenu = '';
  if (chartData.length === 1) {
    minContextMenu = minShow ? (
      <MenuItem
        className={'contextItem'}
        onClick={() => {
          setMinShow(false);
        }}
        key={25}
      >
        عدم نمایش مینیمم
      </MenuItem>
    ) : (
      <MenuItem
        className={'contextItem'}
        onClick={() => {
          setMinShow(true);
        }}
        key={26}
      >
        نمایش مینیمم
      </MenuItem>
    );
  }
  const showTitleHTML = props.showTitle ? (
    <MenuItem className={'contextItem'} onClick={toggleShowTitle} key={8}>
      عدم نمایش عنوان گزارش
    </MenuItem>
  ) : (
    <MenuItem className={'contextItem'} onClick={toggleShowTitle} key={7}>
      نمایش عنوان گزارش
    </MenuItem>
  );

  return (
    <div ref={ref} className={`printChart ${topMarginChart}`}>
      <Fragment>
        <ContextMenuTrigger holdToDisplay={1}>
          <span className="ChartMoreOption">
            <i className="fa fa-grip-lines"></i>
          </span>
        </ContextMenuTrigger>

        <ContextMenuTrigger>{htmlChart}</ContextMenuTrigger>
        <ContextMenu className={'contextNav'}>
          {showTitleHTML}
          <MenuItem className={'contextItem'} onClick={handleShow} key={1}>
            افزودن معیار مشخص
          </MenuItem>
          <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>مشخصات معیار مشخص</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label className={'col-sm-3 col-form-label'}>ارتفاع معیار:</label>
              <Form.Control
                className={'col-sm-9'}
                onBlur={e => {
                  setLineVal(e.target.value);
                }}
                placeholder="مقدار ارتفاع معیار"
              />
              <label className={'col-sm-3 col-form-label'}>نوع کنتور:</label>
              <Form.Control className={'col-sm-9'} as="select" onChange={e => setLineAxies(e.target.value)}>
                <option value="">نوع کنتور را انتخاب کنید</option>
                {chartYScaleText.map((item, index) => {
                  return <option value={index}>{item}</option>;
                })}
              </Form.Control>
              <label className={'col-sm-3 col-form-label'}>عنوان معیار:</label>
              <Form.Control
                className={'col-sm-9'}
                onBlur={e => {
                  setLineTitle(e.target.value);
                }}
                placeholder="عنوان معیار مشخص"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                انصراف
              </Button>
              <Button variant="primary" onClick={handleAdd}>
                افزودن
              </Button>
            </Modal.Footer>
          </Modal>
          <MenuItem
            className={'contextItem'}
            onClick={() => {
              setAllLineAnon([]);
            }}
          >
            حذف معیارها
          </MenuItem>
          {avgContextMenu}
          {minContextMenu}
          {maxContextMenu}

          <MenuItem className={'contextItem'} onClick={downloadPNG} key={3}>
            دانلود تصویر
          </MenuItem>
        </ContextMenu>
      </Fragment>
    </div>
  );
});

export default Charts;

import React, { useState } from 'react';
import './MVProjects.css';
import AllProject from './AllProject/AllProject';
import ProjectDetail from './ProjectDetail/ProjectDetail';


const MVProjects = () => {
  const [MVProjectID, setMVProjectID] = useState({ _id: '' });
  return (
    <div className="h100">
      {MVProjectID._id === '' ? (
        <AllProject setMVProjectID={setMVProjectID} />
      ) : (
        <ProjectDetail MVProjectID={MVProjectID} setMVProjectID={setMVProjectID} />
      )}
    </div>
  );
};

export default MVProjects;

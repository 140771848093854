import React, { useEffect, useState } from 'react';

import Users from './Users/Users';

import { TopMenu } from '../../../components/UI/TopMenu/TopMenu';
import Header from '../../../components/UI/Header/Header';
import { Toast } from '../../../components/UI/Toast/Toast';

const AdminPanel = () => {
  const [needGetUsers, updateNeedGetUsers] = useState(true);

  // Render Page
  return (
    <div className="content-body">
      <div className="row" id="table-hover-row">
        <div className="col-12">
          <Users
            needGetUsers={needGetUsers}
            updateNeedGetUsers={updateNeedGetUsers}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

import React from 'react';
import './Print.css';
const Footer = () => {
  return (
    <div class="PrintFooter">
      <img src="../../../ReportTypes/Kavirfooter.jpg" />
    </div>
  );
};
export default Footer;

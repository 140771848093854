import React, { useEffect, useState } from 'react';
import Axios from '../../../Axios/Axios';
import { Row, Button, Col } from 'react-bootstrap';
import swal from 'sweetalert';

const FormulaItem = props => {
  const [formula, SetFormula] = useState([]);

  const DeleteItem = () => {
    swal({
      title: ` ایا می خواهید فرمول "` + formula.name + `" را حذف نمایید   `,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        Axios.delete('/api/v1/util/formula/' + props.ItemID, config).then(res => {
          props.setIsRenderNavbar(true);
          props.SetShowPage('d-none');
          swal('فرمول مورد نظر حذف شد.', {
            icon: 'success'
          });
        });
      }
    });
  };
  const getTranslateFormula = (formul, formulSynon) => {
    let html = formul;
    formulSynon.forEach((el, index) => {
      let temphtml = el.label;
      html = html.replace(`Synonym${index}`, temphtml);
    });
    return html;
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {
    if (formula._id) {
      if (formula._id !== props.ItemID) {
        Axios.get('/api/v1/util/formula/' + props.ItemID, config).then(formula => {
          SetFormula(formula.data.data.data);
        });
      }
    } else {
      Axios.get('/api/v1/util/formula/' + props.ItemID, config).then(formula => {
        SetFormula(formula.data.data.data);
      });
    }
  });
  return (
    <div className={props.SetShowPage}>
      <div className="Actionbar">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div>
              {/*Start  Delete Organization */}
              <Button variant="btn btn-danger float-right mr-2" onClick={DeleteItem}>
                <i class="feather icon-trash"></i>
              </Button>
              {/*End  Delete Organization */}
            </div>
          </div>
        </div>
      </div>
      <div className="Orginfo">
        <Row>
          <Col xs={12} md={12}>
            <h3> اطلاعات فرمول</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <div className="grayLbl mb-1">
              <i class="fa fa-building"></i>
              <span className="lblBold"> نام :</span>
              <span className="lblNormal">{formula.name}</span>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="grayLbl mb-1">
              <i class="fa fa-building"></i>
              <span className="lblBold"> نوع :</span>
              <span className="lblNormal">{formula.type}</span>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="grayLbl mb-1">
              <i class="fa fa-building"></i>
              <span className="lblBold"> محور (y) :</span>
              <span className="lblNormal">
                {formula.yAxieFormula ? getTranslateFormula(formula.yAxieFormula, formula.yAxieFormulaSynon) : ''}
              </span>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="grayLbl mb-1">
              <i class="fa fa-building"></i>
              <span className="lblBold"> محور (x) :</span>
              <span className="lblNormal">
                {formula.xAxieFormula ? getTranslateFormula(formula.xAxieFormula, formula.xAxieFormulaSynon) : ''}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FormulaItem;

import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

const ChartMonthDaily = props => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  // console.log(props.dataset.cityTemp[0])
  // console.log(props.dataset)
  defaults.normalized = true;
  defaults.font.size = 11;
  defaults.font.family = 'Iranian Sans';
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  const option = {
    plugins: {
      legend: {
        position: 'top',
        display: true
      },
      title: {
        display: true,
        text: 'نمودار روند ماهیانه',
        padding: {
          bottom: 40
        },
        font: {
          size: 18
        }
      }
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        title: {
          display: true,
          text: props.dataset.currentMonthAllDay[0].unit
        },
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    maintainAspectRatio: true,
    responsive: true
  };

  const labelChart=props.dataset.currentMonthAllDay.map(el => {
    return el.dateOf;
  })
  const data = {
    datasets: [
      {
        backgroundColor: '#fbad1e',
        borderColor: '#fbad1e',
        order: 1,
        data: props.dataset.currentMonthAllDay.map(el => {
          return { x: el.day, y: el.total };
        }),
        label: 'مصرف',
      },
      {
        type: 'line',
        label: 'دمای هوا',
        tension: 0,
        backgroundColor: '#9a64a1',
        borderColor: '#9a64a1',
        order: 0,
        data: props.dataset.cityTemp[0].map(el => {
          // console.log(el.avgTemprature);
          let date=el.shamsiDate.substring(8,10);
          // console.log(date)
          return { x: date, y: el.avgTemprature };
        })
      }
    ]
  };

  return <Bar data={data} options={option} redraw={true}/>;
};
export default ChartMonthDaily;

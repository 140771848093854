import axios from 'axios';
import { toast } from 'react-toastify';

const Axios = axios.create({
  // .. where we make our configurations
 // baseURL: 'http://localhost:8000'
});
Axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem('user');
      localStorage.removeItem('jwt');
      window.location = '/login';
    } else {
      return Promise.reject(error);
    }
  }
);

export default Axios;

import React, { useState, useEffect, Fragment, useRef } from 'react';
import './FormulaBuilder.css';
import FormulaControlPanel from './FormulaControlPanel/FormulaControlPanel';
import FormulaChart from './FormulaChart/FormulaChart';
import RegressionChart from './RegressionChart/RegressionChart';
import RegressionControlPanel from './RegressionControlPanel/RegressionControlPanel';
import RightNav from '../../components/UI/RightNav/RightNav';

import Axios from '../../Axios/Axios';
import { toast } from 'react-toastify';
import Axie from '../../components/UI/Axie/Axie';

import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal
} from 'react-bootstrap';

const FormulaBuilder = () => {
  const [chartData, setChartData] = useState([]);
  //UI State
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [showCanvasLeft, setShowCanvasLeft] = useState(true);
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);

  const [allYear, setAllYear] = useState([]);
  const [chartQuery, setChartQuery] = useState({ kind: 'multiyear' });
  const [needLoad, setNeedLoad] = useState(false);
  const [chartType, setChartType] = useState('Bar');
  const [loading, setLoading] = useState(false);
  const [needTempData, setNeedTempData] = useState(0);
  const [formulaName, setFormulaName] = useState('فرمول من');
  const [saveFormulaModal, setSaveFormulaModal] = useState(false);

  const [regressionOrder, setRegressionOrder] = useState('1');

  const [needGetData, setNeedGetData] = useState(true);
  const [needGetYear, setNeedGetYear] = useState(false);
  const [allDataNode, setAllDataNode] = useState([]);
  const [allOrganization, setAllOrganization] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [allBuilding, setAllBuilding] = useState([]);
  const [building, setBuilding] = useState(null);

  const [yAxieFormulaSynon, setYAxieFormulaSynon] = useState([]);
  const [yAxieFormula, setYAxieFormula] = useState(null);
  const [yAxieFormulaText, setYAxieFormulaText] = useState(null);
  const [validYAxie, setValidYAxie] = useState(true);

  const [xAxieFormulaSynon, setXAxieFormulaSynon] = useState([]);
  const [xAxieFormula, setXAxieFormula] = useState(null);
  const [xAxieFormulaText, setXAxieFormulaText] = useState(null);
  const [validXAxie, setValidXAxie] = useState(true);

  useEffect(() => {
    if (needGetData) {
      setNeedGetData(false);
      getAllDataNode();
    }
    if (needGetYear) {
      getAllYear();
    }
    if (needLoad) {
      getChartData();
      setNeedLoad(false);
    }
  });

  const handleCallbackRightNav = async selectedRow => {};
  const saveFormula = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    hideSaveFormulaModal();
    let formulaType = 'simple';
    if (xAxieFormulaSynon.length > 0) {
      formulaType = 'regression';
    }
    let bodyObj = {
      xAxieFormulaSynon,
      xAxieFormula: xAxieFormulaText,
      yAxieFormulaSynon,
      yAxieFormula: yAxieFormulaText,
      name: formulaName,
      building: building._id,
      type: formulaType
    };
    const body = JSON.stringify(bodyObj);
    Axios.post('/api/v1/util/formula/', body, config).then(res =>
      toast.success('فرمول شما ایجاد شد.')
    );
  };

  const hideSaveFormulaModal = () => {
    setSaveFormulaModal(false);
  };
  const changeOrganization = e => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    let org = null;
    allOrganization.forEach(element => {
      if (element._id === e.target.value) {
        org = element;
      }
    });
    setOrganization(org);

    Axios.get(`/api/v1/Building?organization=${org._id}`, config).then(
      building => {
        setAllBuilding(building.data.data.data);
      }
    );
  };
  const changeBuilding = e => {
    let build = null;
    allBuilding.forEach(element => {
      if (element._id === e.target.value) {
        build = element;
      }
    });
    setBuilding(build);
  };
  const getAllYear = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const body = JSON.stringify({
      allSynon: yAxieFormulaSynon.concat(xAxieFormulaSynon)
    });
    const years = await Axios.post('/api/v1/util/formula/years', body, config);
    setAllYear(years.data.data.data);
  };
  const getAllDataNode = async () => {
    let temp = [...allDataNode];
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const org = await Axios.get('/api/v1/organization', config);
    let orgData = org.data.data.data;

    // formula save option Begin
    setAllOrganization(orgData);
    setOrganization(orgData[0]);
    const building = await Axios.get(
      `/api/v1/Building?organization=${orgData[0]._id}`,
      config
    );
    setAllBuilding(building.data.data.data);
    // formula save option end

    orgData.forEach((item, index) => {
      temp.push({
        _id: item._id,
        name: item.name + '(برق)',
        label: item.name,
        abbr: 'org' + (index * 1 + 1) + ' (Electricy)',
        navType: 'organization',
        type: 'برق'
      });
      temp.push({
        _id: item._id,
        name: item.name + '(آب)',
        label: item.name,
        abbr: 'org' + (index * 1 + 1) + ' (Water)',
        navType: 'organization',
        type: 'آب'
      });
      temp.push({
        _id: item._id,
        name: item.name + '(گاز)',
        label: item.name,
        abbr: 'org' + (index * 1 + 1) + ' (Gas)',
        navType: 'organization',
        type: 'گاز'
      });
    });
    const buildings = await Axios.get('/api/v1/building', config);
    let buildingsData = buildings.data.data.data;
    buildingsData.forEach((item, index) => {
      const orgName = orgData.find(el => {
        return el._id === item.organization;
      });
      temp.push({
        _id: item._id,
        name: orgName.name + ' > ' + item.name + '(برق)',
        label: item.name,
        abbr: 'building' + (index * 1 + 1) + ' (Electricy)',
        navType: 'building',
        type: 'برق'
      });
      temp.push({
        _id: item._id,
        name: orgName.name + ' > ' + item.name + '(آب)',
        label: item.name,
        abbr: 'building' + (index * 1 + 1) + ' (Water)',
        navType: 'building',
        type: 'آب'
      });
      temp.push({
        _id: item._id,
        name: orgName.name + ' > ' + item.name + '(گاز)',
        label: item.name,
        abbr: 'building' + (index * 1 + 1) + ' (Gas)',
        navType: 'building',
        type: 'گاز'
      });
    });
    const meters = await Axios.get('/api/v1/meter', config);
    meters.data.data.data.forEach((item, index) => {
      temp.push({
        _id: item._id,
        name:
          item.organization.name +
          ' > ' +
          item.building.name +
          ' > ' +
          item.name,
        label: item.name,
        abbr: 'meter' + (index * 1 + 1),
        navType: 'meter',
        type: item.type
      });
    });
    setAllDataNode(temp);
  };
  const getChartData = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    let bodyObj = {
      ...chartQuery,
      xAxieFormulaSynon,
      xAxieFormula: xAxieFormulaText,
      yAxieFormulaSynon,
      yAxieFormula: yAxieFormulaText,
      regressionOrder: regressionOrder
    };
    const body = JSON.stringify(bodyObj);
    const calcs = await Axios.post('/api/v1/util/formula/calc', body, config);
    setChartData(calcs.data.data.data);
    setLoading(false);
  };

  return (
    <Container fluid id="MainContent" className={mainPad}>
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              className="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3'
                ? setMainPad('pr-0 pl-2')
                : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <RightNav
              setIsRenderNavbar={setIsRenderNavbar}
              isRenderNavbar={isRenderNavbar}
              parentCallback={handleCallbackRightNav}
              type={'no-select'}
            />
          </div>
        </Col>
        <Col
          className="mainContent"
        >
          <div className="pageBack">
            <div
              className="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3'
                  ? setMainPad('pr-0 pl-0')
                  : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>

            <div
              hidden={showCanvasLeft}
              className="CanvasIcon CanvasLeft"
              onClick={() => {
                setCanvasWidthLeft(3);
                setShowCanvasLeft(true);

                canvasWidthRight == 3
                  ? setMainPad('pl-0 pr-0')
                  : setMainPad('pl-2 pr-0');
              }}
            >
              <i className="fa fa-caret-right  "></i>
            </div>

            <Container>
              <form className={'dpLtr'}>
                <Row>
                  <Col className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text dpInputGroupText">
                        Y-Axies
                      </div>
                    </div>
                    <Axie
                      allDataNode={allDataNode}
                      axieFormulaSynon={yAxieFormulaSynon}
                      setAxieFormulaSynon={setYAxieFormulaSynon}
                      axieFormula={yAxieFormula}
                      setAxieFormula={setYAxieFormula}
                      setNeedGetYear={setNeedGetYear}
                      placehodler={
                        "برای درج فرمول از کلیدهای '@' ، '$' ، '#' استفاده نمایید"
                      }
                      validAxie={validYAxie}
                      setValidAxie={setValidYAxie}
                      setAxieFormulaText={setYAxieFormulaText}
                      setChartData={setChartData}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text dpInputGroupText">
                        X-Axies
                      </div>
                    </div>
                    <Axie
                      allDataNode={allDataNode}
                      axieFormulaSynon={xAxieFormulaSynon}
                      setAxieFormulaSynon={setXAxieFormulaSynon}
                      axieFormula={xAxieFormula}
                      setAxieFormula={setXAxieFormula}
                      setNeedGetYear={setNeedGetYear}
                      placehodler={'مقدار پیش فرض این محور زمان میباشد'}
                      validAxie={validXAxie}
                      setValidAxie={setValidXAxie}
                      setAxieFormulaText={setXAxieFormulaText}
                      setChartData={setChartData}
                    />
                  </Col>
                  <Col
                    hidden={
                      (xAxieFormulaSynon.length === 0 &&
                        yAxieFormulaSynon.length === 0) ||
                      !(validYAxie && validXAxie)
                    }
                    md={2}
                  >
                    <Button
                      onClick={() => {
                        setSaveFormulaModal(true);
                      }}
                    >
                      ذخیره
                    </Button>
                    <Modal
                      show={saveFormulaModal}
                      onHide={hideSaveFormulaModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>ذخیره فرمول</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Container>
                          <form>
                            <div className="form-group row">
                              <label className={'col-sm-4 col-form-label'}>
                                نام فرمول
                              </label>
                              <input
                                className={'form-control col-sm-8'}
                                type="text"
                                value={formulaName}
                                onChange={e => {
                                  setFormulaName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="form-group row">
                              <label className={'col-sm-4 col-form-label'}>
                                سازمان
                              </label>
                              <select
                                className={'form-control col-sm-8'}
                                value={organization ? organization._id : null}
                                onChange={changeOrganization}
                              >
                                {allOrganization.map((el, index) => {
                                  return (
                                    <option key={index} value={el._id}>
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group row">
                              <label className={'col-sm-4 col-form-label'}>
                                سایت
                              </label>
                              <select
                                className={'form-control col-sm-8'}
                                value={building ? building._id : null}
                                onChange={changeBuilding}
                              >
                                <option></option>
                                {allBuilding.map((el, index) => {
                                  return (
                                    <option key={index} value={el._id}>
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </form>
                        </Container>
                      </Modal.Body>
                      <Modal.Header>
                        <Button onClick={saveFormula}>ذخیره</Button>
                      </Modal.Header>
                    </Modal>
                  </Col>
                </Row>
              </form>
            </Container>

            <Container>
              <Row
                hidden={
                  yAxieFormulaSynon.length === 0 || xAxieFormulaSynon.length > 0
                }
              >
                <Col>
                  <center hidden={!loading}>
                    
                  <Spinner
                    animation="grow"
                    variant="success"
                    className={'spinnerChart'}
                  />
                  </center>
                  <div hidden={loading}>
                    <FormulaChart
                      hidden={loading}
                      chartType={chartType}
                      data={chartData}
                      label={'فرمول من'}
                    />
                  </div>
                </Col>
              </Row>
              <Row hidden={xAxieFormulaSynon.length === 0}>
                <Col>
                  <center hidden={!loading}>
                     <Spinner
                    animation="grow"
                    variant="success"
                    className={'spinnerChart'}
                  />
                  </center>
                  <div hidden={loading}>
                    <RegressionChart
                      chartType={chartType}
                      data={chartData}
                      label={formulaName}
                      chartQuery={chartQuery}
                      regressionOrder={regressionOrder}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Col
          lg={canvasWidthLeft}
          md={canvasWidthLeft}
          xs={canvasWidthLeft}
          sm={canvasWidthLeft}
          hidden={canvasWidthLeft === 0}
          className="pl-0 LeftPanel"
        >
          <div className="pageBack ">
            <div
              className="ToggleNavIcon ToggleNavLeft"
              onClick={() => {
                setCanvasWidthLeft(0);
                setShowCanvasLeft(false);

                canvasWidthRight === 3
                ? setMainPad('pr-2 pl-0')
                : setMainPad('pr-2 pl-2');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <div hidden={xAxieFormulaSynon.length === 0}>
              <RegressionControlPanel
                allYear={allYear}
                setChartQuery={setChartQuery}
                setNeedLoad={setNeedLoad}
                setChartType={setChartType}
                chartType={chartType}
                setLoading={setLoading}
                setNeedGetYear={setNeedGetYear}
                needGetYear={needGetYear}
                setNeedTempData={setNeedTempData}
                getChartData={getChartData}
                regressionOrder={regressionOrder}
                setRegressionOrder={setRegressionOrder}
                validFormula={
                  validYAxie && validXAxie && yAxieFormulaSynon.length > 0
                }
              />
            </div>
            <div hidden={xAxieFormulaSynon.length > 0}>
              <FormulaControlPanel
                allYear={allYear}
                setChartQuery={setChartQuery}
                setNeedLoad={setNeedLoad}
                setChartType={setChartType}
                chartType={chartType}
                setLoading={setLoading}
                setNeedGetYear={setNeedGetYear}
                needGetYear={needGetYear}
                setNeedTempData={setNeedTempData}
                getChartData={getChartData}
                validFormula={
                  validYAxie && validXAxie && yAxieFormulaSynon.length > 0
                }
              />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FormulaBuilder;

import React, { useState, useEffect } from 'react';
import ReviewBuilding from './ReviewBuilding';
import ReviewEquipment from './ReviewEquipment';
import ReviewTypes from './ReviewTypes';

const Review = props => {
  const [numberOfBuilding, setNumberOfBuilding] = useState(0);
  const [showBuildingType, setShowBuildingType] = useState(false);
  const [showEquipmentType, setShowEquipmentType] = useState(false);
  const [showAllTypes, setShowAllTypes] = useState(false);
  const [buildingNav, setBuildingNav] = useState([]);
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };


  return (
    <div className="h100">
      {showBuildingType ? (
        <ReviewBuilding setShowAllTypes={setShowAllTypes} setShowBuildingType={setShowBuildingType} 
        setBuildingNav={buildingNav} 
        />
      ) : (
        ''
      )}
      {showEquipmentType ? (
        <ReviewEquipment setShowEquipmentType={setShowEquipmentType} setShowAllTypes={setShowAllTypes} setBuildingNav={buildingNav}/>
      ) : (
        ''
      )}

      {numberOfBuilding === 0 || showAllTypes ? (
        <ReviewTypes setShowBuildingType={setShowBuildingType} setShowEquipmentType={setShowEquipmentType} setBuildingNav={setBuildingNav} />
      ) : (
        ''
      )}
    </div>
  );
};
export default Review;

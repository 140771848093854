import React, { Fragment, useEffect, useState } from 'react';

import Axios from '../../../../Axios/Axios';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Row, Container, Col, Table, Spinner,Span } from 'react-bootstrap';
import moment from 'jalali-moment';
import { linearRegression } from '../../../../components/Regression/js/linear/linearRegression';
import { addConstant } from '../../../../components/Regression/js/tools/tools';
import regression from 'regression-extend';

import BaselinePeriodChart from '../BaselinePeriod/BaselinePeriodChart/BaselinePeriodChart';
import ReportingPeriodChart from '../ReportingPeriod/ReportingPeriodChart/ReportingPeriodChart';
import RoutineAdjustmentChart from '../RoutineAdjustment/RoutineAdjustmentChart/RoutineAdjustmentChart';
import ProjectModelChart from '../ProjectModel/ProjectModelChart/ProjectModelChart';
import SavingChart from '../Saving/SavingChart/SavingChart';

import './PrintView.css'
import Header from '../../../Report/Print/Header';
import Footer from '../../../Report/Print/Footer';

const PrintView = props => {
  const [isLoad, SetIsLoad] = useState(false);
  const [baselineChartData, setBaselineChartData] = useState([]);
  const [reportingChartData, setReportingChartData] = useState([]);
  const [allRAChartData, setAllRAChartData] = useState([]);
  const [allRAChartDays, setAllRAChartDays] = useState([]);
  const [chartRAAnonationDay, setChartRAAnonationDay] = useState(null);
  const [previewRegression, setPreviewRegression] = useState(null);
  const [savingChartData, setSavingChartData] = useState(null);
  const [savingConsumptionData, setSavingConsumptionData] = useState(0);
  const [savingEstimateData, setSavingEstimateData] = useState(0);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const MVProject = props.MVProject;
  let projectDescription = convertFromRaw(JSON.parse(MVProject.description));

  // Saving Calc Params
  const calcActual = ((savingEstimateData / MVProject.modelData.length) * 365).toFixed();
  const calcPredicted = ((savingConsumptionData / MVProject.modelData.length) * 365).toFixed();
  const calcSaving = (((savingEstimateData - savingConsumptionData) / MVProject.modelData.length) * 365).toFixed();
  const payBack = (MVProject.cost / (calcSaving * MVProject.unitCost)).toFixed(2);

  const getRAChartData = async RA => {
    const chartDays = [];
    const chartLabels = [];
    let fromDateBaseline = moment(MVProject.baselinePeriodFrom).locale('fa');
    const toDateBaseline = moment(MVProject.baselinePeriodTo).locale('fa');
    const diffDaysBaseline = toDateBaseline.diff(fromDateBaseline, 'day');
    for (let index = 0; index <= diffDaysBaseline; index++) {
      chartDays.push(fromDateBaseline.format('YYYY-MM-DD'));
      fromDateBaseline = fromDateBaseline.add(1, 'day');
    }

    setChartRAAnonationDay({
      from: moment(MVProject.baselinePeriodTo)
        .add(1, 'day')
        .locale('fa')
        .format('YYYY-MM-DD'),
      to: moment(MVProject.reportingPeriodFrom)
        .add(-1, 'day')
        .locale('fa')
        .format('YYYY-MM-DD')
    });

    let fromDateReporting = moment(MVProject.reportingPeriodFrom).locale('fa');
    let toDateReporting = moment(MVProject.reportingPeriodTo).locale('fa');
    const diffDaysReporting = toDateReporting.diff(fromDateReporting, 'day');
    for (let index = 0; index <= diffDaysReporting; index++) {
      chartDays.push(fromDateReporting.format('YYYY-MM-DD'));
      fromDateReporting = fromDateReporting.add(1, 'day');
    }

    fromDateBaseline = moment(MVProject.baselinePeriodFrom).locale('fa');
    toDateReporting = moment(MVProject.reportingPeriodTo).locale('fa');
    const diffDaysLabels = toDateReporting.diff(fromDateBaseline, 'day');
    for (let index = 0; index <= diffDaysLabels; index++) {
      chartLabels.push(fromDateBaseline.format('YYYY-MM-DD'));
      fromDateBaseline = fromDateBaseline.add(1, 'day');
    }

    const body = {
      kind: 'multiDay',
      days: chartDays,
      nav: RA.map(el => {
        return { navID: el._id, navType: 'Meter', navName: el.name };
      })
    };
    const chartData = (await Axios.post(`/api/v1/consumption/gstats`, body, config)).data.data.data;
    setAllRAChartDays(chartLabels);
    setAllRAChartData(
      chartData.map(el => {
        if (el.length === 0) {
          return null;
        } else return el[0].AggregateData;
      })
    );
  };
  const generateModel = async () => {
    const regressionOrder = MVProject.regressionOrder;
    //baseline period data
    let bodyObj = {
      fromDate: moment(MVProject.baselinePeriodFrom)
        .locale('fa')
        .format('YYYY-MM-DD'),
      toDate: moment(MVProject.baselinePeriodTo)
        .locale('fa')
        .format('YYYY-MM-DD'),
      kind: 'range',
      paramCount: MVProject.routineAdjustmentsInModel.length.toString(),
      x1AxieFormula: 'Synonym0',
      x1AxieFormulaSynon: [
        {
          id: MVProject.routineAdjustmentsInModel[0]._id,
          kind: MVProject.routineAdjustmentsInModel[0].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ],
      yAxieFormula: 'Synonym0',
      yAxieFormulaSynon: [
        {
          id: MVProject.meter._id,
          kind: MVProject.meter.unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ]
    };
    if (MVProject.routineAdjustmentsInModel.length > 1) {
      bodyObj['x2AxieFormula'] = 'Synonym0';
      bodyObj['x2AxieFormulaSynon'] = [
        {
          id: MVProject.routineAdjustmentsInModel[1]._id,
          kind: MVProject.routineAdjustmentsInModel[1].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    if (MVProject.routineAdjustmentsInModel.length > 2) {
      bodyObj['x3AxieFormula'] = 'Synonym0';
      bodyObj['x3AxieFormulaSynon'] = [
        {
          id: MVProject.routineAdjustmentsInModel[2]._id,
          kind: MVProject.routineAdjustmentsInModel[2].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    if (MVProject.routineAdjustmentsInModel.length > 3) {
      bodyObj['x4AxieFormula'] = 'Synonym0';
      bodyObj['x4AxieFormulaSynon'] = [
        {
          id: MVProject.routineAdjustmentsInModel[3]._id,
          kind: MVProject.routineAdjustmentsInModel[3].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    if (MVProject.routineAdjustmentsInModel.length > 4) {
      bodyObj['x5AxieFormula'] = 'Synonym0';
      bodyObj['x5AxieFormulaSynon'] = [
        {
          id: MVProject.routineAdjustmentsInModel[4]._id,
          kind: MVProject.routineAdjustmentsInModel[4].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    let body = JSON.stringify(bodyObj);
    const modelBaseData = (await Axios.post('/api/v1/util/formula/trend', body, config)).data.data;

    //reporting period data
    bodyObj['fromDate'] = moment(MVProject.reportingPeriodFrom)
      .locale('fa')
      .format('YYYY-MM-DD');
    bodyObj['toDate'] = moment(MVProject.reportingPeriodTo)
      .locale('fa')
      .format('YYYY-MM-DD');

    body = JSON.stringify(bodyObj);
    const modelReportData = (await Axios.post('/api/v1/util/formula/trend', body, config)).data.data;
    //check meters have data
    if (modelBaseData.data.yAxie.length === 0 || modelReportData.data.yAxie.length === 0) {
      return 0;
    }
    //calculate regression formula
    // //Prepear data
    const yData = [];
    let xData = [];
    const regressionParam = MVProject.routineAdjustmentsInModel.length.toString();
    const days = modelBaseData.days;
    days.forEach((item, index) => {
      yData.push(modelBaseData.data.yAxie[index].y);
      if (regressionParam === '1') {
        if (regressionOrder === '1') {
          xData.push([modelBaseData.data.x1Axie[index].y, 0]);
        } else {
          xData.push([modelBaseData.data.x1Axie[index].y]);
        }
      } else if (regressionParam === '2') {
        xData.push([modelBaseData.data.x1Axie[index].y, modelBaseData.data.x2Axie[index].y]);
      } else if (regressionParam === '3') {
        xData.push([
          modelBaseData.data.x1Axie[index].y,
          modelBaseData.data.x2Axie[index].y,
          modelBaseData.data.x3Axie[index].y
        ]);
      } else if (regressionParam === '4') {
        xData.push([
          modelBaseData.data.x1Axie[index].y,
          modelBaseData.data.x2Axie[index].y,
          modelBaseData.data.x3Axie[index].y,
          modelBaseData.data.x4Axie[index].y
        ]);
      } else if (regressionParam === '5') {
        xData.push([
          modelBaseData.data.x1Axie[index].y,
          modelBaseData.data.x2Axie[index].y,
          modelBaseData.data.x3Axie[index].y,
          modelBaseData.data.x4Axie[index].y,
          modelBaseData.data.x5Axie[index].y
        ]);
      }
    });
    // //calculate regression data
    let regressionResult = {};
    if (regressionParam === '1') {
      if (regressionOrder === '1') {
        //add a 1 at beginning of every y for calculating the constant coefficient.
        xData = addConstant(xData);
        //calculate variance inflation factors
        regressionResult = linearRegression(yData, xData, false);
      } else if (regressionOrder === '2') {
        regressionResult = regression.polynomial(xData, yData, { order: 2 });
      } else if (regressionOrder === '3') {
        regressionResult = regression.polynomial(xData, yData, { order: 3 });
      } else if (regressionOrder === '4') {
        regressionResult = regression.polynomial(xData, yData, { order: 4 });
      }
    } else {
      //add a 1 at beginning of every y for calculating the constant coefficient.
      xData = addConstant(xData);
      //calculate variance inflation factors
      regressionResult = linearRegression(yData, xData, false);
    }
    setPreviewRegression({
      ...regressionResult
    });
  };
  const getRegressionFormula = () => {
    const regressionParam = MVProject.routineAdjustmentsInModel.length.toString();
    if (previewRegression == null) {
      return;
    }
    if (regressionParam > 1 || MVProject.regressionOrder == '1') {
      let temp = previewRegression.coefficients.data.map((item, index) => {
        if (item[0] == 0) {
          return <span key={index}></span>;
        }
        let tempCoeff = item[0];
        if (Math.abs(tempCoeff) < 1) {
          tempCoeff = tempCoeff.toFixed(5);
        } else if (Math.abs(tempCoeff) < 10) {
          tempCoeff = tempCoeff.toFixed(3);
        } else {
          tempCoeff = tempCoeff.toFixed(1);
        }
        if (index === 0) {
          return <span key={index}>{tempCoeff}</span>;
        } else {
          if (tempCoeff.startsWith('-')) {
            return (
              <span key={index}>
                {' - ' + tempCoeff.replace('-', '')}
                <span className="formulX">{' X' + index}</span>
              </span>
            );
          } else {
            return (
              <span key={index}>
                {' + ' + tempCoeff}
                <span className="formulX">{' X' + index}</span>
              </span>
            );
          }
        }
      });
      let retArray = [1];
      retArray = retArray.map(item => {
        return (
          <span key="y">
            <span className="formulY">{'Y '}</span>=
          </span>
        );
      });
      retArray = retArray.concat(temp);
      return retArray;
    } else {
      let temp = previewRegression.equation.map((item, index) => {
        let tempCoeff = item;
        if (Math.abs(tempCoeff).toFixed(5) === 0) {
          tempCoeff = tempCoeff.toFixed(0);
        }
        if (Math.abs(tempCoeff) < 1) {
          tempCoeff = tempCoeff.toFixed(5);
        } else if (Math.abs(tempCoeff) < 10) {
          tempCoeff = tempCoeff.toFixed(3);
        } else {
          tempCoeff = tempCoeff.toFixed(1);
        }
        if (index === previewRegression.equation.length - 1) {
          if (tempCoeff.startsWith('-')) {
            return <span key={index}> - {tempCoeff.replace('-', '')}</span>;
          } else {
            return <span key={index}> + {tempCoeff}</span>;
          }
        } else {
          if (index === 0) {
            return (
              <span key={index}>
                {tempCoeff}
                <b className="formulX">{' X^' + (index + 1) * 1}</b>
              </span>
            );
          } else {
            if (tempCoeff.startsWith('-')) {
              return (
                <span key={index}>
                  {' - ' + tempCoeff.replace('-', '')}
                  <b className="formulX">{' X^' + (index + 1) * 1}</b>
                </span>
              );
            } else {
              return (
                <span key={index}>
                  {' + ' + tempCoeff}
                  <b className="formulX">{' X^' + (index + 1) * 1}</b>
                </span>
              );
            }
          }
        }
      });
      let retArray = [1];
      retArray = retArray.map(item => {
        return (
          <span key="y">
            <b>{'Y '}= </b>
          </span>
        );
      });
      retArray = retArray.concat(temp);
      return retArray;
    }
  };
  const prepearSavingData = async () => {
    const body = {
      kind: 'range',
      fromDate: moment(MVProject.baselinePeriodFrom)
        .locale('fa')
        .format('YYYY-MM-DD'),
      toDate: moment(MVProject.reportingPeriodTo)
        .locale('fa')
        .format('YYYY-MM-DD'),
      nav: [
        {
          navID: MVProject.meter._id,
          navName: MVProject.meter.name,
          navType: 'Meter'
        }
      ]
    };
    const consumptions = await Axios.post(`/api/v1/consumption/gstats`, body, config);
    setSavingChartData(consumptions.data.data.data[0][0]);
    let consumption = 0;
    let estimate = 0;
    MVProject.modelData.forEach(element => {
      consumption += element.y;
      estimate += element.x;
    });
    setSavingConsumptionData(consumption);
    setSavingEstimateData(estimate);
  };
  const getAllReportsData = async () => {
    SetIsLoad(false);
    //reporting data
    const reportFrom = moment(MVProject.baselinePeriodFrom, 'YYYY-MM-DD')
      .locale('fa')
      .format('YYYY-MM-DD');

    const reportTo = moment(MVProject.baselinePeriodTo, 'YYYY-MM-DD')
      .locale('fa')
      .format('YYYY-MM-DD');

    const reportBody = {
      kind: 'range',
      fromDate: reportFrom,
      toDate: reportTo,
      nav: [{ navID: MVProject.meter._id, navType: 'Meter', navName: MVProject.meter.name }]
    };
    let reportData = await Axios.post(`/api/v1/consumption/gstats`, reportBody, config);
    if (reportData.data.data.data.length > 0) {
      if (reportData.data.data.data[0].length > 0) {
        setReportingChartData(reportData.data.data.data[0][0].AggregateData);
      }
    }

    //BaseLine report data
    const baseFrom = moment(MVProject.baselinePeriodFrom, 'YYYY-MM-DD')
      .locale('fa')
      .format('YYYY-MM-DD');

    const baseTo = moment(MVProject.baselinePeriodTo, 'YYYY-MM-DD')
      .locale('fa')
      .format('YYYY-MM-DD');

    const body = {
      kind: 'range',
      fromDate: baseFrom,
      toDate: baseTo,
      nav: [{ navID: MVProject.meter._id, navType: 'Meter', navName: MVProject.meter.name }]
    };
    let BaseLineData = await Axios.post(`/api/v1/consumption/gstats`, body, config);
    if (BaseLineData.data.data.data.length > 0) {
      if (BaseLineData.data.data.data[0].length > 0) {
        setBaselineChartData(BaseLineData.data.data.data[0][0].AggregateData);
      }
    }

    //RA Report Data
    if (MVProject.routineAdjustments.length > 0) {
      await getRAChartData(MVProject.routineAdjustments);
    }
    //Calculate Project Model
    await generateModel();
    //MVProject Saving Data
    await prepearSavingData();
    SetIsLoad(true);
    setTimeout(() => {
      window.print();
      props.setPrintMode(false);
    }, 1000);
  };
  useEffect(() => {
    getAllReportsData();
  }, [props.MVProject]);
  return (

   
    <Fragment >
    <Header></Header>
      <Container hidden={!isLoad} className="mv">
        <div className="prBox">
        <Row>
          <Col md={{ span: 12 }}>
            <h2>تعاریف پایه پروژه</h2>
            {/* <span></span> //توضیح در مورد تیتردر صورت نیاز */}
            <Row className="m-2">
              <span className="lbl" >نام پروژه:  </span>
              <span className="text">{MVProject.name}</span>
            </Row>
            <Row className="m-2">
              <span className="lbl">IPMVP Option: </span>
              <span className="text">{MVProject.IPMVP}</span>
            </Row>
            <Row className="m-2">
              <span className="lbl">{MVProject.building.kind === 'Site' ? 'سایت: ' : 'ساختمان:'}</span>
              <span className="text">{MVProject.building.name}</span>
            </Row>
            <Row className="m-2">
              <span className="lbl">هزینه پروژه (تومان):</span>
              <span className="text">{MVProject.cost}</span>
            </Row>
            <Row className="m-2">
              <span  className="lbl">توضیحات پروژه: </span>
              <span  className="text">
                <Editor
                  readOnly={true}
                  editorState={EditorState.createWithContent(projectDescription)}
                  toolbarHidden={true}
                />
              </span>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={{ span: 12 }}>
            <h2>راهکارهای اجرایی</h2>
            <Row>
            <div className="subTitle">تمام راهکارهای اجرایی (ECM) به کار گرفته شده در این پروژه:</div>
            </Row>
            {/* این ممکنه جند تا باشه از این مدل جلو تر هم داریم */}
            {MVProject.ECM.map((item, index) => {
              const ecmDescription = convertFromRaw(JSON.parse(item.description));
              return (
                <Row>
                  <Col md="12">
                    <Row>
                      <span className="lbl">نام راهکار: </span>
                      <span className="text">{item.name}</span>
                    </Row>
                    <Row>
                      <span className="lbl">رفرنس: </span>
                      <span className="text">{item.refrence}</span>
                    </Row>
                    <Row>
                      <span className="lbl">تاریخ شروع:</span>
                      <span className="text">
                        {moment(item.start)
                          .locale('fa')
                          .format('YYYY-MM-DD')}
                      </span>
                    </Row>
                    <Row>
                      <span className="lbl">تاریخ پایان:</span>
                      <span className="text">
                        {moment(item.end)
                          .locale('fa')
                          .format('YYYY-MM-DD')}
                      </span>
                    </Row>
                    <Row>
                      <span className="lbl">توضیحات:</span>
                      <span className="text">
                        <Editor
                          editorState={EditorState.createWithContent(ecmDescription)}
                          readOnly={true}
                          toolbarHidden={true}
                        />
                      </span>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        </div>

        <div className="prBox">
        <Row>
          <Col md={{ span: 12 }}>
            <h2>دوره زمانی مبنا</h2>
            <Row>
            <div className="subTitle">مصرف انرژی کنتور در بازه زمانی خط مبنا انتخاب شده</div>
            </Row>
            <Row>
              <span className="lbl">کنتور:</span>
              <span className="text">
                {MVProject.meter.name} ({MVProject.meter.unit.unit})
              </span>
            </Row>
            <Row>
              <span className="lbl">هزینه پایه انرژی: </span>
              <span className="text">{MVProject.unitCost} (تومان): </span>
            </Row>
            <Row>
              <span className="lbl">از تاریخ:</span>
              <span className="text">
                {moment(MVProject.baselinePeriodFrom)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
              </span>
            </Row>
            <Row>
              <span className="lbl">تا تاریخ:</span>
              <span className="text">
                {moment(MVProject.baselinePeriodTo)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
              </span>
            </Row>
            <Row className='mt-3'>{baselineChartData.length > 0 ? <BaselinePeriodChart dataset={baselineChartData} /> : ''}</Row>
          </Col>
        </Row>
        </div>
        <div className="prBox">
        <Row>
          <Col md={{ span: 12 }}>
            <h2>دوره گزارش دهی</h2>
            <Row>
            <div className='subTitle'>دوره زمانی پس از اجرای راهکارها جهت بررسی اثربخشی آن</div>

            </Row>
            <Row>
              <span className="lbl">کنتور:</span>
              <span className="text">
                {MVProject.meter.name} ({MVProject.meter.unit.unit})
              </span>
            </Row>
            <Row>
              <span className="lbl">از تاریخ</span>
              <span className="text">
                {moment(MVProject.reportingPeriodFrom)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
              </span>
            </Row>
            <Row>
              <span className="lbl">تا تاریخ:</span>
              <span className="text">
                {moment(MVProject.reportingPeriodTo)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
              </span>
            </Row>
            <Row className='mt-3'>{baselineChartData.length > 0 ? <ReportingPeriodChart dataset={reportingChartData} /> : ''}</Row>
          </Col>
        </Row>
        </div>
        <div className="prBox">
        <Row>
          <Col md={{ span: 12 }}>
            <h2>تصحیحات معمول</h2>
            <Row>
            <div className='subTitle'>متغیرهای مرتبط که جهت محاسبه خط مبنا انتخاب شده است.</div>

            </Row>
            {MVProject.routineAdjustments.map((item, index) => {
              return (
                <Row>
                  <Col md="12">
                    <Row>
                      <span className="lbl">نام کنتور / متغیر:</span>
                      <span className="text">{item.name}</span>
                    </Row>
                    <Row>
                      <span className="lbl">نوع کنتور:</span>
                      <span className="text">{item.type === 'Consumption' ? 'کنتور مصرفی' : 'پارامتر'}</span>
                    </Row>
                    <Row>
                      <span className="lbl">حامل:</span>
                      <span className="text">{item.unit.name}</span>
                    </Row>
                    <Row>
                      <span className="lbl">واحد:</span>
                      <span className="text">{item.unit.unit}</span>
                    </Row>
                    <Row className='mt-3'>
                      {allRAChartData.length > 0 && allRAChartData[index] !== null ? (
                        <RoutineAdjustmentChart
                          dataset={allRAChartData[index]}
                          label={allRAChartDays}
                          chartAnonationDay={chartRAAnonationDay}
                        />
                      ) : (
                        'کنتور/متغیر مورد نظر در این بازه دیتایی ندارد'
                      )}
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        </div>
        <div className="prBox">
        <Row>
          <Col md={{ span: 12 }}>
            <h2>تصحیحات غیر معمول</h2>
            <Row>
            <div className="subTitle">درصورت کاربرد تصحیحات غیرمعمول برای بازه زمانی مشخص و یا کل دوره زمانی خط مبنا اعمال میشود.</div>

            </Row>
            {MVProject.routineAdjustments.map((item, index) => {
              return (
                <Row>
                  <Col md="12">
                    <Row className="m-2">
                      <span className="lbl">نام:</span>
                      <span className="text">{MVProject.nonRoutineAdjustments[0].name}</span>
                    </Row>
                    <Row className="m-2">
                      <span className="lbl">عملگر:</span>
                      <span className="text">
                        {`${MVProject.nonRoutineAdjustments[0].staticFactor} ${MVProject.nonRoutineAdjustments[0].factor}`}
                      </span>
                    </Row>
                    <Row className="m-2">
                      <span className="lbl">بازه زمانی:</span>
                      <span className="text">{MVProject.nonRoutineAdjustments[0].allTime}</span>
                    </Row>
                    <Row className="m-2" hidden={MVProject.nonRoutineAdjustments[0].allTime === 'کل بازه مبنا'}>
                      <span className="lbl">
                        <span>از تاریخ</span>
                      </span>
                      <span className="text">
                        {MVProject.nonRoutineAdjustments[0].start
                          ? MVProject.nonRoutineAdjustments[0].start.substring(0, 10)
                          : ''}
                      </span>
                    </Row>
                    <Row className="m-2" hidden={MVProject.nonRoutineAdjustments[0].allTime === 'کل بازه مبنا'}>
                      <span className="lbl">
                        <span>تا تاریخ:</span>
                      </span>
                      <span className="lbl">
                        {MVProject.nonRoutineAdjustments[0].end
                          ? MVProject.nonRoutineAdjustments[0].end.substring(0, 10)
                          : ''}
                      </span>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        </div>
        <div className="prBox">
        <Row>
          <Col md={{ span: 12 }}>
            <h2>نرمال سازی</h2>
            <Row>
            <div className="subTitle">متغیرهای مرتبط استفاده شده در خط مبنا ،رگرسیون و آنالیز آماری رگرسیون.</div>

            </Row>
            <Row className="m-2">
              <span className="lbl">متغیرهای روتین انتخاب شده</span>
              <span className="text">
                {MVProject.routineAdjustmentsInModel.map((item, index) => {
                  return (
                    <span>
                      {item.name}{' '}
                      {MVProject.routineAdjustmentsInModel.length > 1 &&
                      index !== MVProject.routineAdjustmentsInModel.length - 1
                        ? ' - '
                        : ''}{' '}
                    </span>
                  );
                })}
              </span>
            </Row>
            <Row className="m-2">
              <span className="lbl">درجه رگرسیون (Order):</span>
              <span className="text">{MVProject.regressionOrder}</span>
            </Row>
            {previewRegression ? (
              <Row className="m-2">
                <Container fluid className="ChartControlPanel">
                  <h3 className={'simpleBoxTitle'}>اطلاعات رگرسیون</h3>
                  <Row className="mb-2">
                    <Col md={{ span: 12 }}>
                      <div className="regressionFormula">{getRegressionFormula()}</div>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col>
                          <div className="regressionParam">
                            <span className="lbl">R2 Score</span>
                            <span className="text">
                              {MVProject.routineAdjustmentsInModel.length === 1 && MVProject.regressionOrder !== '1' ? (
                                <span>{`R2: ${previewRegression.r2.toFixed(4)}`}</span>
                              ) : (
                                <span>{`R2: ${previewRegression.rSquared.toFixed(4)}`}</span>
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col>
                          <div className="regressionParam">
                            <span className="lbl">تعداد روز دوره گزارش:</span>
                            <span className="text">
                              {moment(props.MVProject.reportingPeriodTo).diff(
                                moment(props.MVProject.reportingPeriodFrom),
                                'day'
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col>
                          <div className="regressionParam">
                            <span className="lbl">تعداد روز دوره مبنا:</span>
                            <span className="text">
                              {moment(props.MVProject.baselinePeriodTo).diff(
                                moment(props.MVProject.baselinePeriodFrom),
                                'day'
                              )}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <h3 className={'simpleBoxTitle'}>آنالیز آماری رگرسیون</h3>
                  <Row className="mb-2 mt-2">
                    {MVProject.routineAdjustmentsInModel.length > 1 || MVProject.regressionOrder === '1' ? (
                      <Col md={{ span: 12 }}>
                        <Table responsive striped className="RegTable mb-3">
                          <thead>
                            <tr>
                              <th></th>
                              <th>SS</th>
                              <th>F</th>
                              <th>Significance F</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-bold">Regression</td>
                              <td>{previewRegression ? parseFloat(previewRegression.ssr).toFixed(6) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.fValue).toFixed(6) : ''}</td>

                              <td>
                                {previewRegression ? parseFloat(previewRegression.pValueOfFValue).toFixed(6) : ''}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Residual</td>
                              <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(6) : ''}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Total</td>
                              <td>
                                {previewRegression
                                  ? parseFloat(previewRegression.ssr + parseInt(previewRegression.sse)).toFixed(6)
                                  : ''}
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>

                        <Table responsive striped className="RegTable mb-3">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Coefficients</th>
                              <th>Standard Error</th>
                              <th>t Stat</th>
                              <th>P-value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {previewRegression
                              ? previewRegression.pValues.map((item, index) => {
                                  if (!item || isNaN(item)) {
                                  } else
                                    return (
                                      <tr>
                                        <td className="font-weight-bold">{index === 0 ? 'Intercept' : 'x' + index}</td>
                                        <td>{parseFloat(previewRegression.coefficients.data[index]).toFixed(6)}</td>
                                        <td>
                                          {parseFloat(previewRegression.stdErrorOfCoefficients[index]).toFixed(6)}
                                        </td>

                                        <td>{parseFloat(previewRegression.tValues[index]).toFixed(6)}</td>
                                        <td>{parseFloat(previewRegression.pValues[index]).toFixed(6)}</td>
                                      </tr>
                                    );
                                })
                              : ''}
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      <Col md={{ span: 12 }}>
                        <Table responsive striped className="RegTable mb-3">
                          <thead>
                            <tr>
                              <th>me</th>
                              <th>sse</th>
                              <th>mse</th>
                              <th>smse</th>
                              <th>se</th>
                              <th>tstat</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{previewRegression ? parseFloat(previewRegression.me).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.mse).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.smse).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.se).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.tstat).toFixed(4) : ''}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </Row>
                </Container>
              </Row>
            ) : (
              ''
            )}
            
            
          </Col>
        </Row>
        </div>
        <div className="prBox">
        <Row>
          <Col md={{ span: 12 }}>
            <h2>گزارش نهایی M&V</h2>
            <Row className="m-2">
              <SavingChart
                consumptionData={savingChartData}
                chartBeginECM={moment(MVProject.baselinePeriodTo)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
                chartEndECM={moment(MVProject.reportingPeriodFrom)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
                predictData={MVProject.modelData}
                unit={MVProject.meter.unit.unit}
              />
            </Row>
            <Row className="m-2">
              <span className="lbl">پیشبینی مصرف نرمال سالیانه (بدون انجام پروژه):</span>
              <span className="text predicted">{`${calcActual} ${props.MVProject.meter.unit.unit} (${(
                (calcActual * props.MVProject.unitCost).toFixed() * 1
              ).toLocaleString()} تومان)`}</span>
            </Row>
            <Row className="m-2">
              <span className="lbl">مصرف واقعی:</span>
              <span className="text actual" >{`${calcPredicted} ${props.MVProject.meter.unit.unit} (${(
                (calcPredicted * props.MVProject.unitCost).toFixed() * 1
              ).toLocaleString()} تومان)`}</span>
            </Row>
            <Row className="m-2">
              <span className="lbl">میزان صرفه جویی سالیانه:</span>
              <span className="text saving">{`${calcSaving} ${props.MVProject.meter.unit.unit} (${(
                (calcSaving * props.MVProject.unitCost).toFixed() * 1
              ).toLocaleString()} تومان)`}</span>
            </Row>
            <Row className="m-2">
              <span className="lbl">بازگشت سرمایه:</span>
              <span className="text saving">{`${payBack} سال`}</span>
            </Row>
          </Col>
        </Row>
        </div>
      </Container>
      <Container hidden={isLoad}>
        <center className='text-center'>
          <Spinner animation="grow" variant="success" className={'spinnerChart'} />
        </center>
      </Container>
      <Footer></Footer>
    </Fragment>
    
 
  );
};

export default PrintView;

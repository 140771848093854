import React, { useRef, useEffect } from 'react';
import { Chart, defaults } from 'chart.js';
//import regression from 'regression';
import { calculate } from 'excel-regression';
import regression from 'regression-extend';

const RegressionChart = props => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.scales.linear.beginAtZero = false;
  defaults.plugins.legend.position = 'bottom';
  defaults.plugins.legend.rtl = true;
  defaults.plugins.legend.labels.padding = 30;
  defaults.plugins.legend.labels.usePointStyle = true;
  defaults.elements.line.tension = '0.5';
  defaults.plugins.legend.labels.color= '#121212';
  defaults.plugins.title.color='#121212';

  const myRef = useRef();

  useEffect(() => {
    let chartTitle = '';
    if (props.data.length > 0) {
      const tempChart = Chart.getChart(myRef.current);
      if (tempChart) {
        tempChart.destroy();
      }
      const tempData = props.data.map(el => {
        return [el.x, el.y];
      });
      let result;
      let excelRes;
      let extendRes;
      let lineData = [];
      const datasets = [
        {
          type: 'scatter',
          label: 'دیتاهای ورودی خام',
          backgroundColor: ['#fdba3a'],
          borderColor: 'rgba(255, 203, 105,0.9)',
          data: props.data,
          pointRadius: 5,
          fill: false,
          showLine: false
        }
      ];
      if (props.regressionOrder && tempData.length > 1) {
        if (props.regressionOrder === '1') {
          excelRes = calculate(tempData);
          result = regression.linear(tempData);
        } else if (props.regressionOrder === '2') {
          result = regression.polynomial(tempData, { order: 2 });
        } else if (props.regressionOrder === '3') {
          result = regression.polynomial(tempData, { order: 3 });
        } else if (props.regressionOrder === '4') {
          result = regression.polynomial(tempData, { order: 4 });
        }

        lineData = props.data.map(el => {
          return el.x;
        });

        lineData = lineData.sort((a, b) => {
          return a - b;
        });
        lineData = lineData.map(el => {
          return result.predict(el);
        });
        if (result.r2 > 0) {
          chartTitle = result.string + ' | R2 = ' + result.r2 ;
          datasets.push({
            type: 'line',
            label: 'رگرسیون',
            backgroundColor: ['#b8d0fe'],
            borderColor: 'rgb(184, 208, 254)',
            data: lineData,
            pointRadius: 0
          });
        }
      }

      // console.log(props.data);
      // console.log(result);
      // console.log(excelRes);

      new Chart(myRef.current, {
        data: {
          datasets: datasets
        },
        options: {
          barPercentage: 0.3,
          plugins: {
            title: {
              text: chartTitle,
              display: true,
              font: {
                size: 14,
                weight: 'bold'
              }
            }
          }
        }
      });
    }
  }, [props.data]);
  return <canvas ref={myRef}></canvas>;
};

export default RegressionChart;

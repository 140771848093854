import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../Axios/Axios';
import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import RightNav from '../../../components/UI/RightNav/RightNav';
const EnBS = props => {
  const [renderReport, setRenderReport] = useState(true);
  const [allUnits, SetAllUnits] = useState([]);
  const [selectedTrends, setSelectedTrends] = useState([]);
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  const [righNav, setRighNav] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [resetSetSelectedRows,setResetSetSelectedRows] = useState(false);
  const [resetFrom, setResetFrom] = useState(false);
  const [resetTime, setResetTime] = useState('');
  const [resetCheckBox, setResetCheckBox] = useState(false);
  const [allTrend, setAllTrend] = useState([]);

  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (renderReport) {
      Axios.get('/api/v1/trend', config).then(res => {
        setAllTrend(res.data.data.data);
        setRenderReport(false);
      });
      Axios.get('/api/v1/users/me', config).then(user => {
        setUserInfo(user.data.data.data);
      });
      if (resetFrom) {
        props.ResetCurrentForm(resetFrom);
        setResetFrom(false);
      }

    }
  });

  const SelectTrend = (checked, selectRow) => {
    const selectedCheckBoxUnit = selectedTrends;
    if (checked) {
      selectedCheckBoxUnit.push(selectRow);
      setSelectedTrends(selectedCheckBoxUnit);
    } else {
      const index = selectedCheckBoxUnit.indexOf(selectRow);
      if (index !== -1) {
        selectedCheckBoxUnit.splice(index, 1);
        setSelectedTrends(selectedCheckBoxUnit);
      }
    }

  };
  const AddReport = e => {
    e.preventDefault();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const reportNewItem = JSON.stringify({
      name: props.reportName,
      description: props.reportDesc,
      createdBy: userInfo._id,
      reportPage: props.selectedReport,
      time: props.selectedTime,
      Trend: selectedTrends
    });
    if (props.reportName && props.reportDesc &&  selectedTrends.length> 0) {
      if (props.selectedReport.length) {
        Axios.post(`/api/v1/ReportDefinition/`, reportNewItem, config).then(note => {
          toast.success('گزارش شما ایجاد شد', { autoClose: 3000 });
          setRenderReport(true);
          setResetFrom(true);
          setResetTime(' ');
          setIsRenderNavbar(true);
          setResetCheckBox(true);

          // formAddReportRef.current.reset();
        });
      } else {
        toast.error(' لطفا نوع گزارش و زمان آن را انتخاب نمایید.', { autoClose: 3000 });
      }
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 3000 });
    }
  };
  return (
    <div className="row">
     <Col md={{ span: 6, offset: 3 }} >
        <div className="grayback">
          <label className={'col-form-label '}> خط مبنا </label>
          <div className="w-100"></div>
          <div className="scrollBox pl-2">
            <InputGroup className="mb-1">
              {allTrend.map(unit => {
                return (
                  <Col md={12} className="pl-1 col-form-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={unit._id}
                      id="UnitCheckBox"
                      onClick={e => {
                        SelectTrend(e.target.checked, e.target.value);
                      }}
                    />
                    {unit.name}
                  </Col>
                );
              })}
            </InputGroup>
          </div>
        </div>
        <div class="clearfix"></div>
      <Form.Group className="savebtn">
        <Button variant="primary " onClick={AddReport}>
          ذخیره
        </Button>
      </Form.Group>
      </Col>

    </div>
  );
};

export default EnBS;

import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../Axios/Axios';
import './ReportsPrint.css';

import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import EnergyLable from './ViewReportType/EnergyLable/EnergyLable';
import Trend from './ViewReportType/Trend/Trend';
import ISODocuments from './ViewReportType/ISODocuments/ISODocuments';
import EnBS from './ViewReportType/EnBS/EnBS';

const ReportsPrint = props => {
  const ReportsType = useRef();
  const [renderReport, setRenderReport] = useState(true);
  const [currentReport, SetCurrentReport] = useState();
  const [chooseReport, setChooseReport] = useState('');
  const [allReportDefinition, SetAllReportDefinition] = useState([]);
  const [pageload, setPageload] = useState(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (renderReport || props.renderReport) {
      Axios.get('/api/v1/ReportDefinition/', config).then(reportDef => {
        SetAllReportDefinition(reportDef.data.data.data);
        setRenderReport(false);
      });
    }
  });

  const changeReport = e => {
    setChooseReport(e.target.value);
    Axios.get('/api/v1/ReportDefinition/' + e.target.value, config).then(reportDef => {
      SetCurrentReport(reportDef.data.data.data);
      setPageload(true);
    });
  };

  const ChooseReportType = () => {
    const currentReportpageId = currentReport.reportPage[0]._id;
    if (currentReportpageId) {
      // انتخاب کامپوننت گزارش ها
      switch (currentReportpageId) {
        case '61d03098f8473251581d6769':
          // return 'trend';
          return <Trend 
          currentReport={currentReport} 
          setPageload={setPageload} pageload={pageload}></Trend>;
          break;
        case '61adec9f8785c01b3055b6c8':
          return (
            <EnergyLable
              setRenderPrint={props.setRenderPrint}
              renderPrint={props.renderPrint}
              currentReport={currentReport}
              setPageload={setPageload}
              pageload={pageload}
            ></EnergyLable>
          );
          break;
          break;
          case '61ed16b06d61de296085b3fe':
            return (
              <ISODocuments
                setRenderPrint={props.setRenderPrint}
                renderPrint={props.renderPrint}
                currentReport={currentReport}
                setPageload={setPageload}
                pageload={pageload}
              ></ISODocuments>
            );
            break;
            case '61ed150c6d61de296085b3fc':
              return (
                <EnBS
                setRenderPrint={props.setRenderPrint}
                renderPrint={props.renderPrint}
                currentReport={currentReport}
                setPageload={setPageload}
                pageload={pageload}
                ></EnBS>
              );
              break;
        default:
          break;
      }
    }
  };
  return (
    <div>
      <Form className="mt-3">
        <Col md={{ span: 4, offset: 4 }} className="NewReport">
          <InputGroup className="mb-1">
            <label className={'col-sm-3 col-form-label'}>نام گزارش</label>
            <Form.Control
              as="select"
              className={'col-sm-9'}
              custom
              value={chooseReport}
              onChange={e => changeReport(e)}
            >
              <option></option>
              {allReportDefinition.length > 0
                ? allReportDefinition.map((reportDef, index) => {
                    return (
                      <option key={index} value={reportDef._id}>
                        {reportDef.name}
                      </option>
                    );
                  })
                : ''}
            </Form.Control>
          </InputGroup>
        </Col>
      </Form>
      <h1>{currentReport ? ChooseReportType() : ''}</h1>
    </div>
  );
};
export default ReportsPrint;

import React,{Fragment} from 'react';

import { Bar, Line, Pie, Doughnut, defaults, Chart } from 'react-chartjs-2';

const FormulaChart = (props) => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.scales.linear.beginAtZero = false;
  defaults.plugins.legend.position = 'bottom';
  defaults.plugins.legend.rtl = true;
  defaults.plugins.legend.labels.padding = 30;
  defaults.plugins.legend.labels.usePointStyle = true;
  defaults.elements.line.tension = '0.5';
  defaults.plugins.legend.labels.color= '#121212';
  defaults.plugins.title.color='#121212';


  const data = {
    datasets: [
      {
        backgroundColor: ['#fdba3a'],
        borderColor: 'rgba(255, 203, 105,0.7)',
        label: props.label,
        data: props.data
      }
    ]
  };
  const options = {
    barPercentage: 0.3,
    animation: {
      y: {
        easing: "linear",
        duration: 2000,
        from: 400
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          display: false
        }
      }
    }
  };
  let htmlChart = <div></div>;
  switch (props.chartType) {
    case 'Bar':
      htmlChart = (
        <Bar
          data={data}
          options={options}
          redraw={true}
        />
      );
      break;
    case 'Line':
      let optionLine = { ...options };
      htmlChart = (
        <Line
          data={data}
          options={optionLine}
          redraw={true}
        />
      );
      break;
  }
  return <Fragment>{htmlChart}</Fragment>;
};

export default FormulaChart;

import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../../Axios/Axios';
import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import EULabel from './EULabel';
import Header from '../../Print/Header';
import Footer from '../../Print/Footer';

const EnergyLable = props => {
  const EnergyLableReport = useRef();
  const [labelData, setLabelData] = useState([]);

  const [reportYear, setReportYear] = useState('');
  const [allYear, setAllYear] = useState([]);

  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const currentReport = props.currentReport.nav;

  useEffect(() => {
    if (props.pageload) {
      const body = JSON.stringify({
        kind: 'getYears',
        nav: props.currentReport.nav
      });
      Axios.post('/api/v1/consumption/gstats', body, config).then(res => {
        setAllYear(res.data.data.data);
        props.setPageload(false);
      });
    }
    if (labelData.length > 0 && !props.renderPrint) {
      props.setRenderPrint(true);
      window.print();
      setLoading(false);
    }
  });

  const printReport = async () => {
    if (currentReport.length > 0) {
      setLoading(true);

      // Get Building
      let allBuilding = [];
      currentReport.forEach((building, index) => {
        allBuilding.push(Axios.get('/api/v1/Building/' + building.navID, config));
      });
      allBuilding = await Promise.all(allBuilding);

      // Get EActual
      let allEActualData = [];
      allBuilding.forEach(buildItem => {
        const body = JSON.stringify({
          year: reportYear,
          building: buildItem.data.data.data
        });
        allEActualData.push(Axios.post('/api/v1/consumption/energyLabel', body, config));
      });
      allEActualData = await Promise.all(allEActualData);

      // Build Labels
      const allLabel = [];
      allBuilding.forEach((buildItem, index) => {
        const eActual = allEActualData[index].data.data.data.eactual;
        let EIdeal;
        switch (buildItem.data.data.data.type) {
          case 'مسکونی کوچک':
            switch (buildItem.data.data.data.city.climate) {
              case 'بسیار سرد':
                EIdeal = 111;
                break;
              case 'سرد':
                EIdeal = 111;
                break;
              case 'معتدل و بارانی':
                EIdeal = 156;
                break;
              case 'نیمه معتدل و بارانی':
                EIdeal = 156;
                break;
              case 'نیمه خشک':
                EIdeal = 83;
                break;
              case 'گرم و خشک':
                EIdeal = 86;
                break;
              case 'بسیار گرم و خشک':
                EIdeal = 150;
                break;
              case 'بسیار گرم و مرطوب':
                EIdeal = 130;
                break;
            }
            break;
          case 'مسکونی بزرگ':
            switch (buildItem.data.data.data.city.climate) {
              case 'بسیار سرد':
                EIdeal = 102;
                break;
              case 'سرد':
                EIdeal = 102;
                break;
              case 'معتدل و بارانی':
                EIdeal = 106;
                break;
              case 'نیمه معتدل و بارانی':
                EIdeal = 106;
                break;
              case 'نیمه خشک':
                EIdeal = 87;
                break;
              case 'گرم و خشک':
                EIdeal = 75;
                break;
              case 'بسیار گرم و خشک':
                EIdeal = 138;
                break;
              case 'بسیار گرم و مرطوب':
                EIdeal = 118;
                break;
            }
            break;
          case 'اداری دولتی':
            switch (buildItem.data.data.data.city.climate) {
              case 'بسیار سرد':
                EIdeal = 80;
                break;
              case 'سرد':
                EIdeal = 80;
                break;
              case 'معتدل و بارانی':
                EIdeal = 64;
                break;
              case 'نیمه معتدل و بارانی':
                EIdeal = 64;
                break;
              case 'نیمه خشک':
                EIdeal = 74;
                break;
              case 'گرم و خشک':
                EIdeal = 64;
                break;
              case 'بسیار گرم و خشک':
                EIdeal = 86;
                break;
              case 'بسیار گرم و مرطوب':
                EIdeal = 91;
                break;
            }
            break;
          case 'اداری خصوصی':
            switch (buildItem.data.data.data.city.climate) {
              case 'بسیار سرد':
                EIdeal = 120;
                break;
              case 'سرد':
                EIdeal = 120;
                break;
              case 'معتدل و بارانی':
                EIdeal = 152;
                break;
              case 'نیمه معتدل و بارانی':
                EIdeal = 152;
                break;
              case 'نیمه خشک':
                EIdeal = 124;
                break;
              case 'گرم و خشک':
                EIdeal = 117;
                break;
              case 'بسیار گرم و خشک':
                EIdeal = 121;
                break;
              case 'بسیار گرم و مرطوب':
                EIdeal = 197;
                break;
            }
            break;
        }
        const RValue = eActual / EIdeal;
        let energyLabelChar = '';
        switch (buildItem.data.data.data.type) {
          case 'مسکونی کوچک':
            if (RValue < 1) {
              energyLabelChar = 'A';
            } else if (RValue >= 1 && RValue < 1.9) {
              energyLabelChar = 'B';
            } else if (RValue >= 1.9 && RValue < 2.7) {
              energyLabelChar = 'C';
            } else if (RValue >= 2.7 && RValue < 3.4) {
              energyLabelChar = 'D';
            } else if (RValue >= 3.4 && RValue < 4) {
              energyLabelChar = 'E';
            } else if (RValue >= 4 && RValue < 4.5) {
              energyLabelChar = 'F';
            } else if (RValue >= 4.5 && RValue < 5) {
              energyLabelChar = 'G';
            } else {
              energyLabelChar = 'برچسب تعلق نمیگیرد';
            }
            break;
          case 'مسکونی بزرگ':
            if (RValue < 1) {
              energyLabelChar = 'A';
            } else if (RValue >= 1 && RValue < 2) {
              energyLabelChar = 'B';
            } else if (RValue >= 2 && RValue < 2.9) {
              energyLabelChar = 'C';
            } else if (RValue >= 2.9 && RValue < 3.7) {
              energyLabelChar = 'D';
            } else if (RValue >= 3.7 && RValue < 4.4) {
              energyLabelChar = 'E';
            } else if (RValue >= 4.4 && RValue < 5) {
              energyLabelChar = 'F';
            } else if (RValue >= 5 && RValue < 5.4) {
              energyLabelChar = 'G';
            } else {
              energyLabelChar = 'برچسب تعلق نمیگیرد';
            }
            break;
          case 'اداری دولتی':
            if (RValue < 1) {
              energyLabelChar = 'A';
            } else if (RValue >= 1 && RValue < 2) {
              energyLabelChar = 'B';
            } else if (RValue >= 2 && RValue < 3) {
              energyLabelChar = 'C';
            } else if (RValue >= 3 && RValue < 4) {
              energyLabelChar = 'D';
            } else if (RValue >= 4 && RValue < 5) {
              energyLabelChar = 'E';
            } else if (RValue >= 5 && RValue < 6) {
              energyLabelChar = 'F';
            } else if (RValue >= 6 && RValue < 7) {
              energyLabelChar = 'G';
            } else {
              energyLabelChar = 'برچسب تعلق نمیگیرد';
            }
            break;
          case 'اداری خصوصی':
            if (RValue < 1) {
              energyLabelChar = 'A';
            } else if (RValue >= 1 && RValue < 2.2) {
              energyLabelChar = 'B';
            } else if (RValue >= 2.2 && RValue < 3.2) {
              energyLabelChar = 'C';
            } else if (RValue >= 3.2 && RValue < 4) {
              energyLabelChar = 'D';
            } else if (RValue >= 4 && RValue < 4.6) {
              energyLabelChar = 'E';
            } else if (RValue >= 4.6 && RValue < 5.2) {
              energyLabelChar = 'F';
            } else if (RValue >= 5.2 && RValue < 5.5) {
              energyLabelChar = 'G';
            } else {
              energyLabelChar = 'برچسب تعلق نمیگیرد';
            }
            break;
        }
        allLabel.push({
          eActual,
          RValue,
          energyLabelChar,
          EIdeal,
          consumptions: allEActualData[index].data.data.data.consumptions,
          building: buildItem.data.data.data,
          climate: buildItem.data.data.data.city.climate,
          year: reportYear
        });
      });
      setLabelData(allLabel);
    } else {
      console.log('current report nabood');
    }
    props.setRenderPrint(false);
  };
  return (
    <div>
      <Col md={{ span: 4, offset: 4 }} className="d-print-none NewReport ">
        <center hidden={!loading}>
          <Spinner animation="grow" variant="success" className={'spinnerChart'} />
        </center>
        <div hidden={loading}>
          <InputGroup className="mb-1">
            <label className={'col-sm-3 col-form-label'}>سال</label>
            <Form.Control
              as="select"
              className={'col-sm-9'}
              custom
              value={reportYear}
              onChange={e => {
                setReportYear(e.target.value);
                props.setRenderPrint(true);
              }}
            >
              <option></option>
              {allYear.length > 0
                ? allYear[0].map((el, index) => {
                    return (
                      <option key={index} value={el._id}>
                        {el._id}
                      </option>
                    );
                  })
                : ''}
            </Form.Control>
          </InputGroup>

          <Form.Group className="text-center mt-2 mb-2 Printbtn">
            <Button onClick={printReport} variant="primary " disabled={!reportYear}>
              چاپ گزارش
            </Button>
            <ReactToPrint
              copyStyles="true"
              documentTitle={currentReport ? currentReport : 'Report'}
              trigger={() => (
                <Button hidden variant="primary ">
                  ذخیره
                </Button>
              )}
              content={() => EnergyLableReport.current}
            />
          </Form.Group>
        </div>
      </Col>

      <div className="pagePrint" ref={EnergyLableReport}>
        <Header></Header>
        {labelData.length > 0
          ? labelData.map(labelDataItem => {
              return (
                <div className="prBox">
                  <EULabel labelData={labelDataItem} />
                </div>
              );
            })
          : ''}
        {labelData.length > 1 ? (
          <div className="prBox">
            <h2 className="mb-2 text-center">اطلاعات ساختمان ها در سال {reportYear}</h2>
            <Table striped bordered hover>
              <thead className="headTable">
                <tr>
                  <th className="lbl">نام ساختمان</th>
                  <th className="lbl">شاخص مصرف انرژی</th>
                  <th className="lbl">رده مصرف انرژی</th>
                  <th className="lbl"> برچسب</th>
                </tr>
              </thead>
              <tbody>
                {labelData.map(labelDataItem => {
                  return (
                    <tr className="trTable">
                      <td>{labelDataItem.building.name}</td>
                      <td>{labelDataItem.EIdeal ? labelDataItem.EIdeal.toFixed(2) : ''}</td>
                      <td>{labelDataItem.RValue ? labelDataItem.RValue.toFixed(2) : ''}</td>
                      <td>{labelDataItem.energyLabelChar}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          ''
        )}
        <Footer></Footer>
      </div>
    </div>
  );
};

export default EnergyLable;

import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { AG_GRID_LOCALE_FA } from './Locale.fa';

import './Datagrid.css';

import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { falseDependencies } from 'mathjs';

const Datagrid = props => {
  LicenseManager.setLicenseKey('DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6');
  //Grid ag-grid
  const onGridReady = params => {
    props.setGridApi(params.api);
  };

  //chart
  var currentChartRef;

  const createChartContainer = chartRef => {
    if (currentChartRef) {
      currentChartRef.destroyChart();
    }
    var eChart = chartRef.chartElement;
    var eParent = document.querySelector('#myChart');
    eParent.appendChild(eChart);
    currentChartRef = chartRef;
  };
  return (
    <div className="row">
      <div className="ag-theme-material col-12 irSans">
        <AgGridReact
          suppressAggFuncInHeader={true}
          animateRows
          enableRangeSelection={true}
          popupParent={document.body}
          enableCharts={false}
          rowGroupPanelShow={'always'}
          onRowDoubleClicked={props.onDoubleClick}
          groupMultiAutoColumn={true}
          localeText={AG_GRID_LOCALE_FA}
          rowHeight={props.rowHeight ? props.rowHeight : ''}
          defaultColDef={{
            flex: 1,
            minWidth: 100,
            filter: true,
            sortable: true,
            enableRowGroup: true
          }}
          onGridReady={onGridReady}
          rowData={props.rowData}
          sideBar={{
            toolPanels: [
              {
                id: 'filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel'
              }
            ]
          }}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={props.paginationPageSize}
          rowSelection={'single'}
          columnDefs={props.gridColumn}
          enableRtl={true}
          onSelectionChanged={props.onSelectionChanged}
          frameworkComponents={{
            RowRendered: props.onClickItems,
            UserImageRenderer: props.userimage,
            UserJobDescriptionFileRenderer: props.userJobDescriptionFile
          }}
        ></AgGridReact>
      </div>
      <div id="myChart" className="ag-theme-alpine my-chart"></div>
    </div>
  );
};

export default Datagrid;

import React, { useState, useEffect } from 'react';

import './TabECM.css';

import { Button, Modal, InputGroup, FormControl, Row, Tab, Tabs, Col } from 'react-bootstrap';
import swal from 'sweetalert';
import Axios from '../../../../Axios/Axios';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DatePicker from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from 'jalali-moment';

const TabECM = props => {
  const [showAdd, setShowAdd] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [needLoad, setNeedLoad] = useState(true);
  const [needLoadECM, setNeedLoadECM] = useState(true);
  const [allECM, setAllECM] = useState([]);
  const [selectedECM, setSelectedECM] = useState(0);
  const [ECMTab, setECMTab] = useState(0);

  const [name, setName] = useState('');
  const [refrence, setRefrence] = useState('');
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [description, setDescription] = useState(null);

  const [existingName, setExsitingName] = useState('');
  const [exsitingRefrence, setExsitingRefrence] = useState('');
  const [exsitingStart, setExsitingStart] = useState(null);
  const [exsitingEnd, setExsitingEnd] = useState(null);
  const [changeExsitingStart, setChangeExsitingStart] = useState(null);
  const [changeExsitingEnd, setChangeExsitingEnd] = useState(null);
  const [existingDescription, setExistingDescription] = useState(null);

  const editorToolBar = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'image',
      'remove',
      'history'
    ],
    fontFamily: {
      options: ['Arial', 'Tahoma', 'Times New Roman', 'Iranian Sans'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined
    }
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  const selectECMTab = tabKey => {
    setECMTab(tabKey);
    const tabData = props.MVProject.ECM[tabKey];
    setExsitingName(tabData.name);
    setExsitingRefrence(tabData.refrence);
    const persianStart = moment(tabData.start)
      .locale('fa')
      .format('YYYY-MM-DD')
      .split('-');
    const persianEnd = moment(tabData.end)
      .locale('fa')
      .format('YYYY-MM-DD')
      .split('-');
    setExsitingStart({ day: persianStart[2], month: persianStart[1], year: persianStart[0] });
    setExsitingEnd({ year: persianEnd[0], month: persianEnd[1], day: persianEnd[2] });
    const contentState = convertFromRaw(JSON.parse(tabData.description));
    setExistingDescription(EditorState.createWithContent(contentState));
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const handleCloseLink = () => {
    setShowLink(false);
  };
  const addItem = async () => {
    setName('');
    setRefrence('');
    setStart('');
    setEnd('');
    setDescription(null);
    const ecm = [...props.MVProject.ECM];
    const rawECMDesc = convertToRaw(description.getCurrentContent());
    ecm.push({
      name,
      refrence,
      start: moment.from(`${start.year}-${start.month}-${start.day}`, 'fa', 'YYYY-M-D').format('YYYY-MM-DD'),
      end: moment.from(`${end.year}-${end.month}-${end.day}`, 'fa', 'YYYY-M-D').format('YYYY-MM-DD'),
      description: JSON.stringify(rawECMDesc),
      linked: false
    });
    const body = JSON.stringify({
      ECM: ecm
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, body, config);
    const MVProject = { ...props.MVProject, ECM: ecm };
    props.setMVProject(MVProject);
    handleCloseAdd();
  };
  const addExistingItem = async () => {
    const ecm = [...props.MVProject.ECM];
    ecm.push({
      name: allECM[selectedECM].name,
      refrence: allECM[selectedECM].refrence,
      start: moment.from(allECM[selectedECM].start, 'fa', 'YYYY-M-D').format('YYYY-MM-DD'),
      end: moment.from(allECM[selectedECM].end, 'fa', 'YYYY-M-D').format('YYYY-MM-DD'),
      description: allECM[selectedECM].description,
      linked: true
    });
    const body = JSON.stringify({
      ECM: ecm
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, body, config);
    const MVProject = { ...props.MVProject, ECM: ecm };
    props.setMVProject(MVProject);
    setSelectedECM(0);
    handleCloseLink();
  };
  const updateExistingECM = async (startD, endD) => {
    const ecm = [...props.MVProject.ECM];
    const rawECMDesc = convertToRaw(existingDescription.getCurrentContent());
    ecm[ECMTab] = {
      name: existingName,
      refrence: exsitingRefrence,
      start: moment.from(`${startD.year}-${startD.month}-${startD.day}`, 'fa', 'YYYY-M-D').format('YYYY-MM-DD'),
      end: moment.from(`${endD.year}-${endD.month}-${endD.day}`, 'fa', 'YYYY-M-D').format('YYYY-MM-DD'),
      description: JSON.stringify(rawECMDesc)
    };
    const body = JSON.stringify({
      ECM: ecm
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, body, config);
    const MVProject = { ...props.MVProject, ECM: ecm };
    props.setMVProject(MVProject);
  };

  const deleteECMTab = index => {
    swal({
      title: `آیا میخواهید راهکار اجرایی را حذف نمایید؟`,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        const ecm = [...props.MVProject.ECM];
        ecm.splice(index, 1);
        const body = JSON.stringify({
          ECM: ecm
        });
        Axios.patch('/api/v1/MVProject/' + props.MVProject._id, body, config).then(res => {
          const MVProject = { ...props.MVProject, ECM: ecm };
          props.setMVProject(MVProject);
          selectECMTab(0);
          swal('راهکار اجرایی مورد نظر حذف شد.', {
            icon: 'success'
          });
        });
      }
    });
  };

  useEffect(() => {
    if (needLoad && props.MVProject.ECM.length > 0) {
      setNeedLoad(false);
      selectECMTab(0);
    }
    if (needLoadECM) {
      const allECMTemp = [];
      setNeedLoadECM(false);
      Axios.get('/api/v1/MVProject', config).then(allMVPProject => {
        allMVPProject.data.data.data.forEach(el => {
          if (el._id !== props.MVProject._id)
            el.ECM.forEach(ecm => {
              if (!ecm.linked) {
                let startG = moment(ecm.start)
                  .locale('fa')
                  .format('YYYY-MM-DD');
                let endG = moment(ecm.end)
                  .locale('fa')
                  .format('YYYY-MM-DD');
                allECMTemp.push({
                  start: startG,
                  end: endG,
                  description: ecm.description,
                  name: ecm.name,
                  refrence: ecm.refrence,
                  project: el.name,
                  building: el.building.name
                });
              }
            });
        });
        setAllECM(allECMTemp);
      });
    }
  });

  return (
    <form hidden={props.activeStep !== 2}>
      <h2>2-راهکارهای اجرایی</h2>
      <span>تمام راهکارهای اجرایی (ECM) به کار گرفته شده در این پروژه تعریف شود.</span>
      <Row className="m-2">
        <Button
          className="m-1"
          onClick={() => {
            setShowAdd(true);
          }}
        >
          ایجاد راهکار
        </Button>
        <Button
          className="m-1"
          onClick={() => {
            setShowLink(true);
          }}
        >
          لینک راهکارهای پیشین
        </Button>
        <Modal show={showAdd} onHide={handleCloseAdd}>
          <Modal.Header closeButton>
            <Modal.Title>افزودن راهکار اجرایی</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-1">
              <FormControl placeholder="نام راهکار" value={name} onChange={e => setName(e.target.value)} />
            </InputGroup>
            <InputGroup className="mb-1">
              <FormControl placeholder="Refrence" value={refrence} onChange={e => setRefrence(e.target.value)} />
            </InputGroup>
            <InputGroup className="mb-1">
              <DatePicker
                value={start}
                onChange={setStart}
                inputPlaceholder="تاریخ شروع راهکار"
                shouldHighlightWeekends
                locale="fa"
              />
            </InputGroup>
            <InputGroup className="mb-1">
              <DatePicker
                value={end}
                onChange={setEnd}
                inputPlaceholder="تاریخ پایان راهکار"
                shouldHighlightWeekends
                locale="fa"
              />
            </InputGroup>
            <InputGroup className="mb-4">
              <Editor
                editorState={description}
                onEditorStateChange={val => {
                  setDescription(val);
                }}
                toolbar={editorToolBar}
              />
            </InputGroup>
            <InputGroup></InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={addItem} className="mr-3 mt-2">
              ذخیره
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showLink} onHide={handleCloseLink}>
          <Modal.Header closeButton>
            <Modal.Title>لینک کردن راهکار اجرایی</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {allECM.map((el, index) => {
              return (
                <Row
                  onClick={() => {
                    setSelectedECM(index);
                  }}
                  className={selectedECM === index ? 'selectedECM' : ''}
                >
                  <Col md="12">
                    <h3>{el.name}</h3>
                  </Col>
                  <Col md="12">
                    {el.start}-{el.end}
                  </Col>
                  <Col md="12">ساختمان: {el.building}</Col>
                  <Col md="12">پروژه: {el.project}</Col>
                  <hr />
                </Row>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={addExistingItem} className="mr-3 mt-2">
              افزودن
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      <Tabs onSelect={selectECMTab}>
        {props.MVProject.ECM.map((item, index) => {
          return (
            <Tab eventKey={index} title={item.name}>
              <Row>
                <Col md="10">
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder="نام راهکار"
                      value={existingName}
                      onChange={e => setExsitingName(e.target.value)}
                      disabled={item.linked}
                      onBlur={() => {
                        updateExistingECM(exsitingStart, exsitingEnd);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder="Refrence"
                      value={exsitingRefrence}
                      onChange={e => setExsitingRefrence(e.target.value)}
                      disabled={item.linked}
                      onBlur={() => {
                        updateExistingECM(exsitingStart, exsitingEnd);
                      }}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <span>
                      {exsitingStart ? exsitingStart.year + '/' + exsitingStart.month + '/' + exsitingStart.day : ''}
                    </span>
                    <span className="ml-2" hidden={item.linked}>
                      <DatePicker
                        locale={'fa'}
                        inputPlaceholder="تغییر تاریخ شروع راهکار"
                        shouldHighlightWeekends
                        value={changeExsitingStart}
                        onChange={val => {
                          setExsitingStart(val);
                          updateExistingECM(val, exsitingEnd);
                        }}
                      />
                    </span>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <span>{exsitingEnd ? exsitingEnd.year + '/' + exsitingEnd.month + '/' + exsitingEnd.day : ''}</span>
                    <span className="ml-2" hidden={item.linked}>
                      <DatePicker
                        locale={'fa'}
                        inputPlaceholder="تغییر تاریخ پایان راهکار"
                        shouldHighlightWeekends
                        value={changeExsitingEnd}
                        onChange={val => {
                          setExsitingEnd(val);
                          updateExistingECM(exsitingStart, val);
                        }}
                      />
                    </span>
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <Editor
                      editorState={existingDescription}
                      onEditorStateChange={val => {
                        setExistingDescription(val);
                      }}
                      toolbar={editorToolBar}
                      readOnly={item.linked}
                      onBlur={() => {
                        updateExistingECM(exsitingStart, exsitingEnd);
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col
                  md="2"
                  onClick={() => {
                    deleteECMTab(index);
                  }}
                >
                  <i class="fa fa-trash deleteECM"></i>
                </Col>
              </Row>
            </Tab>
          );
        })}
      </Tabs>
    </form>
  );
};

export default TabECM;

import React from 'react';

import { Row, Container, Card, Button, Col, Spinner, Table } from 'react-bootstrap';

import './EULabel.css';

const EULabel = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Container fluid>
        <Row className="justify-content-center page">
          <Col md={12} className="EULTable">
            <Table bordered>
              <tbody>
                <tr className="standardTopRow">
                  <td className="standardLogo">
                    {' '}
                    <img src="../../../../../app-assets/images/Public/StandardLogo.jpg" className="img-responsive" />
                  </td>
                  <td className="text-center">
                    <div className="EULTitle">
                      {` معیار مصرف  ${props.labelData.building.name} سال ${props.labelData.year}`}
                    </div>
                    <div className="EULText">بر اساس استاندارد ملی ایران ISIRI 14254</div>
                  </td>
                </tr>
                <tr>
                  <td className="EULType">
                    <h2 className={`${props.labelData.energyLabelChar}Color p-2 br-7`}>
                      {props.labelData.energyLabelChar}
                    </h2>
                  </td>
                  <td style={{ direction: 'ltr' }}>
                    {' '}
                    <div class="eu-energy-label-container">
                      <div class="eu-energy-label-class">
                        <div class="a"></div>
                        <div class="b"></div>
                        <div class="c"></div>
                        <div class="d"></div>
                        <div class="e"></div>
                        <div class="f"></div>
                        <div class="g"></div>
                      </div>
                    </div>{' '}
                  </td>
                </tr>

                {props.labelData.consumptions.map(el => {
                  if (el._id === 'گاز' || el._id === 'برق') {
                    return (
                      <tr className="consumtionnumber">
                        <td>
                          {' '}
                          <span className="lbl">مصرف حامل انرژی </span>({el._id})
                        </td>
                        <td className="text-center">{el.total}</td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
                <tr className="EnergyGrade">
                  <td className="lbl">
                    رده مصرف انرژی
                    {/* <span class="lbl"> ()</span> */}
                  </td>
                  <td className="text-center">{props.labelData.RValue ? props.labelData.RValue.toFixed(2) : ''}</td>
                </tr>
                <tr className="eActual">
                  <td className="lbl">شاخص مصرف انرژی</td>
                  <td className="text-center">{props.labelData.eActual ? props.labelData.eActual.toFixed(2) : ''}</td>
                </tr>
                <tr className="area">
                  <td className="lbl">زیربنای مفید</td>
                  <td className="text-center">{props.labelData.building.area}</td>
                </tr>
                <tr className="type">
                  <td className="lbl">کاربری</td>
                  <td className="text-center">{props.labelData.building.type}</td>
                </tr>
                <tr className="city">
                  <td className="lbl">شهر</td>
                  <td className="text-center">{props.labelData.building.city.name}</td>
                </tr>
                <tr className="climate">
                  <td className="lbl">اقلیم</td>
                  <td className="text-center">{props.labelData.building.city.climate}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

export default EULabel;

import React, { useState } from 'react';

import { Button, Modal, InputGroup, FormControl, Row, Tab, Tabs, Col, Container } from 'react-bootstrap';
import Axios from '../../../../Axios/Axios';
import DatePicker from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from 'jalali-moment';

const NonRoutineAdjustment = props => {
  const [showAdd, setShowAdd] = useState(false);
  const [allTime, setAllTime] = useState('کل بازه مبنا');
  const [name, setName] = useState('');
  const [factor, setFactor] = useState('-');
  const [staticFactor, setStaticFactor] = useState(0);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [minDate, setMinDate] = useState(() => {
    if (!props.MVProject.baselinePeriodFrom) {
      return null;
    }
    const from = moment(props.MVProject.baselinePeriodFrom)
      .locale('fa')
      .format('YYYY-MM-DD')
      .split('-');
    return { day: from[2] * 1, month: from[1] * 1, year: from[0] * 1 };
  });
  const [maxDate, setMaxDate] = useState(() => {
    if (!props.MVProject.baselinePeriodTo) {
      return null;
    }
    const to = moment(props.MVProject.baselinePeriodTo)
      .locale('fa')
      .format('YYYY-MM-DD')
      .split('-');
    return { day: to[2] * 1, month: to[1] * 1, year: to[0] * 1 };
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const submitNonRoutine = async () => {
    const tempNonRA = {
      name,
      factor,
      staticFactor,
      allTime,
      start: start
        ? moment
            .from(`${start.year}-${start.month}-${start.day}`, 'fa', 'YYYY-M-D')
            .locale('fa')
            .format('YYYY-MM-DD')
        : null,
      end: end
        ? moment
            .from(`${end.year}-${end.month}-${end.day}`, 'fa', 'YYYY-M-D')
            .locale('fa')
            .format('YYYY-MM-DD')
        : null
    };
    const bodyUpdate = JSON.stringify({
      nonRoutineAdjustments: [tempNonRA]
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, bodyUpdate, config);
    const MVProject = {
      ...props.MVProject,
      nonRoutineAdjustments: [tempNonRA]
    };
    props.setMVProject(MVProject);
    handleCloseAdd();
  };
  const deleteNonRoutine = async () => {
    const bodyUpdate = JSON.stringify({
      nonRoutineAdjustments: []
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, bodyUpdate, config);
    const MVProject = {
      ...props.MVProject,
      nonRoutineAdjustments: []
    };
    props.setMVProject(MVProject);
  };
  return (
    <form hidden={props.activeStep !== 6}>
      <h2>6- تصحیحات غیر معمول</h2>
      <span>درصورت کاربرد تصحیحات غیرمعمول برای بازه زمانی مشخص و یا کل دوره زمانی خط مبنا اعمال شود.</span>
      <Row className="m-2">
        <Button className="m-1" onClick={() => setShowAdd(true)}>
          {props.MVProject.nonRoutineAdjustments.length > 0 ? 'تغییر متغیر غیر روتین' : 'افزودن متغیر غیر روتین'}
        </Button>
        <Modal show={showAdd} onHide={handleCloseAdd}>
          <Modal.Header closeButton>
            <Modal.Title>
              {props.MVProject.nonRoutineAdjustments.length > 0 ? 'تغییر متغیر غیر روتین' : 'افزودن متغیر غیر روتین'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row className="m-2">
                <Col md={{ span: 4 }}>نام</Col>
                <Col md={{ span: 4 }}>
                  <InputGroup>
                    <FormControl value={name} onChange={e => setName(e.target.value)} />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="m-2">
                <Col md={{ span: 4 }}>عملگر</Col>
                <Col md={{ span: 4 }}>
                  <FormControl
                    type="text"
                    value={staticFactor}
                    onChange={e => {
                      setStaticFactor(e.target.value);
                    }}
                  />
                </Col>
                <Col md={{ span: 4 }}>
                  <FormControl
                    as="select"
                    value={factor}
                    onChange={e => {
                      setFactor(e.target.value);
                    }}
                  >
                    <option>-</option>
                    <option>+</option>
                    <option>*</option>
                    <option>/</option>
                  </FormControl>
                </Col>
              </Row>
              <Row className="m-2">
                <Col md={{ span: 4 }}>بازه زمانی</Col>
                <select
                  value={allTime}
                  onChange={e => {
                    setAllTime(e.target.value);
                  }}
                >
                  <option>کل بازه مبنا</option>
                  <option>بازه مشخص</option>
                </select>
              </Row>
              <Row className="m-2" hidden={allTime === 'کل بازه مبنا'}>
                <Col md={{ span: 4 }}>
                  <span>از تاریخ</span>
                </Col>
                <Col md={{ span: 4 }}>
                  <DatePicker
                    //value={start}
                    onChange={val => {
                      setStart(val);
                    }}
                    inputPlaceholder={start ? `${start.year}-${start.month}-${start.day}` : 'از تاریخ'}
                    shouldHighlightWeekends
                    locale="fa"
                    minimumDate={minDate}
                    maximumDate={maxDate}
                  />
                </Col>
              </Row>
              <Row className="m-2" hidden={allTime === 'کل بازه مبنا'}>
                <Col md={{ span: 4 }}>
                  <span>تا تاریخ</span>
                </Col>
                <Col md={{ span: 4 }}>
                  <DatePicker
                    // value={end}
                    onChange={val => {
                      setEnd(val);
                    }}
                    inputPlaceholder={end ? `${end.year}-${end.month}-${end.day}` : 'تا تاریخ'}
                    shouldHighlightWeekends
                    locale="fa"
                    minimumDate={minDate}
                    maximumDate={maxDate}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mr-3 mt-2" onClick={submitNonRoutine}>
              ایجاد
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      {props.MVProject.nonRoutineAdjustments.length > 0 ? (
        <Row className="m-2">
          <Col md="10">
            <Container>
              <Row className="m-2">
                <Col md={{ span: 4 }}>نام</Col>
                <Col md={{ span: 4 }}>{props.MVProject.nonRoutineAdjustments[0].name}</Col>
              </Row>
              <Row className="m-2">
                <Col md={{ span: 4 }}>عملگر</Col>
                <Col md={{ span: 4 }}>
                  {`${props.MVProject.nonRoutineAdjustments[0].staticFactor} ${props.MVProject.nonRoutineAdjustments[0].factor}`}
                </Col>
              </Row>
              <Row className="m-2">
                <Col md={{ span: 4 }}>بازه زمانی</Col>
                <Col md={{ span: 4 }}>{props.MVProject.nonRoutineAdjustments[0].allTime}</Col>
              </Row>
              <Row className="m-2" hidden={props.MVProject.nonRoutineAdjustments[0].allTime === 'کل بازه مبنا'}>
                <Col md={{ span: 4 }}>
                  <span>از تاریخ</span>
                </Col>
                <Col md={{ span: 4 }}>
                  {props.MVProject.nonRoutineAdjustments[0].start
                    ? props.MVProject.nonRoutineAdjustments[0].start.substring(0, 10)
                    : ''}
                </Col>
              </Row>
              <Row className="m-2" hidden={props.MVProject.nonRoutineAdjustments[0].allTime === 'کل بازه مبنا'}>
                <Col md={{ span: 4 }}>
                  <span>تا تاریخ</span>
                </Col>
                <Col md={{ span: 4 }}>
                  {props.MVProject.nonRoutineAdjustments[0].end
                    ? props.MVProject.nonRoutineAdjustments[0].end.substring(0, 10)
                    : ''}
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            md="2"
            onClick={() => {
              deleteNonRoutine();
            }}
          >
            <i class="fa fa-trash deleteECM"></i>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </form>
  );
};

export default NonRoutineAdjustment;

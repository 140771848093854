import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

const ChartAnnually = props => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  window.addEventListener('beforeprint', event => {
    console.log('Before print');
  });
  defaults.normalized = true;
  defaults.font.size = 11;
  defaults.font.family = 'Iranian Sans';
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  const option = {
    categoryPercentage: 1,
    barPercentage: 0.45,
    maintainAspectRatio: false,
    responsive: false,
    animation: false,
    plugins: {
      legend: {
        position: 'bottom'
      },
      title: {
        display: true,
        text: 'نمودار مقایسه ای 10 سال گذشته',
        padding: {
          bottom: 40
        },
        font: {
          size: 18
        }
      }
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        title: {
          display: true,
          text: props.dataset._id
        },
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    maintainAspectRatio: true,
    responsive: true
  };
  const labelChart = props.dataset.map(el => {
    return el._id.dateOf;
  });
  const data = {
    datasets: [
      {
        backgroundColor: '#305dfa',
        borderColor: '#305dfa',
        order: 1,
        data: props.dataset.map(el => {
          return { x: el._id.dateOf, y: el.total };
        }),
        label: labelChart
      },
      {
        type: 'line',
        tension: 0,
        backgroundColor: '#fbad1e		',
        borderColor: '#fbad1e		',
        order: 0,
        data: props.dataset.map(el => {
          return { x: el._id.dateOf, y: el.total };
        }),
        label: labelChart
      }
    ]
  };

  return <Bar data={data} options={option} redraw={true} />;
};
export default ChartAnnually;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'jalali-moment';
import Axios from '../../Axios/Axios';
import Datagrid from '../../components/UI/Datagrid/Datagrid';
import OtherDocumentPage from './OtherDocumentPage';

const OtherDocuments = () => {
  const [ShowPage, SetShowPage] = useState();
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [paginationPageSize, updatepaginationPageSize] = useState(10);
  const [needGetFile, setNeedGetFile] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [allFiles, setAllFiles] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showDocumentPage, setShowDocumentPage] = useState(false);
  const [docID, setDocID] = useState('');
  const [selectRow, setSelectRow] = useState(null);
  const [documnetTypes, setDocumnetTypes] = useState([]);
  let [newFile, setFile] = useState(null);

  const onSelectionChanged = () => {
    setSelectRow(gridApi.getSelectedRows());
  };
  const setGridPageSize = e => {
    gridApi.paginationSetPageSize(Number(e.target.value));
    updatepaginationPageSize(e.target.value);
  };
  const RowRendered = props => {
    const loadDocumentPage = () => {
      setDocID(props.value);
      setShowDocumentPage(true);
    };
    return (
      <span>
        <button
          style={{ height: 20, lineHeight: 0.5 }}
          onClick={loadDocumentPage}
          className="btn btnViewDoc"
        >
          مشاهده
        </button>
      </span>
    );
  };

  const fileGridColumn = [
    {
      headerName: 'نام سند',
      field: 'title',
      resizable: true
    },
    {
      headerName: 'تاریخ سند',
      field: 'docDate'
    },
    {
      headerName: 'سال',
      field: 'year'
    },
    {
      headerName: 'نوع سند',
      field: 'documentType.name'
    },
    {
      headerName: 'ایجاد کننده',
      field: 'createdBy.name'
    },
    {
      field: '_id',
      headerName: '',
      cellRenderer: 'RowRendered',
      filter: false
    }
  ];
  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    if (needGetFile) {
      Axios.get('/api/v1/file/document?isStandard=false', config).then(res => {
        setAllFiles(res.data.data.data);

        Axios.get('/api/v1/documentType?isActive=true', config).then(res => {
          setDocumnetTypes(res.data.data.data);
        });
        setNeedGetFile(false);
      });
    }
  });
  const changeFile = e => {
    setFile(e.target.files[0]);
  };
  const uploadFile = e => {
    e.preventDefault();
    if (newFile && fileType && fileName) {
      const fileYear = moment(new Date())
        .locale('fa')
        .format('YYYY');

      const fileDate = moment(new Date())
        .locale('fa')
        .format('YYYY/MM/DD');
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('title', fileName);
      formData.append('isStandard', false);
      if (newFile.name) {
        const filePath = newFile.name;
        const fileExtension = filePath.split('.').pop();
        formData.append('extension', fileExtension);
      }
      formData.append('documentType', fileType);
      formData.append('year', fileYear);
      formData.append('docDate', fileDate);

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.post('/api/v1/file/document', formData, config).then(res => {
        setShowAdd(false);
        toast.success('سند ایجاد شد');
        setNeedGetFile(true);
        formData.delete('');
        setFileName('');
        setFileType('');
      });
    } else {
      toast.error('لطفا تمام فیلدها را تکمیل نمایید');
    }
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const showDocumentList = show => {
    setNeedGetFile(true);
    setShowDocumentPage(show);
    setDocID();
  };

  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <Col className="mainContent pr-0 pl-0">
        <div className="pageBack">
          {!showDocumentPage ? (
            <Row className="mb-2">
              <Col md={6}>
                <Button
                  variant="btn btn-success float-left mr-2"
                  onClick={handleShowAdd}
                >
                  <i class="fa fa-plus pr-1"></i> افزودن سند
                </Button>
                <Modal show={showAdd} onHide={handleCloseAdd}>
                  <Modal.Header closeButton>
                    <Modal.Title>افزودن سند</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <InputGroup className="mb-1">
                        <label className={'col-sm-4 col-form-label'}>
                          فایل{' '}
                        </label>
                        <div className="col-sm-8 pl-0 pr-0">
                          <Form.File
                            id="file"
                            label="فایل"
                            onChange={changeFile}
                          />
                        </div>
                      </InputGroup>
                      <InputGroup className="mb-1">
                        <label className={'col-sm-4 col-form-label'}>
                          نام سند
                        </label>
                        <FormControl
                          aria-label="fileName"
                          aria-describedby="fileName"
                          value={fileName}
                          onChange={e => setFileName(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup className="mb-1">
                        <label className={'col-sm-4 col-form-label'}>
                          نوع سند
                        </label>
                        <div className="col-sm-8 pl-0 pr-0">
                          <select
                            className={'form-control'}
                            onChange={e => setFileType(e.target.value)}
                          >
                            <option value=""></option>
                            {documnetTypes.map(documnetType => {
                              return (
                                <option value={documnetType._id}>
                                  {documnetType.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </InputGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={uploadFile}>
                      بارگذاری
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
              <Col md={{ span: 3, offset: 3 }}>
                <Form className="row mr-2">
                  <Form.Label className="col-md-8 pt-1 text-left">
                    ردیف در صفحه
                  </Form.Label>
                  <Form.Control
                    className="col-md-4"
                    as="select"
                    name="newUser_role"
                    value={paginationPageSize}
                    onChange={setGridPageSize}
                    required
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                  </Form.Control>
                </Form>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {showDocumentPage ? (
            <OtherDocumentPage
              documentId={docID}
              showDocumentList={showDocumentList}
            />
          ) : (
            <Datagrid
              gridColumn={fileGridColumn}
              rowData={allFiles}
              setGridApi={setGridApi}
              paginationPageSize={paginationPageSize}
              onSelectionChanged={onSelectionChanged}
              onClickItems={RowRendered}
            />
          )}
        </div>
      </Col>
    </Container>
  );
};
export default OtherDocuments;

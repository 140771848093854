import React, { Fragment,useState } from 'react';
import Datagrid from '../../../components/UI/Datagrid/Datagrid';


const WeatherHistory = props => {
  const [gridApi, setGridApi] = useState(null);
  const historyGridColumn = [
    {
      headerName: 'تاریخ',
      field: 'shamsiDate'
    },
    {
      headerName: 'متوسط دما',
      field: 'avgTemprature'
    },
    {
      headerName: 'شهر',
      field: 'city.name'
    }
  ];
  return (
    <Datagrid
    gridColumn={historyGridColumn}
    rowData={props.weatherHistory}
    paginationPageSize={10}
    setGridApi={setGridApi}
  />
  )
};

export default WeatherHistory;
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import Axios from '../../Axios/Axios';
import { CirclePicker } from 'react-color';
import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'jalali-moment';
import AutoCompleteSelect from 'react-select';
import swal from 'sweetalert';
import './calendar.css';

const Calendar = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [eventName, setEventName] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showOpenEvent, setShowOpenEvent] = useState(false);
  const [today, setToday] = useState('');
  const [todayShamsi, setTodayShamsi] = useState('');
  const [needGetData, setNeedGetData] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState([]);
  const [eventType, setEventType] = useState('');
  const [eventTimeFrom, setEventTimeFrom] = useState('');
  const [eventTimeTo, setEventTimeTo] = useState('');
  const [showHour, setShowHour] = useState(false);
  const [color, setColor] = useState('');
  const [eventDesc, setEventDesc] = useState('');

  const [CirclePickerColors, setCirclePickerColors] = useState([
    '#d50000',
    '#f4511e',
    '#e67c73',
    '#0b8043',
    '#33b679',
    '#3f51b5',
    '#039be5',
    '#8e24aa',
    '#7986cb',
    '#f6bf26',
    '#616161'
  ]);

  const Time = [
    { label: '01:00', value: '01:00' },
    { label: '01:15', value: '01:15' },
    { label: '01:30', value: '01:30' },
    { label: '01:45', value: '01:45' },
    { label: '02:00', value: '02:00' },
    { label: '02:15', value: '02:15' },
    { label: '02:30', value: '02:30' },
    { label: '02:45', value: '02:45' },
    { label: '03:00', value: '03:00' },
    { label: '03:15', value: '03:15' },
    { label: '03:30', value: '03:30' },
    { label: '03:45', value: '03:45' },
    { label: '04:00', value: '04:00' },
    { label: '04:15', value: '04:15' },
    { label: '04:30', value: '04:30' },
    { label: '04:45', value: '04:45' },
    { label: '05:00', value: '05:00' },
    { label: '05:15', value: '05:15' },
    { label: '05:30', value: '05:30' },
    { label: '05:45', value: '05:45' },
    { label: '06:00', value: '06:00' },
    { label: '06:15', value: '06:15' },
    { label: '06:30', value: '06:30' },
    { label: '06:45', value: '06:45' },
    { label: '07:00', value: '07:00' },
    { label: '07:15', value: '07:15' },
    { label: '07:30', value: '07:30' },
    { label: '07:45', value: '07:45' },
    { label: '08:00', value: '08:00' },
    { label: '08:15', value: '08:15' },
    { label: '08:30', value: '08:30' },
    { label: '08:45', value: '08:45' },
    { label: '09:15', value: '09:15' },
    { label: '09:00', value: '09:00' },
    { label: '09:30', value: '09:30' },
    { label: '09:45', value: '09:45' },
    { label: '10:00', value: '10:00' },
    { label: '10:15', value: '10:15' },
    { label: '10:30', value: '10:30' },
    { label: '10:45', value: '10:45' },
    { label: '11:00', value: '11:00' },
    { label: '11:15', value: '11:15' },
    { label: '11:30', value: '11:30' },
    { label: '11:45', value: '11:45' },
    { label: '12:00', value: '12:00' },
    { label: '13:15', value: '13:15' },
    { label: '13:30', value: '13:30' },
    { label: '13:45', value: '13:45' },
    { label: '14:00', value: '14:00' },
    { label: '14:15', value: '14:15' },
    { label: '14:30', value: '14:30' },
    { label: '14:45', value: '14:45' },
    { label: '15:00', value: '15:00' },
    { label: '15:15', value: '15:15' },
    { label: '15:30', value: '15:30' },
    { label: '15:45', value: '15:45' },
    { label: '16:00', value: '16:00' },
    { label: '16:15', value: '16:15' },
    { label: '16:30', value: '16:30' },
    { label: '16:45', value: '16:45' },
    { label: '17:00', value: '17:00' },
    { label: '17:15', value: '17:15' },
    { label: '17:30', value: '17:30' },
    { label: '17:45', value: '17:45' },
    { label: '18:00', value: '18:00' },
    { label: '18:15', value: '18:15' },
    { label: '18:30', value: '18:30' },
    { label: '18:45', value: '18:45' },
    { label: '19:00', value: '19:00' },
    { label: '19:15', value: '19:15' },
    { label: '19:30', value: '19:30' },
    { label: '19:45', value: '19:45' },
    { label: '20:00', value: '20:00' },
    { label: '20:15', value: '20:15' },
    { label: '20:30', value: '20:30' },
    { label: '20:45', value: '20:45' },
    { label: '21:00', value: '21:00' },
    { label: '21:15', value: '21:15' },
    { label: '21:30', value: '21:30' },
    { label: '21:45', value: '21:45' },
    { label: '22:00', value: '22:00' },
    { label: '22:15', value: '22:15' },
    { label: '22:30', value: '22:30' },
    { label: '22:45', value: '22:45' },
    { label: '23:00', value: '23:00' },
    { label: '23:15', value: '23:15' },
    { label: '23:30', value: '23:30' },
    { label: '23:45', value: '23:45' },
    { label: '00:00', value: '00:00' },
    { label: '00:15', value: '00:15' },
    { label: '00:30', value: '00:30' },
    { label: '00:45', value: '00:45' }
  ];
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {
    if (needGetData) {
      Axios.get('/api/v1/event', config).then(event => {
        setAllEvents(event.data.data.data);
      });
      Axios.get('/api/v1/users/me', config).then(user => {
        setUserInfo(user.data.data.data);
      });
      setNeedGetData(false);
    }
  });
  const handleDateClick = props => {
    setShowAdd(true);
    setToday(props.dateStr);
    var todayShamsi = moment(props.dateStr)
      .locale('fa')
      .format('dddd, DD MMMM  YYYY ');
    setTodayShamsi(todayShamsi);
  };
  const AddItem = props => {
    if (eventName) {
      let eventTimeFromTemp =
        today.split('T').pop() + 'T' + eventTimeFrom.value;
      let eventTimeToTemp = today.split('T').pop() + 'T' + eventTimeTo.value;

      const eventNameItem = JSON.stringify({
        name: eventName,
        date: today,
        type: eventType,
        createdBy: userInfo._id,
        fromHour: eventTimeFrom ? eventTimeFromTemp : '',
        toHour: eventTimeTo ? eventTimeToTemp : '',
        color: color,
        description: eventDesc
      });
      Axios.post(`/api/v1/event/`, eventNameItem, config).then(org => {
        setShowAdd(false);
        toast.success('رویداد "' + eventName + '" ایجاد شد .');
        setNeedGetData(true);
        setEventName('');
        setEventTimeTo('');
        setEventTimeFrom('');
        setEventDesc('');
        setShowHour(false)
      });
    } else {
      // setshowAlert(true);
    }
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const handleCloseOpenEvent = () => {
    setShowOpenEvent(false);
  };
  const handelEventClick = props => {
    Axios.get('/api/v1/event/' + props.event._def.publicId, config).then(
      currentEvent => {
        setCurrentEvent(currentEvent.data.data.data);
        setShowOpenEvent(true);
      }
    );
    // console.log(props.event);
  };
  const DeleteEvent = e => {
    e.preventDefault();
    swal({
      title: ` آیا می خواهید این رویداد را حذف نمایید   `,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        const body = JSON.stringify({
          active: false
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        Axios.delete(`/api/v1/event/` + currentEvent._id, config).then(note => {
          setNeedGetData(true);
          setShowOpenEvent(false);
          swal('رویداد مورد نظر حذف شد.', {
            icon: 'success'
          });
        });
      }
    });
  };
  const handleChangeComplete = color => {
    setColor(color.hex);
  };
  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <div className="pageBack">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            listPlugin
          ]}
          selectable="true"
          locale="fa"
          timeZone="Iran/Tehran"
          direction="rtl"
          firstDay="6"
          themeSystem="bootstrap"
          displayEventEnd="true"
          editable="true"
          allDayText="تمام روز"
          fixedWeekCount="false"
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          eventBackgroundColor="#57c32b"
          eventBorderColor="#57c32b"
          events={allEvents.map(event => {
            {
              return {
                id: event._id,
                title: event.name,
                date: event.date,
                allDay: event.type === 'FullDay' ? true : false,
                start: event.type === 'FullDay' ? event.date : event.fromHour,
                end: event.type === 'FullDay' ? event.date : event.toHour,
                displayEventTime: true,
                color: event.color
              };
            }
          })}
          showNonCurrentDates="false"
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'listYear,listMonth,listWeek,listDay',
            center: 'prevYear,prev,title,next,nextYear',
            right: 'today dayGridMonth,dayGridWeek,dayGridDay'
          }}
          buttonIcons={{
            prev: 'left-single-arrow',
            next: 'right-single-arrow',
            prevYear: 'left-single-arrow',
            nextYear: 'right-single-arrow'
          }}
          buttonText={{
            today: 'امروز',
            month: 'ماه',
            week: 'هفته',
            day: 'روز',
            listYear: 'لیست سالیانه',
            listWeek: 'هفتگی',
            listMonth: 'ماهیانه',
            listDay: 'روزانه'
          }}
          dateClick={handleDateClick}
          eventClick={handelEventClick}
        />

        <Modal show={showAdd} onHide={handleCloseAdd}>
          <Modal.Header closeButton>
            <Modal.Title>افزودن رویداد</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="نام "
                aria-label="eventName"
                aria-describedby="eventNameID"
                value={eventName}
                onChange={e => setEventName(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <div class="form-check">
                <label class="form-check-label" className="font1">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value=""
                    onChange={e => {
                      e.target.checked
                        ? setEventType('FullDay')
                        : setEventType('Hourly');
                      setShowHour(e.target.checked);
                    }}
                  />
                  روزانه | {todayShamsi}
                </label>
              </div>
            </InputGroup>
            <Row>
              <Col md={5} hidden={showHour}>
                <AutoCompleteSelect
                  isClearable={true}
                  isSearchable={true}
                  value={eventTimeFrom}
                  onChange={newValv => {
                    setEventTimeFrom(newValv);
                  }}
                  placeholder={'شروع'}
                  options={Time.map(el => {
                    return {
                      label: el.label,
                      value: el.value
                    };
                  })}
                />
              </Col>
              <Col md={2} hidden={showHour} className="pt-1 text-center">
                {' '}
                –
              </Col>
              <Col md={5} hidden={showHour}>
                <AutoCompleteSelect
                  isClearable={true}
                  isSearchable={true}
                  value={eventTimeTo}
                  onChange={newValv => {
                    setEventTimeTo(newValv);
                  }}
                  placeholder={'پایان'}
                  options={Time.map(el => {
                    return {
                      label: el.label,
                      value: el.value
                    };
                  })}
                />
              </Col>
            </Row>
            <InputGroup className="mb-1 mt-1 ">
              <Form.Group className="mb-1 w100" controlId="eventDescription">
                <Form.Control
                  as="textarea"
                  placeholder="توضیحات"
                  value={eventDesc}
                  rows={3}
                  onChange={e => setEventDesc(e.target.value)}
                />
              </Form.Group>
            </InputGroup>
            <InputGroup className="mt-2">
              <CirclePicker
                colors={CirclePickerColors}
                color={color ? color : '#57c32b'}
                onChangeComplete={handleChangeComplete}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={AddItem}>
              ذخیره
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showOpenEvent} onHide={handleCloseOpenEvent}>
          <Modal.Header closeButton>
            <Modal.Title> رویداد</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={10}></Col>
              <Col md={2} className="text-right pl-2 deleteEvent">
                <i className="fa fa-trash" onClick={DeleteEvent}></i>
              </Col>
            </Row>
            <div class="grayLbl mb-1">
              <span class="lblBold colorLbl">
                <i class="fas fa-bookmark"></i>
                نام :
              </span>
              <span class="lblNormal">{currentEvent.name}</span>
            </div>
            <div class="grayLbl mb-1">
              <span class="lblBold colorLbl">
                <i className="fas fa-calendar-alt"></i>تاریخ :
              </span>

              <span class="lblNormal">
                {moment(currentEvent.date)
                  .locale('fa')
                  .format('dddd, DD MMMM  YYYY ')}
              </span>
            </div>
            <div class="grayLbl mb-1">
              <span class="lblBold colorLbl">
                <i class="fas fa-clock"></i>
                {currentEvent.type === 'FullDay' ? 'روزانه' : 'ساعتی:'}
              </span>
              <span class="lblNormal">
                {currentEvent.fromHour
                  ? currentEvent.type === 'FullDay'
                    ? ''
                    : currentEvent.fromHour
                        .toString()
                        .split('T')
                        .pop()
                  : ''}
              </span>
              <span class="lblNormal">{currentEvent.fromHour ? '-' : ''} </span>
              <span class="lblNormal">
                {currentEvent.fromHour
                  ? currentEvent.type === 'FullDay'
                    ? ''
                    : currentEvent.toHour
                        .toString()
                        .split('T')
                        .pop()
                  : ''}
              </span>
            </div>
            {currentEvent.description ? (
              <div class="grayLbl mb-1">
                <span class="lblBold colorLbl">
                  <i class="fas fa-comment"></i>
                  توضیحات:
                </span>
                <span class="lblNormal">{currentEvent.description}</span>
              </div>
            ) : (
              ''
            )}
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};
export default Calendar;

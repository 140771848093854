import React, { useState, useEffect, Fragment } from 'react';
import {
  Row,
  Container,
  Button,
  ButtonGroup,
  ToggleButton,
  Col
} from 'react-bootstrap';
import moment from 'jalali-moment';
import { Calendar } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import './ChartControlPanel.css';

const ChartControlPanel = props => {
  const [queryUpdate, setQueryUpdate] = useState(false);
  const [compareActive, setCompareActive] = useState(false);
  const [tempToggle, setTempToggle] = useState(false);
  const [allWeek, setAllWeek] = useState([]);
  const [allCompareWeek, setAllCompareWeek] = useState([]);

  const [chartPeriod, setChartPeriod] = useState('multiyear');
  const [chartCost, setChartCost] = useState(false);
  const [chartYear, setChartYear] = useState('');
  const [chartMonth, setChartMonth] = useState('');
  const [chartWeek, setChartWeek] = useState('');
  const [chartRangeDate, setChartRangeDate] = useState({
    from: null,
    to: null
  });
  const [chartRangeDateConvert, setChartRangeDateConvert] = useState({
    from: null,
    to: null
  });
  const [chartMultiDate, setChartMultiDate] = useState([]);
  const [chartMultiDateConvert, setChartMultiDateConvert] = useState([]);
  const [chartCompareYear, setChartCompareYear] = useState('');
  const [chartCompareMonth, setChartCompareMonth] = useState('');
  const [chartCompareWeek, setChartCompareWeek] = useState('');
  const [showFeaturBox, setShowFeaturBox] = useState(true);
  const [iconFeature, setIconFeature] = useState('fas fa-chevron-down');

  //Check Right Nav Has formula
  const righNavHasFormula = () => {
    return props.righNav.some(nav => nav.navType === 'Formula');
  };
  //Compare Button Click
  const comparetBtnClick = e => {
    if (e.target.value === 'true') {
      setCompareActive(false);
      setChartCompareYear('');
      setChartCompareMonth('');
      setChartCompareWeek('');
      setQueryUpdate(true);
    } else {
      setCompareActive(true);
      //Temprature disable and remove from chart
      if (tempToggle) {
        setTempToggle(false);
        setQueryUpdate(true);
      }
    }
  };
  //Chart Type Bar Line ,...
  // const changeChartType = e => {
  //   console.log(e.target.value)
  //   props.setChartType(e.target.value);
  // };
  const changeChartType = ChartType => {
    props.setChartType(ChartType);
  };

  //Get week Of A year
  const weekArray = year => {
    let allWeekArray = [];
    if (year !== '') {
      let index = 1;
      let fDay =
        moment.from(`${year}-01-01`, 'fa', 'YYYY/MM/DD').format('d') * 1;
      let firstDay = moment
        .from(`${year}-01-01`, 'fa', 'YYYY/MM/DD')
        .add(6 - fDay, 'day')
        .locale('fa')
        .format('YYYY/MM/DD');
      let nextWeek = firstDay;
      let nextYear = year * 1 + 1;
      allWeekArray.push(nextWeek);
      while (
        moment
          .from(nextWeek, 'fa', 'YYYY/MM/DD')
          .add(7, 'day')
          .locale('fa')
          .format('YYYY') < nextYear
      ) {
        nextWeek = moment
          .from(nextWeek, 'fa', 'YYYY/MM/DD')
          .add(7, 'day')
          .locale('fa')
          .format('YYYY/MM/DD');
        index = index + 1;
        allWeekArray.push(nextWeek);
      }
    }
    return allWeekArray;
  };
  //Cost Toggle
  const toggleCost = () => {
    chartCost ? setChartCost(false) : setChartCost(true);
    setQueryUpdate(true);
  };
  //Temprature Toggle
  const toggleTemp = () => {
    tempToggle ? setTempToggle(false) : setTempToggle(true);
    setQueryUpdate(true);
  };
  //chart period onCHange
  const changeChartPeriod = periodType => {
    setChartPeriod(periodType);
    setChartYear('');
    setChartMonth('');
    setChartWeek('');
    setChartRangeDate({ from: null, to: null });
    setChartRangeDateConvert({ from: null, to: null });
    setChartMultiDate([]);
    setChartMultiDateConvert([]);
    setChartCompareYear('');
    setChartCompareMonth('');
    setChartCompareWeek('');
    setCompareActive(false);
    //Temprature disable and remove from chart
    setTempToggle(false);
    //
    if (periodType === 'multiyear') {
      setQueryUpdate(true);
    }
  };
  //change chart year
  const changeChartYear = e => {
    setChartYear(e.target.value);
    setChartMonth('');
    setChartWeek('');
    if (chartPeriod === 'year') {
      setQueryUpdate(true);
    }
    if (chartPeriod === 'week') {
      let weeks = weekArray(e.target.value);
      setAllWeek(weeks);
    }
  };
  //change chart month
  const changeChartMonth = e => {
    setChartMonth(e.target.value);
    setChartWeek('');
    if (chartPeriod === 'month') {
      setQueryUpdate(true);
    }
  };
  //change chart week
  const changeChartWeek = e => {
    setChartWeek(e.target.value);
    if (chartPeriod === 'week') {
      setQueryUpdate(true);
    }
  };
  //Render Footer of Range Callendar with on sub
  const renderFooterCalendarRange = () => {
    return (
      <div>
        <Row>
          <Col xs={12} md={12}>
            <Button
              className={'f1 btn btn-success p-1 mb-1'}
              onClick={() => {
                let fromTemp = `${chartRangeDate.from.year}-${
                  chartRangeDate.from.month.toString().length === 2
                    ? chartRangeDate.from.month
                    : '0' + chartRangeDate.from.month
                }-${
                  chartRangeDate.from.day.toString().length === 2
                    ? chartRangeDate.from.day
                    : '0' + chartRangeDate.from.day
                }`;
                let toTemp = `${chartRangeDate.to.year}-${
                  chartRangeDate.to.month.toString().length === 2
                    ? chartRangeDate.to.month
                    : '0' + chartRangeDate.to.month
                }-${
                  chartRangeDate.to.day.toString().length === 2
                    ? chartRangeDate.to.day
                    : '0' + chartRangeDate.to.day
                }`;
                setChartRangeDateConvert({ from: fromTemp, to: toTemp });
                setQueryUpdate(true);
              }}
            >
              تنظیم گزارش
            </Button>
          </Col>
        </Row>
      </div>
    );
  };
  //Render Footer of Multiday Callendar with on sub
  const renderFooterCalendarMultiDay = () => {
    return (
      <div>
        <Button
          className={'f1 btn btn-success p-1 mb-1'}
          onClick={() => {
            let daysTemp = chartMultiDate.map(el => {
              return (
                el.year +
                '-' +
                `${
                  el.month.toString().length === 2 ? el.month : '0' + el.month
                }` +
                '-' +
                `${el.day.toString().length === 2 ? el.day : '0' + el.day}`
              );
            });
            setChartMultiDateConvert(daysTemp);
            setQueryUpdate(true);
          }}
        >
          تنظیم گزارش
        </Button>
      </div>
    );
  };
  // Change Compare Year
  const changeCompareChartYear = e => {
    setChartCompareYear(e.target.value);
    setChartCompareMonth('');
    setChartCompareWeek('');
    if (chartPeriod === 'year') {
      setQueryUpdate(true);
    }
    if (chartPeriod === 'week') {
      let weeks = weekArray(e.target.value);
      setAllCompareWeek(weeks);
    }
  };
  // Change Compare Month
  const changeCompareChartMonth = e => {
    setChartCompareMonth(e.target.value);
    setChartCompareWeek('');
    if (chartPeriod === 'month') {
      setQueryUpdate(true);
    }
  };
  // Change Compare Week
  const changeCompareChartWeek = e => {
    setChartCompareWeek(e.target.value);
    if (chartPeriod === 'week') {
      setQueryUpdate(true);
    }
  };
  useEffect(() => {
    props.compareDisableFunc.current = () => {
      setCompareActive(false);
      setChartCompareYear('');
      setChartCompareMonth('');
      setChartCompareWeek('');
      setQueryUpdate(true);
    };
    if (queryUpdate) {
      setQueryUpdate(false);
      queryUpdateFunc();
    }
  }, [queryUpdate, props.righNav]);
  const queryUpdateFunc = () => {
    props.setChartQuery({
      kind: chartPeriod,
      year: chartYear,
      month: chartMonth,
      week: chartWeek,
      fromDate: chartRangeDateConvert.from,
      toDate: chartRangeDateConvert.to,
      days: chartMultiDateConvert,
      yearCompare: chartCompareYear,
      monthCompare: chartCompareMonth,
      weekCompare: chartCompareWeek,
      compareActive: compareActive,
      cost: chartCost
    });
    if (tempToggle) {
      props.setNeedTempData(2);
    } else {
      props.setNeedTempData(0);
    }
    props.setNeedLoad(true);
    props.setLoading(true);
  };
  const chartsFeatures = () => {
    if (showFeaturBox) {
      setShowFeaturBox(false);
      setIconFeature('fas fa-chevron-up');
    } else {
      setShowFeaturBox(true);
      setIconFeature('fas fa-chevron-down');
    }
  };
  return (
    <Fragment>
      <Container fluid className="ChartControlPanel">
        <Row>
          <Col xs={12} md={12} className="mb-1 pl-0 pr-0">
            <ul className="ChartType">
              <li className="col-md-2">
                <a
                  className={`${props.chartType === 'Bar' ? 'activeBtn' : ''}`}
                  onClick={() => {
                    changeChartType('Bar');
                  }}
                >
                  <i className="far fa-chart-bar"></i>
                </a>
              </li>
              <li className="col-md-2">
                <a
                  className={`${props.chartType === 'Line' ? 'activeBtn' : ''}`}
                  onClick={() => {
                    changeChartType('Line');
                  }}
                >
                  <i className="fas fa-chart-line"></i>
                </a>
              </li>
              <li className="col-md-2">
                <a
                  className={`${props.chartType === 'Area' ? 'activeBtn' : ''}`}
                  hidden={props.righNav.length > 1}
                  onClick={() => {
                    changeChartType('Area');
                  }}
                >
                  <i className="fas fa-chart-area"></i>
                </a>
              </li>
              <li className="col-md-2">
                <a
                  className={`${
                    props.chartType === 'Stacked' ? 'activeBtn' : ''
                  }`}
                  hidden={righNavHasFormula()}
                  onClick={() => {
                    changeChartType('Stacked');
                  }}
                >
                  <i className="fa fa-bar-chart"></i>
                </a>
              </li>
              <li className="col-md-2">
                <a
                  className={`${props.chartType === 'Pie' ? 'activeBtn' : ''}`}
                  hidden={props.righNav.length > 1 || righNavHasFormula()}
                  onClick={() => {
                    setTempToggle(false);
                    changeChartType('Pie');
                  }}
                >
                  <i className="fas fa-chart-pie"></i>
                </a>
              </li>
              <li className="col-md-2">
                <a
                  className={`${
                    props.chartType === 'Doughnut' ? 'activeBtn' : ''
                  }`}
                  hidden={props.righNav.length > 1 || righNavHasFormula()}
                  onClick={() => {
                    setTempToggle(false);
                    changeChartType('Doughnut');
                  }}
                >
                  <i className="fas fa-chart-pie"></i>
                </a>
              </li>
            </ul>
          </Col>
          {/* <select onChange={changeChartType}>
            <option>Bar</option>
            <option>Line</option>
            <option hidden={props.righNav.length>1}>Area</option>
            <option>Stacked</option>
            <option hidden={props.righNav.length>1}>Pie</option>
            <option hidden={props.righNav.length>1}>Doughnut</option>
          </select> */}
        </Row>
        <Row className="mb-2 brdBottom">
          <Col xs={12} md={12} className="pl-0 pr-0">
            <ul className="panelIcons costIcons mb-2">
              <li>
                <a
                  className={`MytoolTip ${chartCost ? 'activeBtn' : ''}`}
                  onClick={toggleCost}
                >
                  <span className="tooltiptext mt-1">هزینه</span>
                  <i className="fas fa-money-bill-alt"></i>
                </a>
              </li>
              <li>
                <a
                  onClick={toggleTemp}
                  className={`MytoolTip ${tempToggle ? 'btn-active' : ''}  
                ${compareActive ? 'disabled' : ''}  
                ${chartPeriod === 'multiyear' ? 'disabled' : ''}
                ${
                  props.chartType === 'Pie' || props.chartType === 'Doughnut'
                    ? 'disabled'
                    : ''
                } 
            `}
                >
                  <span className="tooltiptext mt-1">دمای هوا</span>
                  C°
                </a>
              </li>
              {/* <li>
                <a data-toggle="tooltip" data-placement="left" title="CO2">
                  CO2
                </a>
              </li> */}
            </ul>
          </Col>
          <div className="w-100"></div>
        </Row>
        <Row className="mb-2 brdBottom chartsFeaturesBox">
          <div className="col-md-12 chartsFeatures" onClick={chartsFeatures}>
            <Row>
              <Col md={6}>امکانات</Col>
              <Col md={6} className="text-right">
                <i className={iconFeature}></i>
              </Col>
            </Row>
          </div>
          <div className="" hidden={showFeaturBox}>
            <ul className="panelIcons mt-2">
              <li>
                <a
                  className={`MytoolTip ${
                    props.showHorLine ? 'activeBtn' : ''
                  }`}
                  onClick={() => {
                    props.setShowHorLine(true);
                  }}
                >
                  <span className="tooltiptext mt-1">معیار مشخص</span>
                  <i className="far fa-chart-bar"></i>
                </a>
              </li>
              <li>
                <a
                  className={`MytoolTip ${props.showTitle ? 'activeBtn' : ''}`}
                  onClick={() => {
                    props.showTitle
                      ? props.setShowTitle(false)
                      : props.setShowTitle(true);
                  }}
                >
                  <span className="tooltiptext mt-1">عنوان چارت</span>
                  <i className="fas fa-eye"></i>
                </a>
              </li>
            </ul>
          </div>
        </Row>
        <Row className="mb-2 ">
          <Col xs={12} md={6} className="pl-0 pr-0 noRaduis">
            <Button
              className={`btn   ${
                chartPeriod === 'multiyear' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('multiyear');
              }}
            >
              همه سالها
            </Button>
            <Button
              className={`btn  ${
                chartPeriod === 'month' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('month');
              }}
            >
              ماهیانه
            </Button>
            <Button
              className={`btn  ${
                chartPeriod === 'range' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('range');
              }}
            >
              بازه زمانی
            </Button>
          </Col>
          <Col xs={12} md={6} className="pl-0 pr-0 noRaduis">
            <Button
              className={`btn   ${
                chartPeriod === 'year' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('year');
              }}
            >
              سالیانه
            </Button>
            <Button
              className={`btn   ${
                chartPeriod === 'week' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('week');
              }}
            >
              هفتگی
            </Button>
            <Button
              className={`btn  ${
                chartPeriod === 'multiDay' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('multiDay');
              }}
            >
              چند روز
            </Button>
          </Col>
        </Row>
        <Row
          className={'mb-1'}
          hidden={
            chartPeriod === 'multiyear' ||
            chartPeriod === 'range' ||
            chartPeriod === 'multiDay'
          }
        >
          <Col xs={12} md={3}>
            <label className={'pr-2 pl-1'}>سال</label>
          </Col>
          <Col xs={12} md={9}>
            <select
              className="ControlPanel"
              className="ControlPanel"
              value={chartYear}
              onChange={changeChartYear}
            >
              <option></option>
              {props.allYear.length > 0
                ? props.allYear[0].map((el, index) => {
                    return (
                      <option key={index} value={el._id}>
                        {el._id}
                      </option>
                    );
                  })
                : ''}
            </select>
          </Col>
        </Row>

        <Row hidden={!(chartPeriod === 'month')}>
          <Col xs={12} md={3}>
            <label className={'pr-2 pl-1'}>ماه</label>
          </Col>
          <Col xs={12} md={9}>
            <select
              className="ControlPanel"
              className="ControlPanel"
              value={chartMonth}
              onChange={changeChartMonth}
            >
              <option></option>
              <option value="01">فروردین</option>
              <option value="02">اردیبهشت</option>
              <option value="03">خرداد</option>
              <option value="04">تیر</option>
              <option value="05">مرداد</option>
              <option value="06">شهریور</option>
              <option value="07">مهر</option>
              <option value="08">آبان</option>
              <option value="09">آذر</option>
              <option value="10">دی</option>
              <option value="11">بهمن</option>
              <option value="12">اسفند</option>
            </select>
          </Col>
        </Row>
        <Row hidden={!(chartPeriod === 'week')}>
          <Col xs={12} md={3}>
            <label className={'pr-2 pl-1'}>هفته</label>
          </Col>
          <Col xs={12} md={9}>
            <select
              className="ControlPanel"
              value={chartWeek}
              onChange={changeChartWeek}
            >
              <option></option>
              {allWeek.map((el, index) => {
                return (
                  <option key={index} value={el.replaceAll('/', '-')}>
                    {index + 1 + '- ' + el}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row hidden={!(chartPeriod === 'range')}>
          <Calendar
            value={chartRangeDate}
            onChange={setChartRangeDate}
            locale="fa"
            calendarclassName={'calendarWidth'}
            renderFooter={renderFooterCalendarRange}
          />
        </Row>
        <Row hidden={!(chartPeriod === 'multiDay')}>
          <Calendar
            value={chartMultiDate}
            onChange={setChartMultiDate}
            locale="fa"
            calendarclassName={'calendarWidth'}
            renderFooter={renderFooterCalendarMultiDay}
          />
        </Row>
        <Row className={'pt-3'}>
          <Col xs={12} md={12}>
            <Button
              className={'f1'}
              hidden={
                chartPeriod === 'multiyear' ||
                chartPeriod === 'range' ||
                chartPeriod === 'multiDay' ||
                props.righNav.length > 1
              }
              variant="success"
              onClick={comparetBtnClick}
              value={compareActive}
            >
              مقایسه با
            </Button>
          </Col>
        </Row>
      </Container>

      <Row className={'mb-1'} hidden={!compareActive}>
        <Col xs={12} md={3}>
          <label className={'pr-2 pl-1'}>سال</label>
        </Col>
        <Col xs={12} md={9}>
          <select
            className="ControlPanel"
            value={chartCompareYear}
            onChange={changeCompareChartYear}
          >
            <option></option>
            {props.allYear.length > 0
              ? props.allYear[0].map((el, index) => {
                  return (
                    <option key={index} value={el._id}>
                      {el._id}
                    </option>
                  );
                })
              : ''}
          </select>
        </Col>
      </Row>
      <Row hidden={!(chartPeriod === 'month') || !compareActive}>
        <Col xs={12} md={3}>
          <label className={'pr-2 pl-1'}>ماه</label>
        </Col>
        <Col xs={12} md={9}>
          <select
            className="ControlPanel"
            value={chartCompareMonth}
            onChange={changeCompareChartMonth}
          >
            <option></option>
            <option value="01">فروردین</option>
            <option value="02">اردیبهشت</option>
            <option value="03">خرداد</option>
            <option value="04">تیر</option>
            <option value="05">مرداد</option>
            <option value="06">شهریور</option>
            <option value="07">مهر</option>
            <option value="08">آبان</option>
            <option value="09">آذر</option>
            <option value="10">دی</option>
            <option value="11">بهمن</option>
            <option value="12">اسفند</option>
          </select>
        </Col>
      </Row>
      <Row hidden={!(chartPeriod === 'week') || !compareActive}>
        <Col xs={12} md={3}>
          <label className={'pr-2 pl-1'}>هفته</label>
        </Col>
        <Col xs={12} md={9}>
          <select
            className="ControlPanel"
            value={chartCompareWeek}
            onChange={changeCompareChartWeek}
          >
            <option></option>
            {allCompareWeek.map((el, index) => {
              return (
                <option key={index} value={el.replaceAll('/', '-')}>
                  {index + 1 + '- ' + el}
                </option>
              );
            })}
          </select>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ChartControlPanel;

import React, { Fragment } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';

import './ConflictData.css';

const ConflictData = props => {
  const conflictUploaded = props.conflictUploaded;
  const newUploaded = props.newUploaded;
  const mixedArray = conflictUploaded.map(el => {
    let newVal = null;
    newUploaded.forEach(element => {
      if (element.shamsiDateTXT === el.shamsiDateTXT) {
        newVal = element.value;
      }
    });
    return {
      ...el,
      newVal: newVal
    };
  });
  return (
    <Fragment>
      <DataTable
        className="flex-row-reverse DPDTConflict"
        sortField="shamsiDateTXT"
        sortOrder={-1}
        value={mixedArray}
        stripedRows
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
      >
        <Column field="shamsiDateTXT" header="تاریخ شمسی" sortable style={{ width: '25%' }}></Column>
        <Column field="value" header="مقدار موجود" style={{ width: '25%' }}></Column>
        <Column field="newVal" header="مقدار جدید" style={{ width: '25%' }}></Column>
      </DataTable>
    </Fragment>
  );
};

export default ConflictData;

import React, { useState, useEffect } from 'react';
import Axios from '../../../Axios/Axios';

import './RightNav.css';

const RightNav = props => {
  const [update, SetUpdate] = useState(false);
  const [AllOrganization, SetAllOrganization] = useState([]);
  const [AllBuilding, SetAllBuilding] = useState([]);
  const [AllMeter, SetAllMeter] = useState([]);
  const [allFormula, SetAllFormula] = useState([]);
  const [selectedRows, SetSelectedRows] = useState([]);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  const SendItemInfo = (NavID, NavType, NavName) => {
    //cache last organization

    // if (NavType === 'Organization' ) {
    //   let tempArraySelected = selectedRows;
    //   let exist = [];
    //   tempArraySelected.forEach((el, index) => {
    //     if (el.navID === NavID) {
    //       exist.push(index);
    //     }
    //   });
    //   if (exist.length > 0 ) {
    //     let temp = localStorage.getItem('prevOrganization');
    //     if (temp) {
    //       temp = JSON.parse(temp);
    //       localStorage.setItem('lastOrganization', JSON.stringify(temp));
    //       localStorage.removeItem('prevOrganization');
    //     }
    //   } else  {
    //     let temp = localStorage.getItem('lastOrganization');
    //     if (temp) {
    //       temp = JSON.parse(temp);
    //       localStorage.setItem('prevOrganization', JSON.stringify(temp));
    //     }
    //     localStorage.setItem(
    //       'lastOrganization',
    //       JSON.stringify({
    //         navID: NavID,
    //         navType: 'Organization',
    //         navName: NavName
    //       })
    //     );
    //   }
    // }
    //منو چند انتخابی
    if (props.type === 'multi-select') {
      let tempArraySelected = selectedRows;
      // //انتخاب نوع یکسان
      // if (tempArraySelected.length > 0 && NavType !== tempArraySelected[0].NavType) {
      //   debugger;
      //   toast.error('نوع منوی انتخابی با انتخاب قبلی متفاوت میباشد.', {
      //     autoClose: 5000
      //   });
      //   return 0;
      // }
      //در صورتی که روی یک نود تکراری کلیک شود از انتخاب حذف میشود ( دفعه اول ادد میشه بعدیش حذف میشه و...)
      let exist = [];
      tempArraySelected.forEach((el, index) => {
        if (el.navID === NavID) {
          exist.push(index);
        }
      });
      //اگر وجود داشت حذف
      if (exist.length > 0) {
        tempArraySelected.splice(exist[0], 1);
      }
      //در غیر اینصورت اضافه شود
      else {
        tempArraySelected.push({
          navID: NavID,
          navType: NavType,
          navName: NavName
        });
      }
      SetSelectedRows(tempArraySelected);
    }
    //منوی یک انتخابی
    else if (props.type === 'single-select') {
      //تو این حالت همیشه منو به پرنت یه آرایه با یک آیتم برمیگرده
      SetSelectedRows([{ navID: NavID, navType: NavType, navName: NavName }]);
    } else if (props.type === 'no-select') {
    } else if (props.type === 'single-select-building') {
      if (NavType === 'Building') {
        SetSelectedRows([{ navID: NavID, navType: NavType, navName: NavName }]);
      }
    } else if (props.type === 'multi-select-building') {
      if (NavType === 'Building') {
        let tempArraySelected = selectedRows;
        let exist = [];
        tempArraySelected.forEach((el, index) => {
          if (el.navID === NavID) {
            exist.push(index);
          }
        });
        //اگر وجود داشت حذف
        if (exist.length > 0) {
          tempArraySelected.splice(exist[0], 1);
        }
        //در غیر اینصورت اضافه شود
        else {
          tempArraySelected.push({
            navID: NavID,
            navType: NavType,
            navName: NavName
          });
        }
        SetSelectedRows(tempArraySelected);
      }
    }
    SetUpdate(true);
  };

  let HasBuilding = '';
  let html = AllOrganization.map((org, index) => {
    return (
      <li className="nav-item">
        <div
          className={`Navbox  ${
            selectedRows.some(SelectedRow => SelectedRow.navID === org._id) ? 'SelectedItem' : 'SimpleItem'
          }`}
        >
          {AllBuilding.some(build => build.organization === org._id) ? (
            <a
              navId={org._id}
              navType="Organization"
              className="nav-link collapsed text-truncate"
              href={'#org' + org._id}
              data-toggle="collapse"
              data-target={'#org' + org._id}
            ></a>
          ) : (
            <a className="nav-link collapsed text-truncate" href={'#org' + org._id} data-target={'#org' + org._id}></a>
          )}

          <a className="navClick " onClick={e => SendItemInfo(org._id, 'Organization', org.name)}>
            <i className="fa fa-university"></i>
            <span className="d-none d-sm-inline">{org.name}</span>
          </a>
        </div>
        <div className="collapse" id={'org' + org._id} aria-expanded="false">
          <ul className="flex-column  nav">
            {AllBuilding.map(build => {
              if (build.organization === org._id) {
                return (
                  <li className="nav-item ">
                    <div
                      className={`Navbox  pl-1  ${
                        selectedRows.some(SelectedRow => SelectedRow.navID === build._id)
                          ? 'SelectedItem'
                          : 'SimpleItem'
                      }`}
                    >
                      {AllMeter.some(meter => meter.building._id === build._id) ||
                      allFormula.some(formula => formula.building === build._id) ? (
                        <a
                          className="nav-link  text-truncate collapsed"
                          href={'#build' + build._id}
                          data-toggle="collapse"
                          data-target={'#build' + build._id}
                        ></a>
                      ) : (
                        <a
                          className="nav-link  text-truncate collapsed"
                          href={'#build' + build._id}
                          data-target={'#build' + build._id}
                        ></a>
                      )}
                      <span className="navClick" onClick={e => SendItemInfo(build._id, 'Building', build.name)}>
                        <i className="fa fa-building"></i>
                        <span> {build.name}</span>
                      </span>
                    </div>
                    <div className="collapse" id={'build' + build._id} aria-expanded="false">
                      <ul className="flex-column nav">
                        <li className="nav-item">
                          {allFormula.map(formula => {
                            if (build._id === formula.building) {
                              return (
                                <div
                                  className={`Navbox  ${
                                    selectedRows.some(SelectedRow => SelectedRow.navID === formula._id)
                                      ? 'SelectedItem'
                                      : 'SimpleItem'
                                  }`}
                                  onClick={e => SendItemInfo(formula._id, 'Formula', formula.name)}
                                >
                                  <a
                                    className="nav-link  text-truncate collapsed"
                                    href={'#formula' + formula._id}
                                    data-target={'#formula' + formula._id}
                                  ></a>
                                  <i className={`  pl-1 fa fa-calculator`} style={{ color: '#ff8f00' }}></i>
                                  <span>{formula.name}</span>
                                </div>
                              );
                            }
                          })}
                          {AllMeter.map(meter => {
                            if (meter.building._id === build._id) {
                              if (meter.hasChild == true) {
                                return (
                                  <div>
                                    <div
                                      className={`Navbox pl-2 ${
                                        selectedRows.some(SelectedRow => SelectedRow.navID === meter._id)
                                          ? 'SelectedItem'
                                          : 'SimpleItem'
                                      }`}
                                    >
                                      <a
                                        className="nav-link  text-truncate collapsed"
                                        href={'#meter' + meter._id}
                                        data-toggle="collapse"
                                        data-target={'#meter' + meter._id}
                                      ></a>
                                      <span
                                        className="navClick"
                                        onClick={e => SendItemInfo(meter._id, 'Meter', meter.name)}
                                      >
                                        <i
                                          className={`  fa ${meter.icon}`}
                                          style={{
                                            color: meter.color
                                          }}
                                        ></i>
                                        <span>{meter.name}</span>
                                      </span>
                                    </div>
                                    {AllMeter.map(Secmeter => {
                                      if (Secmeter.parentMeter) {
                                        if (
                                          Secmeter.hasChild == false &&
                                          Secmeter._id !== meter._id &&
                                          Secmeter.parentMeter._id === meter._id
                                        ) {
                                          return (
                                            <div
                                              className="collapse show"
                                              id={'meter' + meter._id}
                                              aria-expanded="false"
                                            >
                                              <ul className="flex-column nav">
                                                <li className="nav-item ">
                                                  <div
                                                    className={`Navbox pl-2 ${
                                                      selectedRows.some(
                                                        SelectedRow => SelectedRow.navID === Secmeter._id
                                                      )
                                                        ? 'SelectedItem'
                                                        : 'SimpleItem'
                                                    }`}
                                                  >
                                                    <a
                                                      className="nav-link  text-truncate collapsed"
                                                      href={'#meter' + Secmeter._id}
                                                      data-target={'#meter' + Secmeter._id}
                                                    ></a>
                                                    <span
                                                      className="navClick"
                                                      onClick={e => SendItemInfo(Secmeter._id, 'Meter', Secmeter.name)}
                                                    >
                                                      <i
                                                        className={`  pl-1 fa ${Secmeter.icon}`}
                                                        style={{
                                                          color: Secmeter.color
                                                        }}
                                                      ></i>
                                                      <span>{Secmeter.name}</span>
                                                    </span>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          );
                                        }
                                      }
                                    })}
                                  </div>
                                );
                              }

                              if (meter.hasChild == false) {
                                if (!meter.parentMeter) {
                                  return (
                                    <div
                                      className={`Navbox  ${
                                        selectedRows.some(SelectedRow => SelectedRow.navID === meter._id)
                                          ? 'SelectedItem'
                                          : 'SimpleItem'
                                      }`}
                                      onClick={e => SendItemInfo(meter._id, 'Meter', meter.name)}
                                    >
                                      <a
                                        className="nav-link  text-truncate collapsed"
                                        href={'#meter' + meter._id}
                                        data-target={'#meter' + meter._id}
                                      ></a>
                                      <i className={`  pl-1 fa ${meter.icon}`} style={{ color: meter.color }}></i>
                                      <span>{meter.name}</span>
                                    </div>
                                  );
                                }
                              }
                            }
                          })}
                        </li>
                      </ul>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </li>
    );
  });

  useEffect(() => {
    if (props.isRenderNavbar) {
      Axios.get('/api/v1/organization', config).then(org => {
        SetAllOrganization(org.data.data.data);
        // مقدار دهی اولیه منو با اولین سازمان
        if (
          org.data.data.data.length > 0 &&
          props.type !== 'no-select' &&
          props.type !== 'single-select-building' &&
          props.type !== 'multi-select-building'
        ) {
          const tempArr = [];
          tempArr.push({
            navID: org.data.data.data[0]._id,
            navType: 'Organization',
            navName: org.data.data.data[0].name
          });

          SetSelectedRows(tempArr);
          SetUpdate(true);
        }
      });
      Axios.get(`/api/v1/Building/`, config).then(res => {
        SetAllBuilding(res.data.data.data);
      });
      Axios.get(`/api/v1/Meter/`, config).then(res => {
        SetAllMeter(res.data.data.data);
      });
      Axios.get(`/api/v1/util/formula?type=simple`, config).then(res => {
        SetAllFormula(res.data.data.data);
      });
      props.setIsRenderNavbar(false);
      SetSelectedRows([]);
    }
    if (update) {
      props.parentCallback(selectedRows);
      SetUpdate(false);
    }
  });

  const ResetSelectedNav = () => {
    SetSelectedRows([]);
  };
  return (
    <div id="sidebar-wrapper">
      <div id="rightNav">
        <div class="" hidden={!props.showResetnav} onClick={ResetSelectedNav}>
          {/* فیلتر */}
        </div>
        <div className="list-group list-group-flush">
          <ul className="nav flex-column flex-nowrap overflow-hidden">{html}</ul>
        </div>
      </div>
    </div>
  );
};
export default RightNav;

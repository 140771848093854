import React, { Fragment } from 'react';
import moment from 'jalali-moment';


const UploadHistory = props => {
  const uploadCSVHTML = (
    <div>
      <table className="table table-striped GreenTable">
        <thead className="thead-dark">
          <th scope="col" key="1">
            عنوان فایل
          </th>
          <th scope="col" key="1">
            تاریخ ایجاد
          </th>
          <th scope="col" key="1">
            ایجاد توسط
          </th>
          <th scope="col" key="1">
            فایل
          </th>
        </thead>
        <tbody>
          {props.historyConsumption.map(el => {
            return (
              <tr>
                <td>{el.title}</td>
                <td>
                  {moment(el.created)
                    .locale('fa')
                    .format('YYYY-MM-DD')}
                </td>
                <td>{el.createdBy.name}</td>
                <td>
                  <a href={el.path.replace('/client/public', '')}>
                    <i class="fa fa-file"></i>
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
  return <Fragment>{uploadCSVHTML}</Fragment>;
};

export default UploadHistory;

import Axios from '../Axios/Axios';
import axios from 'axios';

import { Login_Failed, Login_Success, Logout, Me } from './types';
import { toast } from 'react-toastify';

//login user
export const login = ({ username, password }) => async dispatch => {
  const config = { headers: { 'Content-Type': 'application/json' } };
  const body = JSON.stringify({ email: username, password: password });

  try {
    const res = await axios.post('/api/v1/users/login', body, config);
    toast.success('شما با موفقیت وارد شدید.', {
      onClose: () => {
        dispatch({ type: Login_Success, payload: res.data });
      }
    });
  } catch (err) {
    if (err.response.status === 500) {
      dispatch({ type: Login_Failed });
      toast.error(err.response.statusText, { autoClose: 6000 });
    } else {
      dispatch({ type: Login_Failed });
      toast.error(
        'نام کاربری و رمز عبور خود را اشتباه وارد نمودید، لطفا مجدد تلاش کنید',
        { autoClose: 6000 }
      );
    }
  }
};

export const logout = () => dispatch => {
  dispatch({ type: Logout });
};
export const me = () => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const res = await Axios.get('/api/v1/users/me', config);
  dispatch({ type: Me, payload: res.data });
};

import React, { useEffect, useState } from 'react';

import CSVReader from 'react-csv-reader';
import { Row, Container, Card, Button, Col ,Spinner} from 'react-bootstrap';
import Axios from '../../Axios/Axios';

import { toast } from 'react-toastify';
import moment from 'jalali-moment';


import WeatherHistory from './weatherHistory/weatherHistory';
import SelectColumn from './SelectColumn/SelectColumn';

const WeatherData = () => {

  const [allWeather, setAllWeather] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [needUpdate, setNeedUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [CSVUploaded, setCSVUploaded] = useState([]);
  const [dateCol, setDateCol] = useState(null);
  const [tempCol, setTempCol] = useState(null);
  const [city, setCity] = useState(null);

  const uploadWeather = async () => {
    setLoading(true);
    let myData = CSVUploaded.slice(1).filter(el => {
      return (el[dateCol] && el[tempCol]);
    });
    myData=myData.map(item => {
      return {
        date: item[dateCol],
        avgTemprature: item[tempCol],
        city: city,
        shamsiDate: moment(item[dateCol])
          .locale('fa')
          .format('YYYY-MM-DD')
      };
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const data = await Axios.post(
      `/api/v1/weather/batch`,
      JSON.stringify({
        allData:myData
      }),
      config
    );
    toast.success('دیتای مورد نظر شما با موفقیت آپلود شد.', {
      onClose: () => {}
    });
    setNeedUpdate(true);
    setLoading(false);
  }

  useEffect(() => {
    if (needUpdate) {
      setLoading(true);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.get(`/api/v1/city`, config).then(res => {
        setAllCity(res.data.data.data);
      });
      Axios.get(`/api/v1/weather`, config).then(res => {
        var tempArray = res.data.data.data;
        setAllWeather(tempArray);
        setNeedUpdate(false);
        setLoading(false);

      });
    }
  });
  return (
    <Card className="content-body dp-card">
      <center hidden={!loading}>
        <Spinner
          animation="grow"
          variant="success"
          className={'spinnerChart'}
        />
      </center>
      <Container fluid className={'m-2'} hidden={loading}>
        <WeatherHistory weatherHistory={allWeather} />
        <Row>
          <CSVReader
            onFileLoaded={(data, fileInfo, originalFile) => {
              setCSVUploaded(data);
            }}
          />
          <span hidden={CSVUploaded.length === 0}>
            اکسل شما شامل {CSVUploaded.length - 1} سطر دیتا است.
          </span>
        </Row>
        <Row hidden={CSVUploaded.length === 0}>
          <form class="form-inline">
            <label class="col-form-label" for="fromDate">
              تاریخ:
            </label>
            <SelectColumn CSVData={CSVUploaded} changeIt={setDateCol} />
            <label class="col-form-label" for="fromDate">
              دما:
            </label>
            <SelectColumn CSVData={CSVUploaded} changeIt={setTempCol} />
            <label class="col-form-label" for="fromDate">
              شهر:
            </label>
            <select
              value={city}
              onChange={e => {
                setCity(e.target.value);
              }}
            >
              <option></option>
              {allCity.map((el, index) => {
                return (
                  <option key={index} value={el._id}>
                    {el.name}
                  </option>
                );
              })}
            </select>
          </form>
        </Row>
        <Row hidden={CSVUploaded.length === 0}>
          <Button onClick={uploadWeather}>آپلود اطلاعات</Button>
        </Row>
      </Container>
    </Card>
  );
};

export default WeatherData;

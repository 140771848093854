import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../../Axios/Axios';
import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import Header from '../../Print/Header';
import Footer from '../../Print/Footer';

const ISODocuments = props => {
  const ISODocumentsReport = useRef();
  
  const [allFiles, setAllFiles] = useState([]);
  const [reportYear, setReportYear] = useState('');
  const [allYear, setAllYear] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const currentReport = props.currentReport;

  useEffect(() => {
    if (props.pageload) {
      Axios.get('/api/v1/file/document?isStandard=true&fields=year,-createdBy,-documentType', config).then(res => {
        setAllYear(res.data.data.data);
        props.setPageload(false);
      });
    }
    if (allFiles.length> 0 && !props.renderPrint) {
      props.setRenderPrint(true);
      window.print();
      setLoading(false);
    }
  });
  // 
  const printReport = async () => {
    
    // setDisBtn(true)
    Axios.get('/api/v1/file/document?isStandard=true&year='+ reportYear, config).then(res => {
      setAllFiles(res.data.data.data);
    });
    setLoading(true);
    props.setRenderPrint(false);
  };
  return (
    <div>
      <Col md={{ span: 4, offset: 4 }} className="d-print-none NewReport ">
        <center hidden={!loading}>
          <Spinner animation="grow" variant="success" className={'spinnerChart'} />
        </center>
        <div hidden={loading}>
          <InputGroup className="mb-1">
            <label className={'col-sm-3 col-form-label'}>سال</label>
            <Form.Control
              as="select"
              className={'col-sm-9'}
              custom
              value={reportYear}
              onChange={e => {
                setReportYear(e.target.value);
                props.setRenderPrint(true);
              }}
            >
              <option></option>
              {allYear.length > 0
                ? allYear.map((el, index) => {
                    return (
                      <option key={index} value={el.year}>
                        {el.year}
                      </option>
                    );
                  })
                : ''}
            </Form.Control>
          </InputGroup>

          <Form.Group className="text-center mt-2 mb-2 Printbtn">
            <Button onClick={printReport} variant="primary " disabled={!reportYear }>
              چاپ گزارش
            </Button>
            <ReactToPrint
              copyStyles="true"
              documentTitle={currentReport ? currentReport : 'Report'}
              trigger={() => (
                <Button hidden variant="primary ">
                  ذخیره
                </Button>
              )}
              content={() => ISODocumentsReport.current}
            />
          </Form.Group>
        </div>
      </Col>

      <div className="pagePrint" ref={ISODocumentsReport}>
        <Header></Header>
        {allFiles.length > 0 ? (
          <div className="prBox">
            <h2 className="mb-2 text-center">مستندات استاندارد در سال  {reportYear}</h2>
            <Table striped bordered hover>
              <thead className="headTable">
                <tr>
                  <th className="lbl">نام سند</th>
                  <th className="lbl">تاریخ سند</th>
                  <th className="lbl">نوع سند </th>
                  <th className="lbl"> بند مرتبط</th>
                  <th className="lbl">ایجاد کننده </th>
                </tr>
              </thead>
              <tbody>
                {allFiles.map(FileItem => {
                  return (
                    <tr className="trTable">
                      <td>{FileItem.title}</td>
                      <td>{FileItem.docDate}</td>
                      <td>{FileItem.documentType.name}</td>
                      <td>{FileItem.standardNumber}</td>
                      <td>{FileItem.createdBy.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
         ) : (
          ''
        )} 
        <Footer></Footer>
      </div>
    </div>
  );
};

export default ISODocuments;

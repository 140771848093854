import React, { useState, useEffect } from 'react';
import './DocumentPage.css';
import FileViewer from 'react-file-viewer';
import { Row, Container, Card, Button, Col, Spinner, FormControl, Modal, InputGroup, Form } from 'react-bootstrap';
import moment from 'jalali-moment';
import Axios from '../../Axios/Axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { Link } from 'react-scroll';
import AutoCompleteSelect from 'react-select';
import Note from '../../components/UI/Note/Note';

const OtherDocumentPage = props => {
  const [currentFile, setCurrentFile] = useState();
  const [needGetFile, setNeedGetFile] = useState(true);
  const [fileIcon, setFileIcon] = useState(true);

  const [notesRefresh, setNotesRefresh] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [fileType, setFileType] = useState('');
  const [fileName, setFileName] = useState('');
  const [documnetTypes, setDocumnetTypes] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    if (needGetFile) {
      Axios.get('/api/v1/file/document/' + props.documentId, config).then(res => {
        setCurrentFile(res.data.data.data);
        switch (res.data.data.data.extension) {
          case 'xlsx':
            setFileIcon('fas fa-file-excel');
            break;
          case 'xls':
            setFileIcon('fas fa-file-excel');
            break;
          case 'docx':
            setFileIcon('fas fa-file-word');
            break;
          case 'doc':
            setFileIcon('fas fa-file-word');
            break;
          case 'csv':
            setFileIcon('fas fa-file-csv');
            break;
          case 'pptx':
            setFileIcon('fas fa-file-powerpoint');
            break;
          case 'ppt':
            setFileIcon('fas fa-file-powerpoint');
            break;
          case 'pdf':
            setFileIcon('fas fa-file-pdf');
            break;
          case 'jpg':
            setFileIcon('fas fa-file-image');
            break;
          case 'png':
            setFileIcon('fas fa-file-image');
            break;
          case 'jpeg':
            setFileIcon('fas fa-file-image');
            break;

          default:
            setFileIcon('fas fa-file');
            break;
        }
        Axios.get('/api/v1/documentType?isActive=true', config).then(res => {
          setDocumnetTypes(res.data.data.data);
        });
        setNeedGetFile(false);
      });
    }
  });

  const DeletedFile = e => {
    e.preventDefault();
    swal({
      title: ` آیا می خواهید این سند را حذف نمایید   `,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        const body = JSON.stringify({
          active: false
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        Axios.delete(`/api/v1/file/document/` + currentFile._id, config).then(note => {
          setNotesRefresh(true);
          setNeedGetFile(true);
          props.showDocumentList(false);
          swal('سند مورد نظر حذف شد.', {
            icon: 'success'
          });
        });
      }
    });
  };
  const editFile = e => {
    e.preventDefault();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const fileData = JSON.stringify({
      title: fileName ? fileName : currentFile.title,
      documentType: fileType ? fileType : currentFile.documentType
    });
    Axios.patch('/api/v1/file/document/' + props.documentId, fileData, config).then(res => {
      setShowAdd(false);
      toast.success('سند ویرایش شد ');
      setFileName('');
      setFileType('');
    });
    Axios.get('/api/v1/file/document/' + props.documentId, config).then(res => {
      setCurrentFile(res.data.data.data);
      setNeedGetFile(false);
    });
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleCloseAdd = () => {
    setShowAdd(false);
    setFileName(currentFile.title);
    setFileType(currentFile.documentType);
  };
  const showOtherDocumentsList = () => {
    props.showDocumentList(false);
  };

  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <Row>
        <Col lg={12} md={12} className="mainContent">
          <div className="text-center">
            <button className="mb-2 btn-dark" onClick={showOtherDocumentsList}>
              نمایش لیست مستندات{' '}
            </button>
          </div>
          <div id="DocumentPage">
            {currentFile ? (
              <div>
                <Row className="DocTopBar">
                  <Col md={8}>
                    <div className="docTitle">
                      <i className={fileIcon}></i>
                      {currentFile.title}
                      <span className="fontSmall">({currentFile.extension})</span>
                    </div>
                  </Col>
                  <Col md={4} className="text-right">
                    <a className="btn-simple MytoolTip" href={currentFile.path.replace('/client/public', '')}>
                      <span class="tooltiptext">دانلود </span>
                      <i className="fa fa-download"></i>
                    </a>
                    <Link className="btn-simple MytoolTip" to="notes" offset={50} smooth={true}>
                      <span class="tooltiptext">کامنت ها </span>
                      <i className="fas fa-comments"></i>
                    </Link>
                    <Button variant="simple" className="MytoolTip" onClick={handleShowAdd}>
                      <span class="tooltiptext">ویرایش </span>
                      <i className="fa fa-pen"></i>
                    </Button>
                    <Modal show={showAdd} onHide={handleCloseAdd}>
                      <Modal.Header closeButton>
                        <Modal.Title>ویرایش سند</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <InputGroup className="mb-1">
                            <label className={'col-sm-4 col-form-label'}>نام سند</label>
                            <FormControl
                              aria-label="fileName"
                              aria-describedby="fileName"
                              value={fileName}
                              placeholder={currentFile.title}
                              onChange={e => setFileName(e.target.value)}
                            />
                          </InputGroup>
                          <InputGroup className="mb-1">
                            <label className={'col-sm-4 col-form-label'}>نوع سند</label>
                            <div className="col-sm-8 pl-0 pr-0">
                              <select className={'form-control'} onChange={e => setFileType(e.target.value)}>
                                <option selected value={currentFile.documentType._id}>
                                  {currentFile.documentType.name}
                                </option>
                                {documnetTypes.map(documnetType => {
                                  return <option value={documnetType._id}>{documnetType.name}</option>;
                                })}
                              </select>
                            </div>
                          </InputGroup>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={editFile}>
                          ذخیره{' '}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Button variant="simple" className="MytoolTip" onClick={DeletedFile}>
                      <span class="tooltiptext">حذف </span>
                      <i className="fa fa-trash"></i>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Row className="DocumentFields">
                      <Col md={4}>
                        <div className="filedBox">
                          <span class="lblBold">
                            <i className="fa fa-tag"></i>
                            نوع سند:
                          </span>
                          <span class="lblNormal">{currentFile.documentType.name}</span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="filedBox">
                          <span class="lblBold">
                            <i className="fa fa-calendar"></i>
                            تاریخ سند:
                          </span>
                          <span class="lblNormal">{currentFile.docDate}</span>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="filedBox">
                          <span class="lblBold">
                            <i className="fa fa-user"></i>
                            ایجاد کننده:
                          </span>
                          <span class="lblNormal">{currentFile.createdBy.name}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    {/* <FileViewer
                      fileType={currentFile.fileExtension}
                      filePath={require(currentFile.path)}
                    /> */}
                    <FileViewer fileType="docx" filePath={require('./ISO 50001-2018.docx')} />
                  </Col>
                  <Note
                    itemId={currentFile ? currentFile._id : null}
                    type="document"
                    notesRefresh={notesRefresh}
                    itemName={currentFile ? currentFile.title : null}
                    setNotesRefresh={setNotesRefresh}
                  ></Note>
                </Row>
              </div>
            ) : (
              ''
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default OtherDocumentPage;

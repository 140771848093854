import React, { useState, useEffect } from 'react';
import Axios from '../../../Axios/Axios';

import {
  Row,
  Button,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Alert,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import AutoCompleteSelect from 'react-select';
import './organizationItem.css';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import ModuleList from '../../../components/ModuleList/ModuleList.json';

const OrganizationItem = props => {
  const [Organization, SetOrganization] = useState([]);
  const [allBuilding, SetAllBuilding] = useState([]);
  const [allSite, SetAllSite] = useState([]);

  const [AllCity, setAllCity] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [ShowAddBuild, setShowAddBuild] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [OrgName, setOrgName] = useState('');
  const [OrgNameEdit, setOrgNameEdit] = useState('');
  const [RenderOrganization, setRenderOrganization] = useState(false);
  const [RenderBuilding, setRenderBuilding] = useState(false);
  const [BuildingName, setBuildingName] = useState();
  const [BuildingCity, setBuildingCity] = useState();
  const [BuildingType, setBuildingType] = useState();
  const [BuildingArea, setBuildingArea] = useState();
  const [BuildingpeopleCount, setBuildingpeopleCount] = useState();
  const [buildingSite, setBuildingSite] = useState(true);
  const [industrialSite, setIndustrialSite] = useState(true);
  const [siteName, setSiteName] = useState();
  const [siteCity, setSiteCity] = useState();
  const [siteStandard, setSiteStandard] = useState('');
  const [site, setSite] = useState(null);

  const [loading, setLoading] = useState(false);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {
    Axios.get('/api/v1/organization/' + props.ItemID, config).then(org => {
      SetOrganization(org.data.data.data);
    });
    Axios.get(
      '/api/v1/building?organization=' + props.ItemID + '&kind=Building',
      config
    ).then(build => {
      SetAllBuilding(build.data.data.data);
      setRenderBuilding(false);
    });
    Axios.get(
      '/api/v1/building?organization=' + props.ItemID + '&kind=Site',
      config
    ).then(build => {
      SetAllSite(build.data.data.data);
      setRenderBuilding(false);
    });
    Axios.get('/api/v1/City', config).then(city => {
      setAllCity(city.data.data.data);
    });
    setIndustrialSite(true);
    setBuildingSite(true);
  }, [props.ItemID, RenderOrganization, RenderBuilding]);
  // Start Add Organization
  const handleCloseAdd = () => {
    setShowAdd(false);
    setshowAlert(false);
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleCloseEdit = () => {
    setShowEdit(false);
  };
  const handleShowEdit = () => setShowEdit(true);

  const OrgNewItem = JSON.stringify({
    name: OrgName,
    status: 'فعال'
  });
  // add Org
  const AddItem = () => {
    if (OrgName) {
      Axios.post(`/api/v1/organization/`, OrgNewItem, config).then(org => {
        setShowAdd(false);
        setshowAlert(false);
        props.setIsRenderNavbar(true);
        toast.success('سازمان "' + OrgName + '" ایجاد شد .');

        setOrgName([]);
      });
    } else {
      setshowAlert(true);
    }
  };
  // End Add Organization

  // Start Delete Organization
  const DeleteItem = () => {
    if (allBuilding.length > 0 || allSite.length > 0) {
      swal({
        title:
          `لطفا قبل از حذف این سازمان سایت ها و کنتورهای زیرمجموعه آن را حذف نمایید.`,
        text: 'پس از حذف آنها مجدد تلاش نمایید.',
        icon: 'error',
        dangerMode: true
      })
    }
    else {
      swal({
        title:
          ` ایا می خواهید سازمان "` + Organization.name + `" را حذف نمایید   `,
        text: 'این عمل غیرقابل بازگشت میباشد.',
        icon: 'warning',
        dangerMode: true,
        buttons: ['انصراف', 'قبول']
      }).then(willDelete => {
        if (willDelete) {
          const body = JSON.stringify({
            active: false
          });

          Axios.delete(`/api/v1/organization/` + props.ItemID, config).then(
            res => {
              props.setIsRenderNavbar(true);
              props.SetShowPage('d-none');
              swal('سازمان مورد نظر حذف شد.', {
                icon: 'success'
              });
            }
          );
        }
      });
    }


  };
  // End Delete Organization

  // Start Edit Organization
  const EditItem = () => {
    const updateOrganization = JSON.stringify({
      name: OrgNameEdit
    });
    Axios.patch(
      `/api/v1/organization/` + props.ItemID,
      updateOrganization,
      config
    ).then(org => {
      props.setIsRenderNavbar(true);
      setShowEdit(false);
      setRenderOrganization(true);
    });
  };
  // End Edit Organization

  // Start Add Building
  const handleCloseAddBuild = () => {
    setShowAddBuild(false);
  };
  const handleShowAddBuild = () => setShowAddBuild(true);

  const addNewBuilding = e => {
    setLoading(true);
    if (BuildingName && BuildingCity && BuildingType && BuildingArea) {
      let buildType = BuildingType;
      if (BuildingType === 'مسکونی') {
        if (BuildingArea >= 1000) {
          buildType = 'مسکونی بزرگ';
        } else {
          buildType = 'مسکونی کوچک';
        }
      }
      // switch (buildType) {
      //   case 'مسکونی کوچک':
      //     switch (BuildingeClimat) {
      //       case 'بسیار سرد':
      //         EIdeal = 111;
      //         break;
      //       case 'سرد':
      //         EIdeal = 111;
      //         break;
      //       case 'معتدل و بارانی':
      //         EIdeal = 156;
      //         break;
      //       case 'نیمه معتدل و بارانی':
      //         EIdeal = 156;
      //         break;
      //       case 'نیمه خشک':
      //         EIdeal = 83;
      //         break;
      //       case 'گرم و خشک':
      //         EIdeal = 86;
      //         break;
      //       case 'بسیار گرم و خشک':
      //         EIdeal = 150;
      //         break;
      //       case 'بسیار گرم و مرطوب':
      //         EIdeal = 130;
      //         break;
      //     }
      //     break;
      //   case 'مسکونی بزرگ':
      //     switch (BuildingeClimat) {
      //       case 'بسیار سرد':
      //         EIdeal = 102;
      //         break;
      //       case 'سرد':
      //         EIdeal = 102;
      //         break;
      //       case 'معتدل و بارانی':
      //         EIdeal = 106;
      //         break;
      //       case 'نیمه معتدل و بارانی':
      //         EIdeal = 106;
      //         break;
      //       case 'نیمه خشک':
      //         EIdeal = 87;
      //         break;
      //       case 'گرم و خشک':
      //         EIdeal = 75;
      //         break;
      //       case 'بسیار گرم و خشک':
      //         EIdeal = 138;
      //         break;
      //       case 'بسیار گرم و مرطوب':
      //         EIdeal = 118;
      //         break;
      //     }
      //     break;
      //   case 'اداری دولتی':
      //     switch (BuildingeClimat) {
      //       case 'بسیار سرد':
      //         EIdeal = 80;
      //         break;
      //       case 'سرد':
      //         EIdeal = 80;
      //         break;
      //       case 'معتدل و بارانی':
      //         EIdeal = 64;
      //         break;
      //       case 'نیمه معتدل و بارانی':
      //         EIdeal = 64;
      //         break;
      //       case 'نیمه خشک':
      //         EIdeal = 74;
      //         break;
      //       case 'گرم و خشک':
      //         EIdeal = 64;
      //         break;
      //       case 'بسیار گرم و خشک':
      //         EIdeal = 86;
      //         break;
      //       case 'بسیار گرم و مرطوب':
      //         EIdeal = 91;
      //         break;
      //     }
      //     break;
      //   case 'اداری خصوصی':
      //     switch (BuildingeClimat) {
      //       case 'بسیار سرد':
      //         EIdeal = 120;
      //         break;
      //       case 'سرد':
      //         EIdeal = 120;
      //         break;
      //       case 'معتدل و بارانی':
      //         EIdeal = 152;
      //         break;
      //       case 'نیمه معتدل و بارانی':
      //         EIdeal = 152;
      //         break;
      //       case 'نیمه خشک':
      //         EIdeal = 124;
      //         break;
      //       case 'گرم و خشک':
      //         EIdeal = 117;
      //         break;
      //       case 'بسیار گرم و خشک':
      //         EIdeal = 121;
      //         break;
      //       case 'بسیار گرم و مرطوب':
      //         EIdeal = 197;
      //         break;
      //     }
      //     break;
      // }

      const BuildNewItem = JSON.stringify({
        name: BuildingName,
        organization: Organization._id,
        city: BuildingCity.value,
        area: BuildingArea,
        type: buildType,
        peopleCount: BuildingpeopleCount,
        kind: 'Building'
      });
      Axios.post(`/api/v1/Building/`, BuildNewItem, config).then(build => {
        // setLoading(false);
        setBuildingCity(null);
        setBuildingType('');
        setBuildingArea('');
        props.setIsRenderNavbar(true);
        setShowAddBuild(false);
        toast.success('ساختمان "' + BuildingName + '" ایجاد شد .');
        setBuildingName('');
        setBuildingpeopleCount('');
        setRenderOrganization(true);
      });
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 6000 });
      setLoading(false);
    }
  };
  // End Add Building
  const addNewSite = e => {
    setLoading(true);
    e.preventDefault();
    if (siteName && siteCity) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const BuildNewItem = JSON.stringify({
        name: siteName,
        organization: Organization._id,
        city: siteCity.value,
        standard: siteStandard,
        kind: 'Site'
      });
      Axios.post(`/api/v1/Building/`, BuildNewItem, config).then(build => {
        setSite(build.data.data.data);
        toast.success('سایت "' + siteName + '" ایجاد و انتخاب شد .');
        setSiteName('');
        setSiteCity(null);
        setSiteStandard('');
        setShowAddBuild(false);
        props.setIsRenderNavbar(true);
        setRenderOrganization(true);
      });
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 6000 });
    }
  };
  const renderTooltipBuildType = props => (
    <Tooltip id="button-tooltip" {...props}>
      نوع ساختمان را با توجه به مساحت ان انتخاب نمایید نوع ساختمان را با توجه به
      مساحت ان انتخاب نمایید نوع ساختمان را با توجه به مساحت ان انتخاب نمایید
    </Tooltip>
  );
  const changeSiteType = SiteType => {
    switch (SiteType) {
      case 'Building':
        setBuildingSite(false);
        setIndustrialSite(true);
        break;
      case 'Site':
        setIndustrialSite(false);
        setBuildingSite(true);
        break;
      case '':
        setIndustrialSite(true);
        setBuildingSite(true);
        break;
      default:
        break;
    }
  };
  return (
    <div className={props.SetShowPage}>
      <div className="Actionbar">
        <div className="row">
          <div className="col-md-6">
            {/*Start  Add Organization */}

            <Button
              variant="btn btn-success float-left mr-2"
              onClick={handleShowAdd}
            >
              <i class="fa fa-plus"></i> سازمان
            </Button>

            <Modal show={showAdd} onHide={handleCloseAdd}>
              <Modal.Header closeButton>
                <Modal.Title>افزودن سازمان</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="نام سازمان"
                    aria-label="orgName"
                    aria-describedby="orgNameID"
                    value={OrgName}
                    onChange={e => setOrgName(e.target.value)}
                  />
                </InputGroup>
                <Alert variant="danger" show={showAlert}>
                  لطفا نام سازمان را وارد کنید
                </Alert>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={AddItem}>
                  ذخیره
                </Button>
              </Modal.Footer>
            </Modal>

            {/*End  Add Organization */}

            {/*Start  Add Building */}

            <Button
              variant="btn btn-success float-left mr-2"
              onClick={handleShowAddBuild}
            >
              <i class="fa fa-plus"></i> ساختمان
            </Button>

            <Modal
              show={ShowAddBuild}
              onHide={handleCloseAddBuild}
              className="ModalNewForm"
            >
              <Modal.Header closeButton>
                <Modal.Title>افزودن سایت</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup className="mb-1">
                  <label className={'col-sm-4 col-form-label'}>نوع سایت</label>
                  <div className="col-sm-8 pl-0 pr-0">
                    <select
                      className={'form-control'}
                      onChange={e => changeSiteType(e.target.value)}
                    >
                      <option></option>
                      {ModuleList.map(item => {
                        return (
                          <option disabled={!item.active} value={item.value}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </InputGroup>
                {/* ساختمانی */}
                <Form hidden={buildingSite}>
                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>
                      نام ساختمان
                    </label>
                    <FormControl
                      className={'col-sm-8'}
                      aria-label="BuildingName"
                      aria-describedby="BuildingNameID"
                      value={BuildingName}
                      onChange={e => setBuildingName(e.target.value)}
                    />
                  </InputGroup>

                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>شهر</label>
                    <AutoCompleteSelect
                      className={'col-sm-8 pr-0 pl-0'}
                      isClearable={true}
                      isSearchable={true}
                      value={BuildingCity}
                      onChange={newValv => {
                        setBuildingCity(newValv);
                      }}
                      placeholder={'شهر را انتخاب کنید'}
                      options={AllCity.map(el => {
                        return {
                          label: el.state + ' - ' + el.name,
                          value: el._id
                        };
                      })}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1 BTCustomPos">
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipBuildType}
                    >
                      <div className="info noteInput">
                        <i class="fas fa-info-circle"></i>
                      </div>
                    </OverlayTrigger>
                    <label className={'col-sm-4 col-form-label'}>
                      نوع ساختمان
                    </label>

                    <Form.Control
                      as="select"
                      className={'col-sm-8'}
                      custom
                      onChange={e => setBuildingType(e.target.value)}
                    >
                      <option value="ChooseType">
                        نوع ساختمان را انتخاب کنید
                      </option>
                      <option value="مسکونی">مسکونی</option>
                      <option value="اداری دولتی">اداری دولتی </option>
                      <option value="اداری خصوصی">اداری خصوصی </option>
                    </Form.Control>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>
                      مساحت (m2)
                    </label>
                    <FormControl
                      className={'col-sm-8'}
                      aria-label="BuildingArea"
                      aria-describedby="BuildingAreaID"
                      value={BuildingArea}
                      onChange={e => setBuildingArea(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>
                      تعداد نفرات
                    </label>
                    <FormControl
                      className={'col-sm-8'}
                      aria-label="BuildingArea"
                      aria-describedby="BuildingAreaID"
                      value={BuildingpeopleCount}
                      onChange={e => setBuildingpeopleCount(e.target.value)}
                    />
                  </InputGroup>
                  <Alert variant="danger" show={showAlert}>
                    لطفا فیلدهای خالی را تکمیل نمایید
                  </Alert>
                  <Button variant="primary" onClick={addNewBuilding}>
                    ذخیره
                  </Button>
                </Form>
                {/* صنعتی */}
                <Form hidden={industrialSite}>
                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>
                      نام سایت
                    </label>
                    <FormControl
                      className={'col-sm-8'}
                      aria-label="SiteName"
                      aria-describedby="SiteNameID"
                      value={siteName}
                      onChange={e => setSiteName(e.target.value)}
                    />
                  </InputGroup>

                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>شهر</label>
                    <AutoCompleteSelect
                      className={'col-sm-8 pr-0 pl-0'}
                      isClearable={true}
                      isSearchable={true}
                      value={siteCity}
                      onChange={newValv => {
                        setSiteCity(newValv);
                      }}
                      placeholder={'شهر را انتخاب کنید'}
                      options={AllCity.map(el => {
                        return {
                          label: el.state + ' - ' + el.name,
                          value: el._id
                        };
                      })}
                    />
                  </InputGroup>
                  {/* <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>
                      معیار مصرف انرژی
                    </label>
                    <select
                      className={'form-control col-sm-8 EnFont'}
                      value={siteStandard}
                      onChange={e => setSiteStandard(e.target.value)}
                    >
                      <option></option>
                      <option value="isiri42112">ISIRI 42112</option>
                      <option value="isiri67543">ISIRI 67543</option>
                      <option value="isiri77543">ISIRI 77543</option>
                    </select>
                  </InputGroup> */}
                  <Alert variant="danger" show={showAlert}>
                    لطفا فیلدهای خالی را تکمیل نمایید
                  </Alert>
                  <Button variant="primary" onClick={addNewSite}>
                    ذخیره
                  </Button>
                </Form>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>

            {/*End  Add Building */}
          </div>
          <div className="col-md-6">
            {/*Start  Edit Organization */}
            <div>
              <Button
                variant="btn btn-success float-right"
                onClick={handleShowEdit}
              >
                <i class="feather icon-edit"></i>
              </Button>

              <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title>ویرایش سازمان</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder={Organization.name}
                      aria-label="OrgNameEdit"
                      aria-describedby="OrgNameEditID"
                      onChange={e => setOrgNameEdit(e.target.value)}
                    />
                  </InputGroup>
                  <Alert variant="danger" show={showAlert}>
                    لطفا نام سازمان را وارد کنید
                  </Alert>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={EditItem}>
                    ذخیره
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            {/*End  Edit Organization */}
            <div>
              {/*Start  Delete Organization */}
              <Button
                variant="btn btn-danger float-right mr-2"
                onClick={DeleteItem}
              >
                <i class="feather icon-trash"></i>
              </Button>
              {/*End  Delete Organization */}
            </div>
          </div>
        </div>
      </div>
      <div className="Orginfo">
        <Row>
          <Col xs={12} md={12}>
            <h3> اطلاعات سازمان</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <span className="OrgIcon">
              <i class="fa fa-university"></i>
            </span>

            <span className="lblBold">نام:</span>
            <span className="lblNormal">{Organization.name}</span>
          </Col>
        </Row>
        {allBuilding.length ? (
          <div>
            <h3 className="mt-3">ساختمان ها</h3>
            <div className="table-responsive">
              <table class="table table-striped tblOrg">
                <thead>
                  <tr>
                    <th scope="col">نام </th>
                    <th scope="col">نوع</th>
                    <th scope="col">مساحت</th>
                    <th scope="col">عدد ایده آل</th>
                    <th scope="col">استان </th>
                    <th scope="col">شهر</th>
                  </tr>
                </thead>
                <tbody>
                  {allBuilding.map(build => {
                    return (
                      <tr class="clickable ">
                        <td> {build.name}</td>
                        <td> {build.type}</td>
                        <td> {build.area}</td>
                        <td> {build.eIdeal}</td>
                        <td> {build.city.state}</td>
                        <td>{build.city.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ''
        )}

        {allSite.length ? (
          <div>
            <h3 className="mt-3">سایت ها</h3>
            <div className="table-responsive">
              <table class="table table-striped tblOrg">
                <thead>
                  <tr>
                    <th scope="col">نام </th>
                    <th scope="col">معیار مصرف انرژی</th>
                    <th scope="col">استان </th>
                    <th scope="col">شهر</th>
                  </tr>
                </thead>
                <tbody>
                  {allSite.map(build => {
                    return (
                      <tr class="clickable ">
                        <td> {build.name}</td>
                        <td> {build.standard}</td>
                        <td> {build.city.state}</td>
                        <td>{build.city.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default OrganizationItem;

import React, { useState, useEffect, Fragment, useRef } from 'react';
import './Report.css';

import Axios from '../../Axios/Axios';

import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Tabs,
  Tab,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';
import Trend from './AddReportsType/Trend.js';
import EnergyLable from './AddReportsType/EnergyLable.js';
import ISODocuments from './AddReportsType/ISODocuments.js';
import EnBS from './AddReportsType/EnBS.js';

import ReportsPrint from './ReportsPrint.js';

import swal from 'sweetalert';

const Report = () => {
  const [renderReport, setRenderReport] = useState(true);
  const [allReportDefinition, SetAllReportDefinition] = useState([]);
  const [allReportPages, SetAllReportPages] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [reportName, setReportName] = useState('');
  const [reportDesc, setReportDesc] = useState('');
  const [generalType, setGeneralType] = useState('صنعتی');
  const [selectedReport, setSelectedReport] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [activeTab, setActiveTab] = useState();
  const [energyTypeFields, SetEnergyTypeFields] = useState();
  const [resetFrom, setResetFrom] = useState(false);
  const [resetSubFrom, setResetSubFrom] = useState(false);
  const [resetTime, setResetTime] = useState('');
  const [resteRepPage, setResteRepPage] = useState('');
  const [renderPrint, setRenderPrint] = useState(true);

  const formAddReportRef = useRef(null);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (renderReport) {
      Axios.get('/api/v1/ReportDefinition/', config).then(reportDef => {
        SetAllReportDefinition(reportDef.data.data.data);
        setRenderReport(false);
      });
      Axios.get('/api/v1/users/me', config).then(user => {
        setUserInfo(user.data.data.data);
      });
      Axios.get('/api/v1/ReportPage?type=' + generalType + '&sort=order', config).then(reportPage => {
        SetAllReportPages(reportPage.data.data.data);
        setRenderReport(false);
      });
      if (resetFrom) {
        setReportName(' ');
        setReportDesc(' ');
        setResetTime(' ');
        setResteRepPage(' ');
        setRenderReport(true);
      }
    }
    if (resetFrom) {
      setReportName(' ');
      setReportDesc(' ');
      setResetTime(' ');
      setResteRepPage(' ');
      setResetFrom(false);
      setRenderReport(true);
    }
  });

  const ResetCurrentForm = async resetFrom => {
    setResetFrom(resetFrom);
    if (resetFrom) {
      setReportName(' ');
      setReportDesc(' ');
      setResetTime(' ');
      setResteRepPage(' ');
      setRenderReport(true);
    }
  };
  const ChooseReportTypeFields = props => {
    switch (selectedReport) {
      //  گزارش روند
      case '61d03098f8473251581d6769':
        return (
          <Trend
            reportName={reportName}
            reportDesc={reportDesc}
            selectedReport={selectedReport}
            selectedTime={selectedTime}
            resetTime={resetTime}
            ResetCurrentForm={ResetCurrentForm}
          ></Trend>
        );

        break;
      case '61adec9f8785c01b3055b6c8':
        return (
          <EnergyLable
            selectedReport={selectedReport}
            reportName={reportName}
            reportDesc={reportDesc}
            ResetCurrentForm={ResetCurrentForm}
          ></EnergyLable>
        );
        break;
      case '61ed16b06d61de296085b3fe':
        return (
          <ISODocuments
            selectedReport={selectedReport}
            reportName={reportName}
            reportDesc={reportDesc}
            ResetCurrentForm={ResetCurrentForm}
          ></ISODocuments>
        );
        break;
      case '61ed150c6d61de296085b3fc':
        return (
          <EnBS
            selectedReport={selectedReport}
            reportName={reportName}
            reportDesc={reportDesc}
            ResetCurrentForm={ResetCurrentForm}
          ></EnBS>
        );
        break;
      default:
        break;
    }
  };
  const handleSelectTab = key => {
    setActiveTab(key);
  };
  const DeleteReport = RepId => {
    swal({
      title: ` آیا می خواهید این گزارش را حذف نمایید   `,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        const body = JSON.stringify({
          active: false
        });
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        Axios.delete(`/api/v1/ReportDefinition/` + RepId, config).then(note => {
          setRenderReport(true);
          swal('گزارش مورد نظر حذف شد.', {
            icon: 'success'
          });
          setRenderReport(true);
        });
      }
    });
  };
  const ChooseGeneralType = type => {
    setGeneralType(type);
    setRenderReport(true);
  };
  const SelectReporType = selectRow => {
    setSelectedReport(selectRow);
    SetEnergyTypeFields(ChooseReportTypeFields(selectRow));
  };
  const SelectReporTime = selectTime => {
    setSelectedTime(selectTime);
  };
  return (
    <Container fluid id="MainContent">
      <Row>
        <Col lg={12} md={12} className="mainContent">
          <div className="ReportTabs">
            <Tabs
              defaultActiveKey="ReportList"
              id="ReportTabs"
              activeKey={activeTab}
              onSelect={k => handleSelectTab(k)}
            >
              <Tab eventKey="ReportList" tabClassName="listIcon" title="گزارشات">
                <div className="table-responsive">
                  <table class="table table-striped tblOrg">
                    <thead>
                      <tr>
                        <th scope="col">نام </th>
                        <th scope="col">توضیحات</th>
                        <th scope="col">نوع انرژی</th>
                        <th scope="col">کاربر </th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {allReportDefinition.map(rep => {
                        return (
                          <tr>
                            <td> {rep.name}</td>
                            <td>{rep.description}</td>
                            <td>{rep.type}</td>
                            <td>{rep.createdBy.name} </td>
                            <td>
                              {userInfo._id === rep.createdBy._id ? (
                                <a onClick={() => DeleteReport(rep._id)} className="float-left ml-2">
                                  <i className="fa fa-trash"></i>
                                </a>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="NewReport" title="ایجاد گزارش جدید">
                <Form ref={formAddReportRef} className="mt-3">
                  <Col md={{ span: 8, offset: 2 }} className="NewReport">
                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>نام گزارش</label>
                      <FormControl
                        className={'col-sm-9'}
                        aria-label="ReportName"
                        aria-describedby="ReportName"
                        value={reportName}
                        onChange={e => {
                          setReportName(e.target.value);
                          setRenderPrint(true);
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>توضیحات</label>
                      <FormControl
                        className={'col-sm-9'}
                        aria-label="ReportDesc"
                        aria-describedby="ReportDesc"
                        value={reportDesc}
                        onChange={e => setReportDesc(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>حوزه مرتبط</label>
                      <Form.Control
                        as="select"
                        className={'col-sm-9'}
                        custom
                        onChange={e => {
                          ChooseGeneralType(e.target.value);
                          setResetFrom(true);
                          setSelectedReport([]);
                        }}
                      >
                        <option value="صنعتی">صنعتی</option>
                        <option value="ساختمانی">ساختمانی</option>
                        <option value="حمل و نقل">حمل و نقل</option>
                      </Form.Control>
                    </InputGroup>
                  </Col>

                  <Col md={{ span: 8, offset: 2 }} className="NewReport">
                    <div className="table-responsive">
                      <table id="ReportPage" class="table table-striped tblOrg mt-3">
                        <tbody>
                          {allReportPages.map(repPage => {
                            return (
                              <tr id={repPage._id}>
                                <td className="">
                                  <Form.Check
                                    id={repPage._id}
                                    name="ReportCheckBox"
                                    type="radio"
                                    checked={resteRepPage === repPage._id}
                                    onClick={e => {
                                      SelectReporType(e.target.id);
                                      setResteRepPage(repPage._id);
                                      setResetTime(' ');
                                    }}
                                  />
                                </td>
                                <td className="IconFont">
                                  <i class={repPage.icon}></i>
                                </td>
                                <td className="title">
                                  {repPage.name}
                                  <div className="subTitle">
                                    {repPage.description}
                                    {repPage.type.map(item => {
                                      return <li>{item}</li>;
                                    })}
                                  </div>
                                </td>

                                <td className="descFont">
                                  {repPage.time.map(item => {
                                    return (
                                      <div className="unitRadio">
                                        <Form.Check
                                          id={item}
                                          name="ReportCheckBoxhh"
                                          type="radio"
                                          checked={resetTime === item}
                                          onClick={e => {
                                            setResetTime(item);
                                            SelectReporTime(e.target.id);
                                          }}
                                        />
                                        {item}
                                      </div>
                                    );
                                  })}
                                </td>
                                <td>
                                  <a href={`../../../ReportTypes/` + repPage.pdfLink}>
                                    <img src={`../../../ReportTypes/` + repPage.imgLink} height="80" />
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* فیلدها بر اساس  انتخاب نوع گزاش */}
                    {selectedReport ? ChooseReportTypeFields(selectedReport) : ''}
                  </Col>
                </Form>
              </Tab>
              <Tab eventKey="PreviewReport" title="نمایش گزارش">
                <ReportsPrint renderReport={renderReport} setRenderPrint={setRenderPrint} renderPrint={renderPrint} />
              </Tab>
              {/* <Tab eventKey="ReportSetting" title="  تنظیمات کلی (ادمین)">
              تنظیمات کلی (ادمین)
            </Tab> */}
            </Tabs>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Report;

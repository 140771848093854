import React, { useState, useEffect, Fragment } from 'react';
import { Row, Container, Button, Col, Spinner, Form } from 'react-bootstrap';
import Axios from '../../../../Axios/Axios';
import moment from 'jalali-moment';
import SavingChart from './SavingChart/SavingChart';
import './Saving.css';
import { toast } from 'react-toastify';

const Saving = props => {
  const [needData, setNeedData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [consumptionData, setConsumptionData] = useState(0);
  const [estimateData, setEstimateData] = useState(0);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const prepearData = async () => {
    setLoading(true);
    if (
      !props.MVProject.baselinePeriodFrom ||
      !props.MVProject.baselinePeriodTo ||
      !props.MVProject.reportingPeriodTo ||
      !props.MVProject.reportingPeriodFrom ||
      !props.MVProject.meter ||
      !props.MVProject.modelData
    ) {
      toast.error('لطفا برای مشاهده گزارش نهایی اطلاعات مراحل قبل را کامل نمایید.', { autoClose: 6000 });
      setLoading(false);
      return 0;
    }
    const body = {
      kind: 'range',
      fromDate: moment(props.MVProject.baselinePeriodFrom)
        .locale('fa')
        .format('YYYY-MM-DD'),
      toDate: moment(props.MVProject.reportingPeriodTo)
        .locale('fa')
        .format('YYYY-MM-DD'),
      nav: [
        {
          navID: props.MVProject.meter._id,
          navName: props.MVProject.meter.name,
          navType: 'Meter'
        }
      ]
    };
    const consumptions = await Axios.post(`/api/v1/consumption/gstats`, body, config);
    setChartData(consumptions.data.data.data[0][0]);
    let consumption = 0;
    let estimate = 0;
    props.MVProject.modelData.forEach(element => {
      consumption += element.y;
      estimate += element.x;
    });
    setConsumptionData(consumption);
    setEstimateData(estimate);
    setLoading(false);
  };
  useEffect(() => {
    if (needData) {
      setNeedData(false);
      prepearData();
    }
  });
  const calcActual = ((estimateData / props.MVProject.modelData.length) * 365).toFixed();
  const calcPredicted = ((consumptionData / props.MVProject.modelData.length) * 365).toFixed();
  const calcSaving = (((estimateData - consumptionData) / props.MVProject.modelData.length) * 365).toFixed();
  const payBack = (props.MVProject.cost / (calcSaving * props.MVProject.unitCost)).toFixed(2);
  return (
    <form hidden={props.activeStep !== 8}>
      <div>
        <h2>8- گزارش M&V</h2>
        {chartData ? (
          <Fragment>
            <Row className="m-2">
              <SavingChart
                consumptionData={chartData}
                chartBeginECM={moment(props.MVProject.baselinePeriodTo)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
                chartEndECM={moment(props.MVProject.reportingPeriodFrom)
                  .locale('fa')
                  .format('YYYY-MM-DD')}
                predictData={props.MVProject.modelData}
                unit={props.MVProject.meter.unit.unit}
              />
            </Row>
            <Row className="m-2">
              <Col md={{ span: 4 }}>پیشبینی مصرف نرمال سالیانه (بدون انجام پروژه):</Col>
              <Col md={{ span: 4 }} className="predicted">{`${calcActual} ${props.MVProject.meter.unit.unit} (${(
                (calcActual * props.MVProject.unitCost).toFixed() * 1
              ).toLocaleString()} تومان)`}</Col>
            </Row>
            <Row className="m-2">
              <Col md={{ span: 4 }}>مصرف واقعی:</Col>
              <Col md={{ span: 4 }} className="actual">{`${calcPredicted} ${props.MVProject.meter.unit.unit} (${(
                (calcPredicted * props.MVProject.unitCost).toFixed() * 1
              ).toLocaleString()} تومان)`}</Col>
            </Row>
            <Row className="m-2">
              <Col md={{ span: 4 }}>میزان صرفه جویی سالیانه:</Col>
              <Col md={{ span: 4 }} className="saving">{`${calcSaving} ${props.MVProject.meter.unit.unit} (${(
                (calcSaving * props.MVProject.unitCost).toFixed() * 1
              ).toLocaleString()} تومان)`}</Col>
            </Row>
            <Row className="m-2">
              <Col md={{ span: 4 }}>بازگشت سرمایه:</Col>
              <Col md={{ span: 4 }} className="saving">{`${payBack} سال`}</Col>
            </Row>{' '}
          </Fragment>
        ) : (
          ''
        )}
      </div>
      <center hidden={!loading}>
        <Spinner animation="grow" variant="success" className={'spinnerChart'} />
      </center>
    </form>
  );
};

export default Saving;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'jalali-moment';
import Axios from '../../Axios/Axios';
import Datagrid from '../../components/UI/Datagrid/Datagrid';
import DocumentPage from './DocumentPage';
import AutoCompleteSelect from 'react-select';
import './Documents.css';

const Documents = () => {
  const [ShowPage, SetShowPage] = useState();
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [paginationPageSize, updatepaginationPageSize] = useState(10);
  const [needGetFile, setNeedGetFile] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [loading, setLoading] = useState(true);

  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileStandartNumber, setFileStandartNumber] = useState('');
  const [allFiles, setAllFiles] = useState([]);
  const [navFiles, setNavFiles] = useState([]);
  const [currentNavId, setCurrentNavId] = useState('');
  const [noDocument, setNoDocument] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [updateNav, setUpdateNav] = useState(true);
  const [showDocumentPage, setShowDocumentPage] = useState(false);
  const [docID, setDocID] = useState('');
  const [documnetTypes, setDocumnetTypes] = useState([]);
  let [newFile, setFile] = useState(null);

  const standard = [
    { label: '  1-4- درک سازمان و ماهیت آن', value: '4-1' },
    { label: ' 2-4- درک نیازها و انتظارات طرف‌های ذینفع', value: '4-2' },
    { label: ' 4-3- تعیین دامنه کاربرد سیستم مدیریت انرژی', value: '4-3' },
    { label: '4-4- سیستم مدیریت انرژی', value: '4-4' },
    { label: '1-5- تعهد و رهبری', value: '5-1' },
    { label: '2-5- خط مشی انرژی', value: '5-2' },
    { label: ' 3-5- سمت‌ها، مسئولیت‌ها و اختیارات در سازمان', value: '5-3' },
    { label: '  1-6- اقدامات شناسایی ریسک‌ها و فرصت‌ها', value: '6-1' },
    {
      label: '2-6- اهداف کلان و خرد انرژی و طرح‌ریزی برای دستیابی به آن‌ها',
      value: '6-2'
    },
    { label: '3-6- بازنگری انرژی', value: '6-3' },
    { label: '4-6- شاخص‌های عملکرد انرژی', value: '6-4' },
    { label: '5-6- خط مبنای انرژی', value: '6-5' },
    { label: ' 6-6- طرح‌ریزی برای جمع‌آوری داده‌های انرژی', value: '6-6' },
    { label: '1-7- منابع', value: '7-1' },
    { label: '2-7- صلاحیت', value: '7-2' },
    { label: '3-7- آگاهی', value: '7-3' },
    { label: '4-7- ارتباطات', value: '7-4' },
    { label: ' 5-7- اطلاعات مستند شده', value: '7-5' },
    { label: '1-8- طرح‌ریزی و کنترل عملیات', value: '8-1' },
    { label: '2-8- طراحی', value: '8-2' },
    { label: '3-8- تامین', value: '8-3' },
    {
      label:
        ' 1-9- پایش، اندازه‌گیری، تحلیل و ارزیابی عملکرد انرژی سیستم مدیریت انرژی',
      value: '9-1'
    },
    { label: '2-9- ممیزی داخلی', value: '9-2' },
    { label: '3-9- بازنگری مدیریت', value: '9-3' },
    { label: '1-10- عدم انطباق و اقدام اصلاحی', value: '10-1' },
    { label: '2-10- بهبود مستمر', value: '10-2' }
  ];
  const onSelectionChanged = () => {
    setSelectRow(gridApi.getSelectedRows());
  };
  const setGridPageSize = e => {
    gridApi.paginationSetPageSize(Number(e.target.value));
    updatepaginationPageSize(e.target.value);
  };
  const onSubmit = e => {
    e.preventDefault();
    console.log('refresh prevented');
  };
  const RowRendered = props => {
    const loadDocumentPage = () => {
      setDocID(props.value);
      setShowDocumentPage(true);
    };
    return (
      <span>
        <button
          style={{ height: 20, lineHeight: 0.5 }}
          onClick={loadDocumentPage}
          className="btn btnViewDoc"
        >
          مشاهده
        </button>
      </span>
    );
  };

  const fileGridColumn = [
    {
      headerName: 'نام سند',
      field: 'title',
      resizable: true,
      minWidth: 200
    },
    {
      headerName: 'تاریخ سند',
      field: 'docDate'
    },
    {
      headerName: 'سال',
      field: 'year'
    },
    {
      headerName: 'نوع سند',
      field: 'documentType.name'
    },
    {
      headerName: 'بند مرتبط',
      field: 'standardNumber'
    },
    {
      headerName: 'ایجاد کننده',
      field: 'createdBy.name'
    },
    {
      field: '_id',
      headerName: '',
      cellRenderer: 'RowRendered',
      filter: false
    }
  ];
  const navFileGridColumn = [
    {
      headerName: 'نام سند',
      field: 'title',
      minWidth: 200,
      resizable: true
    },
    {
      headerName: 'تاریخ سند',
      field: 'docDate'
    },
    {
      headerName: 'سال',
      field: 'year'
    },
    {
      headerName: 'نوع سند',
      field: 'documentType.name'
    },
    {
      headerName: 'ایجاد کننده',
      field: 'createdBy.name'
    },
    {
      field: '_id',
      headerName: '',
      cellRenderer: 'RowRendered',
      filter: false
    }
  ];

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };

    if (needGetFile) {
      Axios.get('/api/v1/file/document?isStandard=true', config).then(res => {
        setAllFiles(res.data.data.data);
        Axios.get('/api/v1/documentType?isActive=true', config).then(res => {
          setDocumnetTypes(res.data.data.data);
        });
        setNeedGetFile(false);
      });
    }
    if (updateNav) {
      const ClickedNav = document.getElementsByClassName('navclick');
      Array.from(ClickedNav).forEach(element => {
        element.addEventListener('click', () => {
          Axios.get(
            '/api/v1/file/document?standardNumber=' + element.dataset.navclick,
            config
          ).then(res => {
            setNavFiles(res.data.data.data);
            setCurrentNavId(element.dataset.navclick);
            setShowDocumentPage(false);
            setDocID('');

            if (res.data.data.data.length) {
              setNoDocument(true);
            } else {
              setNoDocument(false);
            }
          });
        });
      });
      setUpdateNav(false);
    }
  });
  const changeFile = e => {
    setFile(e.target.files[0]);
  };
  const uploadFile = e => {
    e.preventDefault();

    if (newFile && fileType && fileName) {
      const fileYear = moment(new Date())
        .locale('fa')
        .format('YYYY');

      const fileDate = moment(new Date())
        .locale('fa')
        .format('YYYY/MM/DD');
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('title', fileName);
      formData.append('isStandard', true);
      if (newFile.name) {
        const filePath = newFile.name;
        const fileExtension = filePath.split('.').pop();
        formData.append('extension', fileExtension);
      }
      if (currentNavId) {
        formData.append('standardNumber', currentNavId);
      } else {
        formData.append('standardNumber', fileStandartNumber.value);
      }
      formData.append('documentType', fileType);
      formData.append('year', fileYear);
      formData.append('docDate', fileDate);

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.post('/api/v1/file/document', formData, config).then(res => {
        setShowAdd(false);
        toast.success('سند ایجاد شد');
        setNeedGetFile(true);
        formData.delete('');
        setFileName('');
        setFileType('');
        Axios.get(
          '/api/v1/file/document?standardNumber=' + currentNavId,
          config
        ).then(res => {
          setNavFiles(res.data.data.data);
          if (res.data.data.data.length) {
            setNoDocument(true);
          } else {
            setNoDocument(false);
          }
        });
      });
    } else {
      toast.error('لطفا تمام فیلدها را تکمیل نمایید');
    }
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const showDocumentList = show => {
    setNeedGetFile(true);
    setShowDocumentPage(show);
    setDocID();
  };
  const clearNav = props => {
    setCurrentNavId('');
    showDocumentList(false);
  };
  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3'
                  ? setMainPad('pr-0 pl-2')
                  : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <div className="resetNav" onClick={clearNav}>
              لیست مستندات
            </div>
            <div id="DocRightNav">
              <ul class="nav flex-column flex-nowrap overflow-hidden">
                <li class="nav-item">
                  <a
                    class="nav-link collapsed text-truncate"
                    href="#Standard4"
                    data-target="#Standard4"
                    data-toggle="collapse"
                  >
                    <span class="d-none d-sm-inline">4- ماهیت سازمان</span>
                  </a>
                  <div class="collapse" id="Standard4" aria-expanded="false">
                    <ul class="flex-column  nav">
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '4-1' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="4-1"
                        >
                          1-4- درک سازمان و ماهیت آن
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '4-2' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="4-2"
                        >
                          2-4- درک نیازها و انتظارات طرف‌های ذینفع
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '4-3' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="4-3"
                        >
                          4-3- تعیین دامنه کاربرد سیستم مدیریت انرژی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '4-4' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="4-4"
                        >
                          4-4- سیستم مدیریت انرژی
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link collapsed text-truncate"
                    href="#Standard5"
                    data-target="#Standard5"
                    data-toggle="collapse"
                  >
                    {' '}
                    <span class="d-none d-sm-inline">5- رهبری</span>
                  </a>
                  <div class="collapse" id="Standard5" aria-expanded="false">
                    <ul class="flex-column  nav">
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '5-1' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="5-1"
                        >
                          1-5- تعهد و رهبری
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '5-2' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="5-2"
                        >
                          2-5- خط مشی انرژی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '5-3' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="5-3"
                        >
                          3-5- سمت‌ها، مسئولیت‌ها و اختیارات در سازمان{' '}
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link collapsed text-truncate"
                    href="#Standard6"
                    data-target="#Standard6"
                    data-toggle="collapse"
                  >
                    <span class="d-none d-sm-inline">6- طرح‌ریزی</span>
                  </a>
                  <div class="collapse" id="Standard6" aria-expanded="false">
                    <ul class="flex-column  nav">
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '6-1' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="6-1"
                        >
                          1-6- اقدامات شناسایی ریسک‌ها و فرصت‌ها
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '6-2' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="6-2"
                        >
                          2-6- اهداف کلان و خرد انرژی و طرح‌ریزی برای دستیابی به
                          آن‌ها
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '6-3' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="6-3"
                        >
                          3-6- بازنگری انرژی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '6-4' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="6-4"
                        >
                          4-6- شاخص‌های عملکرد انرژی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '6-5' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="6-5"
                        >
                          5-6- خط مبنای انرژی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '6-6' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="6-6"
                        >
                          6-6- طرح‌ریزی برای جمع‌آوری داده‌های انرژی
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link collapsed text-truncate"
                    href="#Standard7"
                    data-target="#Standard7"
                    data-toggle="collapse"
                  >
                    {' '}
                    <span class="d-none d-sm-inline">7- پشتیبانی</span>
                  </a>
                  <div class="collapse" id="Standard7" aria-expanded="false">
                    <ul class="flex-column  nav">
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '7-1' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="7-1"
                        >
                          1-7- منابع
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '7-2' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="7-2"
                        >
                          2-7- صلاحیت
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '7-3' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="7-3"
                        >
                          3-7- آگاهی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '7-4' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="7-4"
                        >
                          4-7- ارتباطات
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '7-5' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="7-5"
                        >
                          5-7- اطلاعات مستند شده
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link collapsed text-truncate"
                    href="#Standard8"
                    data-target="#Standard8"
                    data-toggle="collapse"
                  >
                    <span class="d-none d-sm-inline">8- عملیات</span>
                  </a>
                  <div class="collapse" id="Standard8" aria-expanded="false">
                    <ul class="flex-column  nav">
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '8-1' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="8-1"
                        >
                          1-8- طرح‌ریزی و کنترل عملیات
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '8-2' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="8-2"
                        >
                          2-8- طراحی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '8-3' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="8-3"
                        >
                          3-8- تامین
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link collapsed text-truncate"
                    href="#Standard9"
                    data-target="#Standard9"
                    data-toggle="collapse"
                  >
                    <span class="d-none d-sm-inline">9- ارزیابی عملکرد</span>
                  </a>
                  <div class="collapse" id="Standard9" aria-expanded="false">
                    <ul class="flex-column  nav">
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '9-1' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="9-1"
                        >
                          1-9- پایش، اندازه‌گیری، تحلیل و ارزیابی عملکرد انرژی
                          سیستم مدیریت انرژی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '9-2' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="9-2"
                        >
                          2-9- ممیزی داخلی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '9-3' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="9-3"
                        >
                          3-9- بازنگری مدیریت
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link collapsed text-truncate"
                    href="#Standard10"
                    data-target="#Standard10"
                    data-toggle="collapse"
                  >
                    {' '}
                    <span class="d-none d-sm-inline">10- بهبود </span>
                  </a>
                  <div class="collapse" id="Standard10" aria-expanded="false">
                    <ul class="flex-column  nav">
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '10-1' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="10-1"
                        >
                          1-10- عدم انطباق و اقدام اصلاحی
                        </div>
                      </li>
                      <li class="nav-item">
                        <div
                          className={`navclick ${
                            currentNavId === '10-2' ? 'SelectedItem' : ''
                          }`}
                          data-navclick="10-2"
                        >
                          2-10- بهبود مستمر
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col className="mainContent pr-0">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3'
                  ? setMainPad('pr-0 pl-0')
                  : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>

            {!docID ? (
              <Row className="mb-2">
                <Col md={6}>
                  <Button
                    variant="btn btn-success float-left mr-2"
                    onClick={handleShowAdd}
                  >
                    <i class="fa fa-plus pr-1"></i> افزودن سند
                  </Button>
                  <Modal show={showAdd} onHide={handleCloseAdd}>
                    <Modal.Header closeButton>
                      <Modal.Title>افزودن سند</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <InputGroup className="mb-1">
                          <label className={'col-sm-4 col-form-label'}>
                            فایل{' '}
                          </label>
                          <div className="col-sm-8 pl-0 pr-0">
                            <Form.File
                              id="file"
                              label="فایل"
                              onChange={changeFile}
                            />
                          </div>
                        </InputGroup>
                        <InputGroup className="mb-1">
                          <label className={'col-sm-4 col-form-label'}>
                            نام سند
                          </label>
                          <FormControl
                            aria-label="fileName"
                            aria-describedby="fileName"
                            value={fileName}
                            onChange={e => setFileName(e.target.value)}
                          />
                        </InputGroup>
                        <InputGroup className="mb-1">
                          <label className={'col-sm-4 col-form-label'}>
                            نوع سند
                          </label>
                          <div className="col-sm-8 pl-0 pr-0">
                            <select
                              className={'form-control'}
                              onChange={e => setFileType(e.target.value)}
                            >
                              <option value=""></option>
                              {documnetTypes.map(documnetType => {
                                return (
                                  <option value={documnetType._id}>
                                    {documnetType.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </InputGroup>

                        {!currentNavId ? (
                          <InputGroup className="mb-1">
                            <label className={'col-sm-4 col-form-label'}>
                              بند استاندارد{' '}
                            </label>
                            <div className="col-sm-8 pl-0 pr-0">
                              <AutoCompleteSelect
                                isClearable={true}
                                isSearchable={true}
                                value={fileStandartNumber}
                                onChange={newValv => {
                                  setFileStandartNumber(newValv);
                                }}
                                placeholder={'بند استاندارد رو انتخاب نمایید'}
                                options={standard.map(el => {
                                  return {
                                    label: el.label,
                                    value: el.value
                                  };
                                })}
                              />
                            </div>
                          </InputGroup>
                        ) : (
                          ''
                        )}
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={uploadFile}>
                        بارگذاری
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
                <Col md={{ span: 3, offset: 3 }}>
                  <Form className="row mr-2">
                    <Form.Label className="col-md-8 pt-1 text-left">
                      ردیف در صفحه
                    </Form.Label>
                    <Form.Control
                      className="col-md-4"
                      as="select"
                      name="newUser_role"
                      value={paginationPageSize}
                      onChange={setGridPageSize}
                      required
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                    </Form.Control>
                  </Form>
                </Col>
              </Row>
            ) : (
              ''
            )}

            {showDocumentPage ? (
              <DocumentPage
                documentId={docID}
                showDocumentList={showDocumentList}
                standard={standard}
              />
            ) : currentNavId ? (
              noDocument && navFiles.length ? (
                <Datagrid
                  gridColumn={navFileGridColumn}
                  rowData={navFiles}
                  setGridApi={setGridApi}
                  paginationPageSize={paginationPageSize}
                  onSelectionChanged={onSelectionChanged}
                  onClickItems={RowRendered}
                />
              ) : (
                <div className="" hidden={noDocument}>
                  سندی در این قسمت وجود ندارد
                </div>
              )
            ) : (
              <Datagrid
                gridColumn={fileGridColumn}
                rowData={allFiles}
                setGridApi={setGridApi}
                paginationPageSize={paginationPageSize}
                onSelectionChanged={onSelectionChanged}
                onClickItems={RowRendered}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Documents;

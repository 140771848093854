import React, { useState, useEffect } from 'react';
import units from './units.json';
import {
  Row,
  Container,
  Button,
  Col,
  Spinner,
  FormControl,
  InputGroup,
  Form
} from 'react-bootstrap';
import './Calculator.css';
const Calculator = () => {
  const [firstUnit, setFirstUnit] = useState(0);
  const [secondUnit, setSecondUnit] = useState('');
  const [fromUnitNumber, setFromUnitNumber] = useState();
  const [toUnitNumber, setToUnitNumber] = useState();
  const [convertNumber, setConvertNumber] = useState();
  const [result, setResult] = useState();
  const [resultDefault, setResultDefault] = useState();
  
  const [needupdate, setNeedupdate] = useState(false);

  useEffect(() => {
    if (!fromUnitNumber) {
      setToUnitNumber();
      setFromUnitNumber();
    }
    if (needupdate) {
      if (firstUnit && secondUnit) {
        const FromUnit = units.filter(item => {
          return item.id === firstUnit;
        });
        // console.log(FromUnit[0]);
        const ToUnit = FromUnit[0].related.filter(item => {
          return item.unit === secondUnit;
        });
        setConvertNumber(Number(ToUnit[0].number));
        console.log(ToUnit[0].number);
        if (firstUnit && secondUnit && fromUnitNumber) {
          // console.log('convertNumber: '+ (Number(ToUnit[0].number)) +'\n')
          // console.log('fromUnitNumber: '+ fromUnitNumber)
          setToUnitNumber(Number(fromUnitNumber) * Number(ToUnit[0].number));
          console.log(Number(fromUnitNumber) * Number(ToUnit[0].number));
          setResult(
            fromUnitNumber +
              ' ' +
              FromUnit[0].name +
              ' = ' +
              Number(fromUnitNumber) * Number(ToUnit[0].number) +
              ' ' +
              secondUnit
          );
        }
      }else if (fromUnitNumber)
      {
        setResultDefault("واحد های مورد نظر را انتخاب نمایید"
         
        );
      }
      setNeedupdate(false);
    }
  });
  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <div className="pageBack">
        <Form className="ltrForm">
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Row>
                <Col md={12} className="text-center ResultBox">
                  <span className="red">{result ? 'Result:' : ''}</span>
                  {/* <div className="">{resultDefault ? resultDefault : ' '}</div> */}
                  
                  <span>{result ? result : resultDefault}</span>
                </Col>
                <Col md={12} className="unitBox">
                  <Row>
                    <Col md={6}>
                      <InputGroup className="mb-1">
                        <FormControl
                          readonly
                          type="number"
                          placeholder=""
                          aria-label="ToUnitNumber"
                          aria-describedby="ToUnitNumber"
                          value={toUnitNumber ? toUnitNumber : ''}
                        />
                      </InputGroup>
                      <InputGroup className="mb-1 ">
                        <Form.Control
                          as="select"
                          sin
                          multiple
                          custom
                          className="SelectToUnit"
                          onChange={e => {
                            setSecondUnit(e.target.value);
                            setNeedupdate(true);
                          }}
                        >
                          {units.map(unit => (
                            <option value={unit.name}>
                              {' '}
                              {unit.name} {unit.abbr}
                            </option>
                          ))}
                        </Form.Control>
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <InputGroup className="mb-1 text-right">
                        <FormControl
                          autofocus
                          type="number"
                          placeholder=""
                          aria-label="FromUnitNumber"
                          aria-describedby="FromUnitNumber"
                          onChange={e => {
                            setFromUnitNumber(Number(e.target.value));
                            setNeedupdate(true);
                          }}
                        />
                      </InputGroup>

                      <InputGroup className="mb-1 ">
                        <Form.Control
                        multiple
                          as="select"
                          custom
                          className="SelectFromUnit"
                          onChange={e => {
                            setFirstUnit(Number(e.target.value));
                            setNeedupdate(true);
                          }}
                        >
                          {units.map((unit, key) => (
                            <option value={unit.id}>
                              {unit.name} {unit.abbr}
                            </option>
                          ))}
                        </Form.Control>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};
export default Calculator;

import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../../../Axios/Axios';
import jalaliMoment from 'jalali-moment';

import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';

import ChartCompareYear from './ChartCompareYear';
import ChartYearMonthly from './ChartYearMonthly';
import ChartMeterList from './ChartMeterList';
import ChartAnnually from './ChartAnnually';

import './AnnuallyTrend.css';

const AnnuallyTrend = props => {
  const [meterData, setMeterData] = useState([]);
  const [pageload, setPageload] = useState(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {
    if (pageload) {
      const body = JSON.stringify({
        kind: 'year',
        year: props.reportYear,
        month: props.reportMonth,
        units: props.currentReport.unit,
        navs: props.currentReport.nav
      });
      Axios.post('/api/v1/reportdata/trend', body, config).then(res => {
        setMeterData(res.data.data);
        props.setPageLoad(true);
        setPageload(false);
      });
    }
  }, [props.currentReport, props.reportYear]);

  return (
    <div>
      <div className="prBox">
        <h1 className="text-center">گزارش روند مصرف سالیانه</h1>
        <h2 className="text-center mb-4">
          <span className="pr-2">سال: {props.reportYear}</span>
        </h2>
        <div className="reportImage">
          <img src="../../../../ReportTypes/TrendMonthIcon.png" className="img-fluid"></img>
        </div>
        <h3 className="text-center fixed-bottom">
          <div className="pb-1">{props.currentReport.createdBy.name}</div>
          <div className="pb-1">{props.currentReport.name}</div>
          <div className="pb-1">
            {jalaliMoment(props.currentReport.created)
              .locale('fa')
              .format('jYYYY/jM/jD')}
          </div>
        </h3>
      </div>
      <div className="prBox">
        <div>
          <table className="table table-striped GreenTable">
            <thead className="headTable">
              <th scope="col" key="1">
                نام
              </th>
              <th scope="col" key="1">
                کنتور مصرفی | پارامتر
              </th>
              <th scope="col" key="1">
                نوع
              </th>
            </thead>

            {meterData.map(element => {
              return (
                <tbody>
                  {element.nav ? (
                    <tr>
                      <td>
                        {element.navData[0].organization ? <span>{element.navData[0].organization.name} | </span> : ''}
                        {element.navData[0].building ? <span>{element.navData[0].building.name} | </span> : ''}
                        {element.nav.navName}
                      </td>
                      <td>{element.unit.name}</td>
                      <td>
                        {element.nav.navType === 'Organization' ? 'کنتور' : ''}
                        {element.nav.navType === 'Building' ? 'ساختمان' : ''}
                        {element.nav.navType === 'Site' ? 'صنعت' : ''}
                        {element.nav.navType === 'Meter' ? 'کنتور' : ''}
                        {element.nav.navType === 'Formula' ? 'فرمول' : ''}
                      </td>
                    </tr>
                  ) : (
                    ' '
                  )}
                </tbody>
              );
            })}
          </table>
        </div>
      </div>

      {meterData.map(element => {
        return (
          <Col md={{ span: 12, offset: 0 }} xs={{ span: 12, offset: 0 }}>
            <div className="prBox">
              <h1 className="text-center mb-3">
                {element.navData[0].organization ? <span>{element.navData[0].organization.name} | </span> : ''}
                {element.navData[0].building ? <span>{element.navData[0].building.name} | </span> : ''}
                {element.nav.navName} - {element.nav ? element.unit.name : ''}
              </h1>
              {element.currentYear.length > 0 ? (
                <Col md={{ span: 8, offset: 2 }} xs={{ span: 12, offset: 0 }}>
                  <Table className="printTable mb-3" bordered>
                    <tbody>
                      <tr>
                        <td className="lbl">
                          مصرف انرژی
                          <span className="unit">
                            {element.unit.type === 'Consumption' && element.currentYear
                              ? element.currentYear.length > 0
                                ? '(' + element.currentYear[0].unit + ')'
                                : ''
                              : ''}
                          </span>
                        </td>
                        <td>
                          {element.unit.type === 'Consumption'
                            ? element.currentYear.length > 0
                              ? element.currentYear[0].total.toFixed(0)
                              : ''
                            : 'معتبر نیست'}
                        </td>
                      </tr>
                      <tr>
                        <td className="lbl">هزینه (تومان)</td>
                        <td>
                          {element.unit.type === 'Consumption'
                            ? element.currentYear.length > 0
                              ? element.currentYear[0].cost.toFixed(0)
                              : ''
                            : 'معتبر نیست'}
                        </td>
                      </tr>
                      <tr>
                        <td className="lbl">
                          {' '}
                          مقایسه با سال قبل{' '}
                          {element.lastYear.length > 0 ? '(' + element.lastYear[0]._id.dateOf + ')' : 'دیتا وجود ندارد'}
                        </td>
                        <td>
                          {element.lastYear.length > 0 && element.currentYear.length > 0 ? (
                            Math.sign(
                              ((element.currentYear[0].total - element.lastYear[0].total) /
                                element.currentYear[0].total) *
                                100
                            ) > 0 ? (
                              <span className="RedText">
                                {(
                                  ((element.currentYear[0].total - element.lastYear[0].total) /
                                    element.currentYear[0].total) *
                                  100
                                ).toFixed(4)}{' '}
                                +
                              </span>
                            ) : (
                              <span className="greenText">
                                {(
                                  (Math.abs(element.currentYear[0].total - element.lastYear[0].total) /
                                    element.currentYear[0].total) *
                                  100
                                ).toFixed(4)}{' '}
                                -
                              </span>
                            )
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="lbl">
                          مقایسه با دو سال قبل(
                          {element.currentYear.length > 0 && element.twoYearAgo.length > 0
                            ? element.twoYearAgo[0]._id.dateOf
                            : 'دیتا وجود ندارد'}
                          )
                        </td>
                        <td>
                          {element.twoYearAgo.length > 0 && element.currentYear.length > 0 ? (
                            Math.sign(
                              ((element.currentYear[0].total - element.twoYearAgo[0].total) /
                                element.currentYear[0].total) *
                                100
                            ) > 0 ? (
                              <span className="RedText">
                                {(
                                  ((element.currentYear[0].total - element.twoYearAgo[0].total) /
                                    element.currentYear[0].total) *
                                  100
                                ).toFixed(4)}{' '}
                                +
                              </span>
                            ) : (
                              <span className="greenText">
                                {(
                                  (Math.abs(element.currentYear[0].total - element.twoYearAgo[0].total) /
                                    element.currentYear[0].total) *
                                  100
                                ).toFixed(4)}{' '}
                                -
                              </span>
                            )
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <span>لیست کنتورها:</span>
                          {element.meterYear.length > 0 ? (
                            <span className="pr-3">
                              {element.meterYear.map(meterName => {
                                return meterName.meter.name;
                              })}
                            </span>
                          ) : (
                            'کنتوری ثبت نشده است'
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              ) : (
                <div class="text-center mt-2">اطلاعاتی جهت نمایش ثبت نشده است</div>
              )}

              {element.currentYear.length > 0 ? (
                <div className="printChartReport firtChart">
                  {element.meterYear.length > 0 && element.unit.type === 'Consumption' && props.reportYear ? (
                    <ChartCompareYear reportYear={props.reportYear} reportMonth={props.reportMonth} dataset={element} />
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            {element.currentYear.length > 0 ? (
              <div className="prBox">
                <div className="printChartReport">
                  {element.prevYears.length > 0 ? <ChartYearMonthly dataset={element} /> : ''}
                </div>

                <div className="printChartReport">
                  {element.prevYears.length > 0 ? <ChartAnnually dataset={element.prevYears} /> : ''}
                </div>
                <div className="printChartReport">
                  {element.meterYear && element.meterYear.length > 0 ? (
                    <ChartMeterList dataset={element.meterYear} />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </Col>
        );
      })}
    </div>
  );
};

export default AnnuallyTrend;

import React from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

const ReportingPeriodChart = props => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;
  defaults.plugins.legend.labels.color = '#121212';
  defaults.plugins.title.color = '#121212';

  const option = {
    maintainAspectRatio: true,
    animation: {
      y: {
        easing: 'linear',
        duration: 2000,
        from: 400
      }
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10
        }
      }
    }
  };
  const data = {
    datasets: [
      {
        backgroundColor: '#57c32b',
        borderColor: '#57c32b',
        data: props.dataset.map(el => {
          return { x: el.dateOf, y: el.total };
        })
      }
    ],
    labels: props.dataset.map(el => {
      return el.dateOf;
    })
  };
  return <Bar data={data} options={option} />;
};

export default ReportingPeriodChart;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'jalali-moment';
import Axios from '../../Axios/Axios';
import Datagrid from '../../components/UI/Datagrid/Datagrid';
import swal from 'sweetalert';

const DocumentType = () => {
  const [ShowPage, SetShowPage] = useState();
  const [canvasWidthRight, setCanvasWidthRight] = useState('3');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('3');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [paginationPageSize, updatepaginationPageSize] = useState(10);
  const [needGetItems, setNeedGetItems] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [documentTypeName, setDocumentTypeName] = useState('');
  const [allDocumentType, setAllDocumentType] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [currentItem, setCurrentItem] = useState('');

  const onSelectionChanged = () => {
    setSelectRow(gridApi.getSelectedRows());
  };
  const setGridPageSize = e => {
    gridApi.paginationSetPageSize(Number(e.target.value));
    updatepaginationPageSize(e.target.value);
  };
  const RowRendered = props => {
    //delete User
    const DeleteItem = () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      swal({
        title: `آیا مطمئن هستید از حذف این نوع سند `,
        text:
          'سندهای موجود با این نوع سند باید به صورت موردی توسط شما اصلاح گردند',
        icon: 'warning',
        dangerMode: true,
        buttons: ['انصراف', 'قبول']
      }).then(willDelete => {
        if (willDelete) {
          const body = JSON.stringify({
            isActive: false
          });
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
          };
          Axios.patch(`/api/v1/documentType/` + props.value, body, config).then(
            res => {
              setNeedGetItems(true);
              swal('نوع سند مورد نظر حذف شد.', {
                icon: 'success'
              });
            }
          );
        } else {
        }
      });
    };
    // Edit User
    const EditItem = () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };

      Axios.get(`/api/v1/documentType/` + props.value, config).then(
        currentItem => {
          setShowEdit(true);
          setCurrentItem(currentItem.data.data.data);
        }
      );
    };
    return (
      <span>
        <button
          style={{ lineHeight: 0.5 }}
          onClick={DeleteItem}
          className="btn btnViewDoc"
        >
          <i className="feather icon-user-x"></i>
        </button>
        <button
          style={{ lineHeight: 0.5 }}
          onClick={EditItem}
          className="btn btnViewDoc"
        >
          <i className="feather icon-user-check"></i>
        </button>
      </span>
    );
  };

  const fileGridColumn = [
    {
      headerName: 'نام ',
      field: 'name',
      resizable: true
    },
    {
      headerName: 'ایجاد کننده',
      field: 'createdBy.name'
    },
    {
      field: '_id',
      headerName: '',
      cellRenderer: 'RowRendered',
      filter: false
    }
  ];
  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    if (needGetItems) {
      // Axios.get('/api/v1/documentType?isActive=true', config).then(res => {
      Axios.get('/api/v1/documentType?isActive=true', config).then(res => {
        setAllDocumentType(res.data.data.data);
      });
      Axios.get('/api/v1/users/me', config).then(user => {
        setUserInfo(user.data.data.data);
      });
      setNeedGetItems(false);
    }
  });
  const AddDocumetnType = e => {
    e.preventDefault();
    if (documentTypeName) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const NewItem = JSON.stringify({
        name: documentTypeName,
        createdBy: userInfo._id,
        isActive: true
      });
      Axios.post('/api/v1/documentType', NewItem, config).then(res => {
        setShowAdd(false);
        toast.success('نوع سند ایجاد شد');
        setNeedGetItems(true);
        setDocumentTypeName('');
      });
    } else {
      toast.error('لطفا نام نوع سند را تکمیل نمایید');
    }
  };
  const EditDocumetnType = e => {
    e.preventDefault();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    let ItemName = '';
    if (documentTypeName) {
      ItemName = documentTypeName;
    } else {
      ItemName = currentItem.name;
    }
    const EditItem = JSON.stringify({
      name: ItemName
    });
    Axios.patch(
      `/api/v1/documentType/${currentItem._id}`,
      EditItem,
      config
    ).then(res => {
      toast.success('نوع سند ویرایش شد');
      setNeedGetItems(true);
      setDocumentTypeName('');
      setShowEdit(false);
    });
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const handleCloseEdit = () => {
    setShowEdit(false);
  };
  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <Col className="mainContent pr-0 pl-0">
        <div className="pageBack">
          <Row className="mb-2">
            <Col md={6}>
              <Button
                variant="btn btn-success float-left mr-2"
                onClick={handleShowAdd}
              >
                <i class="fa fa-plus pr-1"></i> نوع سند
              </Button>
              <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                  <Modal.Title>افزودن نوع سند</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-4 col-form-label'}>نام</label>
                      <FormControl
                        aria-label="documentTypeName"
                        aria-describedby="documentTypeName"
                        value={documentTypeName}
                        onChange={e => setDocumentTypeName(e.target.value)}
                      />
                    </InputGroup>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={AddDocumetnType}>
                    افزودن
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
            <Col md={{ span: 3, offset: 3 }}>
              <Form className="row mr-2">
                <Form.Label className="col-md-8 pt-1 text-left">
                  ردیف در صفحه
                </Form.Label>
                <Form.Control
                  className="col-md-4"
                  as="select"
                  name="newUser_role"
                  value={paginationPageSize}
                  onChange={setGridPageSize}
                  required
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                </Form.Control>
              </Form>
            </Col>

            <Col md={6}>
              <Modal show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title>ویرایش نوع سند</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-4 col-form-label'}>نام</label>
                      <FormControl
                        aria-label="documentTypeName"
                        aria-describedby="documentTypeName"
                        placeholder={currentItem.name}
                        onChange={e => setDocumentTypeName(e.target.value)}
                      />
                    </InputGroup>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={EditDocumetnType}>
                    ذخیره
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
          <Datagrid
            gridColumn={fileGridColumn}
            rowData={allDocumentType}
            setGridApi={setGridApi}
            paginationPageSize={paginationPageSize}
            onSelectionChanged={onSelectionChanged}
            onClickItems={RowRendered}
          />
        </div>
      </Col>
    </Container>
  );
};
export default DocumentType;

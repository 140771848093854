import React, { Fragment, useState, useRef } from 'react';
import './Axie.css';

import AutoCompleteSelect from 'react-select';
import { parse } from 'mathjs';
import { Container, Modal } from 'react-bootstrap';

const Axie = props => {
  const axieRef = useRef();

  const [AxieCModal, setAxieCModal] = useState(false);
  const [AxieCostModal, setAxieCostModal] = useState(false);
  const [AxieOperatorModal, setAxieOperatorModal] = useState(false);

  //Constants
  const allowFunction = [
    'sin',
    'cos',
    'tan',
    'asin',
    'acos',
    'atan',
    'sqrt',
    'log'
  ];
  // const allowConstant = [
  //   { op: 'PI', display: ' Π (PI)' },
  //   { op: '37.68', display: ' ارزش حرارتی گاز' },
  //   { op: '3.7', display: ' ارزش حرارتی برق' },
  //   { op: '37.3', display: ' ارزش حرارتی گازوییل' },
  //   { op: '41', display: ' ارزش حرارتی مازوت' }
  // ];
  const allowFunctionObject = [
    { op: '+', display: ' + (جمع)' },
    { op: '-', display: ' - (تفریق)' },
    { op: '*', display: ' * (ضرب)' },
    { op: '/', display: ' / (تقسیم)' },
    { op: '^', display: ' ^ (توان)' },
    { op: 'sin(', display: ' sin() (sine of )' },
    { op: 'cos(', display: ' cos() (cosine of )' },
    { op: 'tan(', display: ' tan() (tangant of )' },
    { op: 'asin(', display: ' asin() (arc sine of )' },
    { op: 'acos(', display: ' acos() (arc cosine of )' },
    { op: 'atan(', display: ' atan() (arc tangant of )' },
    { op: 'sqrt(', display: ' sqrt() (جذز)' },
    { op: 'log2(', display: ' log2() (لگاریتم بر پایه 2)' },
    { op: 'log10(', display: ' log10() (لگاریتم بر پایه 10)' },
    { op: 'log(,', display: ' log(x,y) (لگاریتم x بر پایه y)' }
  ];
  //Axie Functions Begin >>
  const hideAxieCModal = () => {
    setAxieCModal(false);
  };
  const selectAxieC = event => {
    var mySpan = document.createElement('span');
    mySpan.setAttribute('data-kind', event.kind);
    mySpan.setAttribute('data-type', event.type);
    mySpan.setAttribute('data-nav', event.navType);
    mySpan.setAttribute('data-id', event.value);
    mySpan.setAttribute('data-label', event.label);
    mySpan.contentEditable = false;
    mySpan.title = event.label;
    mySpan.style.backgroundColor = '#f7efd6';
    mySpan.style.padding = '5px';
    mySpan.innerHTML = `[<abbr>${event.abbr}</abbr>]`;
    axieRef.current.append(mySpan);
    axieRef.current.append(document.createTextNode('\u00A0'));
    setAxieCModal(false);
    AxieFormulaValidate();
  };
  const hideAxieCostModal = () => {
    setAxieCostModal(false);
  };
  const selectAxieCost = event => {
    var mySpan = document.createElement('span');
    mySpan.setAttribute('data-kind', event.kind);
    mySpan.setAttribute('data-type', event.type);
    mySpan.setAttribute('data-nav', event.navType);
    mySpan.setAttribute('data-id', event.value);
    mySpan.setAttribute('data-label', event.label);
    mySpan.contentEditable = false;
    mySpan.title = event.label;
    mySpan.style.backgroundColor = '#e4e4e4';
    mySpan.style.padding = '5px';
    mySpan.innerHTML = `[<abbr>${event.abbr}</abbr>]`;
    axieRef.current.append(mySpan);
    axieRef.current.append(document.createTextNode('\u00A0'));
    setAxieCostModal(false);
    AxieFormulaValidate();
  };
  const hideAxieOperatorModal = () => {
    setAxieOperatorModal(false);
  };
  const selectAxieOperator = event => {
    axieRef.current.append(event.value);
    setAxieOperatorModal(false);
    AxieFormulaValidate();
  };
  const AxieFormulaValidate = e => {
    const tempAxieFormulaSynon = [];
    if (axieRef.current.childNodes.length === 0) {
      props.setAxieFormulaSynon(tempAxieFormulaSynon);
      return 0;
    }
    let tempAxieFormula = '';
    let i = 0;
    axieRef.current.childNodes.forEach(element => {
      if (element.nodeName === '#text') {
        tempAxieFormula += element.nodeValue.trim();
      } else if (element.nodeName === 'SPAN') {
        tempAxieFormulaSynon.push({
          id: element.dataset.id,
          kind: element.dataset.kind,
          type: element.dataset.type,
          navType: element.dataset.nav,
          label:element.dataset.label
        });
        tempAxieFormula += 'Synonym' + i;
        i += 1;
      } else {
      }
    });
    try {
      const tempFormula = parse(tempAxieFormula);
      if (tempFormula.hasOwnProperty('value')) {
        if (tempFormula.value === undefined) {
          props.setAxieFormula(null);
          props.setValidAxie(true);
          while (axieRef.current.firstChild) {
            axieRef.current.removeChild(axieRef.current.firstChild);
          }
          return 0;
        }
      }
      let validation = true;
      if (tempFormula.type === 'ConstantNode') {
        validation = false;
      }
      let symbolsValidation = true;
      tempFormula.traverse((node, path, parent) => {
        if (node.type === 'SymbolNode') {
          if (
            node.name.startsWith('Synonym') ||
            node.name === 'PI' ||
            allowFunction.includes(node.name)
          ) {
            symbolsValidation = true;
          } else {
            symbolsValidation = false;
          }
        }
        if (node.type === 'FunctionNode') {
          if (!allowFunction.includes(node.fn.name)) {
            validation = false;
          }
        }
      });
      if (!symbolsValidation) {
        validation = false;
      }
      if (tempAxieFormulaSynon.length === 0) {
        validation = false;
      }
      props.setAxieFormula(tempFormula);
      props.setAxieFormulaSynon(tempAxieFormulaSynon);
      props.setValidAxie(validation);
      props.setAxieFormulaText(tempFormula.toString());
      if (validation && e.type === 'blur') {
        props.setNeedGetYear(true);
      }
    } catch {
      props.setValidAxie(false);
    }
  };
  const AxieFormulaSpecial = event => {
    if (event.charCode === 64) {
      setAxieCModal(true);
    } else if (event.charCode === 36) {
      setAxieCostModal(true);
    } else if (event.charCode === 63) {
      setAxieOperatorModal(true);
    }
    if (event.charCode === 35) {
      setAxieOperatorModal(true);
    }
    props.setChartData([]);

  };
  //Axie Functions End <<
  return (
    <Fragment>
      <p
        contentEditable
        placeholder={props.placehodler}
        ref={axieRef}
        className={`form-control dpInputControl text-right ${
          !props.axieFormula
            ? ''
            : props.validAxie
            ? 'validAxie'
            : 'invalidAxie'
        }`}
        onBlur={AxieFormulaValidate}
        onKeyPress={AxieFormulaSpecial}
        onKeyUp={AxieFormulaValidate}
      ></p>
      {/* Axies Modals */}
      {/* Consumption */}
      <Modal show={AxieCModal} onHide={hideAxieCModal}>
        <Modal.Header closeButton>
          <Modal.Title>مصارف انرژی</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form>
              <AutoCompleteSelect
                className={'col-sm-12 pl-0 pr-0'}
                menuIsOpen={true}
                autoFocus={true}
                isClearable={true}
                isSearchable={true}
                onChange={selectAxieC}
                placeholder={'مقدار مصرف را برای درج در فرمول انتخاب کنید.'}
                options={props.allDataNode.map(el => {
                  return {
                    label: el.name,
                    value: el._id,
                    abbr: el.abbr,
                    navType: el.navType,
                    kind: el.type,
                    displayLabel:el.label,
                    type: 'consumption'
                  };
                })}
              />
            </form>
          </Container>
        </Modal.Body>
      </Modal>
      {/* Cost */}
      <Modal show={AxieCostModal} onHide={hideAxieCostModal}>
        <Modal.Header closeButton>
          <Modal.Title>هزینه ها</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form>
              <AutoCompleteSelect
                className={'col-sm-12 pl-0 pr-0'}
                menuIsOpen={true}
                autoFocus={true}
                isClearable={true}
                isSearchable={true}
                onChange={selectAxieCost}
                placeholder={
                  'مقدار هزینه مصرف را برای درج در فرمول انتخاب کنید.'
                }
                options={props.allDataNode.map(el => {
                  return {
                    label: el.name,
                    value: el._id,
                    abbr: el.abbr,
                    navType: el.navType,
                    kind: el.type,
                    displayLabel:el.label,
                    type: 'cost'
                  };
                })}
              />
            </form>
          </Container>
        </Modal.Body>
      </Modal>
      {/* operator */}
      <Modal show={AxieOperatorModal} onHide={hideAxieOperatorModal}>
        <Modal.Header closeButton>
          <Modal.Title>عملگرهای ریاضی</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form>
              <AutoCompleteSelect
                className={'col-sm-12 pl-0 pr-0'}
                menuIsOpen={true}
                autoFocus={true}
                isClearable={true}
                isSearchable={true}
                onChange={selectAxieOperator}
                placeholder={'از بین عملگرهای زیر میتوانید انتخاب کنید.'}
                options={allowFunctionObject.map(el => {
                  return {
                    label: el.display,
                    value: el.op
                  };
                })}
              />
            </form>
          </Container>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Axie;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Container
} from 'react-bootstrap';

export const PageNotFound = () => {
  let history = useHistory();
  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <div className="pageBack">
        <div class="col-xl-7 col-md-8 offset-md-2 d-flex justify-content-center">
          <div class="card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
            <div class="card-content">
              <div class="card-body text-center">
                <img
                  src="../../../app-assets/images/pages/404.png"
                  class="img-fluid align-self-center"
                  alt="branding logo"
                />
                <h2 class="my-1">صفحه مورد نظر شما وجود ندارد! </h2>
                <p class="p-2"></p>
                <button
                  onClick={() => {
                    history.push('/');
                    window.location.reload();
                  }}
                  class="btn btn-primary btn-lg  mb-3 waves-effect waves-light"
                >
                  بازگشت به صفحه اصلی
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

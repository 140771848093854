import React, { useState, useEffect, Fragment, useRef } from 'react';
import Axios from '../../Axios/Axios';

import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Tabs,
  Tab,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';

const Momentary = () => {

  useEffect(() => {
  });


  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <div className="pageBack">
        <div class="col-xl-7 col-md-8 offset-md-2 d-flex justify-content-center">
          <div class="card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
            <div class="card-content">
              <div class="card-body text-center">
                <img
                  src="../../../app-assets/images/pages/NoMeterFound.png"
                  class="img-fluid align-self-center"
                  alt="branding logo"
                />
                <h2 class="my-1">کنتوری ثبت نشده است </h2>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Momentary;

import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../../Axios/Axios';
import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import Header from '../../Print/Header';
import Footer from '../../Print/Footer';
import MonthlyTrend from '../Trend/MonthlyTrend/MonthlyTrend';
import AnnuallyTrend from './AnnuallyTrend/AnnuallyTrend';
import SeasonalTrend from './SeasonalTrend/SeasonalTrend';

const Trend = props => {
  const TrendReport = useRef();
  const [pageLoad, setPageLoad] = useState(false);
  const [currentReport, setCurrentReport] = useState();
  const [allYear, setAllYear] = useState([]);
  const [reportYear, setReportYear] = useState('');
  const [reportMonth, setReportMonth] = useState('');
  const [reportSeason, setReportSeason] = useState('');
  const [renderLableData, setRenderLableData] = useState(false);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {

    if (props.pageload) {
      setCurrentReport(props.currentReport.nav);
      const body = JSON.stringify({
        kind: 'getYears',
        nav: props.currentReport.nav
      });
      Axios.post('/api/v1/consumption/gstats', body, config).then(res => {
        setAllYear(res.data.data.data);
        props.setPageload(false);
      });
    }
  });
  const printReport = props => {
    window.print();
  };
  return (
    <div>
      <Col md={{ span: 4, offset: 4 }} className="d-print-none NewReport ">
        <InputGroup className="mb-1">
          <label className={'col-sm-3 col-form-label'}>سال</label>
          <Form.Control
            as="select"
            className={'col-sm-9'}
            custom
            value={reportYear}
            onChange={e => {
              setReportYear(e.target.value);
              setPageLoad(false);
              setRenderLableData(true);
            }}
          >
            <option></option>
            {allYear.length > 0
              ? allYear[0].map((el, index) => {
                  return (
                    <option key={index} value={el._id}>
                      {el._id}
                    </option>
                  );
                })
              : ''}
          </Form.Control>
        </InputGroup>
        {props.currentReport.time == 'ماهیانه' ? (
          <InputGroup className="mb-1">
            <label className={'col-sm-3 col-form-label'}>ماه</label>
            <Form.Control
              as="select"
              className={'col-sm-9'}
              custom
              onChange={e => {
                setReportMonth(e.target.value);
                setPageLoad(false);
              }}
            >
              <option></option>
              <option value="01">فروردین</option>
              <option value="02">اردیبهشت</option>
              <option value="03">خرداد</option>
              <option value="04">تیر</option>
              <option value="05">مرداد</option>
              <option value="06">شهریور</option>
              <option value="07">مهر</option>
              <option value="08">آبان</option>
              <option value="09">آذر</option>
              <option value="10">دی</option>
              <option value="11">بهمن</option>
              <option value="12">اسفند</option>
            </Form.Control>
          </InputGroup>
        ) : (
          ''
        )}
        {props.currentReport.time == 'فصلی' ? (
          <InputGroup className="mb-1">
            <label className={'col-sm-3 col-form-label'}>فصل</label>
            <Form.Control
              as="select"
              className={'col-sm-9'}
              custom
              onChange={e => {
                setReportSeason(e.target.value);
                setPageLoad(false);
              }}
            >
              <option></option>
              <option value="بهار">بهار</option>
              <option value="تابستان">تابستان</option>
              <option value="پاییز">پاییز </option>
              <option value="زمستان">زمستان</option>
            </Form.Control>
          </InputGroup>
        ) : (
          ''
        )}
        <Form.Group className="text-center mt-2 mb-2 Printbtn">
          <Button onClick={printReport} variant="primary" disabled={!pageLoad || props.pageload }>
            چاپ گزارش
          </Button>
          <ReactToPrint
            copyStyles="true"
            documentTitle={currentReport ? currentReport : 'Report'}
            trigger={() => (
              <Button hidden variant="primary ">
                ذخیره
              </Button>
            )}
            content={() => TrendReport.current}
          />
        </Form.Group>
      </Col>
      <div className="pagePrint" ref={TrendReport}>
        <Header></Header>
        {props.currentReport.time == 'ماهیانه' && reportYear && reportMonth ? (
          <MonthlyTrend
            currentReport={props.currentReport}
            reportYear={reportYear}
            reportMonth={reportMonth}
            setPageLoad={setPageLoad}
          />
        ) : (
          ' '
        )}
        {props.currentReport.time == 'سالیانه' && reportYear ? (
          <AnnuallyTrend currentReport={props.currentReport} reportYear={reportYear} setPageLoad={setPageLoad} />
        ) : (
          ' '
        )}
        {props.currentReport.time == 'فصلی' && reportSeason ? (
          <SeasonalTrend
            currentReport={props.currentReport}
            reportYear={reportYear}
            reportSeason={reportSeason}
            setPageLoad={setPageLoad}
          />
        ) : (
          ' '
        )}
        <Footer></Footer>
      </div>
    </div>
  );
};
export default Trend;

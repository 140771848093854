import React, { useState, useEffect, useRef } from 'react';
import { Row, Container, Button, Col, Spinner, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Axios from '../../Axios/Axios';
import moment from 'jalali-moment';
import swal from 'sweetalert';
import ReactToPrint from 'react-to-print';

import './PeriodicChart.css';
import RightNav from '../../components/UI/RightNav/RightNav';
import ChartControlPanel from './ChartControlPanel/ChartControlPanel';
import Chart from './Chart/Chart';
import Note from '../../components/UI/Note/Note';

//ایده ها: compare-metric array->done
const PeriodicChart = props => {
  const componentRef = useRef();
  const compareDisableFunc = useRef();
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  // let meter = [
  //   // { id: '61646b68f97fdd02c80a6047', name: 'اتاق سرور 1', unit: 'kWh' },
  //   // { id: '617a683f8ebf590dccb40686', name: 'اتاق سرور 2', unit: 'kWh' }
  // ];
  const [city, setCity] = useState([{}]);
  const [righNav, setRighNav] = useState([]);
  const [chartData, setChartData] = useState({ data: [], formulaData: [] });
  const [needTempData, setNeedTempData] = useState(0);
  const [chartTempData, setChartTempData] = useState([]);
  const [needLoad, setNeedLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chartQuery, setChartQuery] = useState({ kind: 'multiyear' });
  const [chartType, setChartType] = useState('Bar');
  const [allYear, setAllYear] = useState([]);
  const [notesRefresh, setNotesRefresh] = useState(true);
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [showCanvasLeft, setShowCanvasLeft] = useState(true);
  const [showHorLine, setShowHorLine] = useState(false);
  const [showTitle, setShowTitle] = useState(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  const handleCallbackRightNav = async selectedRow => {
    setNotesRefresh(true);
    setLoading(true);
    setRighNav(selectedRow);
    const body = JSON.stringify({
      kind: 'getYears',
      nav: selectedRow
    });
    const allYearTemp = await Axios.post('/api/v1/consumption/gstats', body, config);
    const allCity = await Axios.post('/api/v1/util/getCities', body, config);
    if (allCity.data.data.data.length > 0) {
      setCity(allCity.data.data.data);
    }
    let tempQuery = { ...chartQuery };
    tempQuery.compareActive = false;
    setChartQuery(tempQuery);
    compareDisableFunc.current();
    setAllYear(allYearTemp.data.data.data);
    setNeedLoad(true);
  };

  const getCityTemp = async () => {
    let myPromise = [];
    city.forEach(element => {
      let tempQuery = { ...chartQuery };
      tempQuery['city'] = element._id;
      const body = JSON.stringify(tempQuery);
      myPromise.push(Axios.post(`/api/v1/weather/stats`, body, config));
    });
    const res = await Promise.all(myPromise);
    setChartTempData(res.map(el => el.data.data.data));
  };
  const getFormulaData = async resData => {
    let tempData = { ...resData };
    let formula = [];
    righNav.forEach(nav => {
      if (nav.navType === 'Formula') {
        formula.push(Axios.get('/api/v1/util/formula/' + nav.navID, config));
      }
    });
    formula = await Promise.all(formula);
    formula = formula.map(item => item.data.data.data);
    let formulaData = [];
    formula.forEach(item => {
      let bodyObj = {
        ...chartQuery,
        yAxieFormulaSynon: item.yAxieFormulaSynon,
        yAxieFormula: item.yAxieFormula,
        xAxieFormulaSynon: item.xAxieFormulaSynon,
        xAxieFormula: item.xAxieFormula
      };
      const body = JSON.stringify(bodyObj);
      formulaData.push(Axios.post('/api/v1/util/formula/calc', body, config));
    });
    formulaData = await Promise.all(formulaData);
    formulaData = formulaData.map(item => item.data.data.data);
    tempData.formula = formula;
    tempData.formulaData = formulaData;
    setChartData(tempData);
    setLoading(false);
  };
  useEffect(() => {
    if (needLoad && chartQuery !== null) {
      let chartQueryLocal = chartQuery;
      let tempRightNav = [];
      righNav.forEach(navItem => {
        if (navItem.navType !== 'Formula') {
          tempRightNav.push(navItem);
        }
      });
      chartQueryLocal.nav = tempRightNav;
      const body = JSON.stringify(chartQueryLocal);
      Axios.post(`/api/v1/consumption/gstats`, body, config).then(res => {
        getFormulaData(res.data.data);
      });
      if (needTempData === 2) {
        // let tempQuery = { ...chartQuery };
        // tempQuery['city'] = city._id;
        // const body = JSON.stringify(tempQuery);
        // Axios.post(`/api/v1/weather/stats`, body, config).then(res => {
        //   setChartTempData(res.data.data.data);
        // });
        getCityTemp();
        setNeedTempData(1);
      } else if (needTempData === 0) {
        setChartTempData([]);
      }

      setNeedLoad(false);
    }
  });

  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div className="w-100"></div>
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-2') : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>

            <RightNav
              setIsRenderNavbar={setIsRenderNavbar}
              isRenderNavbar={isRenderNavbar}
              parentCallback={handleCallbackRightNav}
              type={'multi-select'}
              showResetnav="true"
            />
          </div>
        </Col>
        <Col className="mainContent">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-0') : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>

            <div
              hidden={showCanvasLeft}
              class="CanvasIcon CanvasLeft"
              onClick={() => {
                setCanvasWidthLeft(3);
                setShowCanvasLeft(true);

                canvasWidthRight == 3 ? setMainPad('pl-0 pr-0') : setMainPad('pl-2 pr-0');
              }}
            >
              <i className="fa fa-caret-right  "></i>
            </div>
            {/* </Col> */}
            {chartData.data.length === 0 && chartData.formulaData.length === 0 ? (
              <Row className={'justify-content-center align-items-center'}>
                <span class="badge badge-info">
                  <i class="fa fa-info-circle pr-1"></i>
                  برای مشاهده گزارش از منو حداقل یک قسمت را انتخاب نمایید.
                </span>
              </Row>
            ) : (
              <Row>
                <Col md={12} className="ChartPrintIcon">
                  <center hidden={!loading}>
                    <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                  </center>
                  <ReactToPrint
                    copyStyles="true"
                    documentTitle="Chart"
                    trigger={() => (
                      <button className="printIcon">
                        <i className="fa fa-print"></i>
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                  <div hidden={loading}>
                    <Chart
                      ref={componentRef}
                      chartData={chartData}
                      chartDataTitle={chartData}
                      chartCompareDataTitle={chartData}
                      chartType={chartType}
                      meter={righNav}
                      chartTempData={chartTempData}
                      chartQuery={chartQuery}
                      city={city}
                      show={showHorLine}
                      setShow={setShowHorLine}
                      showTitle={showTitle}
                      setShowTitle={setShowTitle}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <div class=" mt-3 mb-3" hidden={righNav.length !== 1}>
              <Container fluid>
                <Note
                  itemId={righNav.length === 1 ? righNav[0].navID : null}
                  type="chart"
                  notesRefresh={notesRefresh}
                  itemName={righNav.length === 1 ? righNav[0].navName : null}
                  setNotesRefresh={setNotesRefresh}
                />
              </Container>
            </div>
          </div>
        </Col>
        <Col
          lg={canvasWidthLeft}
          md={canvasWidthLeft}
          xs={canvasWidthLeft}
          sm={canvasWidthLeft}
          hidden={canvasWidthLeft === 0}
          className="pl-0 LeftPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavLeft"
              onClick={() => {
                setCanvasWidthLeft(0);
                setShowCanvasLeft(false);

                canvasWidthRight === 3 ? setMainPad('pr-2 pl-0') : setMainPad('pr-2 pl-2');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <ChartControlPanel
              righNav={righNav}
              allYear={allYear}
              setChartQuery={setChartQuery}
              setNeedLoad={setNeedLoad}
              setChartType={setChartType}
              chartType={chartType}
              setLoading={setLoading}
              setNeedTempData={setNeedTempData}
              setShowHorLine={setShowHorLine}
              showHorLine={showHorLine}
              showTitle={showTitle}
              setShowTitle={setShowTitle}
              compareDisableFunc={compareDisableFunc}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PeriodicChart;

import React, { useState, useEffect, Fragment } from 'react';

import { Button, Row, Col, FormCheck, Spinner, Container, Table, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Axios from '../../../../Axios/Axios';
import moment from 'jalali-moment';
import { parse } from 'mathjs';

import { linearRegression } from '../../../../components/Regression/js/linear/linearRegression';
import { addConstant } from '../../../../components/Regression/js/tools/tools';
import regression from 'regression-extend';

import ProjectModelChart from './ProjectModelChart/ProjectModelChart';

const ProjectModel = props => {
  const [loading, setLoading] = useState(false);
  const [needData, setNeedData] = useState(true);
  const [routineAdjustmentNavs, setRoutineAdjustmentNavs] = useState(() => {
    return props.MVProject.routineAdjustmentsInModel;
  });
  const [regressionOrder, setRegressionOrder] = useState(() => {
    if (props.MVProject.regressionOrder) {
      return props.MVProject.regressionOrder;
    } else return '1';
  });
  const [previewRegression, setPreviewRegression] = useState(null);
  const [chartData, setChartData] = useState(null);

  const RANav = props.MVProject.routineAdjustments;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  const checkBoxToggle = async RA => {
    const findRAIndex = routineAdjustmentNavs.findIndex(el => {
      return el._id === RA._id;
    });
    let NewRANavs;
    if (findRAIndex === -1) {
      if (routineAdjustmentNavs.length === 5) {
        toast.error('مدل سازی با بیشتر از 5 متغیر امکان پذیر نیست.', { autoClose: 6000 });
        return 0;
      }
      NewRANavs = [...routineAdjustmentNavs, RA];
      setRoutineAdjustmentNavs(NewRANavs);
    } else {
      NewRANavs = [...routineAdjustmentNavs];
      NewRANavs.splice(findRAIndex, 1);
      setRoutineAdjustmentNavs(NewRANavs);
    }
    const body = JSON.stringify({
      routineAdjustmentsInModel: NewRANavs
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, body, config);
    const MVProject = {
      ...props.MVProject,
      routineAdjustmentsInModel: NewRANavs
    };
    props.setMVProject(MVProject);
    setPreviewRegression(null);
  };

  const generateModel = async () => {
    if (
      !props.MVProject.baselinePeriodFrom ||
      !props.MVProject.baselinePeriodTo ||
      !props.MVProject.reportingPeriodTo ||
      !props.MVProject.reportingPeriodFrom ||
      !props.MVProject.meter
    ) {
      toast.error('لطفا برای قابلیت مدل سازی، اطلاعات مراحل قبل را کامل نمایید.', { autoClose: 6000 });
      setLoading(false);
      return 0;
    }
    if (routineAdjustmentNavs.length === 0) {
      toast.error('انتخاب یک حداقل یک متغیر برای مدل سازی است.', { autoClose: 6000 });
      return 0;
    }
    setLoading(true);
    //baseline period data
    let bodyObj = {
      fromDate: moment(props.MVProject.baselinePeriodFrom)
        .locale('fa')
        .format('YYYY-MM-DD'),
      toDate: moment(props.MVProject.baselinePeriodTo)
        .locale('fa')
        .format('YYYY-MM-DD'),
      kind: 'range',
      paramCount: routineAdjustmentNavs.length.toString(),
      x1AxieFormula: 'Synonym0',
      x1AxieFormulaSynon: [
        {
          id: routineAdjustmentNavs[0]._id,
          kind: routineAdjustmentNavs[0].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ],
      yAxieFormula: 'Synonym0',
      yAxieFormulaSynon: [
        {
          id: props.MVProject.meter._id,
          kind: props.MVProject.meter.unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ]
    };
    if (routineAdjustmentNavs.length > 1) {
      bodyObj['x2AxieFormula'] = 'Synonym0';
      bodyObj['x2AxieFormulaSynon'] = [
        {
          id: routineAdjustmentNavs[1]._id,
          kind: routineAdjustmentNavs[1].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    if (routineAdjustmentNavs.length > 2) {
      bodyObj['x3AxieFormula'] = 'Synonym0';
      bodyObj['x3AxieFormulaSynon'] = [
        {
          id: routineAdjustmentNavs[2]._id,
          kind: routineAdjustmentNavs[2].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    if (routineAdjustmentNavs.length > 3) {
      bodyObj['x4AxieFormula'] = 'Synonym0';
      bodyObj['x4AxieFormulaSynon'] = [
        {
          id: routineAdjustmentNavs[3]._id,
          kind: routineAdjustmentNavs[3].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    if (routineAdjustmentNavs.length > 4) {
      bodyObj['x5AxieFormula'] = 'Synonym0';
      bodyObj['x5AxieFormulaSynon'] = [
        {
          id: routineAdjustmentNavs[4]._id,
          kind: routineAdjustmentNavs[4].unit.type,
          navType: 'meter',
          type: 'consumption'
        }
      ];
    }
    let body = JSON.stringify(bodyObj);
    const modelBaseData = (await Axios.post('/api/v1/util/formula/trend', body, config)).data.data;

    //reporting period data
    bodyObj['fromDate'] = moment(props.MVProject.reportingPeriodFrom)
      .locale('fa')
      .format('YYYY-MM-DD');
    bodyObj['toDate'] = moment(props.MVProject.reportingPeriodTo)
      .locale('fa')
      .format('YYYY-MM-DD');

    body = JSON.stringify(bodyObj);
    const modelReportData = (await Axios.post('/api/v1/util/formula/trend', body, config)).data.data;
    //check meters have data
    if (modelBaseData.data.yAxie.length === 0 || modelReportData.data.yAxie.length === 0) {
      toast.error(
        'برای مدل سازی اطلاعات باید تمامی متغیرهای روتین در بازه مبنا و گزارش مصارفشان ثبت شده باشند. لطفا متغیرهای روتین انتخابی خود را ویرایش نمایید.',
        { autoClose: 6000 }
      );
      setLoading(false);
      return 0;
    }
    //calculate regression formula
    // //Prepear data
    const yData = [];
    let xData = [];
    const regressionParam = routineAdjustmentNavs.length.toString();
    const days = modelBaseData.days;
    days.forEach((item, index) => {
      yData.push(modelBaseData.data.yAxie[index].y);
      if (regressionParam === '1') {
        if (regressionOrder === '1') {
          xData.push([modelBaseData.data.x1Axie[index].y, 0]);
        } else {
          xData.push([modelBaseData.data.x1Axie[index].y]);
        }
      } else if (regressionParam === '2') {
        xData.push([modelBaseData.data.x1Axie[index].y, modelBaseData.data.x2Axie[index].y]);
      } else if (regressionParam === '3') {
        xData.push([
          modelBaseData.data.x1Axie[index].y,
          modelBaseData.data.x2Axie[index].y,
          modelBaseData.data.x3Axie[index].y
        ]);
      } else if (regressionParam === '4') {
        xData.push([
          modelBaseData.data.x1Axie[index].y,
          modelBaseData.data.x2Axie[index].y,
          modelBaseData.data.x3Axie[index].y,
          modelBaseData.data.x4Axie[index].y
        ]);
      } else if (regressionParam === '5') {
        xData.push([
          modelBaseData.data.x1Axie[index].y,
          modelBaseData.data.x2Axie[index].y,
          modelBaseData.data.x3Axie[index].y,
          modelBaseData.data.x4Axie[index].y,
          modelBaseData.data.x5Axie[index].y
        ]);
      }
    });
    // //calculate regression data
    let regressionResult = {};
    if (regressionParam === '1') {
      if (regressionOrder === '1') {
        //add a 1 at beginning of every y for calculating the constant coefficient.
        xData = addConstant(xData);
        //calculate variance inflation factors
        regressionResult = linearRegression(yData, xData, false);
      } else if (regressionOrder === '2') {
        regressionResult = regression.polynomial(xData, yData, { order: 2 });
      } else if (regressionOrder === '3') {
        regressionResult = regression.polynomial(xData, yData, { order: 3 });
      } else if (regressionOrder === '4') {
        regressionResult = regression.polynomial(xData, yData, { order: 4 });
      }
    } else {
      //add a 1 at beginning of every y for calculating the constant coefficient.
      xData = addConstant(xData);
      //calculate variance inflation factors
      regressionResult = linearRegression(yData, xData, false);
    }
    setPreviewRegression({
      ...regressionResult
    });
    // build chart data
    const chartDataModel = modelReportData.data.yAxie.map((el, index) => {
      if (routineAdjustmentNavs.length === 1) {
        return {
          x: getPredictData(
            modelReportData.data.x1Axie[index].y,
            null,
            null,
            null,
            null,
            regressionOrder === '1' ? regressionResult.regressionEquation.replace('0x2', '') : regressionResult.string
          ),
          y: el.y,
          day: modelReportData.data.x1Axie[index].x
        };
      } else if (routineAdjustmentNavs.length === 2) {
        return {
          x: getPredictData(
            modelReportData.data.x1Axie[index].y,
            modelReportData.data.x2Axie[index].y,
            null,
            null,
            null,
            regressionResult.regressionEquation
          ),
          y: el.y,
          day: modelReportData.data.x1Axie[index].x
        };
      } else if (routineAdjustmentNavs.length === 3) {
        return {
          x: getPredictData(
            modelReportData.data.x1Axie[index].y,
            modelReportData.data.x2Axie[index].y,
            modelReportData.data.x3Axie[index].y,
            null,
            null,
            regressionResult.regressionEquation
          ),
          y: el.y,
          day: modelReportData.data.x1Axie[index].x
        };
      } else if (routineAdjustmentNavs.length === 4) {
        return {
          x: getPredictData(
            modelReportData.data.x1Axie[index].y,
            modelReportData.data.x2Axie[index].y,
            modelReportData.data.x3Axie[index].y,
            modelReportData.data.x4Axie[index].y,
            null,
            regressionResult.regressionEquation
          ),
          y: el.y,
          day: modelReportData.data.x1Axie[index].x
        };
      } else if (routineAdjustmentNavs.length === 5) {
        return {
          x: getPredictData(
            modelReportData.data.x1Axie[index].y,
            modelReportData.data.x2Axie[index].y,
            modelReportData.data.x3Axie[index].y,
            modelReportData.data.x4Axie[index].y,
            modelReportData.data.x5Axie[index].y,
            regressionResult.regressionEquation
          ),
          y: el.y,
          day: modelReportData.data.x1Axie[index].x
        };
      }
    });
    const bodyUpdate = JSON.stringify({
      modelData: chartDataModel
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, bodyUpdate, config);
    const MVProject = {
      ...props.MVProject,
      modelData: chartDataModel
    };
    props.setMVProject(MVProject);
    setChartData(chartDataModel);
    setLoading(false);
  };

  const changeRegressionOrder = async val => {
    setRegressionOrder(val);
    const body = JSON.stringify({
      regressionOrder: val
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, body, config);
    const MVProject = {
      ...props.MVProject,
      regressionOrder: val
    };
    props.setMVProject(MVProject);
  };
  const getPredictData = (x1, x2, x3, x4, x5, formul) => {
    if (routineAdjustmentNavs.length === 1) {
      let formulTemp = parse(formul.replace('0x2', ''));
      if (regressionOrder === '1') {
        return formulTemp.evaluate({ x1: x1 });
      } else return formulTemp.evaluate({ x: x1 });
    } else {
      let formulTemp = parse(formul);
      return formulTemp.evaluate({ x1: x1, x2: x2, x3: x3, x4: x4, x5: x5 });
    }
  };
  const getRegressionFormula = () => {
    const regressionParam = routineAdjustmentNavs.length.toString();
    if (previewRegression == null) {
      return;
    }
    if (regressionParam > 1 || regressionOrder == '1') {
      let temp = previewRegression.coefficients.data.map((item, index) => {
        if (item[0] == 0) {
          return <span key={index}></span>;
        }
        let tempCoeff = item[0];
        if (Math.abs(tempCoeff) < 1) {
          tempCoeff = tempCoeff.toFixed(5);
        } else if (Math.abs(tempCoeff) < 10) {
          tempCoeff = tempCoeff.toFixed(3);
        } else {
          tempCoeff = tempCoeff.toFixed(1);
        }
        if (index === 0) {
          return <span key={index}>{tempCoeff}</span>;
        } else {
          if (tempCoeff.startsWith('-')) {
            return (
              <span key={index}>
                {' - ' + tempCoeff.replace('-', '')}
                <span className="formulX">{' X' + index}</span>
              </span>
            );
          } else {
            return (
              <span key={index}>
                {' + ' + tempCoeff}
                <span className="formulX">{' X' + index}</span>
              </span>
            );
          }
        }
      });
      let retArray = [1];
      retArray = retArray.map(item => {
        return (
          <span key="y">
            <span className="formulY">{'Y '}</span>=
          </span>
        );
      });
      retArray = retArray.concat(temp);
      return retArray;
    } else {
      let temp = previewRegression.equation.map((item, index) => {
        let tempCoeff = item;
        if (Math.abs(tempCoeff).toFixed(5) === 0) {
          tempCoeff = tempCoeff.toFixed(0);
        }
        if (Math.abs(tempCoeff) < 1) {
          tempCoeff = tempCoeff.toFixed(5);
        } else if (Math.abs(tempCoeff) < 10) {
          tempCoeff = tempCoeff.toFixed(3);
        } else {
          tempCoeff = tempCoeff.toFixed(1);
        }
        if (index === previewRegression.equation.length - 1) {
          if (tempCoeff.startsWith('-')) {
            return <span key={index}> - {tempCoeff.replace('-', '')}</span>;
          } else {
            return <span key={index}> + {tempCoeff}</span>;
          }
        } else {
          if (index === 0) {
            return (
              <span key={index}>
                {tempCoeff}
                <b className="formulX">{' X^' + (index + 1) * 1}</b>
              </span>
            );
          } else {
            if (tempCoeff.startsWith('-')) {
              return (
                <span key={index}>
                  {' - ' + tempCoeff.replace('-', '')}
                  <b className="formulX">{' X^' + (index + 1) * 1}</b>
                </span>
              );
            } else {
              return (
                <span key={index}>
                  {' + ' + tempCoeff}
                  <b className="formulX">{' X^' + (index + 1) * 1}</b>
                </span>
              );
            }
          }
        }
      });
      let retArray = [1];
      retArray = retArray.map(item => {
        return (
          <span key="y">
            <b>{'Y '}= </b>
          </span>
        );
      });
      retArray = retArray.concat(temp);
      return retArray;
    }
  };

  useEffect(() => {
    if (needData) {
      setNeedData(false);
      generateModel();
    }
  });
  return (
    <form hidden={props.activeStep !== 7}>
      <div hidden={loading}>
        <h2>7- نرمال سازی</h2>
        <span>متغیرهای مرتبط و درجه رگرسیون جهت ایجاد مدل انتخاب شود.</span>
        <Row className="m-2">
          <Col md={{ span: 4 }}>انتخاب متغیرهای روتین:</Col>
          <Col md={{ span: 8 }}>
            <Row>
              {RANav.map((el, index) => {
                const findRAIndex = routineAdjustmentNavs.findIndex(element => {
                  return el._id === element._id;
                });
                const isChecked = findRAIndex > -1;
                return (
                  <Col md={{ span: 4 }}>
                    <FormCheck
                      type="checkbox"
                      label={el.name}
                      checked={isChecked}
                      onClick={() => {
                        checkBoxToggle(el);
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row className="m-2">
          <Col md={{ span: 4 }} hidden={routineAdjustmentNavs.length > 1}>
            <label className={'pr-2'}>درجه رگرسیون (Order)</label>
            <select
              value={regressionOrder}
              onChange={e => {
                changeRegressionOrder(e.target.value);
              }}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </Col>
          <Col md={{ span: 4 }}>
            <Button onClick={generateModel}>ایجاد مدل</Button>
          </Col>
        </Row>
        {previewRegression ? (
          <Row className="m-2">
            <Container fluid className="ChartControlPanel">
              <h3 className={'simpleBoxTitle'}>اطلاعات رگرسیون</h3>
              <Row className="mb-2">
                <Col md={{ span: 12 }}>
                  <div className="regressionFormula">{getRegressionFormula()}</div>
                </Col>
                <Col md={12}>
                  <Row>
                    <Col md={{ span: 4 }}>
                      <div className="regressionParam">
                        <div className="regParamTitle">R2 Score</div>
                        <div className="regParamText">
                          {routineAdjustmentNavs.length === 1 && regressionOrder !== '1' ? (
                            <span>{`R2: ${previewRegression.r2.toFixed(4)}`}</span>
                          ) : (
                            <span>{`R2: ${previewRegression.rSquared.toFixed(4)}`}</span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={{ span: 4 }}>
                      <div className="regressionParam">
                        <div className="regParamTitle">تعداد روز دوره گزارش</div>
                        <div className="regParamText">
                          {moment(props.MVProject.reportingPeriodTo).diff(
                            moment(props.MVProject.reportingPeriodFrom),
                            'day'
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={{ span: 4 }}>
                      <div className="regressionParam">
                        <div className="regParamTitle">تعداد روز دوره مبنا</div>
                        <div className="regParamText">
                          {moment(props.MVProject.baselinePeriodTo).diff(
                            moment(props.MVProject.baselinePeriodFrom),
                            'day'
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <h3 className={'simpleBoxTitle'}>آنالیز آماری رگرسیون</h3>
              <Row className="mb-2 mt-2">
                {routineAdjustmentNavs.length > 1 || regressionOrder === '1' ? (
                  <Col md={{ span: 12 }}>
                    <Table responsive striped className="RegTable mb-3">
                      <thead>
                        <tr>
                          <th></th>
                          <th>SS</th>
                          <th>F</th>
                          <th>Significance F</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="font-weight-bold">Regression</td>
                          <td>{previewRegression ? parseFloat(previewRegression.ssr).toFixed(6) : ''}</td>
                          <td>{previewRegression ? parseFloat(previewRegression.fValue).toFixed(6) : ''}</td>

                          <td>{previewRegression ? parseFloat(previewRegression.pValueOfFValue).toFixed(6) : ''}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Residual</td>
                          <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(6) : ''}</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="font-weight-bold">Total</td>
                          <td>
                            {previewRegression
                              ? parseFloat(previewRegression.ssr + parseInt(previewRegression.sse)).toFixed(6)
                              : ''}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>

                    <Table responsive striped className="RegTable mb-3">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Coefficients</th>
                          <th>Standard Error</th>
                          <th>t Stat</th>
                          <th>P-value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {previewRegression
                          ? previewRegression.pValues.map((item, index) => {
                              if (!item || isNaN(item)) {
                              } else
                                return (
                                  <tr>
                                    <td className="font-weight-bold">{index === 0 ? 'Intercept' : 'x' + index}</td>
                                    <td>{parseFloat(previewRegression.coefficients.data[index]).toFixed(6)}</td>
                                    <td>{parseFloat(previewRegression.stdErrorOfCoefficients[index]).toFixed(6)}</td>

                                    <td>{parseFloat(previewRegression.tValues[index]).toFixed(6)}</td>
                                    <td>{parseFloat(previewRegression.pValues[index]).toFixed(6)}</td>
                                  </tr>
                                );
                            })
                          : ''}
                      </tbody>
                    </Table>
                  </Col>
                ) : (
                  <Col md={{ span: 12 }}>
                    <Table responsive striped className="RegTable mb-3">
                      <thead>
                        <tr>
                          <th>me</th>
                          <th>sse</th>
                          <th>mse</th>
                          <th>smse</th>
                          <th>se</th>
                          <th>tstat</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{previewRegression ? parseFloat(previewRegression.me).toFixed(4) : ''}</td>
                          <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(4) : ''}</td>
                          <td>{previewRegression ? parseFloat(previewRegression.mse).toFixed(4) : ''}</td>
                          <td>{previewRegression ? parseFloat(previewRegression.smse).toFixed(4) : ''}</td>
                          <td>{previewRegression ? parseFloat(previewRegression.se).toFixed(4) : ''}</td>
                          <td>{previewRegression ? parseFloat(previewRegression.tstat).toFixed(4) : ''}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                )}
              </Row>
            </Container>
          </Row>
        ) : (
          ''
        )}
        {chartData ? (
          <Row>
            <ProjectModelChart chartData={chartData} unit={props.MVProject.meter.unit.unit} />
          </Row>
        ) : (
          ''
        )}
      </div>
      <center hidden={!loading}>
        <Spinner animation="grow" variant="success" className={'spinnerChart'} />
      </center>
    </form>
  );
};

export default ProjectModel;

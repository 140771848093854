import React, { useState } from 'react';
import { Row, Container, Button, Col, Spinner, Form } from 'react-bootstrap';

const ProjectRightNav = props => {
  return (
    <div>
      <Container>
        <Row
          className={`trendSteps ${props.activeStep === 1 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(1);
          }}
        >
          <div>
            <span className="trendStepsNumber">1</span>
            <span>تعریف پروژه</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 2 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(2);
          }}
        >
          <div>
            <span className="trendStepsNumber">2</span>
            <span>راهکارهای اجرایی</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 3 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(3);
          }}
        >
          <div>
            <span className="trendStepsNumber">3</span>
            <span>دوره زمانی مبنا</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 4 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(4);
          }}
        >
          <div>
            <span className="trendStepsNumber">4</span>
            <span>دوره گزارش دهی</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 5 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(5);
          }}
        >
          <div>
            <span className="trendStepsNumber">5</span>
            <span>تصحیحات معمول</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 6 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(6);
          }}
        >
          <div>
            <span className="trendStepsNumber">6</span>
            <span>تصحیحات غیر معمول</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 7 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(7);
          }}
        >
          <div>
            <span className="trendStepsNumber">7</span>
            <span>نرمال سازی</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 8 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(8);
          }}
        >
          <div>
            <span className="trendStepsNumber">8</span>
            <span>گزارش M&V</span>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectRightNav;

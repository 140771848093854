import React,{useState} from 'react';
import { Row, Container, Button, Col, Spinner, Form } from 'react-bootstrap';

const TrendRightNav = (props) => {

  const changeToggleNewTrend = () => {
    props.setNewTrend(props.newTrend ? false : true);
    props.setChartData(null);
    props.setPreviewRegression(null);
    if (props.newTrend) {
      props.setActiveStep(3);
    } else {
      props.setActiveStep(1);
    }
  };

  return (
    <div>
      <Container>
        <Row className="toggleTrend mt-2 mb-2">
          <Col md={{ span: 6, offset: 2 }} className="pr-0 pl-0 mb-1 text-left">
            {props.newTrend ? 'خط مبنا جدید' : 'انتخاب خط مبنا'}
          </Col>
          <Col md={{ span: 4 }}>
            <Form.Check
              id="switchEnabled"
              type="switch"
              label=" "
              checked={props.newTrend}
              onChange={changeToggleNewTrend}
            />
          </Col>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 1 ? 'activeStep' : ''}`}
          hidden={!props.newTrend}
          onClick={() => {
            props.setActiveStep(1);
          }}
        >
          <div>
            <span className="trendStepsNumber">
              1
            </span>
            <span>تعیین متغیرهای مرتبط</span>
          </div>
        </Row>
        <Row
          hidden={!props.newTrend}
          className={`trendSteps ${props.activeStep === 2 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(2);
          }}
        >
          <div>
          <span className="trendStepsNumber">
              2
            </span>
            <span>آنالیز آماری</span>
          </div>
        </Row>
        <Row
          hidden={props.newTrend}
          className={`trendSteps ${props.activeStep === 3 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(3);
          }}
        >
          <div>
          <span className="trendStepsNumber">
              1
            </span>
            <span>انتخاب خط مبنا</span>
          </div>
        </Row>
        <Row
          className={`trendSteps ${props.activeStep === 4 ? 'activeStep' : ''}`}
          onClick={() => {
            props.setActiveStep(4);
          }}
        >
          <div>
          <span className="trendStepsNumber">
          {props.newTrend ?  '3' : '2'}

            </span>
            <span>گزارش خط مبنا</span>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default TrendRightNav;

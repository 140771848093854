import React from 'react';
import { Bar, defaults, Chart, Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

const SavingChart = props => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;

  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  if (!props.consumptionData) {
    return '';
  }
  const option = {
    maintainAspectRatio: true,
    scales: {
      y: {
        title: {
          display: true,
          text: '(' + props.unit + ')'
        },
        grid: {
          display: false
        },
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10
        }
      }
    },
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            xMin: props.chartBeginECM,
            xMax: props.chartBeginECM,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              content: 'شروع پروژه های ECM',
              rotation: 90,
              enabled: true,
              position: 'start',
              padding: 2,
              backgroundColor: 'rgba(0,0,0,0.4)'
            }
          },
          line2: {
            type: 'line',
            xMin: props.chartEndECM,
            xMax: props.chartEndECM,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              content: 'پایان پروژه های ECM',
              rotation: 90,
              enabled: true,
              position: 'start',
              padding: 2,
              backgroundColor: 'rgba(0,0,0,0.4)'
            }
          }
        }
      }
    }
  };
  const data = {
    datasets: [
      {
        backgroundColor: '#57c32b',
        label: 'مصارف واقعی',
        borderColor: '#57c32b',
        data: props.consumptionData.AggregateData.map(el => {
          return { x: el.dateOf, y: el.total };
        })
      },
      {
        backgroundColor: '#6e61ed',
        label: 'پیش بینی مصرف',
        borderColor: '#6e61ed',
        data: props.predictData.map(el => {
          return { x: el.day, y: el.x };
        })
      }
    ]
  };
  return <Line data={data} options={option} />;
};

export default SavingChart;

import React, { useState, useEffect, Fragment } from 'react';
import { Row, Container, Button, Col } from 'react-bootstrap';
import moment from 'jalali-moment';
import { Calendar } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import './RegressionControlPanel.css';

const RegressionControlPanel = props => {
  const [queryUpdate, setQueryUpdate] = useState(false);
  const [tempToggle, setTempToggle] = useState(false);
  const [allWeek, setAllWeek] = useState([]);

  const [chartPeriod, setChartPeriod] = useState('year');
  const [chartCost, setChartCost] = useState(false);
  const [chartYear, setChartYear] = useState('');
  const [chartMonth, setChartMonth] = useState('');
  const [chartWeek, setChartWeek] = useState('');
  const [chartRangeDate, setChartRangeDate] = useState({
    from: null,
    to: null
  });
  const [chartRangeDateConvert, setChartRangeDateConvert] = useState({
    from: null,
    to: null
  });
  const [chartMultiDate, setChartMultiDate] = useState([]);
  const [chartMultiDateConvert, setChartMultiDateConvert] = useState([]);


  const changeChartType = ChartType => {
    props.setChartType(ChartType);
  };

  //Get week Of A year
  const weekArray = year => {
    let allWeekArray = [];
    if (year !== '') {
      let index = 1;
      let fDay =
        moment.from(`${year}-01-01`, 'fa', 'YYYY/MM/DD').format('d') * 1;
      let firstDay = moment
        .from(`${year}-01-01`, 'fa', 'YYYY/MM/DD')
        .add(6 - fDay, 'day')
        .locale('fa')
        .format('YYYY/MM/DD');
      let nextWeek = firstDay;
      let nextYear = year * 1 + 1;
      allWeekArray.push(nextWeek);
      while (
        moment
          .from(nextWeek, 'fa', 'YYYY/MM/DD')
          .add(7, 'day')
          .locale('fa')
          .format('YYYY') < nextYear
      ) {
        nextWeek = moment
          .from(nextWeek, 'fa', 'YYYY/MM/DD')
          .add(7, 'day')
          .locale('fa')
          .format('YYYY/MM/DD');
        index = index + 1;
        allWeekArray.push(nextWeek);
      }
    }
    return allWeekArray;
  };
  //Temprature Toggle
  const toggleTemp = () => {
    tempToggle ? setTempToggle(false) : setTempToggle(true);
    setQueryUpdate(true);
  };
  //chart period onCHange
  const changeChartPeriod = periodType => {
    setChartPeriod(periodType);
    setChartYear('');
    setChartMonth('');
    setChartWeek('');
    setChartRangeDate({ from: null, to: null });
    setChartRangeDateConvert({ from: null, to: null });
    setChartMultiDate([]);
    setChartMultiDateConvert([]);
    //Temprature disable and remove from chart
    setTempToggle(false);
    //
    if (periodType === 'multiyear') {
      setQueryUpdate(true);
    }
  };
  //change chart year
  const changeChartYear = e => {
    setChartYear(e.target.value);
    setChartMonth('');
    setChartWeek('');
    if (chartPeriod === 'year') {
      setQueryUpdate(true);
    }
    if (chartPeriod === 'week') {
      let weeks = weekArray(e.target.value);
      setAllWeek(weeks);
    }
  };
  //change chart month
  const changeChartMonth = e => {
    setChartMonth(e.target.value);
    setChartWeek('');
    if (chartPeriod === 'month') {
      setQueryUpdate(true);
    }
  };
  //change chart week
  const changeChartWeek = e => {
    setChartWeek(e.target.value);
    if (chartPeriod === 'week') {
      setQueryUpdate(true);
    }
  };
  //Render Footer of Range Callendar with on sub
  const renderFooterCalendarRange = () => {
    return (
      <div>
        <Row>
          <Col xs={12} md={12}>
            <Button
              className={'f1 btn btn-success p-1 mb-1'}
              onClick={() => {
                let fromTemp = `${chartRangeDate.from.year}-${
                  chartRangeDate.from.month.toString().length === 2
                    ? chartRangeDate.from.month
                    : '0' + chartRangeDate.from.month
                }-${
                  chartRangeDate.from.day.toString().length === 2
                    ? chartRangeDate.from.day
                    : '0' + chartRangeDate.from.day
                }`;
                let toTemp = `${chartRangeDate.to.year}-${
                  chartRangeDate.to.month.toString().length === 2
                    ? chartRangeDate.to.month
                    : '0' + chartRangeDate.to.month
                }-${
                  chartRangeDate.to.day.toString().length === 2
                    ? chartRangeDate.to.day
                    : '0' + chartRangeDate.to.day
                }`;
                setChartRangeDateConvert({ from: fromTemp, to: toTemp });
                setQueryUpdate(true);
              }}
            >
              تنظیم گزارش
            </Button>
          </Col>
        </Row>
      </div>
    );
  };
  //Render Footer of Multiday Callendar with on sub
  const renderFooterCalendarMultiDay = () => {
    return (
      <div>
        <Button
          className={'f1 btn btn-success p-1 mb-1'}
          onClick={() => {
            let daysTemp = chartMultiDate.map(el => {
              return (
                el.year +
                '-' +
                `${
                  el.month.toString().length === 2 ? el.month : '0' + el.month
                }` +
                '-' +
                `${el.day.toString().length === 2 ? el.day : '0' + el.day}`
              );
            });
            setChartMultiDateConvert(daysTemp);
            setQueryUpdate(true);
          }}
        >
          تنظیم گزارش
        </Button>
      </div>
    );
  };
  useEffect(() => {
    if (queryUpdate) {
      setQueryUpdate(false);
      queryUpdateFunc();
    }
    if (props.allYear.length > 0 && props.needGetYear) {
      props.setNeedGetYear(false);
      setChartYear(props.allYear[0]._id);
      setQueryUpdate(true);
    }
  }, [queryUpdate,props.allYear]); //inja
  const queryUpdateFunc = () => {
    props.setChartQuery({
      kind: chartPeriod,
      year: chartYear,
      month: chartMonth,
      week: chartWeek,
      fromDate: chartRangeDateConvert.from,
      toDate: chartRangeDateConvert.to,
      days: chartMultiDateConvert
    });
    if (tempToggle) {
      props.setNeedTempData(2);
    } else {
      props.setNeedTempData(0);
    }
    props.setNeedLoad(true);
    props.setLoading(true);
  };
  return (
    <Fragment>
      <Container fluid className="ChartControlPanel">
        <Row className={' mb-2'}>
          <Col xs={12} md={9}>
            <label className={'pr-2 pl-1'}>درجه رگرسیون (Order)</label>
          </Col>
          <Col xs={12} md={3}>
            <select
              className="ControlPanel"
              value={props.regressionOrder}
              onChange={e => {
                props.setRegressionOrder(e.target.value);
                setQueryUpdate(true);
              }}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
          </Col>
        </Row>
        {/* <Row className="mb-2">
          <Col
            xs={12}
            md={6}
            hidden={chartPeriod === 'multiyear' || compareActive}
          >
            <Button
              className={`btn ${tempToggle ? 'btn-active' : 'btn-simple'}`}
              onClick={toggleTemp}
            >
              °C
            </Button>
          </Col>
        </Row> */}
        <Row className="mb-2">
          <Col xs={12} md={6} className="pl-0 pr-0 noRaduis">
            <Button
              className={`btn   ${
                chartPeriod === 'multiyear' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('multiyear');
              }}
            >
              همه سالها
            </Button>
            <Button
              className={`btn  ${
                chartPeriod === 'month' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('month');
              }}
            >
              ماهیانه
            </Button>
            <Button
              className={`btn  ${
                chartPeriod === 'range' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('range');
              }}
            >
              بازه زمانی
            </Button>
          </Col>
          <Col xs={12} md={6} className="pl-0 pr-0 noRaduis">
            <Button
              className={`btn   ${
                chartPeriod === 'year' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('year');
              }}
            >
              سالیانه
            </Button>
            <Button
              className={`btn   ${
                chartPeriod === 'week' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('week');
              }}
            >
              هفتگی
            </Button>
            <Button
              className={`btn  ${
                chartPeriod === 'multiDay' ? 'activeBtn' : 'btn-simple'
              }`}
              onClick={() => {
                changeChartPeriod('multiDay');
              }}
            >
              چند روز
            </Button>
          </Col>
        </Row>
        <Row
          className={'mb-1'}
          hidden={
            chartPeriod === 'multiyear' ||
            chartPeriod === 'range' ||
            chartPeriod === 'multiDay'
          }
        >
          <Col xs={12} md={3}>
            <label className={'pr-2 pl-1'}>سال</label>
          </Col>
          <Col xs={12} md={9}>
            <select
              className="ControlPanel"
              value={chartYear}
              onChange={changeChartYear}
            >
              <option></option>
              {props.allYear.length > 0
                ? props.allYear.map((el, index) => {
                    return (
                      <option key={index} value={el._id}>
                        {el._id}
                      </option>
                    );
                  })
                : ''}
            </select>
          </Col>
        </Row>

        <Row hidden={!(chartPeriod === 'month')}>
          <Col xs={12} md={3}>
            <label className={'pr-2 pl-1'}>ماه</label>
          </Col>
          <Col xs={12} md={9}>
            <select
              className="ControlPanel"
              value={chartMonth}
              onChange={changeChartMonth}
            >
              <option></option>
              <option value="01">فروردین</option>
              <option value="02">اردیبهشت</option>
              <option value="03">خرداد</option>
              <option value="04">تیر</option>
              <option value="05">مرداد</option>
              <option value="06">شهریور</option>
              <option value="07">مهر</option>
              <option value="08">آبان</option>
              <option value="09">آذر</option>
              <option value="10">دی</option>
              <option value="11">بهمن</option>
              <option value="12">اسفند</option>
            </select>
          </Col>
        </Row>
        <Row hidden={!(chartPeriod === 'week')}>
          <Col xs={12} md={3}>
            <label className={'pr-2 pl-1'}>هفته</label>
          </Col>
          <Col xs={12} md={9}>
            <select
              className="ControlPanel"
              value={chartWeek}
              onChange={changeChartWeek}
            >
              <option></option>
              {allWeek.map((el, index) => {
                return (
                  <option key={index} value={el.replaceAll('/', '-')}>
                    {index + 1 + '- ' + el}
                  </option>
                );
              })}
            </select>
          </Col>
        </Row>
        <Row hidden={!(chartPeriod === 'range')}>
          <Calendar
            value={chartRangeDate}
            onChange={setChartRangeDate}
            locale="fa"
            calendarClassName={'calendarWidth'}
            renderFooter={renderFooterCalendarRange}
          />
        </Row>
        <Row hidden={!(chartPeriod === 'multiDay')}>
          <Calendar
            value={chartMultiDate}
            onChange={setChartMultiDate}
            locale="fa"
            calendarClassName={'calendarWidth'}
            renderFooter={renderFooterCalendarMultiDay}
          />
        </Row>
      </Container>
    </Fragment>
  );
};

export default RegressionControlPanel;

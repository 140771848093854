import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'jalali-moment';
import Axios from '../../Axios/Axios';
import Datagrid from '../../components/UI/Datagrid/Datagrid';
import './JobDescription.css';
import AutoCompleteSelect from 'react-select';
import FileViewer from 'react-file-viewer';
import { Document, Page, pdfjs } from 'react-pdf';
import swal from 'sweetalert';

const JobDescription = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [allJobUsers, setAllJobUsers] = useState([]);
  const [needGetusers, setNeedGetusers] = useState(true);
  const [showFile, setShowFile] = useState(false);
  const [filePath, setFilePath] = useState('');
  const [userName, setUserName] = useState('');
  const [userImpact, setUserImpact] = useState('');
  const [userPosition, setUserPosition] = useState('');
  const [allJobPosition, setAllJobPosition] = useState([]);

  const [itemSelected, setItemSelected] = useState([]);

  const [paginationPageSize, updatepaginationPageSize] = useState(10);
  const [gridApi, setGridApi] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let [newFile, setFile] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const userGridColumn = [
    {
      field: 'photo',
      headerName: '',
      cellRenderer: 'UserImageRenderer',
      minWidth: 120,
      maxWidth: 120,
      filter: false
    },
    {
      headerName: 'نام و نام خانوادگی',
      field: 'name',
      resizable: true,
      minWidth: 180
    },
    {
      headerName: 'شماره پرسنلی',
      field: 'personalCode'
    },
    {
      headerName: 'سمت',
      field: 'position.name'
    },
    {
      headerName: 'تاثیر در EnMS ',
      field: 'impact'
    },
    {
      headerName: 'شرح شغل',
      field: 'relatedFile',
      cellRenderer: 'UserJobDescriptionFileRenderer',
      filter: false
    },

    {
      field: '_id',
      headerName: '',
      cellRenderer: 'RowRendered',
      filter: false
    }
  ];
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const onSelectionChanged = () => {
    setSelectRow(gridApi.getSelectedRows());
  };
  const setGridPageSize = e => {
    gridApi.paginationSetPageSize(Number(e.target.value));
    updatepaginationPageSize(e.target.value);
  };
  const RowRendered = props => {
    const DeleteJobDescrition = async () => {
      swal({
        title: ` آیا می خواهید این شرح شغل را حذف نمایید؟  `,
        text: 'این عمل غیرقابل بازگشت میباشد.',
        icon: 'warning',
        dangerMode: true,
        buttons: ['انصراف', 'قبول']
      }).then(async willDelete => {
        if (willDelete) {
          const body = JSON.stringify({
            active: false
          });
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
          };

          let currentUser = await Axios.get(
            '/api/v1/users/' + props.value,
            config
          );
          if (currentUser.data.data.data.relatedFile) {
            let deleteFile = await Axios.delete(
              '/api/v1/file/' + currentUser.data.data.data.relatedFile._id,
              config
            );
            const userInfo = JSON.stringify({
              impact: '',
              haveJobDescription: false
            });
            Axios.patch('/api/v1/users/' + props.value, userInfo, config).then(
              async res => {
                setNeedGetusers(true);
                toast.success('شرح شغل  حذف شد');
              }
            );
          }
        }
      });
    };
    const UpdateJobDescrition = () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.get('/api/v1/users/' + props.value, config).then(res => {
        setItemSelected(res.data.data.data);
        setShowEdit(true);
      });
    };
    return (
      <span>
        <button
          style={{ lineHeight: 0.5 }}
          onClick={UpdateJobDescrition}
          className="btn btnViewDoc"
        >
          <i className="fa fa-pen"></i>
        </button>
        <button
          style={{ lineHeight: 0.5 }}
          onClick={DeleteJobDescrition}
          className="btn btnViewDoc"
        >
          <i className="fa fa-trash"></i>
        </button>
      </span>
    );
  };
  const UserImageRenderer = props => {
    return (
      <div className="JobDescriptionImage">
        <img
          className="img-thumbnail round"
          src={props.value}
          width="70"
          height="70"
        ></img>
      </div>
    );
  };
  const UserJobDescriptionFileRenderer = props => {
    // setFilePath(props.value)
    return (
      <div className="FilePreview">
        <div
          onClick={e => {
            setShowFile(true);
            if(props.value){
              const FileUrl = props.value.path.replace('/client/public', '');
              setFilePath(FileUrl);
            }

          }}
        >
          <i className="fa fa-eye"></i>
        </div>
      </div>
    );
  };

  const handleShowAdd = () => {
    setUserImpact('');
    setUserPosition('');
    setUserName('');
    setShowAdd(true);
  };
  const handleCloseAdd = () => setShowAdd(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleCloseFile = () => setShowFile(false);
  const changeFile = e => {
    setFile(e.target.files[0]);
  };

  const uploadFile = e => {
    e.preventDefault();
    if (newFile && userImpact && userPosition) {
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('title', newFile.name);
      formData.append('isProfile', false);
      formData.append('readOnly', false);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.post('/api/v1/file', formData, config).then(res => {
        const userInfo = JSON.stringify({
          relatedFile: res.data.data._id,
          impact: userImpact,
          position: userPosition,
          haveJobDescription: true
        });
        Axios.patch('/api/v1/users/' + userName.value, userInfo, config).then(
          async res => {
            setNeedGetusers(true);
            toast.success('شرح شغل ایجاد شد');
            setUserImpact('');
            setUserPosition('');
            setUserName('');
            setShowAdd(false);
          }
        );
      });
    } else {
      toast.error('لطفا تمام فیلدها را تکمیل نمایید');
    }
  };

  const EditFile =  async () => {
    // e.preventDefault();
    const userPositonTemp = userPosition
      ? userPosition
      : itemSelected.position._id;
    const userImpactTemp = userImpact ? userImpact : itemSelected.impact;
    const fileTemp = newFile ? newFile : itemSelected.fileType;

    if (newFile) {
      const formData = new FormData();
      formData.append('file', fileTemp);
      formData.append('title', fileTemp.name);
      formData.append('isProfile', false);
      formData.append('readOnly', false);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      let currentUser = await Axios.get(
        '/api/v1/users/' + itemSelected._id,
        config
      );
      if (currentUser.data.data.data.relatedFile) {
        let deleteFile = await Axios.delete(
          '/api/v1/file/' + currentUser.data.data.data.relatedFile._id,
          config
        );
      }
      Axios.post('/api/v1/file', formData, config).then(res => {
        const userInfo = JSON.stringify({
          relatedFile: res.data.data._id,
          impact: userImpactTemp,
          position: userPositonTemp
        });
        Axios.patch('/api/v1/users/' + itemSelected._id, userInfo, config).then(
          async res => {
            setNeedGetusers(true);
            toast.success('شرح شغل ویرایش شد');
            setUserImpact('');
            setUserPosition('');
            setUserName('');
            setShowEdit(false);
          }
        );
      });
    } else {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const userInfo = JSON.stringify({
        impact: userImpactTemp,
        position: userPositonTemp
      });
      Axios.patch('/api/v1/users/' + itemSelected._id, userInfo, config).then(
        async res => {
          setNeedGetusers(true);
          toast.success('شرح شغل ویرایش شد');
          setUserImpact('');
          setUserPosition('');
          setShowEdit(false);
        }
      );
    }
  };
  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    if (needGetusers) {
      Axios.get('/api/v1/users?haveJobDescription=true', config).then(res => {
        setAllJobUsers(res.data.data.data);
      });
      Axios.get('/api/v1/users', config).then(res => {
        setAllUsers(res.data.data.data);
      });
      Axios.get('/api/v1/JobPosition?isActive=true', config).then(res => {
        setAllJobPosition(res.data.data.data);
      });
      setNeedGetusers(false);
    }
  });
  return (
    <Container fluid id="MainContent" className="pr-0 pl-0 JobDescription">
      <Col className="mainContent pr-0 pl-0">
        <div className="pageBack">
          <Row className="mb-2">
            {/* نمایش فایل شرح شغل */}
            <Modal
              show={showFile}
              onHide={handleCloseFile}
              className="JobDescriptionModal"
            >
              <Modal.Header closeButton>
                <Modal.Title> شرح شغل </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {filePath}
                <Document
                  // file="./uploads/Elin.pdf"
                  file={filePath}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                {/* <FileViewer
                      fileType="pdf"

                    //   filePath={require('./uploads/Elin.pdf')}
                    filePath ='http://energyhouse.ir/wp-content/uploads/2018/06/EnergyHouseLearningCatRev012018.pdf'
                    /> */}
              </Modal.Body>
            </Modal>
            {/* نمایش فایل شرح شغل */}

            {/* ویرایش شرح شغل */}
            <Modal show={showEdit} onHide={handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title> ویرایش شرح شغل</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>سمت</label>
                    <div className="col-sm-8 pl-0 pr-0">
                      <select
                        className={'form-control'}
                        onChange={e => setUserPosition(e.target.value)}
                        value={
                          userPosition
                            ? userPosition
                            : itemSelected.position
                            ? itemSelected.position._id
                            : ''
                        }
                      >
                        <option value=""></option>
                        {allJobPosition.map(jobPosition => {
                          return (
                            <option value={jobPosition._id}>
                              {jobPosition.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>
تاثیر در EnMS
                    </label>
                    <FormControl
                    placeholder={itemSelected.impact}
                      aria-label="fileName"
                      aria-describedby="fileName"
                      value={userImpact}
                      onChange={e => setUserImpact(e.target.value)}
                    />
                  </InputGroup>

                  <InputGroup className="mb-1">
                    <label className={'col-sm-4 col-form-label'}>
                      شرح شغل (pdf)
                    </label>
                    <div className="col-sm-8 pl-0 pr-0">
                      <Form.File id="file" onChange={changeFile} />
                    </div>
                  </InputGroup>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={EditFile}>
                  ذخیره
                </Button>
              </Modal.Footer>
            </Modal>
            {/* ویرایش شرح شغل */}
            <Col md={6}>
              <Button
                variant="btn btn-success float-left mr-2"
                onClick={handleShowAdd}
              >
                <i class="fa fa-plus pr-1"></i> شرح شغل
              </Button>
              <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                  <Modal.Title> افزودن شرح شغل</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-4 col-form-label'}>
                        نام و نام خانوادگی
                      </label>
                      <div className="col-sm-8 pl-0 pr-0">
                        <AutoCompleteSelect
                          isClearable={true}
                          isSearchable={true}
                          value={userName}
                          onChange={newValv => {
                            setUserName(newValv);
                          }}
                          placeholder={'نام شخص را انتخاب نمایید'}
                          options={allUsers.map(user => {
                            let usernameCode =
                              user.name + ' | ' + user.personalCode;
                            return {
                              label: usernameCode,
                              value: user._id
                            };
                          })}
                        />
                      </div>
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-4 col-form-label'}>سمت</label>
                      <div className="col-sm-8 pl-0 pr-0">
                        <select
                          className={'form-control'}
                          onChange={e => setUserPosition(e.target.value)}
                        >
                          <option value=""></option>
                          {allJobPosition.map(jobPosition => {
                            return (
                              <option value={jobPosition._id}>
                                {jobPosition.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-4 col-form-label'}>
  تاثیر در EnMS
                      </label>
                      <FormControl
                        aria-label="fileName"
                        aria-describedby="fileName"
                        value={userImpact}
                        onChange={e => setUserImpact(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-4 col-form-label'}>
                        شرح شغل (pdf)
                      </label>
                      <div className="col-sm-8 pl-0 pr-0">
                        <Form.File id="file" onChange={changeFile} />
                      </div>
                    </InputGroup>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={uploadFile}>
                    بارگذاری
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
            <Col md={{ span: 3, offset: 3 }}>
              <Form className="row mr-2">
                <Form.Label className="col-md-8 pt-1 text-left">
                  ردیف در صفحه
                </Form.Label>
                <Form.Control
                  className="col-md-4"
                  as="select"
                  name="newUser_role"
                  value={paginationPageSize}
                  onChange={setGridPageSize}
                  required
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                </Form.Control>
              </Form>
            </Col>
          </Row>
          <Datagrid
            gridColumn={userGridColumn}
            rowData={allJobUsers}
            setGridApi={setGridApi}
            paginationPageSize={paginationPageSize}
            onSelectionChanged={onSelectionChanged}
            onClickItems={RowRendered}
            userimage={UserImageRenderer}
            userJobDescriptionFile={UserJobDescriptionFileRenderer}
            rowHeight="80"
          />
        </div>
      </Col>
    </Container>
  );
};
export default JobDescription;

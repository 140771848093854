import React from 'react';
import './Print.css';
const Header = () => {
  return (
    <div class="PrintHeader">
      <img  src="../../../ReportTypes/KavirHeader.jpg" />
    </div>
  );
};
export default Header;

import React, { useState, useEffect, useRef, Fragment } from 'react';
import Axios from '../../../../Axios/Axios';
import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import moment from 'jalali-moment';
import DatePicker from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { linearRegression } from '../../../../components/Regression/js/linear/linearRegression';
import { addConstant } from '../../../../components/Regression/js/tools/tools';
import regression from 'regression-extend';
import TrendChart from './TrendChart';
import { parse } from 'mathjs';

import './EnBS.css';
import ReactToPrint from 'react-to-print';
import Header from '../../Print/Header';
import Footer from '../../Print/Footer';

const EnBS = props => {
  const EnBSReport = useRef();
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [chartLoad, setChartLoad] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [chartDatalength, setChartDatalength] = useState();


  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const currentReport = props.currentReport.nav;

  useEffect(() => {
    if (props.setRenderPrint) {
      setStart()
      setEnd()
      setShowPrint(false);
      setChartData([]);
    }
  }, [props.currentReport]);

  const getTrend = (startVal, endVal) => {
    if (props.pageload && (end || endVal)) {
      setShowPrint(false);
      setChartData([]);
      if (props.currentReport.Trend && props.currentReport.Trend.length > 0) {
        let tempArray = [];
        let days;
        props.currentReport.Trend.map(async TrendItem => {
          const getPredictData = (paramC, x1, x2, x3, x4, x5, formul, day) => {
            let regressionParam = TrendItem.regressionParam;
            let regressionOrder = TrendItem.regressionOrder;
            let formulTemp = parse(formul.replace('0x2', ''));
            if (regressionParam > 1 || regressionOrder == '1') {
              return {
                x: day,
                y: formulTemp.evaluate({ x: x1, x1: x1, x2: x2, x3: x3, x4: x4, x5: x5 })
              };
            } else {
              return {
                x: day,
                y: formulTemp.evaluate({ x: x1, 'x^2': x1 })
              };
            }
          };

          let regressionParam = TrendItem.regressionParam;
          let regressionOrder = TrendItem.regressionOrder;
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
          };
          if (startVal) {
            var fromDate = startVal.year + '-' + startVal.month + '-' + startVal.day;
          }
          if (endVal) {
            var toDate = endVal.year + '-' + endVal.month + '-' + endVal.day;
          }

          let bodyObjReport = {
            kind: 'range',
            fromDate: fromDate,
            toDate: toDate,
            paramCount: regressionParam,
            yAxieFormulaSynon: TrendItem.yAxieFormulaSynon,
            yAxieFormula: TrendItem.yAxieFormula,
            x1AxieFormulaSynon: TrendItem.x1AxieFormulaSynon,
            x1AxieFormula: TrendItem.x1AxieFormula,
            x2AxieFormulaSynon: TrendItem.x2AxieFormulaSynon,
            x2AxieFormula: TrendItem.x2AxieFormula,
            x3AxieFormulaSynon: TrendItem.x3AxieFormulaSynon,
            x3AxieFormula: TrendItem.x3AxieFormula,
            x4AxieFormulaSynon: TrendItem.x4AxieFormulaSynon,
            x4AxieFormula: TrendItem.x4AxieFormula,
            x5AxieFormulaSynon: TrendItem.x5AxieFormulaSynon,
            x5AxieFormula: TrendItem.x5AxieFormula
          };

          const bodyReport = JSON.stringify(bodyObjReport);
          const reportData = await Axios.post('/api/v1/util/formula/trend', bodyReport, config);
          days = reportData.data.data.days;
          let actualData = reportData.data.data.data.yAxie;
          const tempReportData = reportData.data.data.data;
          const predictData = reportData.data.data.days.map((item, index) => {
            return getPredictData(
              regressionParam,
              tempReportData.x1Axie ? tempReportData.x1Axie[index].y : null,
              tempReportData.x2Axie ? tempReportData.x2Axie[index].y : null,
              tempReportData.x3Axie ? tempReportData.x3Axie[index].y : null,
              tempReportData.x4Axie ? tempReportData.x4Axie[index].y : null,
              tempReportData.x5Axie ? tempReportData.x5Axie[index].y : null,
              TrendItem.regressionFormula,
              item
            );
          });

          let from = TrendItem.from;
          let to = TrendItem.to;
          let bodyObj = {
            kind: 'range',
            fromDate: from,
            toDate: to,
            paramCount: regressionParam,
            yAxieFormulaSynon: TrendItem.yAxieFormulaSynon,
            yAxieFormula: TrendItem.yAxieFormula,
            x1AxieFormulaSynon: TrendItem.x1AxieFormulaSynon,
            x1AxieFormula: TrendItem.x1AxieFormula,
            x2AxieFormulaSynon: TrendItem.x2AxieFormulaSynon,
            x2AxieFormula: TrendItem.x2AxieFormula,
            x3AxieFormulaSynon: TrendItem.x3AxieFormulaSynon,
            x3AxieFormula: TrendItem.x3AxieFormula,
            x4AxieFormulaSynon: TrendItem.x4AxieFormulaSynon,
            x4AxieFormula: TrendItem.x4AxieFormula,
            x5AxieFormulaSynon: TrendItem.x5AxieFormulaSynon,
            x5AxieFormula: TrendItem.x5AxieFormula
          };
          const body = JSON.stringify(bodyObj);
          const trendData = await Axios.post('/api/v1/util/formula/trend', body, config);
          days = trendData.data.data.days;
          const yData = [];
          let xData = [];
          days.forEach((item, index) => {
            yData.push(trendData.data.data.data.yAxie[index].y);
            if (regressionParam === '1') {
              if (regressionOrder === '1') {
                // amir
                xData.push([trendData.data.data.data.x1Axie[index].y, 0]);
              } else {
                // amir
                xData.push([trendData.data.data.data.x1Axie[index].y]);
              }
            } else if (regressionParam === '2') {
              xData.push([trendData.data.data.data.x1Axie[index].y, trendData.data.data.data.x2Axie[index].y]);
            } else if (regressionParam === '3') {
              xData.push([
                trendData.data.data.data.x1Axie[index].y,
                trendData.data.data.data.x2Axie[index].y,
                trendData.data.data.data.x3Axie[index].y
              ]);
            } else if (regressionParam === '4') {
              xData.push([
                trendData.data.data.data.x1Axie[index].y,
                trendData.data.data.data.x2Axie[index].y,
                trendData.data.data.data.x3Axie[index].y,
                trendData.data.data.data.x4Axie[index].y
              ]);
            } else if (regressionParam === '5') {
              xData.push([
                trendData.data.data.data.x1Axie[index].y,
                trendData.data.data.data.x2Axie[index].y,
                trendData.data.data.data.x3Axie[index].y,
                trendData.data.data.data.x4Axie[index].y,
                trendData.data.data.data.x5Axie[index].y
              ]);
            }
          });
          let regressionResult = {};
          if (regressionParam === '1') {
            if (regressionOrder === '1') {
              //add a 1 at beginning of every y for calculating the constant coefficient.
              xData = addConstant(xData);
              //calculate variance inflation factors
              regressionResult = linearRegression(yData, xData, false);
            } else if (regressionOrder === '2') {
              regressionResult = regression.polynomial(xData, yData, { order: 2 });
            } else if (regressionOrder === '3') {
              regressionResult = regression.polynomial(xData, yData, { order: 3 });
            } else if (regressionOrder === '4') {
              regressionResult = regression.polynomial(xData, yData, { order: 4 });
            }
          } else {
            //add a 1 at beginning of every y for calculating the constant coefficient.
            xData = addConstant(xData);
            //calculate variance inflation factors

            regressionResult = linearRegression(yData, xData, false);
          }

          let a = moment.from(from, 'fa', 'YYYY-MM-DD');
          let b = moment.from(to, 'fa', 'YYYY-MM-DD');
          var diffs = b.diff(a, 'days');
          let previewRegression = {
            ...regressionResult,
            inDays: diffs + 1,
            outDays: days.length,
            from: from,
            to: to
          };

          var trendItemName = TrendItem.name;
          tempArray.push({
            days,
            actualData,
            predictData,
            trendItemName,
            previewRegression,
            regressionParam,
            regressionOrder
          });

          setChartData(tempArray);
          setChartDatalength(tempArray.length)
        });
        setChartLoad(true);
      }
      setPageLoad(false);
    } else {
      // console.log('current report nabood');
    }
  };
  const printReport = async () => {
    if (chartData) {
      setChartLoad(true);
      window.print();
    }
  };
  return (
    <div>
      <Col md={{ span: 4, offset: 4 }} className="d-print-none NewReport ">

        <div className="mt-2 notification">لطفا تاریخ گزارش دهی را انتخاب نمایید.</div>
        <div className="mt-1 notification">توجه فرمایید باید اطلاعات در خط مبنا وجود داشته باشد</div>
        <div>
          <InputGroup className="mb-1 fromDate ">
            <label className={'col-sm-3 col-form-label'}>از تاریخ</label>
            <span className={'col-sm-9 pr-0 pl-0 '}>
              <DatePicker
                className={'col-sm-9'}
                value={start}
                onChange={val => {
                  setStart(val);
                  setChartLoad(false);
                  getTrend(val, end);
                }}
                inputPlaceholder={start ? `${start.year}-${start.month}-${start.day}` : 'تاریخ شروع دوره'}
                shouldHighlightWeekends
                locale="fa"
              />
            </span>
          </InputGroup>
          <InputGroup className="mb-1 toDate ">
            <label className={'col-sm-3 col-form-label'}>تا تاریخ </label>
            <span className={'col-sm-9 pr-0 pl-0'}>
              <DatePicker
                className={'col-sm-9'}
                value={end}
                onChange={val => {
                  setEnd(val);
                  setChartLoad(false);
                  getTrend(start, val);
                }}
                inputPlaceholder={end ? `${end.year}-${end.month}-${end.day}` : 'تاریخ پایان دوره'}
                shouldHighlightWeekends
                locale="fa"
              />
            </span>
          </InputGroup>

          <Form.Group className="text-center mt-2 mb-2 Printbtn">
            {/* disabled={pageLoad && chartLoading } */}
            {/* <center hidden={showPrint}>
          <Spinner animation="grow" variant="success" className={'spinnerChart'} />
        </center> */}


            <Button onClick={printReport} variant="primary" disabled={!showPrint && chartDatalength != chartData.length}>
              چاپ گزارش
            </Button>
            <ReactToPrint
              copyStyles="true"
              documentTitle={currentReport ? currentReport : 'Report'}
              trigger={() => (
                <Button hidden variant="primary ">
                  ذخیره
                </Button>
              )}
              content={() => EnBSReport.current}
            />
          </Form.Group>
        </div>
      </Col>
      <div className="pagePrint" ref={EnBSReport}>
        <Header></Header>
        {chartData.length > 0 ? (
          chartData.map(chartDataItem => {

            return (
              <div>
                <div className="prBox">
                  <div className="trendName">{chartDataItem.trendItemName}</div>
                  {/* <Row className={'mb-2'} hidden={chartLoading}> */}
                  <Row className={'mb-2'} >
                    <Fragment hidden={!chartLoad}>
                      <TrendChart chartData={chartDataItem} chartLoad={chartLoad} setShowPrint={setShowPrint} />
                    </Fragment>
                  </Row>
                </div>
                <div className="prBox">
                  <Row>
                    <h3 className={'simpleBoxTitle'}>آنالیز آماری رگرسیون</h3>
                    <Row className="mb-2 mt-2">
                      {chartDataItem.regressionParam &&
                        (chartDataItem.regressionParam > '1' || chartDataItem.regressionOrder == '1') ? (
                        <Col md={{ span: 12 }}>
                          <Table responsive striped className="RegTable mb-3">
                            <thead>
                              <tr>
                                <th></th>
                                <th>SS</th>
                                <th>F</th>
                                <th>Significance F</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="font-weight-bold">Regression</td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.ssr).toFixed(6)
                                    : ''}
                                </td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.fValue).toFixed(6)
                                    : ''}
                                </td>

                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.pValueOfFValue).toFixed(6)
                                    : ''}
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bold">Residual</td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.sse).toFixed(6)
                                    : ''}
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td className="font-weight-bold">Total</td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(
                                      chartDataItem.previewRegression.ssr +
                                      parseInt(chartDataItem.previewRegression.sse)
                                    ).toFixed(6)
                                    : ''}
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </Table>

                          <Table responsive striped className="RegTable mb-3">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Coefficients</th>
                                <th>Standard Error</th>
                                <th>t Stat</th>
                                <th>P-value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {chartDataItem.previewRegression
                                ? chartDataItem.previewRegression.pValues.map((item, index) => {
                                  if (!item || isNaN(item)) {
                                  } else
                                    return (
                                      <tr>
                                        <td className="font-weight-bold">
                                          {index === 0 ? 'Intercept' : 'x' + index}
                                        </td>
                                        <td>
                                          {parseFloat(
                                            chartDataItem.previewRegression.coefficients.data[index]
                                          ).toFixed(6)}
                                        </td>
                                        <td>
                                          {parseFloat(
                                            chartDataItem.previewRegression.stdErrorOfCoefficients[index]
                                          ).toFixed(6)}
                                        </td>

                                        <td>
                                          {parseFloat(chartDataItem.previewRegression.tValues[index]).toFixed(6)}
                                        </td>
                                        <td>
                                          {parseFloat(chartDataItem.previewRegression.pValues[index]).toFixed(6)}
                                        </td>
                                      </tr>
                                    );
                                })
                                : ''}
                            </tbody>
                          </Table>
                        </Col>
                      ) : (
                        <Col md={{ span: 12 }}>
                          <Table responsive striped className="RegTable mb-3">
                            <thead>
                              <tr>
                                <th>me</th>
                                <th>sse</th>
                                <th>mse</th>
                                <th>smse</th>
                                <th>se</th>
                                <th>tstat</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.me).toFixed(4)
                                    : ''}
                                </td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.sse).toFixed(4)
                                    : ''}
                                </td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.mse).toFixed(4)
                                    : ''}
                                </td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.smse).toFixed(4)
                                    : ''}
                                </td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartDataItem.previewRegression.se).toFixed(4)
                                    : ''}
                                </td>
                                <td>
                                  {chartDataItem.previewRegression
                                    ? parseFloat(chartData.previewRegression.tstat).toFixed(4)
                                    : ''}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      )}
                    </Row>
                  </Row>
                </div>
              </div>
            );
          })
        ) : (
          <div className="prBox">
            <div>No data</div>
            {/* {console.log('no data')} */}
          </div>
        )}

        <Footer></Footer>
      </div>
    </div>
  );
};

export default EnBS;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'jalali-moment';
import Axios from '../../Axios/Axios';
import Chart from '../PeriodicChart/Chart/Chart.js';
import Datagrid from '../../components/UI/Datagrid/Datagrid';
import EULabel from '../EnergyLabel/EULabel/EULabel';

import './Home.css';

const Home = () => {
  const [paginationPageSize, updatepaginationPageSize] = useState(10);
  const [needGetFile, setNeedGetFile] = useState(true);
  const [gridApi, setGridApi] = useState(null);

  const [firstChartData, setFirstChartData] = useState({
    data: [],
    dataCompare: []
  });
  const [secondChartData, setSecondChartData] = useState({
    data: [],
    dataCompare: []
  });
  const [thirdChartData, setThirdChartData] = useState({
    data: [],
    dataCompare: []
  });

  const [firstChartQuery, setFirstChartQuery] = useState([]);
  const [secondChartQuery, setSecondChartQuery] = useState([]);
  const [thirdChartQuery, setThirdChartQuery] = useState([]);
  const [chartTempData, setChartTempData] = useState([]);
  const [righNav, setRighNav] = useState([{ navType: 'building' }]);
  const [tomorrowEvents, setTomorrowEvents] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [building, setBuilding] = useState([]);
  const [EIdeal, setEIdeal] = useState();
  const [climate, setClimate] = useState(null);
  const [labelData, setLabelData] = useState(null);
  const [showLegend, setShowLegend] = useState(false);
  
  const FirstChartColor = ['#ffb91d'];
  const SecondChartColor = ['#6e61ed'];
  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    if (needGetFile) {
      // Start First Chart  برق
      setFirstChartQuery({
        kind: 'year',
        year: '1399',
        month: '01'
      });
      let firstChartQueryLocal = {
        kind: 'year',
        year: '1399',
        month: '01'
      };
      firstChartQueryLocal.nav = [
        {
          navID: '6195fff7600bfe46506622ef',
          navName: 'ساختمان مرکزی',
          navType: 'Building'
        }
      ];

      const firstBody = JSON.stringify(firstChartQueryLocal);
      Axios.post(`/api/v1/consumption/gstats`, firstBody, config).then(res => {
        res.data.data.data.forEach(navData => {
          navData.forEach(dataset => {
            if (dataset._id === 'برق') {
              let ChartDataset = dataset;
              ChartDataset.nav = res.data.data.nav;
              ChartDataset.dataCompare = res.data.data.dataCompare;
              let chartDataOne = {
                data: [[dataset]],
                dataCompare: res.data.data.dataCompare,
                nav: res.data.data.nav
              };
              setFirstChartData(chartDataOne);
            }
          });
        });
      });
      // End First Chart  برق
      // Start Second Chart  کلی
      setSecondChartQuery({
        kind: 'month',
        year: '1399',
        month: '01'
      });

      let secondChartQueryLocal = {
        kind: 'month',
        year: '1399',
        month: '01'
      };
      secondChartQueryLocal.nav = [
        {
          navID: '6195fff7600bfe46506622ef',
          navName: 'ساختمان مرکزی',
          navType: 'Building'
        }
      ];

      const secondBody = JSON.stringify(secondChartQueryLocal);
      Axios.post(`/api/v1/consumption/gstats`, secondBody, config).then(res => {
        setSecondChartData(res.data.data);
      });
      // End Second Chart  کلی

      // Start Third گاز  کلی
      setThirdChartQuery({
        kind: 'year',
        year: '1399',
        month: '01'
      });

      let ThirdChartQueryLocal = {
        kind: 'year',
        year: '1399',
        month: '01'
      };
      ThirdChartQueryLocal.nav = [
        {
          navID: '619919206f9a852f8867ad9e',
          navName: 'پتروشیمی پارس',
          navType: 'Organization'
        }
      ];

      const thirdBody = JSON.stringify(ThirdChartQueryLocal);
      Axios.post(`/api/v1/consumption/gstats`, thirdBody, config).then(res => {
        res.data.data.data.forEach(navData => {
          navData.forEach(dataset => {
            if (dataset._id === 'گاز') {
              let ChartDatasetThree = dataset;
              ChartDatasetThree.nav = res.data.data.nav;
              ChartDatasetThree.dataCompare = res.data.data.dataCompare;
              let chartDataThree = {
                data: [[dataset]],
                dataCompare: res.data.data.dataCompare,
                nav: res.data.data.nav
              };
              setThirdChartData(chartDataThree);
            }
          });
        });
      });
      // End third Chart  گاز

      // Start Get Today  Events
      let today = moment(new Date()).format('YYYY-MM-DD');
      const tomorrow = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD');
      Axios.get('/api/v1/event?date=' + today, config).then(todayEvent => {
        setTodayEvents(todayEvent.data.data.data);
      });
      Axios.get('/api/v1/event?date=' + tomorrow, config).then(
        tomorrowEvent => {
          setTomorrowEvents(tomorrowEvent.data.data.data);
        }
      );
      // End Get Today  Events

      // Start get last Documents
      Axios.get(
        '/api/v1/file/document?isStandard=true&sort=-created&limit=7',
        config
      ).then(res => {
        setAllFiles(res.data.data.data);
      });
      // End get last Documents

      // Start Energy Lable

      EnergyLable();

      // End Energy Lable
      setNeedGetFile(false);
    }
  });
  const EnergyLable = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const eBuilding = await Axios.get(
      '/api/v1/Building/6195fff7600bfe46506622ef',
      config
    );
    const building = eBuilding.data.data.data;
    const chartYear = '1399';
    const climate = 'سرد';
    if (chartYear && building && climate) {
      let ideal = changeEIDeal(climate, building);
      setEIdeal(ideal);

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const body = JSON.stringify({
        year: chartYear,
        building: building
      });
      const eActualData = await Axios.post(
        '/api/v1/consumption/energyLabel',
        body,
        config
      );
      const eActual = eActualData.data.data.data.eactual;
      const RValue = eActual / ideal;

      let energyLabelChar = '';
      switch (building.type) {
        case 'مسکونی کوچک':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 1.9) {
            energyLabelChar = 'B';
          } else if (RValue >= 1.9 && RValue < 2.7) {
            energyLabelChar = 'C';
          } else if (RValue >= 2.7 && RValue < 3.4) {
            energyLabelChar = 'D';
          } else if (RValue >= 3.4 && RValue < 4) {
            energyLabelChar = 'E';
          } else if (RValue >= 4 && RValue < 4.5) {
            energyLabelChar = 'F';
          } else if (RValue >= 4.5 && RValue < 5) {
            energyLabelChar = 'G';
          } else if (RValue >= 5) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
        case 'مسکونی بزرگ':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 2) {
            energyLabelChar = 'B';
          } else if (RValue >= 2 && RValue < 2.9) {
            energyLabelChar = 'C';
          } else if (RValue >= 2.9 && RValue < 3.7) {
            energyLabelChar = 'D';
          } else if (RValue >= 3.7 && RValue < 4.4) {
            energyLabelChar = 'E';
          } else if (RValue >= 4.4 && RValue < 5) {
            energyLabelChar = 'F';
          } else if (RValue >= 5 && RValue < 5.4) {
            energyLabelChar = 'G';
          } else if (RValue >= 5.4) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
        case 'اداری دولتی':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 2) {
            energyLabelChar = 'B';
          } else if (RValue >= 2 && RValue < 3) {
            energyLabelChar = 'C';
          } else if (RValue >= 3 && RValue < 4) {
            energyLabelChar = 'D';
          } else if (RValue >= 4 && RValue < 5) {
            energyLabelChar = 'E';
          } else if (RValue >= 5 && RValue < 6) {
            energyLabelChar = 'F';
          } else if (RValue >= 6 && RValue < 7) {
            energyLabelChar = 'G';
          } else if (RValue >= 7) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
        case 'اداری خصوصی':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 2.2) {
            energyLabelChar = 'B';
          } else if (RValue >= 2.2 && RValue < 3.2) {
            energyLabelChar = 'C';
          } else if (RValue >= 3.2 && RValue < 4) {
            energyLabelChar = 'D';
          } else if (RValue >= 4 && RValue < 4.6) {
            energyLabelChar = 'E';
          } else if (RValue >= 4.6 && RValue < 5.2) {
            energyLabelChar = 'F';
          } else if (RValue >= 5.2 && RValue < 5.5) {
            energyLabelChar = 'G';
          } else if (RValue >= 5.5) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
      }
      setLabelData({
        eActual,
        RValue,
        energyLabelChar,
        ideal,
        consumptions: eActualData.data.data.data.consumptions,
        building: building,
        climate: climate,
        year: chartYear
      });
    }
  };
  const changeEIDeal = (cl, bl) => {
    let BuildingeClimat = cl;
    switch (bl.type) {
      case 'مسکونی کوچک':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 111;
            break;
          case 'سرد':
            return 111;
            break;
          case 'معتدل و بارانی':
            return 156;
            break;
          case 'نیمه معتدل و بارانی':
            return 156;
            break;
          case 'نیمه خشک':
            return 83;
            break;
          case 'گرم و خشک':
            return 86;
            break;
          case 'بسیار گرم و خشک':
            return 150;
            break;
          case 'بسیار گرم و مرطوب':
            return 130;
            break;
        }
        break;
      case 'مسکونی بزرگ':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 102;
            break;
          case 'سرد':
            return 102;
            break;
          case 'معتدل و بارانی':
            return 106;
            break;
          case 'نیمه معتدل و بارانی':
            return 106;
            break;
          case 'نیمه خشک':
            return 87;
            break;
          case 'گرم و خشک':
            return 75;
            break;
          case 'بسیار گرم و خشک':
            return 138;
            break;
          case 'بسیار گرم و مرطوب':
            return 118;
            break;
        }
        break;
      case 'اداری دولتی':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 80;
            break;
          case 'سرد':
            return 80;
            break;
          case 'معتدل و بارانی':
            return 64;
            break;
          case 'نیمه معتدل و بارانی':
            return 64;
            break;
          case 'نیمه خشک':
            return 74;
            break;
          case 'گرم و خشک':
            return 64;
            break;
          case 'بسیار گرم و خشک':
            return 86;
            break;
          case 'بسیار گرم و مرطوب':
            return 91;
            break;
        }
        break;
      case 'اداری خصوصی':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 120;
            break;
          case 'سرد':
            return 120;
            break;
          case 'معتدل و بارانی':
            return 152;
            break;
          case 'نیمه معتدل و بارانی':
            return 152;
            break;
          case 'نیمه خشک':
            return 124;
            break;
          case 'گرم و خشک':
            return 117;
            break;
          case 'بسیار گرم و خشک':
            return 121;
            break;
          case 'بسیار گرم و مرطوب':
            return 197;
            break;
        }
        break;
    }
  };
  return (
 
    <Container fluid id="MainContent" className="pr-0 pl-0 HomePage">
      <Col className="mainContent ">
        <Row className="row-eq-height">
          <Col md={5} className="pr-0 pl-0 mb-2">
            <div className="pageBackHome">
              {firstChartData ? (
                <div className="HomeTitle">ساختمان مرکزی - 1399 - مصرف برق </div>
              ) : (
                ''
              )}
              {firstChartData ? (
                <Chart
                  chartData={firstChartData}
                  chartDataTitle={firstChartData}
                  chartCompareDataTitle={firstChartData}
                  chartType="Line"
                  meter={righNav}
                  chartTempData={chartTempData}
                  chartQuery={firstChartQuery}
                  showLegend={showLegend}
                  ChartColor={SecondChartColor}

                  // city={city}
                />
              ) : (
                ''
              )}
            </div>
          </Col>
          <Col md={5} className="mb-2">
            <div className="pageBackHome">
              {thirdChartData ? (
                <div className="HomeTitle">ساختمان مرکزی - 1399 - مصرف برق </div>
              ) : (
                ''
              )}
              {thirdChartData ? (
                <Chart
                  chartData={thirdChartData}
                  chartDataTitle={thirdChartData}
                  chartCompareDataTitle={thirdChartData}
                  chartType="Bar"
                  meter={righNav}
                  chartTempData={chartTempData}
                  chartQuery={thirdChartQuery}
                  showLegend={showLegend}
                  ChartColor={FirstChartColor}

                  // city={city}
                />
              ) : (
                ''
              )}
            </div>
          </Col>
          <Col md={2} className="pr-0 pl-0 mb-2">
            <div className="pageBackTransparent">
              {/* {secondChartData ? (
                <Chart
                  chartData={secondChartData}
                  chartDataTitle={secondChartData}
                  chartCompareDataTitle={secondChartData}
                  chartType="Bar"
                  meter={righNav}
                  chartTempData={secondChartData}
                  chartQuery={secondChartQuery}
                  // city={city}
                />
              ) : (
                ''
              )} */}
              <div className="consBoxOne consBox">
              <h6>49012.33</h6>

                <h3>مصرف برق</h3>
                <h4> بهمن ماه 1399 </h4>

              </div>
              <div className="consBoxTwo consBox">
              <h6>98301.56</h6>
                <h3>مصرف گاز</h3>
                <h4> بهمن ماه 1399 </h4>
              </div>
            </div>
          </Col>
        </Row>
        <div className="w-100"></div>
        <Row className="row-eq-height">
          <Col md={6} className="pr-0 pl-0">
            <div className="pageBackHome">
              <div>
                <div className="HomeTitle">آخرین اسناد</div>
                <div className="table-responsive">
                  <table class="table tblSimple">
                    <thead>
                      <tr>
                        <th scope="col">نام </th>
                        <th scope="col">نوع</th>
                        <th scope="col">واحد</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allFiles
                        ? allFiles.map(allFile => {
                            return (
                              // <tr onclick={ShowDocumentPage}>
                                <tr >
                                <td className="col-md-8">{allFile.title}</td>
                                <td className="col-md-2">
                                  {' '}
                                  {allFile.standardNumber}
                                </td>
                                <td className="col-md-2">
                                  {' '}
                                  {allFile.documentType.name}
                                </td>
                              </tr>
                            );
                          })
                        : 'وجود ندارد '}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="pageBackHome ">
              <div className="eventBox">
                <div className="HomeTitle">رویدادها</div>
                <div className="todayEvents">
                  <h3 className="EventTblTitle mt-3">امروز</h3>
                  {todayEvents
                    ? todayEvents.map(todayEvent => {
                        return (
                          <Row>
                            <Col md={4} className="pr-0">
                              <span className="eventDate">
                                {todayEvent.type === 'FullDay'
                                  ? 'تمام روز  '
                                  : todayEvent.fromHour
                                      .toString()
                                      .split('T')
                                      .pop() +
                                    '-' +
                                    todayEvent.toHour
                                      .toString()
                                      .split('T')
                                      .pop()}
                              </span>
                            </Col>
                            <Col md={8}>
                              <span
                                class="rightBrd"
                                style={{ backgroundColor: todayEvent.color }}
                              ></span>
                              <span className="eventName">
                                {todayEvent.name}
                              </span>{' '}
                            </Col>
                          </Row>
                        );
                      })
                    : 'وجود ندارد '}
                </div>
                <div className="tomorrowEvents">
                  <h3 className="EventTblTitle mt-3">فردا</h3>
                  {tomorrowEvents
                    ? tomorrowEvents.map(tomorrowEvent => {
                        return (
                          <Row>
                            <Col md={4} className="pr-0">
                              <span className="eventDate">
                                {tomorrowEvent.type === 'FullDay'
                                  ? 'تمام روز  '
                                  : tomorrowEvent.fromHour
                                      .toString()
                                      .split('T')
                                      .pop() +
                                    '-' +
                                    tomorrowEvent.toHour
                                      .toString()
                                      .split('T')
                                      .pop()}
                              </span>
                            </Col>
                            <Col md={8}>
                              <span
                                class="rightBrd"
                                style={{ backgroundColor: tomorrowEvent.color }}
                              ></span>
                              <span className="eventName">
                                {tomorrowEvent.name}
                              </span>{' '}
                            </Col>
                          </Row>
                        );
                      })
                    : 'وجود ندارد '}
                </div>
              </div>
            </div>
          </Col>

          <Col md={3} className="pr-0 pl-0">
            <div className="pageBackHome">
              <div className="HomeTitle">
              معیار مصرف
              </div>
              <div className="HomeTitleUnder" > ساختمان مرکزی سال 1399</div>
              {labelData ? <EULabel labelData={labelData} /> : ' '}
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};
export default Home;

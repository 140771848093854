import React, { useEffect, useState } from 'react';

import Datagrid from '../../../components/UI/Datagrid/Datagrid';
import moment from 'jalali-moment';

const AllProject = props => {
  const [gridApi, setGridApi] = useState(null);

  const historyGridColumn = [
    {
      headerName: 'نام پروژه',
      field: 'name',
      onCellClicked: e => {
        props.setMVProjectID(e.data);
      }
    },
    {
      headerName: 'IPMVP Option',
      field: 'IPMVP'
    },
    {
      headerName: 'تاریخ ایجاد',
      field: 'created',
      valueFormatter: params =>
        moment(params.value)
          .locale('fa')
          .format('YYYY-MM-DD')
    },
    {
      headerName: 'ایجاد توسط',
      field: 'createdBy.name'
    },
    {
      headerName: 'آخرین ویرایش',
      field: 'edited',
      valueFormatter: params =>
        moment(params.value)
          .locale('fa')
          .fromNow()
    }
  ];
  return (
    <Datagrid
      gridColumn={historyGridColumn}
      rowData={props.AllMVProjects}
      paginationPageSize={10}
      setGridApi={setGridApi}
    />
  );
};

export default AllProject;

import React, { Fragment,useState, useEffect  } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Axios from '../../../../Axios/Axios';

import moment from 'jalali-moment';

import { logout } from '../../../../actions/auth';
import './Navbar.css';
const Navbar = props => {
  const [isRenderNotification, setIsRenderNotification] = useState(true);
  const [allNotification, setAllNotification] = useState([]);

  useEffect(() => {
    if (isRenderNotification === true) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.get('/api/v1/users/me', config).then(user => {
        Axios.get(
          `/api/v1/notification?isActive=true&assignTo=` +
            user.data.data.data._id +
            `&sort=created,true`,
          config
        ).then(notification => {
          setAllNotification(notification.data.data.data);
        });
      });

      setIsRenderNotification(false);
    }
  });
  const HideNotification = NotificationID => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const UpdateNote = JSON.stringify({
      isActive: 'false'
    });

    Axios.patch(
      `/api/v1/notification/` + NotificationID,
      UpdateNote,
      config
    ).then(notification => {
      setIsRenderNotification(true);
    });
  };

  return (
    <Fragment>
      <nav className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-fixed  navbar-brand-center">
        <div className="navbar-header d-xl-block d-none">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item">
              <a className="navbar-brand" href="/">
                <img
                  className="img-fluid"
                  src="../../../../../app-assets/images/logo/EMSLogo.png"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse" id="navbar-mobile">
              <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                <ul className="nav navbar-nav">
                  <li className="nav-item mobile-menu d-xl-none mr-auto">
                    <a
                      className="nav-link nav-menu-main menu-toggle hidden-xs"
                      href="#"
                    >
                      <i className="ficon feather icon-menu"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <ul className="nav navbar-nav float-right">
                <li className="dropdown dropdown-notification nav-item">
                  <a
                    className="nav-link nav-link-label"
                    href="/"
                    data-toggle="dropdown"
                  >
                    <i className="ficon feather icon-bell"></i>
                    <span className="badge badge-pill badge-primary badge-up">
                      {allNotification.length}
                    </span>
                  </a>
                  <ul
                    id="notifications"
                    className="dropdown-menu dropdown-menu-media dropdown-menu-right"
                  >
                    <li className="dropdown-menu-header">
                      <div className="dropdown-header m-0 p-2">
                        <h3 className="white">
                          {allNotification.length > 0
                            ? allNotification.length + '  پیام جدید '
                            : ' پیام جدید ندارید '}
                        </h3>
                      </div>
                    </li>
                    <li className="scrollable-container media-list">
                      {allNotification.sort().map(notification => {
                        var Created = moment(notification.created)
                          .locale('fa')
                          .fromNow();

                        return (
                          <div class="d-flex justify-content-between" href="">
                            <div class="media d-flex align-items-start">
                              <div class="media-left">
                                <i class="feather icon-plus-square font-medium-5 primary"></i>
                              </div>
                              <div class="media-body">
                                <div class="row">
                                  <div class="col-md-6">
                                    <h6 class="primary media-heading">
                                      {notification.Title}
                                    </h6>
                                  </div>
                                  <div class="col-md-6 text-right">
                                    <small>
                                      <time
                                        class="media-meta"
                                        dateTime="2015-06-11T18:29:20+08:00"
                                      >
                                        {Created}
                                      </time>
                                    </small>
                                    <span
                                      class="HideNotification ml-1"
                                      onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        HideNotification(notification._id);
                                      }}
                                    >
                                      ×
                                    </span>
                                  </div>
                                  <div class="w-100"></div>
                                  <small class="notification-text">
                                    {notification.text}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </li>
                    <li className="dropdown-menu-footer">
                      <a className="dropdown-item p-1 text-center" href="">
                        مشاهده تمام پیام ها
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown dropdown-user nav-item">
                  <a
                    className="dropdown-toggle nav-link dropdown-user-link"
                    href="#"
                    data-toggle="dropdown"
                  >
                    <div className="user-nav d-sm-flex d-none">
                      <span className="user-name text-bold-600">
                        {props.user.name}
                      </span>
                      {/* <span className="user-status">{props.user.role}</span> */}
                    </div>
                    <span>
                      <img
                        className="round"
                        src={props.user.photo}
                        alt="avatar"
                        height="40"
                        width="40"
                      />
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a className="dropdown-item">
                      <Link to="/Profile">
                        <i className="feather icon-user"></i> پروفایل من
                      </Link>
                    </a>
                    <a className="dropdown-item" href="app-email.html">
                      <i className="feather icon-mail"></i> پیام ها
                    </a>
                    <a className="dropdown-item" href="app-todo.html">
                      <i className="feather icon-check-square"></i> وظایف
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" onClick={props.logout}>
                      <i className="feather icon-power"></i> خروج
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <ul className="main-search-list-defaultlist d-none">
        <li className="d-flex align-items-center">
          <a className="pb-25" href="#">
            <h6 className="text-primary mb-0">Files</h6>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between w-100"
            href="#"
          >
            <div className="d-flex">
              <div className="mr-50">
                <img
                  src="../../../app-assets/images/icons/xls.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Two new item submitted</p>
                <small className="text-muted">Marketing Manager</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;17kb
            </small>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between w-100"
            href="#"
          >
            <div className="d-flex">
              <div className="mr-50">
                <img
                  src="../../../app-assets/images/icons/jpg.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">52 JPG file Generated</p>
                <small className="text-muted">FontEnd Developer</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;11kb
            </small>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between w-100"
            href="#"
          >
            <div className="d-flex">
              <div className="mr-50">
                <img
                  src="../../../app-assets/images/icons/pdf.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">25 PDF File Uploaded</p>
                <small className="text-muted">Digital Marketing Manager</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;150kb
            </small>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between w-100"
            href="#"
          >
            <div className="d-flex">
              <div className="mr-50">
                <img
                  src="../../../app-assets/images/icons/doc.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Anna_Strong</p>
                <small className="text-muted">Web Designer</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;256kb
            </small>
          </a>
        </li>
        <li className="d-flex align-items-center">
          <a className="pb-25" href="#">
            <h6 className="text-primary mb-0">Members</h6>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between py-50 w-100"
            href="#"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-50">
                <img
                  src="../../../app-assets/images/portrait/small/avatar-s-8.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">John Doe</p>
                <small className="text-muted">UI designer</small>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between py-50 w-100"
            href="#"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-50">
                <img
                  src="../../../app-assets/images/portrait/small/avatar-s-1.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Michal Clark</p>
                <small className="text-muted">FontEnd Developer</small>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between py-50 w-100"
            href="#"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-50">
                <img
                  src="../../../app-assets/images/portrait/small/avatar-s-14.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Milena Gibson</p>
                <small className="text-muted">Digital Marketing Manager</small>
              </div>
            </div>
          </a>
        </li>
        <li className="auto-suggestion d-flex align-items-center cursor-pointer">
          <a
            className="d-flex align-items-center justify-content-between py-50 w-100"
            href="#"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-50">
                <img
                  src="../../../app-assets/images/portrait/small/avatar-s-6.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Anna Strong</p>
                <small className="text-muted">Web Designer</small>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <ul className="main-search-list-defaultlist-other-list d-none">
        <li className="auto-suggestion d-flex align-items-center justify-content-between cursor-pointer">
          <a className="d-flex align-items-center justify-content-between w-100 py-50">
            <div className="d-flex justify-content-start">
              <span className="mr-75 feather icon-alert-circle"></span>
              <span>No results found.</span>
            </div>
          </a>
        </li>
      </ul>
    </Fragment>
  );
};

export default connect(null, { logout })(Navbar);

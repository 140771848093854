import React, { useState, useEffect } from 'react';
import { Row, Container, Card, Button, Col, Spinner, FormControl, Modal, InputGroup, Form } from 'react-bootstrap';
import Axios from '../../../Axios/Axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import moment from 'jalali-moment';

const Note = props => {
  const [allNotes, SetAllNotes] = useState([]);
  const [showAddNote, setShowAddNote] = useState(true);
  const [noteText, setNoteText] = useState('');
  const [clickedNote, setClickedNote] = useState('');
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subloading, setSubloading] = useState(false);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {
    if (props.notesRefresh) {
      Axios.get('/api/v1/users/me', config).then(user => {
        setUserInfo(user.data.data.data);
      });
      Axios.get('/api/v1/note?type=' + props.type + '&itemId=' + props.itemId, config).then(note => {
        SetAllNotes(note.data.data.data);
        props.setNotesRefresh(false);
      });
    }
  });

  const newNote = e => {
    if (showAddNote) {
      setShowAddNote(false);
    } else {
      setShowAddNote(true);
    }
  };
  const newSubNote = noteId => {
    setClickedNote(noteId);
  };
  const CancleNote = noteId => {
    setClickedNote();
  };

  const handleAddNote = e => {
    e.preventDefault();

    if (noteText) {
      setLoading(true);
      const NoteNewItem = JSON.stringify({
        text: noteText,
        type: props.type,
        itemId: props.itemId,
        createdBy: userInfo._id
      });

      Axios.post(`/api/v1/note/`, NoteNewItem, config).then(note => {
        toast.success('نظر شما ایجاد شد');
        props.setNotesRefresh(true);
        setNoteText('');
        setLoading(false);
      });
    }
    if (!noteText) {
      toast.error('لطفا متن نظر را وارد نمایید');
    }
  };
  const handleAddSubNote = (NoteId, NoteAuthor) => {
    const NotificationNewItem = JSON.stringify({
      Title: 'ثبت نظر جدید',
      text: userInfo.name + ' ' + ' نظر جدیدی بر روی نظر شما ثبت کرده است ',
      assignTo: NoteAuthor
    });
    if (noteText) {
      setSubloading(true)
      const NoteNewItem = JSON.stringify({
        text: noteText,
        parentNote: NoteId,
        type: props.type,
        itemId: props.itemId,
        createdBy: userInfo._id
      });

      Axios.post(`/api/v1/note/`, NoteNewItem, config).then(note => {
        toast.success('نظر شما ایجاد شد');
        props.setNotesRefresh(true);
        setNoteText('');
        setSubloading(false)
        Axios.post(`/api/v1/notification/`, NotificationNewItem, config).then(notification => {});
      });
    }
    if (!noteText) {
      toast.error('لطفا متن نظر را وارد نمایید');
    }
  };
  const DeleteNote = NoteId => {
    swal({
      title: ` آیا می خواهید این نظر را حذف نمایید   `,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        const body = JSON.stringify({
          active: false
        });

        Axios.delete(`/api/v1/note/` + NoteId, config).then(note => {
          props.setNotesRefresh(true);
          swal('نظر مورد نظر حذف شد.', {
            icon: 'success'
          });
        });
      }
    });
  };

  return (
    <Col md={12} className="mt-2">
      <Container fluid id="notes">
        <Row>
          <Col md={6}>
            <span className="lblBold"> نظرات: </span>
            <span className="lblNormal">{props.itemName}</span>
            <span className="lblSmall pl-1">({allNotes.length} نظر)</span>
          </Col>
          <Col md={6} className="text-right">
            <Button variant="primary" onClick={newNote}>
              <i className="fa fa-plus"></i>
              افزودن کامنت
            </Button>
          </Col>

          <Col md={12} className=" mb-3">
            <div hidden={showAddNote}>
              <Form className="mt-3">
                <Form.Group className="mb-1" controlId="NewNote">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="متن نظر را وارد نمایید"
                    value={noteText}
                    onChange={e => setNoteText(e.target.value)}
                  />
                </Form.Group>
                <center hidden={!loading}>
                  <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                </center>
                <Button hidden={loading} variant="primary" type="submit" onClick={handleAddNote}>
                  ثبت نظر
                </Button>
              </Form>
            </div>
            <div className="AllNotes">
              <ul>
                {allNotes.length === 0
                  ? ' تا کنون نظری برای  ' + props.itemName + ' ثبت نشده است '
                  : allNotes.map(note => {
                      var Created = moment(note.created)
                        .locale('fa')
                        .format('YYYY/MM/DD  h:mm:ss a');
                      if (!note.parentNote) {
                        return (
                          <li class="parentChild">
                            <Row>
                              <Col md={8}>
                                <span>
                                  <img
                                    className="round"
                                    src={note.createdBy.photo}
                                    alt="avatar"
                                    height="40"
                                    width="40"
                                  />
                                </span>
                                <span className="ComUserName">{note.createdBy.name}</span>-
                                <span className="ComDate">{Created}</span>
                              </Col>
                              <Col md={4} className="ComAction">
                                {userInfo._id === note.createdBy._id ? (
                                  <a onClick={() => DeleteNote(note._id)} className="float-right ml-1 mr-1">
                                    <i className="fa fa-trash"></i>
                                    حذف
                                  </a>
                                ) : (
                                  ''
                                )}
                                <a
                                  onClick={e => {
                                    newSubNote(note._id);
                                  }}
                                  className="float-right ml-1 mr-1"
                                >
                                  <i className="fa fa-reply"></i>
                                  پاسخ
                                </a>
                              </Col>
                            </Row>

                            <Col md={12}>
                              <div className="ComTxt">{note.text}</div>
                            </Col>

                            <Col md={12}>
                              {/* Sub Note */}
                              <div hidden={note._id !== clickedNote}>
                                <Form className="mt-3">
                                  <Form.Group className="mb-1" controlId="NewNote">
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="متن نظر را وارد نمایید"
                                      value={noteText}
                                      onChange={e => setNoteText(e.target.value)}
                                    />
                                  </Form.Group>
                                  <center hidden={!subloading}>
                                    <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                                  </center>
                                  <Button
                                    hidden={subloading}
                                    variant="primary"
                                    type="submit"
                                    onClick={e => {
                                      e.preventDefault();
                                      handleAddSubNote(note._id, note.createdBy._id);
                                    }}
                                  >
                                    ثبت نظر
                                  </Button>
                                  <Button
                                    hidden={subloading}
                                    variant="primary"
                                    type="text"
                                    className="ml-2"
                                    onClick={e => {
                                      e.preventDefault();
                                      CancleNote();
                                    }}
                                  >
                                    انصراف
                                  </Button>
                                </Form>
                              </div>
                              {allNotes.map(subNote => {
                                if (subNote.parentNote === note._id) {
                                  var Created = moment(subNote.created)
                                    .locale('fa')
                                    .format('YYYY/MM/DD  h:mm:ss a');

                                  return (
                                    <li class="childNote">
                                      <Row>
                                        <Col md={8}>
                                          <span>
                                            <img
                                              className="round"
                                              src={subNote.createdBy.photo}
                                              alt="avatar"
                                              height="40"
                                              width="40"
                                            />
                                          </span>
                                          <span className="ComUserName">{subNote.createdBy.name}</span>-
                                          <span className="ComDate">{Created}</span>
                                        </Col>
                                        <Col md={4} className="ComAction">
                                          {userInfo._id === subNote.createdBy._id ? (
                                            <a
                                              onClick={() => DeleteNote(subNote._id)}
                                              className="float-right ml-1 mr-1"
                                            >
                                              <i className="fa fa-trash"></i>
                                              حذف
                                            </a>
                                          ) : (
                                            ''
                                          )}
                                        </Col>
                                      </Row>

                                      <Col md={12}>
                                        <div className="ComTxt">{subNote.text}</div>
                                      </Col>

                                      <Row></Row>
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </Col>
                          </li>
                        );
                      }
                    })}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};
export default Note;

import React, { useState, useEffect } from 'react';

import './RoutineAdjustment.css';
import RoutineAdjustmentChart from './RoutineAdjustmentChart/RoutineAdjustmentChart';

import { Button, Modal, InputGroup, FormControl, Row, Tab, Tabs, Col } from 'react-bootstrap';
import Axios from '../../../../Axios/Axios';
import swal from 'sweetalert';
import moment from 'jalali-moment';

const RoutineAdjustment = props => {
  const [routineAdjustments, setRoutineAdjustments] = useState(() => {
    if (props.MVProject.routineAdjustments.length > 0) {
      return props.MVProject.routineAdjustments;
    } else return [];
  });
  const [RATab, setRATab] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [allMeter, setAllMeter] = useState([]);
  const [needLoadMeter, setNeedLoadMeter] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allChartData, setAllChartData] = useState([]);
  const [allChartDays, setAllChartDays] = useState([]);
  const [chartAnonationDay, setChartAnonationDay] = useState(null);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {
    if (needLoadMeter) {
      setNeedLoadMeter(false);
      Axios.get('/api/v1/meter?building=' + props.MVProject.building._id, config).then(meter => {
        setAllMeter(meter.data.data.data);
      });
      if (props.MVProject.routineAdjustments.length > 0) {
        getChartData(props.MVProject.routineAdjustments);
      }
    }
  });

  const getChartData = async RA => {
    const chartDays = [];
    const chartLabels = [];
    let fromDateBaseline = moment(props.MVProject.baselinePeriodFrom).locale('fa');
    const toDateBaseline = moment(props.MVProject.baselinePeriodTo).locale('fa');
    const diffDaysBaseline = toDateBaseline.diff(fromDateBaseline, 'day');
    for (let index = 0; index <= diffDaysBaseline; index++) {
      chartDays.push(fromDateBaseline.format('YYYY-MM-DD'));
      fromDateBaseline = fromDateBaseline.add(1, 'day');
    }

    setChartAnonationDay({
      from: moment(props.MVProject.baselinePeriodTo)
        .add(1, 'day')
        .locale('fa')
        .format('YYYY-MM-DD'),
      to: moment(props.MVProject.reportingPeriodFrom)
        .add(-1, 'day')
        .locale('fa')
        .format('YYYY-MM-DD')
    });

    let fromDateReporting = moment(props.MVProject.reportingPeriodFrom).locale('fa');
    let toDateReporting = moment(props.MVProject.reportingPeriodTo).locale('fa');
    const diffDaysReporting = toDateReporting.diff(fromDateReporting, 'day');
    for (let index = 0; index <= diffDaysReporting; index++) {
      chartDays.push(fromDateReporting.format('YYYY-MM-DD'));
      fromDateReporting = fromDateReporting.add(1, 'day');
    }

    fromDateBaseline = moment(props.MVProject.baselinePeriodFrom).locale('fa');
    toDateReporting = moment(props.MVProject.reportingPeriodTo).locale('fa');
    const diffDaysLabels = toDateReporting.diff(fromDateBaseline, 'day');
    for (let index = 0; index <= diffDaysLabels; index++) {
      chartLabels.push(fromDateBaseline.format('YYYY-MM-DD'));
      fromDateBaseline = fromDateBaseline.add(1, 'day');
    }

    const body = {
      kind: 'multiDay',
      days: chartDays,
      nav: RA.map(el => {
        return { navID: el._id, navType: 'Meter', navName: el.name };
      })
    };
    const chartData = (await Axios.post(`/api/v1/consumption/gstats`, body, config)).data.data.data;
    setAllChartDays(chartLabels);
    setAllChartData(
      chartData.map(el => {
        if (el.length === 0) {
          return null;
        } else return el[0].AggregateData;
      })
    );
  };

  const selectRATab = tabkey => {
    setRATab(tabkey);
  };
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const sendItemInfo = meter => {
    setSelectedRows([meter]);
  };
  const addRA = async () => {
    if (selectedRows.length === 0) {
      handleCloseAdd();
      return 0;
    }
    const tempRA = [...selectedRows, ...routineAdjustments];
    const tempRAID = tempRA.map(el => {
      return el._id;
    });
    const bodyUpdate = JSON.stringify({
      routineAdjustments: tempRAID
    });
    await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, bodyUpdate, config);
    setRoutineAdjustments(tempRA);
    const MVProject = {
      ...props.MVProject,
      routineAdjustments: tempRA
    };
    props.setMVProject(MVProject);
    getChartData(tempRA);
  };
  const deleteRA = index => {
    swal({
      title: `آیا میخواهید این متغیر را حذف نمایید؟`,
      text: 'در صورت نیاز باید مجدد این متغیر را اضافه نمایید.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        const tempRA = [...routineAdjustments];
        tempRA.splice(index, 1);
        const body = JSON.stringify({
          routineAdjustments: tempRA
        });
        Axios.patch('/api/v1/MVProject/' + props.MVProject._id, body, config).then(res => {
          setRoutineAdjustments(tempRA);
          const MVProject = {
            ...props.MVProject,
            routineAdjustments: tempRA
          };
          props.setMVProject(MVProject);
          getChartData(tempRA);
        });
      }
    });
  };
  return (
    <form hidden={props.activeStep !== 5}>
      <h2>5- تصحیحات معمول</h2>
      <span>متغیرهای مرتبط جهت محاسبه خط مبنا انتخاب شود.</span>
      <Row className="m-2">
        <Button className="m-1" onClick={() => setShowAdd(true)}>
          افزودن متغیر
        </Button>
        <Modal show={showAdd} onHide={handleCloseAdd}>
          <Modal.Header closeButton>
            <Modal.Title>افزودن متغیر از کنتورها یا متغیرها</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="list-group list-group-flush" id="allMeterTree">
              <ul className="nav flex-column flex-nowrap overflow-hidden">
                {allMeter.map(meter => {
                  if (meter.hasChild == true) {
                    return (
                      <li className="nav-item">
                        <div
                          className={`Navbox pl-2 ${
                            selectedRows.some(SelectedRow => SelectedRow._id === meter._id)
                              ? 'SelectedItem'
                              : 'SimpleItem'
                          }`}
                          onClick={e => sendItemInfo(meter)}
                        >
                          <a
                            className="nav-link  text-truncate collapsed"
                            href={'#meter' + meter._id}
                            data-toggle="collapse"
                            data-target={'#meter' + meter._id}
                          ></a>
                          <span className="navClick">
                            <i
                              className={`fa ${meter.icon}`}
                              style={{
                                color: meter.color
                              }}
                            ></i>
                            <span>{meter.name}</span>
                          </span>
                        </div>
                        {allMeter.map(Secmeter => {
                          if (Secmeter.parentMeter) {
                            if (
                              Secmeter.hasChild == false &&
                              Secmeter._id !== meter._id &&
                              Secmeter.parentMeter._id === meter._id
                            ) {
                              return (
                                <div className="collapse show" id={'meter' + meter._id} aria-expanded="false">
                                  <ul className="flex-column nav">
                                    <li className="nav-item ">
                                      <div
                                        className={`Navbox pl-2 ${
                                          selectedRows.some(SelectedRow => SelectedRow._id === Secmeter._id)
                                            ? 'SelectedItem'
                                            : 'SimpleItem'
                                        }`}
                                        onClick={e => sendItemInfo(Secmeter)}
                                      >
                                        <a
                                          className="nav-link  text-truncate collapsed"
                                          href={'#meter' + Secmeter._id}
                                          data-target={'#meter' + Secmeter._id}
                                        ></a>
                                        <span className="navClick">
                                          <i
                                            className={`  pl-1 fa ${Secmeter.icon}`}
                                            style={{
                                              color: Secmeter.color
                                            }}
                                          ></i>
                                          <span>{Secmeter.name}</span>
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              );
                            }
                          }
                        })}
                      </li>
                    );
                  }
                  if (meter.hasChild == false) {
                    if (!meter.parentMeter) {
                      return (
                        <li className="nav-item">
                          <div
                            className={`Navbox  ${
                              selectedRows.some(SelectedRow => SelectedRow._id === meter._id)
                                ? 'SelectedItem'
                                : 'SimpleItem'
                            }`}
                            onClick={e => sendItemInfo(meter)}
                          >
                            <a
                              className="nav-link  text-truncate collapsed"
                              href={'#meter' + meter._id}
                              data-target={'#meter' + meter._id}
                            ></a>
                            <i className={`  pl-1 fa ${meter.icon}`} style={{ color: meter.color }}></i>
                            <span>{meter.name}</span>
                          </div>
                        </li>
                      );
                    }
                  }
                })}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="mr-3 mt-2"
              onClick={() => {
                addRA();
                handleCloseAdd();
              }}
            >
              انتخاب
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      <Tabs onSelect={selectRATab}>
        {routineAdjustments.map((el, index) => {
          return (
            <Tab eventKey={index} title={el.name} key={index} className="tab-pane">
              <Row>
                <Col md="11">
                  <Row className="m-1">
                    <Col md={3} className={'font-weight-bold lblTab'}>
                      نام کنتور / متغیر:
                    </Col>
                    <Col md={3}>{el.name}</Col>
                    <Col md={3} className={'font-weight-bold lblTab'}>
                      نوع کنتور:
                    </Col>
                    <Col md={3}>{el.type === 'Consumption' ? 'کنتور مصرفی' : 'پارامتر'}</Col>
                  </Row>
                  <Row className="m-1">
                    <Col md={3} className={'font-weight-bold lblTab'}>
                      حامل:
                    </Col>
                    <Col md={3}>{el.unit.name}</Col>
                    <Col md={3} className={'font-weight-bold lblTab'}>
                      واحد:
                    </Col>
                    <Col md={3}>{el.unit.unit}</Col>
                  </Row>
                  <Row className="m-1">
                    {allChartData.length > 0 && allChartData[index] !== null ? (
                      <RoutineAdjustmentChart
                        dataset={allChartData[index]}
                        label={allChartDays}
                        chartAnonationDay={chartAnonationDay}
                      />
                    ) : (
                      'کنتور/متغیر مورد نظر در این بازه دیتایی ندارد'
                    )}
                  </Row>
                </Col>
                <Col
                  md="1"
                  onClick={() => {
                    deleteRA(index);
                  }}
                >
                  <i class="fa fa-trash deleteRA"></i>
                </Col>
              </Row>
            </Tab>
          );
        })}
      </Tabs>
    </form>
  );
};

export default RoutineAdjustment;

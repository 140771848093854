import React from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

function min(ctx, datasetIndex, prop) {
  const dataset = ctx.chart.data.datasets[datasetIndex];
  return dataset.data.reduce((v, point) => Math.min(point[prop], v), Infinity);
}

function max(ctx, datasetIndex, prop) {
  const dataset = ctx.chart.data.datasets[datasetIndex];
  return dataset.data.reduce((v, point) => Math.max(point[prop], v), -Infinity);
}

const RoutineAdjustmentChart = props => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  if (!props.dataset) {
    return '';
  }
  const option = {
    maintainAspectRatio: true,
    scales: {
      y: {
        grid: {
          display: false
        },
        beginAtZero: true
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10
        }
      }
    },
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            xMin: props.chartAnonationDay.from,
            xMax: props.chartAnonationDay.from,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              content: 'شروع پروژههای ECM',
              rotation: 90,
              enabled: true,
              position: 'start',
              padding: 2,
              backgroundColor: 'rgba(0,0,0,0.4)'
            }
          },
          line2: {
            type: 'line',
            xMin: props.chartAnonationDay.to,
            xMax: props.chartAnonationDay.to,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 2,
            label: {
              content: 'پایان پروژه های ECM',
              rotation: 90,
              enabled: true,
              position: 'start',
              padding: 2,
              backgroundColor: 'rgba(0,0,0,0.4)'
            }
          },
          box1: {
            type: 'box',
            backgroundColor: 'rgba(232, 208, 209, 0.5)',
            borderColor: 'rgba(232, 208, 209, 0.2)',
            borderRadius: 4,
            borderWidth: 1,
            xMax: props.chartAnonationDay.from,
            xMin: props.chartAnonationDay.to
          }
        }
      }
    }
  };
  const data = {
    datasets: [
      {
        backgroundColor: '#57c32b',
        borderColor: '#57c32b',
        data: props.dataset.map(el => {
          return { x: el.dateOf, y: el.total };
        })
      }
    ],
    labels: props.label
  };
  return <Bar data={data} options={option} />;
};

export default RoutineAdjustmentChart;

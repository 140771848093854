import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import MeterData from './MeterData/MeterData';

import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper';
import AutoCompleteSelect from 'react-select';
import { CirclePicker } from 'react-color';

import { Row, Container, Card, Col, Form, Spinner, OverlayTrigger, Button, Tooltip } from 'react-bootstrap';
import Axios from '../../Axios/Axios';
import ModuleList from '../../components/ModuleList/ModuleList.json';

import './UploadMeterData.css';

const UploadMeterData = () => {
  const [stepper, setStepper] = useState(null);

  const [allOrganization, setAllOrganization] = useState([]);
  const [allBuilding, setAllBuilding] = useState([]);
  const [allSite, setAllSite] = useState([]);
  const [allMeter, setAllMeter] = useState([]);

  const [needLoadTree, setNeedLoadTree] = useState(1);
  const [needLoadMeter, setNeedLoadMeter] = useState(true);
  const [organization, setOrganization] = useState(null);
  const [building, setBuilding] = useState(null);
  const [site, setSite] = useState(null);
  const [meter, setMeter] = useState(null);

  const [loading, setLoading] = useState(false);

  const [newBuilding, setNewBuilding] = useState(false);
  const [newSite, setNewSite] = useState(false);

  const [BuildingName, setBuildingName] = useState();
  const [siteName, setSiteName] = useState();
  const [BuildingCity, setBuildingCity] = useState();
  const [siteCity, setSiteCity] = useState();
  const [BuildingType, setBuildingType] = useState();
  const [BuildingArea, setBuildingArea] = useState();
  const [BuildingpeopleCount, setBuildingpeopleCount] = useState();
  const [siteStandard, setSiteStandard] = useState('');

  const [AllCity, setAllCity] = useState([]);
  const [allParameterUnit, setAllParameterUnit] = useState([]);
  const [allConsumptionUnit, setAllConsumptionUnit] = useState([]);

  const [newMeter, setNewMeter] = useState(false);
  const [MeterName, setMeterName] = useState('');
  const [MeterType, setMeterType] = useState('Consumption');
  const [MeterUnit, setMeterUnit] = useState('');
  const [MeterIcon, setMeterIcon] = useState('');
  const [Color, setColor] = useState('#fff');
  const [BeforeStepOne, setBeforeStepOne] = useState('PassStep');
  const [BeforeStepTwo, setBeforeStepTwo] = useState('');
  const [BeforeStepThree, setBeforeStepThree] = useState('');
  const [BeforeStepFour, setBeforeStepFour] = useState('');
  const [ShowMeterIcon, setShowMeterIcon] = useState('hideBox');
  const [CirclePickerColors, setCirclePickerColors] = useState([
    '#8bc34a',
    '#ffc107',
    '#2196f3',
    '#f44336',
    '#ffeb3b',
    '#B3B3B3',
    '#333'
  ]);
  const [buildingSite, setBuildingSite] = useState(true);
  const [industrialSite, setIndustrialSite] = useState(true);

  const addNewBuilding = e => {
    setLoading(true);
    e.preventDefault();
    if (BuildingName && BuildingCity && BuildingType && BuildingArea) {
      let buildType = BuildingType;
      if (BuildingType === 'مسکونی') {
        if (BuildingArea >= 1000) {
          buildType = 'مسکونی بزرگ';
        } else {
          buildType = 'مسکونی کوچک';
        }
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const BuildNewItem = JSON.stringify({
        name: BuildingName,
        organization: organization._id,
        city: BuildingCity.value,
        area: BuildingArea,
        type: buildType,
        peopleCount: BuildingpeopleCount,
        kind: 'Building'
      });
      Axios.post(`/api/v1/Building/`, BuildNewItem, config).then(build => {
        setLoading(false);
        setBuilding(build.data.data.data);
        try {
          const temp = new Stepper(document.querySelector('#myStepper'), {
            linear: false,
            animation: true
          });
          setStepper(temp.to(3));
          setBeforeStepOne('PassStep');
          setBeforeStepTwo('PassStep');
          setBeforeStepThree('active');
        } catch (error) {
          console.log(error);
        }
        setNeedLoadTree(4);
        toast.success('ساختمان "' + BuildingName + '" ایجاد و انتخاب شد .');

        setNewBuilding(false);
        setBuildingName('');
        setBuildingCity(null);
        setBuildingType('');
        setBuildingArea('');
        setBuildingpeopleCount('');
      });
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 6000 });
      setLoading(false);
    }
  };
  const addNewSite = e => {
    setLoading(true);
    e.preventDefault();
    if (siteName && siteCity) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const BuildNewItem = JSON.stringify({
        name: siteName,
        organization: organization._id,
        city: siteCity.value,
        standard: siteStandard,
        kind: 'Site'
      });
      Axios.post(`/api/v1/Building/`, BuildNewItem, config).then(build => {
        setLoading(false);
        setSite(build.data.data.data);
        setBuilding(build.data.data.data);
        try {
          const temp = new Stepper(document.querySelector('#myStepper'), {
            linear: false,
            animation: true
          });
          setStepper(temp.to(3));
          setBeforeStepOne('PassStep');
          setBeforeStepTwo('PassStep');
          setBeforeStepThree('active');
        } catch (error) {
          console.log(error);
        }
        setNeedLoadTree(4);
        toast.success('سایت "' + siteName + '" ایجاد و انتخاب شد .');

        setNewSite(false);
        setSiteName('');
        setSiteCity(null);
        setSiteStandard('');
      });
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 6000 });
      setLoading(false);
    }
  };
  const addNewMeter = e => {
    setLoading(true);
    e.preventDefault();
    if (MeterName && MeterType && MeterUnit) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const MeterNewItem = JSON.stringify({
        name: MeterName,
        hasChild: 'false',
        type: MeterType,
        unit: MeterUnit._id,
        organization: { _id: organization._id },
        building: { _id: building._id },
        icon: MeterIcon ? MeterIcon : MeterUnit.icon,
        color: Color ? Color : MeterUnit.color
      });
      Axios.post(`/api/v1/Meter/`, MeterNewItem, config).then(resMeter => {
        setLoading(false);
        setMeter(resMeter.data.data.data);
        Axios.get(`/api/v1/Meter/${resMeter.data.data.data._id}`, config).then(getNewMeter => {
          setLoading(false);
          setMeter(getNewMeter.data.data.data);
          try {
            const temp = new Stepper(document.querySelector('#myStepper'), {
              linear: false,
              animation: true
            });
            setStepper(temp.to(4));
            setBeforeStepOne('PassStep');
            setBeforeStepTwo('PassStep');
            setBeforeStepThree('PassStep');
            setBeforeStepFour('active');

            toast.success('کنتور "' + MeterName + '" ایجاد و انتخاب شد .');
          } catch (error) {
            console.log(error);
          }
          setNeedLoadTree(3);
          setNewMeter(false);
          setMeterName('');
          setMeterType('');
          setMeterUnit('');
          setMeterIcon('');
          setColor('#fff');
        });
      });
    } else {
      toast.error('همه فیلدها را پرنمایید.', { autoClose: 6000 });
      setLoading(false);
    }
  };
  const changeOrganization = e => {
    let org = null;
    allOrganization.forEach(element => {
      if (element._id === e.target.value) {
        org = element;
      }
    });
    setOrganization(org);
    setNeedLoadTree(2);

    try {
      const temp = new Stepper(document.querySelector('#myStepper'), {
        linear: false,
        animation: true
      });
      setStepper(temp.to(2));
      toast.success('سازمان "' + org.name + '" انتخاب شد.');
      setBeforeStepOne('PassStep');
    } catch (error) {
      console.log(error);
    }
  };
  const changeBuilding = e => {
    let build = null;
    allBuilding.forEach(element => {
      if (element._id === e.target.value) {
        build = element;
      }
    });
    try {
      const temp = new Stepper(document.querySelector('#myStepper'), {
        linear: false,
        animation: true
      });
      setStepper(temp.to(3));

      toast.success('ساختمان "' + build.name + '" انتخاب شد.');
      setBeforeStepOne('PassStep');
      setBeforeStepTwo('PassStep');
      setBeforeStepThree('active');
    } catch (error) {
      console.log(error);
    }
    setBuilding(build);
    setNeedLoadTree(3);
  };
  const changeUnit = e => {
    let unit = null;
    allConsumptionUnit.forEach(element => {
      if (element._id === e.target.value) {
        unit = element;
      }
    });
    allParameterUnit.forEach(element => {
      if (element._id === e.target.value) {
        unit = element;
      }
    });
    setMeterUnit(unit);
  };
  const changeSite = e => {
    let build = null;
    allSite.forEach(element => {
      if (element._id === e.target.value) {
        build = element;
      }
    });
    try {
      const temp = new Stepper(document.querySelector('#myStepper'), {
        linear: false,
        animation: true
      });
      setStepper(temp.to(3));

      toast.success('سایت "' + build.name + '" انتخاب شد.');
      setBeforeStepOne('PassStep');
      setBeforeStepTwo('PassStep');
      setBeforeStepThree('active');
    } catch (error) {
      console.log(error);
    }
    setBuilding(build);
    setNeedLoadTree(3);
  };
  const changeMeter = e => {
    let meter = null;
    allMeter.forEach(element => {
      if (element._id === e.target.value) {
        meter = element;
      }
    });
    try {
      const temp = new Stepper(document.querySelector('#myStepper'), {
        linear: false,
        animation: true
      });
      setStepper(temp.to(4));

      toast.success('کنتور "' + meter.name + '" کنتور شد.');
      setBeforeStepOne('PassStep');
      setBeforeStepTwo('PassStep');
      setBeforeStepThree('PassStep');
      setBeforeStepFour('active');
    } catch (error) {
      console.log(error);
    }
    if (meter.hasChild) {
      toast.info(
        `دقت نمایید که کنتور ${e.target.value} دارای کنتورهایی در زیرمجموعه است در صورت ورود اطلاعات این کنتور در گزارشات اطلاعات این کنتور با زیرمجموعه جمع میشود.`,
        {
          autoClose: 100000,
          onClose: () => {
            setNeedLoadMeter(true);
            setMeter(meter);
          }
        }
      );
    } else {
      setNeedLoadMeter(true);
      setMeter(meter);
    }
  };
  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };

    //get all Organization & Unit & City
    if (needLoadTree === 1) {
      setNeedLoadTree(0);
      Axios.get('/api/v1/City', config).then(city => {
        setAllCity(city.data.data.data);
      });
      Axios.get('/api/v1/organization', config).then(org => {
        setAllOrganization(org.data.data.data);
        if (org.data.data.data.length > 0) {
          let temp = localStorage.getItem('lastOrganization');
          if (temp) {
            temp = JSON.parse(temp);
            org.data.data.data.forEach(item => {
              if (item._id === temp.navID) {
                setOrganization(item);
              }
            });
          } else {
            setOrganization(org.data.data.data[0]);
          }
          setNeedLoadTree(2);
          try {
            const temp = new Stepper(document.querySelector('#myStepper'), {
              linear: false,
              animation: true
            });
            setStepper(temp.to(2));
          } catch (error) {
            console.log(error);
          }
        }
      });
      Axios.get('/api/v1/unit?type=Consumption', config).then(units => {
        setAllConsumptionUnit(units.data.data.data);
      });
      Axios.get('/api/v1/unit?type=Parameter', config).then(units => {
        setAllParameterUnit(units.data.data.data);
      });
    }
    //get Building
    if (needLoadTree === 2) {
      setNeedLoadTree(0);
      Axios.get(`/api/v1/Building?organization=${organization._id}&kind=Building`, config).then(res => {
        setAllBuilding(res.data.data.data);
        setAllMeter([]);
      });
      Axios.get(`/api/v1/Building?organization=${organization._id}&kind=Site`, config).then(res => {
        setAllSite(res.data.data.data);
        setAllMeter([]);
      });
    }
    //get Meter
    if (needLoadTree === 3) {
      setNeedLoadTree(0);
      Axios.get(`/api/v1/Meter?building=${building._id}`, config).then(res => {
        setAllMeter(res.data.data.data);
      });
    }
    //reload Building after create new Building
    if (needLoadTree === 4) {
      setNeedLoadTree(0);
      Axios.get(`/api/v1/Building?organization=${organization._id}&kind=Building`, config).then(res => {
        setAllBuilding(res.data.data.data);
        setAllMeter([]);
      });
    }
  });
  const handleChangeComplete = color => {
    setColor(color.hex);
  };

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      نوع ساختمان را با توجه به مساحت ان انتخاب نمایید نوع ساختمان را با توجه به مساحت ان انتخاب نمایید نوع ساختمان را
      با توجه به مساحت ان انتخاب نمایید
    </Tooltip>
  );
  const changeSiteType = SiteType => {
    switch (SiteType) {
      case 'Building':
        setBuildingSite(false);
        setIndustrialSite(true);
        break;
      case 'Site':
        setIndustrialSite(false);
        setBuildingSite(true);
        break;
      case '':
        setIndustrialSite(true);
        setBuildingSite(true);
        break;
      default:
        break;
    }
  };
  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <Col className="mainContent pr-0 pl-0">
        <div className="pageBack">
          <Card className="content-body dp-card">
            <Container>
              <div id="myStepper" className="bs-stepper">
                <div className="bs-stepper-header">
                  <div
                    className={` step ${BeforeStepOne}`}
                    data-target="#stepper-l-1"
                    onClick={() => {
                      setBeforeStepOne('');
                      setBeforeStepOne('active');
                      setBeforeStepTwo('');
                      setBeforeStepThree('');
                    }}
                  >
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">
                        <i className={'fa fa-university'}></i>
                      </span>
                      <span className="bs-stepper-label">انتخاب سازمان</span>
                    </button>
                  </div>
                  <div className="line"></div>
                  <div
                    className={` step ${BeforeStepTwo} ${organization ? '' : 'notActive'}`}
                    data-target="#stepper-l-2"
                    onClick={() => {
                      setBeforeStepOne('');
                      setBeforeStepOne('PassStep');
                      setBeforeStepTwo('');
                      setBeforeStepTwo('active');
                      setBeforeStepThree('');
                    }}
                  >
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">
                        <i className={'fa fa-building'}></i>
                      </span>
                      <span className="bs-stepper-label">انتخاب سایت</span>
                    </button>
                  </div>
                  <div className="line"></div>
                  <div
                    className={` step ${BeforeStepThree} ${building ? '' : 'notActive'}`}
                    data-target="#stepper-l-3"
                    onClick={() => {
                      setBeforeStepOne('');
                      setBeforeStepOne('PassStep');
                      setBeforeStepTwo('');
                      setBeforeStepTwo('PassStep');
                      setBeforeStepThree('');
                      setBeforeStepThree('active');
                    }}
                  >
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">
                        <i className={'fa fa-bolt'}></i>
                      </span>
                      <span className="bs-stepper-label">انتخاب کنتور</span>
                    </button>
                  </div>
                  <div className="line"></div>
                  <div
                    className={` step ${BeforeStepFour} ${meter ? '' : 'notActive'}`}
                    data-target="#stepper-l-4"
                    onClick={() => {
                      setBeforeStepOne('');
                      setBeforeStepTwo('');
                      setBeforeStepThree('');
                      setBeforeStepOne('PassStep');
                      setBeforeStepTwo('PassStep');
                      setBeforeStepThree('PassStep');
                    }}
                  >
                    <button className="step-trigger">
                      <span className="bs-stepper-circle">
                        <i className={'fa fa-file'}></i>
                      </span>
                      <span className="bs-stepper-label">آپلود اطلاعات</span>
                    </button>
                  </div>
                </div>
                <div className="bs-stepper-content">
                  <form>
                    <div id="stepper-l-1" className="content">
                      <Col md={{ span: 8, offset: 2 }} className="mb-1">
                        <div className="form-group row">
                          <label className={'col-sm-2 col-form-label'}>سازمان</label>
                          <select
                            id={'organization-Select'}
                            className={'form-control col-sm-10'}
                            value={organization ? organization._id : null}
                            onChange={changeOrganization}
                          >
                            {allOrganization.map((el, index) => {
                              return (
                                <option key={index} value={el._id}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                    </div>

                    <div id="stepper-l-2" className="content">
                      <nav aria-label="breadcrumb" class="StepperBread">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item ">
                            <span className="lbl">سازمان:</span> {organization ? <span>{organization.name}</span> : ''}
                          </li>
                        </ol>
                      </nav>

                      <Row>
                        <Col md={{ span: 8, offset: 2 }} className="mb-1">
                          <div className="form-group row">
                            <label className={'col-sm-4 col-form-label'}>نوع سایت را انتخاب نمایید</label>
                            <div className="col-sm-8 pl-0">
                              <select className={'form-control'} onChange={e => changeSiteType(e.target.value)}>
                                <option></option>
                                {ModuleList.map(item => {
                                  return (
                                    <option disabled={!item.active} value={item.value}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </Col>
                        {/* ساختمانی */}
                        <Col md={{ span: 8, offset: 2 }}>
                          <div className="BuildingSite" hidden={buildingSite}>
                            <div className="row" hidden={newBuilding}>
                              <label className={'col-sm-4 col-form-label'}>انتخاب از ساختمان های قبلی</label>
                              <select
                                value={building ? building._id : null}
                                onChange={changeBuilding}
                                className={'form-control col-sm-6'}
                              >
                                <option></option>
                                {allBuilding.map((el, index) => {
                                  return (
                                    <option key={index} value={el._id}>
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <Col md="2" className="text-right pl-0">
                                <button
                                  className="btn btn-red "
                                  onClick={e => {
                                    setNewBuilding(true);
                                    e.preventDefault();
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </Col>
                            </div>
                            <div className="NewSitebox" hidden={!newBuilding}>
                              <center hidden={!loading}>
                                <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                              </center>
                              <div hidden={loading} className="col-md-8 offset-md-2">
                                <div className="form-group row">
                                  <label className={'col-sm-4 col-form-label'}>نام ساختمان</label>
                                  <input
                                    className={'form-control col-sm-8'}
                                    value={BuildingName}
                                    onChange={e => setBuildingName(e.target.value)}
                                  />
                                </div>
                                <div className="form-group row">
                                  <label className={'col-sm-4 col-form-label'}>شهر</label>
                                  <AutoCompleteSelect
                                    className={'col-sm-8 pl-0 pr-0'}
                                    isClearable={true}
                                    isSearchable={true}
                                    value={BuildingCity}
                                    onChange={newValv => {
                                      setBuildingCity(newValv);
                                    }}
                                    placeholder={'شهر را انتخاب کنید'}
                                    options={AllCity.map(el => {
                                      return {
                                        label: el.state + ' - ' + el.name,
                                        value: el._id
                                      };
                                    })}
                                  />
                                </div>
                                <div className="form-group row posRel">
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                  >
                                    <div className="info noteInput">
                                      <i class="fas fa-info-circle"></i>
                                    </div>
                                  </OverlayTrigger>
                                  <label className={'col-sm-4 col-form-label'}>نوع ساختمان</label>
                                  <select
                                    className={'form-control col-sm-8'}
                                    value={BuildingType}
                                    onChange={e => setBuildingType(e.target.value)}
                                  >
                                    <option></option>
                                    <option value="مسکونی">مسکونی</option>
                                    <option value="اداری دولتی">اداری دولتی </option>
                                    <option value="اداری خصوصی">اداری خصوصی </option>
                                  </select>
                                </div>
                                <div className="form-group row">
                                  <label className={'col-sm-4 col-form-label'}>
                                    مساحت ساختمان
                                    <span class="pr-1">(m2)</span>
                                  </label>
                                  <input
                                    type="number"
                                    step="10"
                                    className={'form-control col-sm-8'}
                                    value={BuildingArea}
                                    onChange={e => setBuildingArea(e.target.value)}
                                  />
                                </div>
                                <div className="form-group row">
                                  <label className={'col-sm-4 col-form-label'}>تعداد نفرات</label>
                                  <input
                                    type="number"
                                    step="10"
                                    className={'form-control col-sm-8'}
                                    value={BuildingpeopleCount}
                                    onChange={e => setBuildingpeopleCount(e.target.value)}
                                  />
                                </div>
                                <div className="form-group col-md-12 text-center mb-3 mt-3 ">
                                  <button
                                    className="chooseType btn btn-primary col-sm-2 mr-1 ml-1"
                                    onClick={addNewBuilding}
                                  >
                                    ایجاد ساختمان
                                  </button>
                                  <button
                                    className=" chooseType btn btn-danger col-sm-2 mr-1 ml-1"
                                    onClick={e => {
                                      setNewBuilding(false);
                                      e.preventDefault();
                                    }}
                                  >
                                    انصراف
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* صنعتی */}
                          <div className="IndustrialSite" hidden={industrialSite}>
                            <div className="form-group row" hidden={newSite}>
                              <label className={'col-sm-4 col-form-label'}>انتخاب از سایت های قبلی</label>
                              <select
                                value={site ? site._id : null}
                                onChange={changeSite}
                                className={'form-control col-sm-6'}
                              >
                                <option></option>
                                {allSite.map((el, index) => {
                                  return (
                                    <option key={index} value={el._id}>
                                      {el.name}
                                    </option>
                                  );
                                })}
                              </select>
                              <Col md="2" className="text-right pl-0">
                                <button
                                  className="btn btn-red"
                                  onClick={e => {
                                    setNewSite(true);
                                    e.preventDefault();
                                  }}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </Col>
                            </div>
                            <div className="NewSitebox" hidden={!newSite}>
                              <center hidden={!loading}>
                                <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                              </center>
                              <div hidden={loading} className="col-md-8 offset-md-2">
                                <div className="form-group row">
                                  <label className={'col-sm-4 col-form-label'}>نام سایت</label>
                                  <input
                                    className={'form-control col-sm-8'}
                                    value={BuildingName}
                                    onChange={e => setSiteName(e.target.value)}
                                  />
                                </div>
                                <div className="form-group row">
                                  <label className={'col-sm-4 col-form-label'}>شهر</label>
                                  <AutoCompleteSelect
                                    className={'col-sm-8 pl-0 pr-0'}
                                    isClearable={true}
                                    isSearchable={true}
                                    value={BuildingCity}
                                    onChange={newValv => {
                                      setSiteCity(newValv);
                                    }}
                                    placeholder={'شهر را انتخاب کنید'}
                                    options={AllCity.map(el => {
                                      return {
                                        label: el.state + ' - ' + el.name,
                                        value: el._id
                                      };
                                    })}
                                  />
                                </div>
                                {/* <div className="form-group row posRel">
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip}
                                  >
                                    <div className="info noteInput">
                                      <i class="fas fa-info-circle"></i>
                                    </div>
                                  </OverlayTrigger>
                                  <label className={'col-sm-4 col-form-label'}>معیار مصرف انرژی</label>
                                  <select
                                    className={'form-control col-sm-8 EnFont'}
                                    value={siteStandard}
                                    onChange={e => setSiteStandard(e.target.value)}
                                  >
                                    <option></option>
                                    <option value="isiri42112">ISIRI 42112</option>
                                    <option value="isiri67543">ISIRI 67543</option>
                                    <option value="isiri77543">ISIRI 77543</option>
                                  </select>
                                </div> */}
                                <div className="form-group col-md-12 text-center mb-3 mt-3 ">
                                  <button
                                    className="chooseType btn btn-primary col-sm-2 mr-1 ml-1"
                                    onClick={addNewSite}
                                  >
                                    ایجاد سایت
                                  </button>
                                  <button
                                    className=" chooseType btn btn-danger col-sm-2 mr-1 ml-1"
                                    onClick={e => {
                                      setNewSite(false);
                                      e.preventDefault();
                                    }}
                                  >
                                    انصراف
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div id="stepper-l-3" className="content text-center">
                      <nav aria-label="breadcrumb" class="StepperBread">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <span className="lbl">سازمان:</span>
                            {organization ? <span>{organization.name}</span> : ''}
                          </li>
                          <li class="breadcrumb-item active" aria-current="page">
                            <span className="lbl">سایت:</span>
                            {building ? <span>{building.name}</span> : ''}
                          </li>
                        </ol>
                      </nav>
                      <Col md={{ span: 8, offset: 2 }} className="mb-1">
                        <div className="form-group row" hidden={newMeter}>
                          <label className={'col-sm-2 col-form-label'}>کنتور</label>
                          <select
                            className={'form-control col-sm-8'}
                            value={meter ? meter._id : null}
                            onChange={changeMeter}
                          >
                            <option></option>
                            {allMeter.map((el, index) => {
                              return (
                                <option key={index} value={el._id}>
                                  {el.name} ({el.unit.unit})
                                </option>
                              );
                            })}
                          </select>
                          <div className="text-right pl-0 col-md-2">
                            <button
                              className="btn btn-red"
                              onClick={e => {
                                setNewMeter(true);
                                e.preventDefault();
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </Col>
                      <div hidden={!newMeter}>
                        <center hidden={!loading}>
                          <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                        </center>
                        <Col md={{ span: 8, offset: 2 }}>
                          <div hidden={loading} className="NewSitebox">
                            <Col md={{ span: 8, offset: 2 }}>
                              <div className="form-group row">
                                <label className={'col-sm-4 col-form-label'}>
                                  {MeterType === 'Consumption' ? 'نام کنتور' : 'نام متغیر'}
                                </label>
                                <input
                                  className={'form-control col-sm-8'}
                                  value={MeterName}
                                  onChange={e => setMeterName(e.target.value)}
                                />
                              </div>
                              <div className="form-group row">
                                <label className={'col-sm-4 col-form-label'}>
                                  {MeterType === 'Consumption' ? 'نوع کنتور' : 'نوع متغیر'}
                                </label>
                                <select
                                  className={'form-control col-sm-8'}
                                  value={MeterType}
                                  onChange={e => setMeterType(e.target.value)}
                                >
                                  <option value="Consumption">کنتور مصرفی</option>
                                  <option value="Parameter">پارامترها</option>
                                </select>
                              </div>
                              <div className="form-group row">
                                <label className={'col-sm-4 col-form-label'}>
                                  {MeterType === 'Consumption' ? 'واحد کنتور' : 'واحد متغیر'}
                                </label>
                                <select
                                  className={'form-control col-sm-8'}
                                  value={MeterUnit ? MeterUnit._id : null}
                                  onChange={e => changeUnit(e)}
                                >
                                  <option></option>
                                  {MeterType === 'Consumption'
                                    ? allConsumptionUnit.map((element, index) => {
                                        return (
                                          <option
                                            index={index}
                                            value={element._id}
                                          >{`${element.name}(${element.unit})`}</option>
                                        );
                                      })
                                    : allParameterUnit.map((element, index) => {
                                        return (
                                          <option
                                            index={index}
                                            value={element._id}
                                          >{`${element.name}(${element.unit})`}</option>
                                        );
                                      })}
                                </select>
                              </div>
                              <div className={`form-group row `}>
                                <label className={`col-sm-4 col-form-label`}>
                                  {MeterType === 'Consumption' ? 'آیکون کنتور' : 'آیکون متغیر'}
                                </label>
                                <select
                                  multiple
                                  className={`form-control col-sm-8 IconSelect`}
                                  onChange={e => setMeterIcon(e.target.value)}
                                >
                                  <option></option>
                                  <option value="fa-sun-o">Sun &#xf185;</option>
                                  <option value="fa-snowflake-o">Snow &#xf2dc;</option>
                                  <option value="fa-battery-full">battery &#xf240;</option>
                                  <option value="fa fa-lightbulb-o">lightbulb &#xf0eb;</option>
                                </select>
                              </div>
                              <div className={`form-group row`}>
                                <label className={`col-sm-4 col-form-label`}>
                                  {MeterType === 'Consumption' ? 'رنگ کنتور' : 'رنگ متغیر'}
                                </label>
                                <div className={`col-sm-8`}>
                                  <CirclePicker
                                    colors={CirclePickerColors}
                                    color={Color}
                                    onChangeComplete={handleChangeComplete}
                                  />
                                </div>
                              </div>
                            </Col>
                            <div className="form-group col-md-12 text-center mb-3 mt-3 ">
                              <button className="chooseType btn btn-primary col-sm-2 mr-1 ml-1" onClick={addNewMeter}>
                                {MeterType === 'Consumption' ? 'ایجاد کنتور' : 'ایجاد متغیر'}
                              </button>
                              <button
                                className=" chooseType btn btn-danger col-sm-2 mr-1 ml-1"
                                onClick={e => {
                                  setNewMeter(false);
                                  e.preventDefault();
                                }}
                              >
                                انصراف
                              </button>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </div>
                    <div id="stepper-l-4" className="content text-center">
                      <nav aria-label="breadcrumb" class="StepperBread">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item">
                            <span className="lbl">سازمان:</span>
                            {organization ? <span>{organization.name}</span> : ''}
                          </li>
                          <li class="breadcrumb-item">
                            <span className="lbl">سایت:</span>
                            {building ? <span>{building.name}</span> : ''}
                          </li>
                          <li class="breadcrumb-item active" aria-current="page">
                            <span className="lbl">کنتور:</span>
                            {meter ? <span>{meter.name}</span> : ''}
                          </li>
                        </ol>
                      </nav>
                      <Row>
                        {meter ? (
                          <MeterData
                            meter={meter}
                            setNeedLoadMeter={setNeedLoadMeter}
                            setBeforeStepFour={setBeforeStepFour}
                            needLoadMeter={needLoadMeter}
                          />
                        ) : (
                          ''
                        )}
                      </Row>
                    </div>
                  </form>
                </div>
              </div>
            </Container>
          </Card>
        </div>
      </Col>
    </Container>
  );
};

export default UploadMeterData;

import React, { Fragment } from 'react';

const CSVDataTable = (props) => {
  const changeSelect= (e) => {
    props.changeIt(parseInt(e.target.value));
  }
  let CSVUploaded = props.CSVData;
  let selectColumn;
  if (CSVUploaded.length > 0) {
    selectColumn = (
      <select onChange={changeSelect}>
        <option></option>
        {CSVUploaded[0].map((el, index) => {
          return <option value={index} key={index}>{el}</option>;
        })}
      </select>
    );
  }
  return <Fragment>{selectColumn}</Fragment>;
};

export default CSVDataTable;

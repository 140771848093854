import React, { useState,useRef  } from 'react';

import EULabel from './EULabel/EULabel';
import LabelControlPanel from './LabelControlPanel/LabelControlPanel';
import ReactToPrint from 'react-to-print';

import { Row, Container, Card, Button, Col, Spinner } from 'react-bootstrap';
import './EnergyLabel.css'


const EnergyLabel = () => {
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [labelData, setLabelData] = useState(null);
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                setMainPad('pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <LabelControlPanel
              setLoading={setLoading}
              setLabelData={setLabelData}
            />
          </div>
        </Col>
        <Col className="mainContent pr-0">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                setMainPad(' pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <center hidden={!loading}>
              <Spinner
                animation="grow"
                variant="success"
                className={'spinnerChart'}
              />
            </center>
            <Container hidden={loading} className="printENergyLable">
              {labelData ? (
                <ReactToPrint
                  copyStyles="true"
                  documentTitle="ENergyLable"
                  trigger={() => (
                    <button class="printIcon">
                      <i class="fa fa-print"></i>
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              ) : (
                ''
              )}
              {labelData ? (
                <EULabel ref={componentRef} labelData={labelData} />
              ) : (
                <div class="justify-content-center align-items-center row">
                  <span class="badge badge-info">
                    <i class="fa fa-info-circle pr-1"></i>جهت مشاهده معیار مصرف، ساختمان و سال مورد نظر را انتخاب نمایید
                  </span>
                </div>
              )}
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default EnergyLabel

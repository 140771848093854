import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';

import Login from './Pages/System/Login/Login';
import AdminPanel from './Pages/System/Admin/AdminPanel';
import { PageNotFound } from './Pages/System/PageNotFound/PageNotFound';
import Home from './Pages/Home/Home';
import Profile from './Pages/System/Profile/Profile';
import UploadMeterData from './Pages/UploadMeterData/UploadMeterData';
import PeriodicChart from './Pages/PeriodicChart/PeriodicChart';
import Organization from './Pages/Organizaton/Organization';
import weatherData from './Pages/weatherData/weatherData';
import EnergyLabel from './Pages/EnergyLabel/EnergyLabel';
import Trend from './Pages/Trend/Trend';
import FormulaBuilder from './Pages/FormulaBuilder/FormulaBuilder';
import Report from './Pages/Report/Report';
import Documents from './Pages/Documents/Documents';
import DocumentPage from './Pages/Documents/DocumentPage';
import OtherDocuments from './Pages/Documents/OtherDocuments';
import Calendar from './Pages/Calendar/Calendar';
import JobDescription from './Pages/JobDescription/JobDescription';
import DocumentType from './Pages/DocumentType/DocumentType';
import JobPosition from './Pages/JobPosition/JobPosition';
import MVProjects from './Pages/MVProjects/MVProjects';
import Calculator from './Pages/Calculator/Calculator';
import Momentary from './Pages/Momentary/Momentary';
import PermissionLevel from './Pages/PermissionLevel/PermissionLevel';
import Review from './Pages/Review/Review';

//redux
import { Provider } from 'react-redux';
import { PrivateRoute } from './components/Permission/PrivateRoute/PrivateRoute';
import { PrivateRouteWithMaster } from './components/Permission/PrivateRouteWithMaster/PrivateRouteWithMaster';
import store from './store';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRouteWithMaster exact path="/admin" component={AdminPanel} />
          <PrivateRouteWithMaster exact path="/" component={PeriodicChart} />
          <PrivateRouteWithMaster exact path="/Home" component={Home} />
          <PrivateRouteWithMaster exact path="/trend" component={Trend} />
          <PrivateRouteWithMaster exact path="/EnergyLabel" component={EnergyLabel} />
          <PrivateRouteWithMaster exact path="/UploadMeterData" component={UploadMeterData} />
          <PrivateRouteWithMaster exact path="/Profile" component={Profile} />
          <PrivateRouteWithMaster exact path="/Organization" component={Organization} />
          <PrivateRouteWithMaster exact path="/PeriodicChart" component={PeriodicChart} />
          <PrivateRouteWithMaster exact path="/Profile" component={Profile} />
          <PrivateRouteWithMaster exact path="/weatherData" component={weatherData} />
          <PrivateRouteWithMaster exact path="/FormulaBuilder" component={FormulaBuilder} />
          <PrivateRouteWithMaster exact path="/Report" component={Report} />
          <PrivateRouteWithMaster exact path="/Documents" component={Documents} />
          <PrivateRouteWithMaster exact path="/DocumentPage" component={DocumentPage} />
          <PrivateRouteWithMaster exact path="/DocumentPage/:docID" component={Documents} />
          <PrivateRouteWithMaster exact path="/OtherDocuments" component={OtherDocuments} />
          <PrivateRouteWithMaster exact path="/OtherDocuments/:docID" component={OtherDocuments} />
          <PrivateRouteWithMaster exact path="/Calendar" component={Calendar} />
          <PrivateRouteWithMaster exact path="/JobDescription" component={JobDescription} />
          <PrivateRouteWithMaster exact path="/DocumentType" component={DocumentType} />
          <PrivateRouteWithMaster exact path="/JobPosition" component={JobPosition} />
          <PrivateRouteWithMaster exact path="/MVProjects" component={MVProjects} />
          <PrivateRouteWithMaster exact path="/Calculator" component={Calculator} />
          <PrivateRouteWithMaster exact path="/Momentary" component={Momentary} />
          <PrivateRouteWithMaster exact path="/permissionLevel" component={PermissionLevel} />
          <PrivateRouteWithMaster exact path="/Review" component={Review} />
          <PrivateRoute path="/403" component={PageNotFound} />
          <PrivateRoute  component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState, useRef } from 'react';
import { Chart, defaults } from 'chart.js';

const TrendChart = props => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.plugins.legend.position = 'bottom';
  defaults.plugins.legend.rtl = true;
  defaults.plugins.legend.labels.padding = 30;
  defaults.plugins.legend.labels.color = '#121212';
  defaults.plugins.title.color = '#121212';


  const myRef = useRef();
  const footer = tooltipItems => {
    if (tooltipItems.length < 2) {
      return;
    } else {
      let diffs = parseFloat(tooltipItems[0].formattedValue) - parseFloat(tooltipItems[1].formattedValue);
      let diffSign = '';
      if (diffs < 0) {
        diffs = Math.abs(diffs);
        diffSign = '-';
      }
      return (
        'اختلاف نسبت به خط مبنا: ' +
        diffs.toFixed(2) +
        diffSign +
        ' (%' +
        ((diffs / Math.abs(parseFloat(tooltipItems[1].formattedValue))) * 100).toFixed(2) +
        diffSign +
        ')'
      );
    }
  };

  useEffect(() => {
    if (props.chartLoad) {
      const tempChart = Chart.getChart(myRef.current);
      if (tempChart) {
        tempChart.destroy();
      }
      let data = {
        datasets: [
          {
            type: 'line',
            backgroundColor: 'rgba(184, 208, 254,0.9)',
            label: `Predict`,
            borderColor: 'rgba(184, 208, 254,0.7)',
            borderWidth: 3,
            radius: 0,
            data: props.chartData.predictData
          },
          {
            type: 'line',
            backgroundColor: 'rgba(253, 186, 58,0.9)',
            label: `Actual`,
            borderColor: 'rgba(253, 186, 58,0.7)',
            borderWidth: 3,
            radius: 0,
            data: props.chartData.actualData
          }
        ]
      };
      const options = {
        interaction: {
          mode: 'x'
        },
        animation: {
          y: {
            easing: 'linear',
            duration: 2000,
            from: 400
          }
        },
        scales: {
          x: {
            type: 'category',
            labels: props.chartData.days,
            grid: {
              display: false
            },
            ticks: {
              maxRotation: 30
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer: footer
            }
          }
        }
      };
      new Chart(myRef.current, {
        data: data,
        options: options
      });
      props.setShowPrint(true);
    }
  }, [props.chartLoad]);
  return <canvas ref={myRef}></canvas>;
};

export default TrendChart;

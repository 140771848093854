import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Axios from '../../../Axios/Axios';
import { toast } from 'react-toastify';
import { Row, Container, Col, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';

import RightNav from '../../../components/UI/RightNav/RightNav';
import AllProjectGrid from './AllProjectGrid';

const AllProject = props => {
  const [nav, setNav] = useState({ navID: '', navType: '', navName: '' });
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  const [needLoad, setNeedLoad] = useState(true);
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [showCanvasLeft, setShowCanvasLeft] = useState(true);

  const [AllMVProjects, SetAllMVProjects] = useState([]);
  const [showAddProject, setShowAddProject] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectType, setProjectType] = useState('');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (needLoad && nav.navID) {
      setNeedLoad(false);
      Axios.get('/api/v1/MVProject?building=' + nav.navID, config).then(res => {
        SetAllMVProjects(res.data.data.data);
      });
    }
  });
  const handleCallback = selectedRow => {
    if (selectedRow.length > 0) {
      setNav({ navID: selectedRow[0].navID, navType: selectedRow[0].navType, navName: selectedRow[0].navName });
      setNeedLoad(true);
    }
  };
  const handleShowAddProject = () => {
    setShowAddProject(true);
  };
  const handleCloseAddProject = () => {
    setProjectName('');
    setProjectType('');
    setShowAddProject(false);
  };
  const submitAddProject = async () => {
    handleCloseAddProject();
    const body = JSON.stringify({
      name: projectName,
      building: nav.navID,
      createdBy: props.user._id,
      IPMVP: projectType
    });
    await Axios.post('/api/v1/MVProject', body, config);
    toast.success('پروژه مورد نظر ایجاد شد.');
    setNeedLoad(true);
  };

  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-2') : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <RightNav
              setIsRenderNavbar={setIsRenderNavbar}
              isRenderNavbar={isRenderNavbar}
              parentCallback={handleCallback}
              type={'single-select-building'}
            ></RightNav>
          </div>
        </Col>
        <Col className="mainContent pr-0">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-0') : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <div
              hidden={showCanvasLeft}
              class="CanvasIcon CanvasLeft"
              onClick={() => {
                setCanvasWidthLeft(3);
                setShowCanvasLeft(true);

                canvasWidthRight == 3 ? setMainPad('pl-0 pr-0') : setMainPad('pl-2 pr-0');
              }}
            >
              <i className="fa fa-caret-right  "></i>
            </div>
            <div>
              <div className="mb-1">
                <Button onClick={handleShowAddProject} disabled={nav.navName === ''}>
                  افزودن پروژه
                </Button>
                <Modal show={showAddProject} onHide={handleCloseAddProject}>
                  <Modal.Header closeButton>
                    <Modal.Title>{`افزودن پروژه برای ${nav.navName}`}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <InputGroup className="mb-1">
                        <label className={'col-sm-4 col-form-label'}>نام پروژه</label>
                        <FormControl
                          value={projectName}
                          onChange={e => {
                            setProjectName(e.target.value);
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="mb-1">
                        <label className={'col-sm-4 col-form-label'}>IPMVP Option</label>
                        <FormControl
                          as="select"
                          value={projectType}
                          onChange={e => {
                            setProjectType(e.target.value);
                          }}
                        >
                          <option></option>
                          <option>IPMVP Option A</option>
                          <option>IPMVP Option B</option>
                          <option>IPMVP Option C</option>
                          <option>IPMVP Option D</option>
                        </FormControl>
                      </InputGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={submitAddProject}>
                      افزودن
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <AllProjectGrid AllMVProjects={AllMVProjects} setMVProjectID={props.setMVProjectID} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps, {})(AllProject);

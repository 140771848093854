import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Modal, Form } from 'react-bootstrap';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import CheckboxTree from 'react-checkbox-tree';
import './PermissionLevel.css';
import Axios from '../../Axios/Axios';
import { toast } from 'react-toastify';

import Datagrid from '../../components/UI/Datagrid/Datagrid';

const PermissionLevel = () => {
  const [needLoad, setNeedLoad] = useState(true);
  const [newPLShow, setNewPLShow] = useState(false);
  const [editPLShow, setEditPLShow] = useState(false);
  const [allPermissionLevel, setAllPermissionLevel] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [newPermissionName, setNewPermissionName] = useState('');
  const [updatePermissionName, setUpdatePermissionName] = useState('');
  const [allModule, setAllModule] = useState([]);
  const [allNodes, setAllNodes] = useState([]);
  const [allNodeChecked, setAllNodeChecked] = useState([]);
  const [allNodeExpand, setAllNodeExpand] = useState([]);
  const [allEditNodeChecked, setAllEditNodeChecked] = useState([]);
  const [allEditNodeExpand, setAllEditNodeExpand] = useState([]);
  const [itemSelected, setItemSelected] = useState(null);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const permLevelGridColumn = [
    {
      headerName: 'نام',
      field: 'name'
    },
    { field: '_id', headerName: '', cellRenderer: 'RowRendered', filter: false }
  ];
  useEffect(() => {
    if (needLoad) {
      setNeedLoad(false);
      Axios.get(`/api/v1/permissionLevel`, config).then(permLevels => {
        setAllPermissionLevel(permLevels.data.data.data);
      });
    }
  });
  useEffect(() => {
    Axios.get(`/api/v1/Module`, config).then(modules => {
      setAllModule(modules.data.data.data);
      setAllNodes(getNodes(modules.data.data.data));
    });
  }, []);

  const RowRendered = props => {
    const EditPL = () => {
      setAllEditNodeChecked(props.data.modules.map(item => item._id));
      setUpdatePermissionName(props.data.name);
      setItemSelected(props.value);
      setEditPLShow(true);
    };
    return (
      <span>
        <button style={{ lineHeight: 0.5 }} className="btn" onClick={EditPL}>
          <i className="feather icon-edit"></i>
        </button>
      </span>
    );
  };
  const handleClose = () => {
    setNewPLShow(false);
  };
  const handleCloseEdit = () => {
    setEditPLShow(false);
  };
  const getNodes = items => {
    const nodes = [];
    items.forEach(item => {
      if (!item.parent) {
        const tempNode = { value: item._id, label: item.name, children: [] };
        items.forEach(nestedItem => {
          if (nestedItem.parent === item._id) {
            tempNode.children.push({ label: nestedItem.name, value: nestedItem._id });
          }
        });
        nodes.push(tempNode);
      }
    });
    return nodes;
  };
  const onSavePL = event => {
    event.preventDefault();
    const tempAllNode = [...allNodeChecked];
    allNodeChecked.forEach(item => {
      const module = allModule.find(node => {
        return node._id === item;
      });
      if (module.parent) {
        const parentInd = tempAllNode.findIndex(node => {
          return node === module.parent;
        });
        if (parentInd === -1) {
          tempAllNode.push(module.parent);
        }
      }
    });
    const body = JSON.stringify({
      name: newPermissionName,
      modules: tempAllNode
    });
    Axios.post('/api/v1/permissionLevel', body, config).then(r => {
      setNeedLoad(true);
      handleClose();
      toast.success('سطح دسترسی مورد نظر شما ایجاد شد.');
    });
  };
  const onUpdatePL = event => {
    event.preventDefault();
    const tempAllNode = [...allEditNodeChecked];
    allEditNodeChecked.forEach(item => {
      const module = allModule.find(node => {
        return node._id === item;
      });
      if (module.parent) {
        const parentInd = tempAllNode.findIndex(node => {
          return node === module.parent;
        });
        if (parentInd === -1) {
          tempAllNode.push(module.parent);
        }
      }
    });
    const body = JSON.stringify({
      name: updatePermissionName,
      modules: tempAllNode
    });
    Axios.patch('/api/v1/permissionLevel/' + itemSelected, body, config).then(r => {
      setNeedLoad(true);
      handleCloseEdit();
      toast.success('سطح دسترسی مورد نظر شما بروزرسانی شد.');
    });
  };
  return (
    <Container fluid className="content-body">
      <Row>
        <div
          className="fonticon-wrap newPLBTN"
          onClick={() => {
            setNewPLShow(true);
          }}
        >
          <i className="feather icon-plus"></i>
        </div>
        <Modal show={newPLShow} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> سطح دسترسی جدید</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onSavePL}>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="formGroupName">
                    <Form.Label>نام</Form.Label>
                    <Form.Control
                      required
                      value={newPermissionName}
                      onChange={e => {
                        setNewPermissionName(e.target.value);
                      }}
                      type="text"
                      placeholder="نام"
                    />
                    <Form.Control.Feedback type="invalid">نام اجباری میباشد</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={12}>
                  <CheckboxTree
                    nodes={allNodes}
                    checked={allNodeChecked}
                    expanded={allNodeExpand}
                    onCheck={ch => {
                      setAllNodeChecked(ch);
                    }}
                    onExpand={ch => {
                      setAllNodeExpand(ch);
                    }}
                  />
                </Col>
              </Row>
              <button className="btn btn-primary mr-1 mb-1 waves-effect waves-light" type="submit">
                ایجاد
              </button>
              <div className="btn btn-light mr-1 mb-1 waves-effect waves-light" onClick={handleClose}>
                انصراف
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal show={editPLShow} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title> ویرایش سطح دسترسی </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onUpdatePL}>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="formGroupName">
                    <Form.Label>نام</Form.Label>
                    <Form.Control
                      required
                      value={updatePermissionName}
                      onChange={e => {
                        setUpdatePermissionName(e.target.value);
                      }}
                      type="text"
                      placeholder="نام"
                    />
                    <Form.Control.Feedback type="invalid">نام اجباری میباشد</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={12}>
                  <CheckboxTree
                    nodes={allNodes}
                    checked={allEditNodeChecked}
                    expanded={allEditNodeExpand}
                    onCheck={ch => {
                      setAllEditNodeChecked(ch);
                    }}
                    onExpand={ch => {
                      setAllEditNodeExpand(ch);
                    }}
                  />
                </Col>
              </Row>
              <button className="btn btn-primary mr-1 mb-1 waves-effect waves-light" type="submit">
                بروزرسانی
              </button>
              <div className="btn btn-light mr-1 mb-1 waves-effect waves-light" onClick={handleCloseEdit}>
                انصراف
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Row>
      <Row>
        <Col md={12}>
          <Datagrid
            gridColumn={permLevelGridColumn}
            rowData={allPermissionLevel}
            setGridApi={setGridApi}
            paginationPageSize={10}
            onClickItems={RowRendered}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PermissionLevel;

import React, { useState, useEffect } from 'react';
import Axios from '../../../Axios/Axios';

import {
  Row,
  Button,
  Col,
  Modal,
  InputGroup,
  FormControl,
  Alert,
  Form,
  Container,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CirclePicker } from 'react-color';
import './BuildingItem.css';
import AutoCompleteSelect from 'react-select';
import swal from 'sweetalert';

const BuildingItem = props => {
  const [Building, setBuilding] = useState([]);
  const [Organization, setOrganization] = useState([]);
  const [AllMeter, setAllMeter] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [MeterName, setMeterName] = useState('');
  const [MeterType, setMeterType] = useState('Consumption');
  const [MeterUnit, setMeterUnit] = useState('');
  const [MeterIcon, setMeterIcon] = useState('');
  const [Color, setColor] = useState('');
  const [loading, setLoading] = useState(false);
  const [RenderBuilding, setRenderBuilding] = useState(false);
  const [BuildingName, setBuildingName] = useState();
  const [BuildingCity, setBuildingCity] = useState();
  const [BuildingType, setBuildingType] = useState();
  const [BuildingArea, setBuildingArea] = useState();
  const [BuildingPeopleCount, setBuildingPeopleCount] = useState();
  const [AllCity, setAllCity] = useState([]);
  const [showEditBuild, setShowEditBuild] = useState(false);
  const [selected, setSelected] = useState('');
  const [buildingSite, setBuildingSite] = useState(true);
  const [industrialSite, setIndustrialSite] = useState(true);
  const [siteName, setSiteName] = useState();
  const [siteCity, setSiteCity] = useState();
  const [siteStandard, setSiteStandard] = useState('');
  const [allParameterUnit, setAllParameterUnit] = useState([]);
  const [allConsumptionUnit, setAllConsumptionUnit] = useState([]);
  const [CirclePickerColors, setCirclePickerColors] = useState([
    '#8bc34a',
    '#ffc107',
    '#2196f3',
    '#f44336',
    '#ffeb3b',
    '#B3B3B3',
    '#333'
  ]);
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    Axios.get('/api/v1/City', config).then(city => {
      setAllCity(city.data.data.data);
    });
    Axios.get('/api/v1/unit?type=Consumption', config).then(units => {
      setAllConsumptionUnit(units.data.data.data);
    });
    Axios.get('/api/v1/unit?type=Parameter', config).then(units => {
      setAllParameterUnit(units.data.data.data);
    });
    Axios.get('/api/v1/Building/' + props.ItemID, config).then(build => {
      setBuilding(build.data.data.data);
      if (build.data.data.data.kind === 'Building') {
        setBuildingSite(false);
        setIndustrialSite(true);
      } else {
        setBuildingSite(true);
        setIndustrialSite(false);
      }
      // console.log(build.data.data.data);
      Axios.get(
        '/api/v1/organization/' + build.data.data.data.organization,
        config
      ).then(org => {
        setOrganization(org.data.data.data);
      });
      Axios.get('/api/v1/meter?building=' + props.ItemID, config).then(
        meter => {
          setAllMeter(meter.data.data.data);
        }
      );
    });
  }, [props.ItemID]);
  const handleCloseAdd = () => {
    setShowAdd(false);
    setshowAlert(false);
  };
  const handleShowAdd = () => setShowAdd(true);
  const changeUnit = e => {
    let unit = null;
    allConsumptionUnit.forEach(element => {
      if (element._id === e.target.value) {
        unit = element;
      }
    });
    allParameterUnit.forEach(element => {
      if (element._id === e.target.value) {
        unit = element;
      }
    });
    setMeterUnit(unit);
  };
  const handleShowEdit = () => setShowEdit(true);
  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const handleCloseEditBuild = () => {
    setShowEditBuild(false);
    setBuildingCity('');
    setBuildingName('');
    setSiteCity('');
    setBuildingCity('');
    setBuildingArea('');
    setBuildingType('');
    setBuildingPeopleCount('');
  };
  const handleShowEditBuild = () => {
    setShowEditBuild(true);
  };
  const MeterNewItem = JSON.stringify({
    name: MeterName,
    hasChild: 'false',
    type: MeterType,
    unit: MeterUnit._id,
    organization: { _id: Organization._id },
    building: { _id: Building._id },
    icon: (MeterIcon ? MeterIcon : MeterUnit.icon),
    color: (Color ? Color : MeterUnit.color)
  });
  const AddItem = () => {
    if (MeterName && MeterType && MeterUnit) {
      Axios.post(`/api/v1/Meter/`, MeterNewItem, config).then(org => {
        setLoading(false);
        setShowAdd(false);
        setshowAlert(false);
        props.setIsRenderNavbar(true);
        setMeterType('');
        setMeterName('');
        setMeterUnit('');
        toast.success('کنتور "' + MeterName + '" ایجاد شد .');
      });
    } else {
      setshowAlert(true);
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 6000 });
      setLoading(false);
    }
  };
  // Start Delete Building
  const DeleteItem = () => {
    if (AllMeter.length > 0) {
      swal({
        title:
          `لطفا قبل از حذف این ساختمان / سایت، کنتورهای زیرمجموعه آن را حذف نمایید.`,
        text: 'پس از حذف آنها مجدد تلاش نمایید.',
        icon: 'error',
        dangerMode: true
      })
    }
    else {
      swal({
        title: ` ایا می خواهید ساختمان "` + Building.name + `" را حذف نمایید   `,
        text: 'این عمل غیرقابل بازگشت میباشد.',
        icon: 'warning',
        dangerMode: true,
        buttons: ['انصراف', 'قبول']
      }).then(willDelete => {
        if (willDelete) {
          const body = JSON.stringify({
            active: false
          });

          Axios.delete(`/api/v1/building/` + props.ItemID, config).then(res => {
            props.setIsRenderNavbar(true);
            props.SetShowPage('d-none');
            swal('سازمان مورد نظر حذف شد.', {
              icon: 'success'
            });
          });
        }
      });
    }
  };
  const handleChangeComplete = color => {
    // console.log(color.hex);
    setColor(color.hex);
  };
  // Satrt Edit Building
  const EditItemBuilding = () => {
    let BuildingNameTemp = BuildingName;
    let BuildingTypeTemp = BuildingType;
    let BuildingAreaTemp = BuildingArea;
    let BuildingPeopleCountTemp = BuildingPeopleCount;
    let BuildingCityTemp =''
    if(BuildingCity){
       BuildingCityTemp = BuildingCity.value;
    }
    if (!BuildingNameTemp) {
      BuildingNameTemp = Building.name;
    }
    if (!BuildingTypeTemp) {
      BuildingTypeTemp = Building.type;
    }
    if (!BuildingAreaTemp) {
      BuildingAreaTemp = Building.area;
    }
    if (!BuildingPeopleCountTemp) {
      BuildingPeopleCountTemp = Building.peopleCount;
    }
    if (!BuildingCityTemp) {
      BuildingCityTemp = Building.city._id;
    }
    let buildType = BuildingTypeTemp;
    if (BuildingTypeTemp === 'مسکونی') {
      if (BuildingArea >= 1000) {
        buildType = 'مسکونی بزرگ';
      } else {
        buildType = 'مسکونی کوچک';
      }
    }
    // switch (buildType) {
    //   case 'مسکونی کوچک':
    //     switch (BuildingeClimat) {
    //       case 'بسیار سرد':
    //         EIdeal = 111;
    //         break;
    //       case 'سرد':
    //         EIdeal = 111;
    //         break;
    //       case 'معتدل و بارانی':
    //         EIdeal = 156;
    //         break;
    //       case 'نیمه معتدل و بارانی':
    //         EIdeal = 156;
    //         break;
    //       case 'نیمه خشک':
    //         EIdeal = 83;
    //         break;
    //       case 'گرم و خشک':
    //         EIdeal = 86;
    //         break;
    //       case 'بسیار گرم و خشک':
    //         EIdeal = 150;
    //         break;
    //       case 'بسیار گرم و مرطوب':
    //         EIdeal = 130;
    //         break;
    //     }
    //     break;
    //   case 'مسکونی بزرگ':
    //     switch (BuildingeClimat) {
    //       case 'بسیار سرد':
    //         EIdeal = 102;
    //         break;
    //       case 'سرد':
    //         EIdeal = 102;
    //         break;
    //       case 'معتدل و بارانی':
    //         EIdeal = 106;
    //         break;
    //       case 'نیمه معتدل و بارانی':
    //         EIdeal = 106;
    //         break;
    //       case 'نیمه خشک':
    //         EIdeal = 87;
    //         break;
    //       case 'گرم و خشک':
    //         EIdeal = 75;
    //         break;
    //       case 'بسیار گرم و خشک':
    //         EIdeal = 138;
    //         break;
    //       case 'بسیار گرم و مرطوب':
    //         EIdeal = 118;
    //         break;
    //     }
    //     break;
    //   case 'اداری دولتی':
    //     switch (BuildingeClimat) {
    //       case 'بسیار سرد':
    //         EIdeal = 80;
    //         break;
    //       case 'سرد':
    //         EIdeal = 80;
    //         break;
    //       case 'معتدل و بارانی':
    //         EIdeal = 64;
    //         break;
    //       case 'نیمه معتدل و بارانی':
    //         EIdeal = 64;
    //         break;
    //       case 'نیمه خشک':
    //         EIdeal = 74;
    //         break;
    //       case 'گرم و خشک':
    //         EIdeal = 64;
    //         break;
    //       case 'بسیار گرم و خشک':
    //         EIdeal = 86;
    //         break;
    //       case 'بسیار گرم و مرطوب':
    //         EIdeal = 91;
    //         break;
    //     }
    //     break;
    //   case 'اداری خصوصی':
    //     switch (BuildingeClimat) {
    //       case 'بسیار سرد':
    //         EIdeal = 120;
    //         break;
    //       case 'سرد':
    //         EIdeal = 120;
    //         break;
    //       case 'معتدل و بارانی':
    //         EIdeal = 152;
    //         break;
    //       case 'نیمه معتدل و بارانی':
    //         EIdeal = 152;
    //         break;
    //       case 'نیمه خشک':
    //         EIdeal = 124;
    //         break;
    //       case 'گرم و خشک':
    //         EIdeal = 117;
    //         break;
    //       case 'بسیار گرم و خشک':
    //         EIdeal = 121;
    //         break;
    //       case 'بسیار گرم و مرطوب':
    //         EIdeal = 197;
    //         break;
    //     }
    //     break;
    // }

    const updateBuilding = JSON.stringify({
      name: BuildingNameTemp,
      organization: Organization._id,
      city: BuildingCityTemp,
      area: BuildingAreaTemp,
      type: BuildingTypeTemp,
      peopleCount: BuildingPeopleCountTemp
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };

    Axios.patch(
      `/api/v1/building/` + props.ItemID,
      updateBuilding,
      config
    ).then(org => {
      props.setIsRenderNavbar(true);
      setShowEditBuild(false);
      toast.success('ساختمان "' + BuildingNameTemp + '" ویرایش شد .');
      handleCloseEditBuild(true);
      setBuildingCity('');
      setBuildingName('');
      setBuildingCity('');
      setBuildingArea('');
      setBuildingType('');
      setBuildingPeopleCount('');
    });
  };
  //End Edit building

  const EditItemSite = () => {
    let SiteNameTemp = siteName;
    let SiteStandardTemp = siteStandard;
    let SiteCityTemp= ''
    if(siteCity){
       SiteCityTemp = siteCity.value;
    }

    if (!SiteNameTemp) {
      SiteNameTemp = Building.name;
    }
    if (!SiteStandardTemp) {
      SiteStandardTemp = Building.standard;
    }
    if (!SiteCityTemp) {
      SiteCityTemp = Building.city._id;
    }

    const updateBuilding = JSON.stringify({
      name: SiteNameTemp,
      organization: Organization._id,
      city: SiteCityTemp,
      standard: SiteStandardTemp
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };

    Axios.patch(
      `/api/v1/building/` + props.ItemID,
      updateBuilding,
      config
    ).then(org => {
      props.setIsRenderNavbar(true);
      setShowEditBuild(false);
      toast.success('سایت "' + SiteNameTemp + '" ویرایش شد .');
      handleCloseEditBuild(true);
      setSiteCity('');
      setSiteName('');
      setSiteStandard('');
    });
  };
  const renderTooltipBuildType = props => (
    <Tooltip id="button-tooltip" {...props}>
      نوع ساختمان را با توجه به مساحت ان انتخاب نمایید نوع ساختمان را با توجه به
      مساحت ان انتخاب نمایید نوع ساختمان را با توجه به مساحت ان انتخاب نمایید
    </Tooltip>
  );
  return (
    <div>
      <div className="Actionbar">
        <div className="row">
          <div className="col-md-6">
            {/*Start  Add Meter */}
            <Button
              variant="btn btn-success float-left mr-2"
              onClick={handleShowAdd}
            >
              <i class="fa fa-plus"></i> کنتور / متغیر
            </Button>
            <Modal show={showAdd} onHide={handleCloseAdd}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {MeterType === 'Consumption'
                    ? 'افزودن کنتور'
                    : 'افزودن متغیر'}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <InputGroup className="mb-1">
                    <Form.Control
                      as="select"
                      custom
                      onChange={e => setMeterType(e.target.value)}
                      value={MeterType}
                    >
                      <option value="Consumption">کنتور مصرفی</option>
                      <option value="Parameter">پارامترها</option>
                    </Form.Control>
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <FormControl
                      placeholder={
                        MeterType === 'Consumption' ? 'نام کنتور' : 'نام متغیر'
                      }
                      aria-label="MeterName"
                      aria-describedby="MeterName"
                      value={MeterName}
                      onChange={e => setMeterName(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mb-1">
                    <Form.Control
                      as="select"
                      custom
                      value={MeterUnit ? MeterUnit._id : null}
                      onChange={e => changeUnit(e)}
                    >
                      <option value="ChooseUnit">
                        {MeterType === 'Consumption'
                          ? 'واحد کنتور'
                          : 'واحد متغیر'}
                        را انتخاب نمایید
                      </option>
                      {MeterType === 'Consumption'
                        ? allConsumptionUnit.map((element, index) => {
                          return (
                            <option
                              index={index}
                              value={element._id}
                            >{`${element.name}(${element.unit})`}</option>
                          );
                        })
                        : allParameterUnit.map((element, index) => {
                          return (
                            <option
                              index={index}
                              value={element._id}
                            >{`${element.name}(${element.unit})`}</option>
                          );
                        })}
                    </Form.Control>
                  </InputGroup>
                  <InputGroup className="mb-1 ">
                    <Form.Control
                      as="select"
                      multiple
                      custom
                      className="IconSelect"
                      onChange={e => setMeterIcon(e.target.value)}
                    >
                      <option value="fa-sun-o">Sun &#xf185;</option>
                      <option value="fa-snowflake-o">Snow &#xf2dc;</option>
                      <option value="fa-battery-full">battery &#xf240;</option>
                      <option value="fa fa-lightbulb-o">
                        lightbulb &#xf0eb;
                      </option>
                    </Form.Control>
                  </InputGroup>

                  <CirclePicker
                    colors={CirclePickerColors}
                    color={Color}
                    onChangeComplete={handleChangeComplete}
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={AddItem}>
                  ذخیره
                </Button>
              </Modal.Footer>
            </Modal>

            {/*End  Add Meter */}
          </div>

          {/* Start Edit building pegah*/}
          <div className="col-md-6">
            <div>
              <Button
                variant="btn btn-success float-right"
                onClick={handleShowEditBuild}
              >
                <i class="feather icon-edit"></i>
              </Button>

              <Modal show={showEditBuild} onHide={handleCloseEditBuild}>
                <Modal.Header closeButton>
                  <Modal.Title>ویرایش سایت</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form hidden={buildingSite}>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>
                        نام ساختمان
                      </label>
                      <FormControl
                        className={'col-sm-9'}
                        aria-label="BuildingName"
                        aria-describedby="BuildingNameID"
                        value={BuildingName}
                        placeholder={Building.name}
                        onChange={e => setBuildingName(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>شهر</label>
                      <AutoCompleteSelect
                        className={'col-sm-9 pr-0 pl-0'}
                        isClearable={true}
                        isSearchable={true}
                        value={
                          !BuildingCity && Building.city
                            ? {
                              label:
                                Building.city.state +
                                ' - ' +
                                Building.city.name,
                              value: Building.city._id
                            }
                            : BuildingCity
                        }
                        onChange={newValv => {
                          setBuildingCity(newValv);
                        }}
                        options={AllCity.map(el => {
                          return {
                            label: el.state + ' - ' + el.name,
                            value: el._id
                          };
                        })}
                      />
                    </InputGroup>

                    <InputGroup className="mb-1 BTCustomPos">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipBuildType}
                      >
                        <div className="info noteInput">
                          <i class="fas fa-info-circle"></i>
                        </div>
                      </OverlayTrigger>
                      <label className={'col-sm-3 col-form-label'}>
                        نوع ساختمان
                      </label>
                      <Form.Control
                        as="select"
                        className={'col-sm-9'}
                        custom
                        onChange={e => setBuildingType(e.target.value)}
                      >
                        <option
                          selected={Building.type == 'مسکونی' ? 'selected' : ''}
                          value="مسکونی"
                        >
                          مسکونی
                        </option>
                        <option
                          selected={
                            Building.type == 'اداری دولتی' ? 'selected' : ''
                          }
                          value="اداری دولتی"
                        >
                          اداری دولتی{' '}
                        </option>
                        <option
                          selected={
                            Building.type == 'اداری خصوصی' ? 'selected' : ''
                          }
                          value="اداری خصوصی"
                        >
                          اداری خصوصی{' '}
                        </option>
                      </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>
                        مساحت (m2)
                      </label>
                      <FormControl
                        className={'col-sm-9'}
                        aria-label="BuildingArea"
                        aria-describedby="BuildingAreaID"
                        value={BuildingArea}
                        placeholder={Building.area}
                        onChange={e => setBuildingArea(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>
                        تعداد نفرات
                      </label>
                      <FormControl
                        className={'col-sm-9'}
                        aria-label="BuildingArea"
                        aria-describedby="BuildingAreaID"
                        value={BuildingPeopleCount}
                        placeholder={Building.peopleCount}
                        onChange={e =>
                          e.target.value
                            ? setBuildingPeopleCount(e.target.value)
                            : setBuildingPeopleCount(Building.peopleCount)
                        }
                      />
                    </InputGroup>
                    <Button variant="primary" onClick={EditItemBuilding}>
                      ذخیره
                    </Button>
                  </Form>
                  <Form hidden={industrialSite}>
                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>
                        نام سایت
                      </label>
                      <FormControl
                        className={'col-sm-9'}
                        aria-label="SiteName"
                        aria-describedby="SiteNameID"
                        value={siteName}
                        placeholder={Building.name}
                        onChange={e => setSiteName(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup className="mb-1">
                      <label className={'col-sm-3 col-form-label'}>شهر</label>
                      <AutoCompleteSelect
                        className={'col-sm-9 pr-0 pl-0'}
                        isClearable={true}
                        isSearchable={true}
                        value={
                          !siteCity && Building.city
                            ? {
                              label:
                                Building.city.state +
                                ' - ' +
                                Building.city.name,
                              value: Building.city._id
                            }
                            : siteCity
                        }
                        onChange={newValv => {
                          setSiteCity(newValv);
                        }}
                        options={AllCity.map(el => {
                          return {
                            label: el.state + ' - ' + el.name,
                            value: el._id
                          };
                        })}
                      />
                    </InputGroup>

                    <InputGroup className="mb-1 BTCustomPos">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipBuildType}
                      >
                        <div className="info noteInput">
                          <i class="fas fa-info-circle"></i>
                        </div>
                      </OverlayTrigger>
                      <label className={'col-sm-3 col-form-label'}>
                        معیار مصرف انرژی{' '}
                      </label>
                      <Form.Control
                        as="select"
                        className={'col-sm-9'}
                        custom
                        onChange={e => setSiteStandard(e.target.value)}
                      >
                        <option
                          selected={
                            Building.standard == 'isiri42112' ? 'selected' : ''
                          }
                          value="isiri42112"
                        >
                          ISIRI 42112
                        </option>
                        <option
                          selected={
                            Building.standard == 'isiri67543' ? 'selected' : ''
                          }
                          value="isiri67543"
                        >
                          ISIRI 67543
                        </option>
                        <option
                          selected={
                            Building.type == 'isiri77543' ? 'selected' : ''
                          }
                          value="isiri77543"
                        >
                          ISIRI 77543
                        </option>
                      </Form.Control>
                    </InputGroup>

                    <Button variant="primary" onClick={EditItemSite}>
                      ذخیره
                    </Button>
                  </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>

            <div>
              {/*Start  Delete Building */}
              <Button
                variant="btn btn-danger float-right mr-2"
                onClick={DeleteItem}
              >
                <i class="feather icon-trash"></i>
              </Button>
              {/*End  Delete Building */}
            </div>
          </div>
        </div>
      </div>
      <div className="Orginfo">
        <Row>
          <Col xs={12} md={12}>
            <h3> اطلاعات ساختمان</h3>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="grayLbl mb-1">
                <i class="fa fa-university"></i>
                <span className="lblBold">نام ساختمان:</span>
                <span className="lblNormal">{Building.name}</span>
              </div>
              <div className="grayLbl mb-1">
                <i class="fa fa-building"></i>
                <span className="lblBold"> نام سازمان:</span>
                <span className="lblNormal">{Organization.name}</span>
              </div>
              {Building.kind === 'Building' ? (
                <div>
                  <div className="grayLbl mb-1">
                    <i class="fa fa-tag"></i>
                    <span className="lblBold"> نوع ساختمان:</span>
                    <span className="lblNormal">{Building.type}</span>
                  </div>
                  <div className="grayLbl mb-1">
                    <i class="fas fa-ruler-combined"></i>
                    <span className="lblBold"> مساحت ساختمان:</span>
                    <span className="lblNormal">{Building.area}</span>
                  </div>
                </div>
              ) : (
                ''
              )}
              {Building.kind === 'Site' ? (
                <div className="grayLbl mb-1">
                  <i class="fa fa-tag"></i>
                  <span className="lblBold"> معیار مصرف انرژی :</span>
                  <span className="lblNormal">{Building.standard}</span>
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col xs={12} md={6}>
              <div className="grayLbl mb-1">
                <i class="fa fa-map-marker"></i>
                <span className="lblBold"> استان :</span>
                <span className="lblNormal">
                  {Building.city ? Building.city.state : ''}
                </span>
              </div>
              <div className="grayLbl mb-1">
                <i class="fa fa-map-pin"></i>
                <span className="lblBold"> شهر :</span>
                <span className="lblNormal">
                  {Building.name ? Building.city.name : ''}
                </span>
              </div>
              {Building.kind === 'Building' ? (
                <div>
                  <div className="grayLbl mb-1">
                    <i class="fas fa-users"></i>
                    <span className="lblBold">تعداد نفرات: </span>
                    <span className="lblNormal">{Building.peopleCount}</span>
                  </div>
                  <div className="grayLbl mb-1">
                    <i class="fas fa-tachometer-alt"></i>
                    <span className="lblBold"> عدد ایده آل :</span>
                    <span className="lblNormal">{Building.eIdeal}</span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Container>
        <h3 className="mt-3">کنتورها </h3>
        <div className="table-responsive">
          <table class="table table-striped tblOrg">
            <thead>
              <tr>
                <th scope="col">نام </th>
                <th scope="col">نوع</th>
                <th scope="col">واحد</th>
              </tr>
            </thead>
            <tbody>
              {AllMeter.map(meter => {
                if (!meter.parentMeter) {
                  return (
                    <tr
                      class="clickable "
                      onclick="window.location='http://google.com'"
                    >
                      <td> {meter.name}</td>
                      <td>
                        {meter.type === 'Consumption'
                          ? 'کنتور مصرفی'
                          : 'پارامترها'}
                      </td>
                      <td>
                        {' '}
                        {meter.unit.name} ({meter.unit.unit})
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default BuildingItem;

import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../Axios/Axios';
import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import RightNav from '../../../components/UI/RightNav/RightNav';
const Trend = props => {
  const [renderReport, setRenderReport] = useState(true);
  const [allUnits, SetAllUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  const [righNav, setRighNav] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [resetSetSelectedRows,setResetSetSelectedRows] = useState(false);
  const [resetFrom, setResetFrom] = useState(false);
  const [resetTime, setResetTime] = useState('');
  const [resetCheckBox, setResetCheckBox] = useState(false);

  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (renderReport) {
      Axios.get('/api/v1/unit/', config).then(units => {
        SetAllUnits(units.data.data.data);
        setRenderReport(false);
      });
      Axios.get('/api/v1/users/me', config).then(user => {
        setUserInfo(user.data.data.data);
      });
      if (resetFrom) {
        props.ResetCurrentForm(resetFrom);
        setResetFrom(false);
      }

    }
  });

  const SelectUnits = (checked, selectRow) => {
    const selectedCheckBoxUnit = selectedUnits;
    if (checked) {
      selectedCheckBoxUnit.push(selectRow);
      setSelectedUnits(selectedCheckBoxUnit);
    } else {
      const index = selectedCheckBoxUnit.indexOf(selectRow);
      if (index !== -1) {
        selectedCheckBoxUnit.splice(index, 1);
        setSelectedUnits(selectedCheckBoxUnit);
      }
    }
    console.log('selectedUnits');
    console.log(selectedUnits);
  };
  const handleCallbackRightNav = async selectedRow => {
    setRighNav(selectedRow);
    // console.log(selectedRow);
    const body = JSON.stringify({
      kind: 'getYears',
      nav: selectedRow
    });
  };

  const AddReport = e => {
    e.preventDefault();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const reportNewItem = JSON.stringify({
      name: props.reportName,
      description: props.reportDesc,
      createdBy: userInfo._id,
      reportPage: props.selectedReport,
      time: props.selectedTime,
      unit: selectedUnits,
      nav: righNav
    });
    if (props.reportName && props.reportDesc && props.selectedTime && selectedUnits.length> 0) {
      if (props.selectedReport.length) {
        Axios.post(`/api/v1/ReportDefinition/`, reportNewItem, config).then(note => {
          toast.success('گزارش شما ایجاد شد', { autoClose: 3000 });
          setRenderReport(true);
          setResetFrom(true);
          setResetTime(' ');
          setIsRenderNavbar(true);
          setResetCheckBox(true);

          // formAddReportRef.current.reset();
        });
      } else {
        toast.error(' لطفا نوع گزارش و زمان آن را انتخاب نمایید.', { autoClose: 3000 });
      }
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 3000 });
    }
  };
  return (
    <div className="row">
      <Col md={6}>
        <div className="grayback">
          <label className={' col-form-label'}> انتخاب سایت مورد نظر</label>
          <div className="scrollBox">
            <RightNav
              setIsRenderNavbar={setIsRenderNavbar}
              isRenderNavbar={isRenderNavbar}
              parentCallback={handleCallbackRightNav}
              type={'multi-select'}
              showResetnav="true"
            />
          </div>
        </div>
      </Col>
      <Col md={6}>
        <div className="grayback">
          <label className={'col-form-label '}>کنتور مصرفی | پارامتر</label>
          <div className="w-100"></div>
          <div className="scrollBox pl-2">
            <InputGroup className="mb-1">
              {allUnits.map(unit => {
                return (
                  <Col md={12} className="pl-1 col-form-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={unit._id}
                      id="UnitCheckBox"
                      onClick={e => {
                        SelectUnits(e.target.checked, e.target.value);
                      }}
                    />
                    {unit.name} ({unit.unit})
                  </Col>
                );
              })}
            </InputGroup>
          </div>
        </div>
      </Col>
      <Form.Group className="savebtn">
        <Button variant="primary " onClick={AddReport}>
          ذخیره
        </Button>
      </Form.Group>
    </div>
  );
};

export default Trend;

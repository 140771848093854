import React, { useEffect, useState, Fragment } from 'react';

import './MeterData.css';

import CSVReader from 'react-csv-reader';
import { Row, Container, Card, Button, Col, Spinner, OverlayTrigger, Tooltip, Form, Alert } from 'react-bootstrap';
import moment from 'jalali-moment';

import CSVDataTable from './CSVDataTable/CSVDataTable';
import SelectColumn from './SelectColumn/SelectColumn';
import ConflictData from './ConflicData/ConflictData';
import UploadHistory from './UploadHistory/UploadHistory';

import Axios from '../../../Axios/Axios';

import { toast } from 'react-toastify';
import swal from 'sweetalert';

const MeterData = props => {
  const meterID = props.meter ? props.meter._id : '';
  const meterUnit = props.meter ? props.meter.unit.unit : '';
  const meterType = props.meter ? props.meter.unit.name : '';
  const [allUploadConsumptions, setUploadConsumptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [CSVUploaded, setCSVUploaded] = useState([]);
  const [CSVCorrect, setCSVCorrect] = useState([]);
  const [excellKind, setExcellKind] = useState('range');
  const [fromCol, setFromCol] = useState();
  const [toCol, setToCol] = useState();
  const [valueCol, setValueCol] = useState();
  const [formatCol, setFormatCol] = useState('');
  const [costCol, setCostCol] = useState(null);
  const [isConflict, setIsConflict] = useState(null);
  const [conflictUploaded, setConflictUploaded] = useState([]);
  const [newUploaded, setNewUploaded] = useState([]);
  const [newFile, setFile] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const setFormat = e => {
    setFormatCol(e.target.value);
  };
  const checkData = async function(e) {
    e.preventDefault();
    ///////// Filter corupt data /////////////
    const CSVUploadedCorrect = CSVUploaded.slice(1).filter(el => {
      return !(el[fromCol] === '' || el[toCol] === '' || el[valueCol] === '');
    });
    setCSVCorrect(CSVUploadedCorrect);
    if (isNaN(fromCol) || fromCol === '') {
      toast.error(`ستون ${excellKind === 'range' ? 'از تاریخ' : 'تاریخ'} را از ستونهای اکسل مشخص نمایید.`, {
        autoClose: 6000
      });
      return 0;
    }
    if ((isNaN(toCol) || toCol === '') && excellKind === 'range') {
      toast.error('ستون تا تاریخ را از ستونهای اکسل مشخص نمایید.', {
        autoClose: 6000
      });
      return 0;
    }
    if (isNaN(valueCol) || valueCol === '') {
      toast.error('ستون مقادیر مصرف را از ستونهای اکسل مشخص نمایید.', {
        autoClose: 6000
      });
      return 0;
    }
    if (formatCol === '') {
      toast.error('نحوه درج تاریخ ها را در اکسل مشخص نمایید.', {
        autoClose: 6000
      });
      return 0;
    }
    setIsConflict(false);
    //////// Conflict check /////////
    //conflict dakhel khode file ham check baiad shavad ?????????????????
    setConflictUploaded([]);
  };
  const uploadData = async function(e) {
    setLoading(true);
    e.preventDefault();

    //add record to Consumption

    const data = await Axios.post(
      `/api/v1/consumption/batch`,
      JSON.stringify({
        excellData: CSVCorrect,
        to: toCol,
        from: fromCol,
        value: valueCol,
        format: formatCol,
        meterID: meterID,
        unit: meterUnit,
        type: meterType,
        cost: costCol,
        kind: excellKind
      }),
      config
    );
    if (data.data.code === 422) {
      toast.error('سطرهایی از اطلاعات ورودی اکسل با اطلاعات موجود تداخل دارد.', { autoClose: 6000 });
      setCSVUploaded([]);
      setConflictUploaded(data.data.exist);
      setNewUploaded(data.data.new);
      setLoading(false);
      setIsConflict(true);
    } else {
      // upload file to database
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('title', newFile.name);
      formData.append('isProfile', false);
      formData.append('readOnly', true);
      formData.append('meter', meterID);

      Axios.post('/api/v1/file', formData, config).then(function(res) {
        e.preventDefault();
        props.setNeedLoadMeter(true);
        toast.success('دیتای مورد نظر شما با موفقیت آپلود شد.', {
          onClose: () => {
            setCSVUploaded([]);
            setCSVCorrect([]);
            props.setNeedLoadMeter(true);
            setLoading(false);
            props.setBeforeStepFour('PassStep');
            setDisableButton(true);
            setConflictUploaded([]);
            setIsConflict(null);
            setFile(null);
          }
        });
      });
    }
  };
  const deleteConflict = async function(e) {
    swal({
      title: `آیا برای رونویسی دیتاهای قبلی دارای تداخل با اکسل وارد شده مطئن هستید؟`,
      text: 'این عمل غیرقابل بازگشت میباشد.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['انصراف', 'قبول']
    }).then(willDelete => {
      if (willDelete) {
        Axios.post(
          `/api/v1/consumption/deleteMany`,
          JSON.stringify({
            conflictData: conflictUploaded
          }),
          config
        ).then(() => {
          uploadData(e);
          // setFile(null);
          // setCSVUploaded([]);
          // setCSVCorrect([]);
          // setConflictUploaded([]);
          // setIsConflict(null);
          // swal('تداخلات مورد نظر حذف شدند و اکسل شما با موفقیت بارگزاری گردید.', {
          //   icon: 'success'
          // });
        });
      }
    });
  };
  useEffect(() => {
    if (props.needLoadMeter) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.get(`/api/v1/file?meter=${meterID}`, config).then(res => {
        setUploadConsumptions(res.data.data.data);
        props.setNeedLoadMeter(false);
        setFile(null);
        setCSVUploaded([]);
        setCSVCorrect([]);
        setConflictUploaded([]);
        setIsConflict(null);
        document.getElementById('rangeCSVUpload').value = '';
      });
    }
  });

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      {/* <ul class="text-right">
        <li>فرمت اکسل</li>
        <li>ستونها</li>
      </ul> */}
      مشاهده فایل نمونه
    </Tooltip>
  );
  return (
    <Fragment>
      <center hidden={!loading}>
        <Spinner animation="grow" variant="success" className={'spinnerChart'} />
      </center>
      <Container fluid hidden={loading} id="MeterData">
        <Container fluid className={'m-1 posRel uploadInfo text-left'}>
          <h3 className="simpleBoxTitle mt-0">
            آپلود فایل <span> (واحد تعیین شده: {meterUnit})</span>
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <div className="info noteInput">
                <i class="fas fa-info-circle"></i>
              </div>
            </OverlayTrigger>
          </h3>
          <div className="w-100"></div>
          <CSVReader
            onFileLoaded={(data, fileInfo, originalFile) => {
              setCSVUploaded(data);
              setFile(originalFile);
              setDisableButton(false);
              setIsConflict(null);
            }}
            inputId="rangeCSVUpload"
          />
          <CSVDataTable CSVData={CSVUploaded} />
        </Container>
        <Container className={'m-2 text-left'} fluid hidden={CSVUploaded.length === 0}>
          <h3 className="simpleBoxTitle">تنظیمات فایل ورودی</h3>
          <Form className="chooseFiled">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="col-md-2">نوع اکسل:</Form.Label>
              <select
                value={excellKind}
                onChange={e => {
                  setExcellKind(e.target.value);
                }}
                className="col-md-9"
              >
                <option value="day">روزانه</option>
                <option value="range">بازه ای</option>
              </select>
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="col-md-2">{excellKind === 'day' ? 'تاریخ:' : 'از تاریخ:'}</Form.Label>
              <SelectColumn CSVData={CSVUploaded} changeIt={setFromCol} className={'form-control col-sm-8'} />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail" hidden={excellKind === 'day'}>
              <Form.Label className="col-md-2">تا تاریخ:</Form.Label>
              <SelectColumn CSVData={CSVUploaded} changeIt={setToCol} className="col-md-9" />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="col-md-2">مقدار مصرف:</Form.Label>
              <SelectColumn CSVData={CSVUploaded} changeIt={setValueCol} className="col-md-9" />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="col-md-2">فرمت تاریخ:</Form.Label>
              <select onChange={setFormat} className="col-md-9">
                <option></option>
                <option value="YYYY/MM/DD">YYYY/MM/DD (1400/01/01)</option>
                <option value="YY/MM/DD">YY/MM/DD (00/01/01)</option>
              </select>
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="col-md-2"> هزینه (درصورت وجود):</Form.Label>
              <SelectColumn CSVData={CSVUploaded} changeIt={setCostCol} className="col-md-9" />
            </Form.Group>
            {isConflict === false ? (
              <Button hidden={disableButton || newFile === null} onClick={uploadData}>
                آپلود اطلاعات
              </Button>
            ) : (
              <Button variant="primary" type="submit" className="mt-2" onClick={checkData}>
                بررسی اطلاعات
              </Button>
            )}
          </Form>
        </Container>
        <Container className={'m-2 text-left'} fluid hidden={!isConflict}>
          <Row>
            <Col md="8">
              <h3 className="simpleBoxTitleRed">
                داده های زیر در سوابق کنتور انتخابی، پیش از این وارد شده و تکراری است.
              </h3>
            </Col>
            <Col md="4" className="d-flex flex-row-reverse">
              <Button className="mt-2 mb-1" onClick={deleteConflict}>
                جایگزینی مقادیر جدید
                <i class="fa fa-trash mr-1" aria-hidden="true"></i>
              </Button>
            </Col>
          </Row>
          <ConflictData conflictUploaded={conflictUploaded} newUploaded={newUploaded} />
        </Container>
        <Container className={'m-2 uploadBtn'} fluid hidden={isConflict || isNaN(isConflict)}>
          <Alert hidden={!disableButton} variant="success">
            دیتای شما آپلود شده است
          </Alert>
        </Container>
        <Container className={'m-2 text-left'} fluid hidden={allUploadConsumptions.length === 0}>
          <h3 className="simpleBoxTitle">سوابق آپلود </h3>
          <div className="w-100"></div>
          <UploadHistory historyConsumption={allUploadConsumptions} />
        </Container>
      </Container>
    </Fragment>
  );
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default MeterData;

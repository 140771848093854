import React, { useEffect, useState } from 'react';
import Axios from '../../../../Axios/Axios';
import { Row, Container, Card, Button, Col, Spinner, FormControl, Modal, InputGroup, Form } from 'react-bootstrap';
import Datagrid from '../../../../components/UI/Datagrid/Datagrid';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

const Users = ({ updateNeedGetUsers, needGetUsers }) => {
  //grid Config

  const [gridApi, setGridApi] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [paginationPageSize, updatepaginationPageSize] = useState(10);
  const [validated, setValidated] = useState(false);
  const [userPosition, setUserPosition] = useState('');
  const [allJobPosition, setAllJobPosition] = useState([]);
  const [allPermissionLevel, setAllPermissionLevel] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const [allUser, setUsers] = useState([]);
  const [newUsershow, setNewUsershow] = useState(false);
  const [editUsershow, setEditUsershow] = useState(false);
  let [newFile, setFile] = useState(null);

  const onSelectionChanged = () => {
    setSelectRow(gridApi.getSelectedRows());
  };
  const setGridPageSize = e => {
    gridApi.paginationSetPageSize(Number(e.target.value));
    updatepaginationPageSize(e.target.value);
  };
  const userGridColumn = [
    {
      headerName: 'نام',
      field: 'name'
    },
    {
      headerName: 'نام کاربری',
      field: 'email'
    },
    {
      headerName: 'سطح دسترسی',
      field: 'permissionLevel.name'
    },
    {
      headerName: 'ایمیل',
      field: 'emailAddress'
    },
    {
      headerName: 'سمت',
      field: 'position.name'
    },
    {
      headerName: 'کد پرسنلی',
      field: 'personalCode'
    },
    { field: '_id', headerName: '', cellRenderer: 'RowRendered', filter: false }
  ];
  const RowRendered = props => {
    //delete User
    const DeleteUser = () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.get(`/api/v1/users/` + props.value, config).then(user => {
        if (user.data.data.data.role === 'root') {
          toast.error('کاربر سیستم قابل تغییر و حذف نمیباشد', {
            autoClose: 5000
          });
          return false;
        }
        swal({
          title: `آیا مطمئن هستید برای حذف کاربر ${user.data.data.data.name}`,
          text: 'این عمل غیرقابل بازگشت میباشد.',
          icon: 'warning',
          dangerMode: true,
          buttons: ['انصراف', 'قبول']
        }).then(willDelete => {
          if (willDelete) {
            const body = JSON.stringify({
              active: false
            });
            const config = {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
              }
            };
            Axios.patch(`/api/v1/users/` + props.value, body, config).then(res => {
              updateNeedGetUsers(true);
              swal('کاربر مورد نظر حذف شد.', {
                icon: 'success'
              });
            });
          } else {
          }
        });
      });
    };
    // Edit User
    const EditUser = () => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      Axios.get(`/api/v1/users/` + props.value, config).then(user => {
        if (user.data.data.data.role === 'root') {
          toast.error('کاربر سیستم قابل تغییر و حذف نمیباشد', {
            autoClose: 5000
          });
          return false;
        } else {
          setEditUsershow(true);

          Axios.get(`/api/v1/users/` + props.value, config).then(user => {
            setItemSelected(user.data.data.data);
            if (user.data.data.data.role === 'root') {
              toast.error('کاربر سیستم قابل تغییر و حذف نمیباشد', {
                autoClose: 5000
              });
              return false;
            }
          });
        }
      });
    };
    return (
      <span>
        <button style={{ lineHeight: 0.5 }} onClick={DeleteUser} className="btn btnViewDoc">
          <i className="feather icon-user-x"></i>
        </button>
        <button style={{ lineHeight: 0.5 }} onClick={EditUser} className="btn btnViewDoc">
          <i className="feather icon-user-check"></i>
        </button>
      </span>
    );
  };
  //load Users table

  const handleEditClose = () => {
    setEditUsershow(false);
  };
  const handleClose = () => {
    setNewUsershow(false);
  };
  const handleShow = () => {
    setValidated(false);
    setFormData({
      newUser_name: '',
      newUser_username: '',
      newUser_email: '',
      newUser_password: '',
      newUser_passwordConfirm: '',
      newUser_permissionLevel: '',
      newUser_position: '',
      newUser_personalCode: '',
      newUser_confirmValid: false
    });
    setNewUsershow(true);
  };

  //Form New User State
  const [formData, setFormData] = useState({
    newUser_name: '',
    newUser_username: '',
    newUser_email: '',
    newUser_password: '',
    newUser_passwordConfirm: '',
    newUser_permissionLevel: '',
    newUser_position: '',
    newUser_personalCode: '',
    newUser_confirmValid: false
  });
  const {
    newUser_name,
    newUser_permissionLevel,
    newUser_email,
    newUser_username,
    newUser_password,
    newUser_passwordConfirm,
    newUser_personalCode
  } = formData;

  const changeData = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Validation Form New User

  const changeFile = e => {
    setFile(e.target.files[0]);
  };
  const handleAddUser = async event => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    // Create User (Sign Up)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const body = JSON.stringify({
      name: newUser_name,
      email: newUser_username,
      emailAddress: newUser_email,
      password: newUser_password,
      passwordConfirm: newUser_passwordConfirm,
      permissionLevel: newUser_permissionLevel
    });
    let createUser = await Axios.post('/api/v1/users/signup', body, config);

    if (createUser) {
      if (newFile) {
        const formData = new FormData();
        formData.append('file', newFile);
        formData.append('title', newFile.name);
        formData.append('isProfile', true);
        formData.append('readOnly', false);
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        };
        Axios.post('/api/v1/file', formData, config).then(res => {
          const photoUrl = res.data.data.path.replace('/client/public', '');
          const userInfo = JSON.stringify({
            photo: photoUrl,
            position: userPosition ? userPosition : null,
            personalCode: newUser_personalCode
          });
          Axios.patch('/api/v1/users/' + createUser.data.data.user._id, userInfo, config)

            .then(res => {
              toast.success('کاربر مورد نظر شما با موفقیت ساخته شد.', {
                onClose: () => {
                  updateNeedGetUsers(true);
                  handleClose();
                }
              });
            })
            .catch(error => {
              if (error.response) {
                // Request made and server responded
                toast.error(error.response.data.message, {
                  autoClose: 5000
                });
              }
            });
        });
      } else {
        const userInfo = JSON.stringify({
          position: userPosition ? userPosition : null,
          personalCode: newUser_personalCode,
          photo: '/uploads/1644329710498.png'
        });
        Axios.patch('/api/v1/users/' + createUser.data.data.user._id, userInfo, config)

          .then(async res => {
            toast.success('کاربر مورد نظر شما با موفقیت ساخته شد.', {
              onClose: () => {
                updateNeedGetUsers(true);
                handleClose();
              }
            });
          })
          .catch(error => {
            if (error.response) {
              // Request made and server responded
              toast.error(error.response.data.message, {
                autoClose: 5000
              });
            }
          });
      }
    }
  };

  const HandleEditUser = async e => {
    e.preventDefault();
    const userNameTemp = newUser_name ? newUser_name : itemSelected.name;
    const userRoleTemp = newUser_permissionLevel ? newUser_permissionLevel : itemSelected.permissionLevel;
    const useremailAddressTemp = newUser_email ? newUser_email : itemSelected.emailAddress;
    const userPositonTemp = userPosition ? userPosition : itemSelected.position ? itemSelected.position._id : null;
    const userpersonalCodeTemp = newUser_personalCode ? newUser_personalCode : itemSelected.personalCode;
    if (newFile) {
      const formData = new FormData();
      formData.append('file', newFile);
      formData.append('title', newFile.name);
      formData.append('isProfile', true);
      formData.append('readOnly', true);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      let currentUser = await Axios.get('/api/v1/users/' + itemSelected._id, config);
      Axios.post('/api/v1/file', formData, config).then(res => {
        const photoUrl = res.data.data.path.replace('/client/public', '');
        const userInfo = JSON.stringify({
          name: userNameTemp,
          permissionLevel: userRoleTemp,
          email: useremailAddressTemp,
          position: userPositonTemp ? userPositonTemp : null,
          personalCode: userpersonalCodeTemp,
          photo: photoUrl
        });
        Axios.patch('/api/v1/users/' + itemSelected._id, userInfo, config).then(async res => {
          updateNeedGetUsers(true);
          toast.success('کاربر ویرایش شد');
          setUserPosition('');
          // setUserName('');
          setFile('');
          setEditUsershow(false);
        });
      });
    } else {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const userInfo = JSON.stringify({
        name: userNameTemp,
        permissionLevel: userRoleTemp,
        emailAddress: useremailAddressTemp,
        position: userPositonTemp ? userPositonTemp : null,
        personalCode: userpersonalCodeTemp
      });
      Axios.patch('/api/v1/users/' + itemSelected._id, userInfo, config).then(async res => {
        toast.success('شرح شغل ویرایش شد');
        updateNeedGetUsers(true);
        setEditUsershow(false);
      });
    }
  };
  //Load All user state & componnetMount

  useEffect(() => {
    if (!needGetUsers) {
      return;
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    Axios.get('/api/v1/users', config).then(res => {
      updateNeedGetUsers(false);
      let data = res.data.data.data;
      setUsers(data);
    });
    Axios.get('/api/v1/JobPosition?isActive=true', config).then(res => {
      setAllJobPosition(res.data.data.data);
    });
    Axios.get('/api/v1/permissionLevel', config).then(res => {
      setAllPermissionLevel(res.data.data.data);
    });
  });

  // Render Page
  return (
    <Container fluid id="MainContent" className="pr-0 pl-0">
      <Col className="mainContent pr-0 pl-0">
        <div className="pageBack">
          <Row className="mb-1">
            <Col md={6}>
              <div className="col-md-10 col-sm-10 col-9">
                <div className="fonticon-container" onClick={handleShow}>
                  <div className="fonticon-wrap">
                    <i className="feather icon-user-plus"></i>
                  </div>
                </div>
              </div>
              <Modal show={newUsershow} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title> کاربر جدید</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form noValidate validated={validated} onSubmit={handleAddUser}>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formGroupName">
                          <Form.Label>نام و نام خانوادگی</Form.Label>
                          <Form.Control
                            required
                            name="newUser_name"
                            value={newUser_name}
                            onChange={changeData}
                            type="text"
                            placeholder="نام"
                          />
                          <Form.Control.Feedback type="invalid">نام اجباری میباشد</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                          <Form.Label>رمز عبور</Form.Label>
                          <Form.Control
                            type="password"
                            required
                            name="newUser_password"
                            value={newUser_password}
                            onChange={changeData}
                            minLength="8"
                            maxLength="24"
                            placeholder="رمز عبور"
                          />
                          <Form.Control.Feedback type="invalid">
                            رمز عبور باید بین 8 تا 24 حرف باشد
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupRole">
                          <Form.Label>سطح دسترسی</Form.Label>
                          <Form.Control
                            as="select"
                            name="newUser_permissionLevel"
                            value={newUser_permissionLevel}
                            onChange={changeData}
                            required
                            defaultValue=""
                          >
                            <option></option>
                            {allPermissionLevel.map(item => {
                              return <option value={item._id}>{item.name}</option>;
                            })}
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">یک مورد انتخاب نمایید</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupRole">
                          <Form.Label>سمت</Form.Label>
                          <Form.Control
                            as="select"
                            name="newUser_position"
                            onChange={e => setUserPosition(e.target.value)}
                            value={userPosition}
                          >
                            <option value=""></option>
                            {allJobPosition.map(jobPosition => {
                              return <option value={jobPosition._id}>{jobPosition.name}</option>;
                            })}
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">یک مورد انتخاب نمایید</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formGroupEmail">
                          <Form.Label>نام کاربری</Form.Label>
                          <Form.Control
                            type="text"
                            name="newUser_username"
                            value={newUser_username}
                            onChange={changeData}
                            required
                            placeholder="نام کاربری"
                          />
                          <Form.Control.Feedback type="invalid">نام کاربری اجباری میباشد</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupPasswordConfirm">
                          <Form.Label>تکرار رمز عبور</Form.Label>
                          <Form.Control
                            type="password"
                            name="newUser_passwordConfirm"
                            value={newUser_passwordConfirm}
                            onChange={changeData}
                            minLength="8"
                            maxLength="24"
                            required
                            placeholder="تکرار رمز عبور"
                          />
                          <Form.Control.Feedback type="invalid">
                            تکرار رمز عبور باید مشابه رمز عبور باشد
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupEmail">
                          <Form.Label>ایمیل</Form.Label>
                          <Form.Control
                            type="email"
                            name="newUser_email"
                            value={newUser_email}
                            onChange={changeData}
                            placeholder="ایمیل"
                          />
                          <Form.Control.Feedback type="invalid">
                            ایمیل را به فرمت درست وارد نمایید
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupName">
                          <Form.Label>کد پرسنلی</Form.Label>
                          <Form.Control
                            required
                            name="newUser_personalCode"
                            value={newUser_personalCode}
                            onChange={changeData}
                            type="text"
                            placeholder="کد پرسنلی"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group controlId="formGroupName">
                          <Form.Label> عکس پروفایل</Form.Label>
                          <Form.File id="file" onChange={changeFile} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <button className="btn btn-primary mr-1 mb-1 waves-effect waves-light" type="submit">
                      ایجاد کاربر
                    </button>
                    <div className="btn btn-light mr-1 mb-1 waves-effect waves-light" onClick={handleClose}>
                      انصراف
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>

              {/* Edit User */}
              <Modal show={editUsershow} onHide={handleEditClose}>
                <Modal.Header closeButton>
                  <Modal.Title>ویرایش کاربر </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form noValidate validated={validated} onSubmit={HandleEditUser}>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formGroupName">
                          <Form.Label>نام و نام خانوادگی</Form.Label>
                          <Form.Control
                            required
                            name="newUser_name"
                            onChange={changeData}
                            type="text"
                            placeholder={itemSelected.name}
                            value={newUser_name}
                          />
                          <Form.Control.Feedback type="invalid">نام اجباری میباشد</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupRole">
                          <Form.Label>سطح دسترسی</Form.Label>
                          <Form.Control
                            as="select"
                            name="newUser_permissionLevel"
                            value={newUser_permissionLevel ? newUser_permissionLevel : itemSelected.permissionLevel}
                            onChange={changeData}
                            required
                            defaultValue=""
                          >
                            <option></option>
                            {allPermissionLevel.map(item => {
                              return <option value={item._id}>{item.name}</option>;
                            })}
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">یک مورد انتخاب نمایید</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupRole">
                          <Form.Label>سمت</Form.Label>
                          <Form.Control
                            as="select"
                            name="newUser_position"
                            onChange={e => setUserPosition(e.target.value)}
                            value={userPosition ? userPosition : itemSelected.position ? itemSelected.position._id : ''}
                          >
                            <option value=""></option>
                            {allJobPosition.map(jobPosition => {
                              return <option value={jobPosition._id}>{jobPosition.name}</option>;
                            })}
                          </Form.Control>

                          <Form.Control.Feedback type="invalid">یک مورد انتخاب نمایید</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formGroupEmail">
                          <Form.Label>ایمیل</Form.Label>
                          <Form.Control
                            type="email"
                            name="newUser_email"
                            value={newUser_email}
                            onChange={changeData}
                            placeholder={itemSelected.emailAddress}
                          />
                          <Form.Control.Feedback type="invalid">
                            ایمیل را به فرمت درست وارد نمایید
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formGroupName">
                          <Form.Label>کد پرسنلی</Form.Label>
                          <Form.Control
                            required
                            placeholder={itemSelected.personalCode}
                            name="newUser_personalCode"
                            value={newUser_personalCode}
                            onChange={changeData}
                            type="text"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Col md={6}>{newFile ? '' : <img src={itemSelected.photo} className="img-fluid" />}</Col>
                        <Form.Group controlId="formGroupName">
                          <Form.Label> عکس پروفایل</Form.Label>
                          <Form.File id="file" onChange={changeFile} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <button className="btn btn-primary mr-1 mb-1 waves-effect waves-light" type="submit">
                      دخیره
                    </button>
                    <div className="btn btn-light mr-1 mb-1 waves-effect waves-light" onClick={handleEditClose}>
                      انصراف
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
              {/* End User Edit */}
            </Col>
            <Col md={{ span: 3, offset: 3 }}>
              <Form className="row mr-2">
                <Form.Label className="col-md-8 pt-1 text-left">ردیف در صفحه</Form.Label>
                <Form.Control
                  className="col-md-4"
                  as="select"
                  name=""
                  value={paginationPageSize}
                  onChange={setGridPageSize}
                  required
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                </Form.Control>
              </Form>
            </Col>
          </Row>
          <Datagrid
            gridColumn={userGridColumn}
            rowData={allUser}
            setGridApi={setGridApi}
            paginationPageSize={paginationPageSize}
            onSelectionChanged={onSelectionChanged}
            onClickItems={RowRendered}
          />
        </div>
      </Col>
    </Container>
  );
};

export default Users;

import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../Axios/Axios';
import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import RightNav from '../../../components/UI/RightNav/RightNav';
const ISODocuments = props => {
  const [renderReport, setRenderReport] = useState(true);
  const [allUnits, SetAllUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  const [righNav, setRighNav] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [resetSetSelectedRows,setResetSetSelectedRows] = useState(false);
  const [resetFrom, setResetFrom] = useState(false);

  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (renderReport) {
      Axios.get('/api/v1/unit/', config).then(units => {
        SetAllUnits(units.data.data.data);
        setRenderReport(false);
      });
      Axios.get('/api/v1/users/me', config).then(user => {
        setUserInfo(user.data.data.data);
      });
      if (resetFrom) {
        props.ResetCurrentForm(resetFrom);
        setResetFrom(false);
      }

    }
  });

  const handleCallbackRightNav = async selectedRow => {
    setRighNav(selectedRow);
    console.log(selectedRow)
    // console.log(selectedRow);
    const body = JSON.stringify({
      kind: 'getYears',
      nav: selectedRow
    });
  };

  const AddReport = e => {
    e.preventDefault();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const reportNewItem = JSON.stringify({
      name: props.reportName,
      description: props.reportDesc,
      createdBy: userInfo._id,
      reportPage: props.selectedReport,
      // nav: righNav
    });
    if (props.reportName && props.reportDesc) {
      if (props.selectedReport.length) {
        Axios.post(`/api/v1/ReportDefinition/`, reportNewItem, config).then(note => {
          toast.success('گزارش شما ایجاد شد', { autoClose: 3000 });
          setRenderReport(true);
          setResetFrom(true);
          setIsRenderNavbar(true);

          // formAddReportRef.current.reset();
        });
      } else {
        toast.error('لطفا نوع گزارش را انتخاب نمایید', { autoClose: 3000 });
      }
    } else {
      toast.error('همه فیلدها را با دقت پرنمایید.', { autoClose: 3000 });
    }
  };
  return (
    <div className="row">
      
      <Form.Group className="savebtn">
        <Button variant="primary " onClick={AddReport}>
          ذخیره
        </Button>
      </Form.Group>
    </div>
  );
};

export default ISODocuments;


import Axios from '../../../../Axios/Axios';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { me } from '../../../../actions/auth';

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';

const ImageModal = props => {
  const [allPics, setAllPics] = useState(null);
  const [mounted, setMounted] = useState(true);
  const [loading, setLoading] = useState(true);

  //Change with existing
  const changeWithExisting = e => {
    setLoading(true);
    const photoUrl = e.target.src.replace('http://localhost:3000', '');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    const body = JSON.stringify({
      photo: photoUrl
    });
    Axios.patch('/api/v1/users/updateMe', body, config).then(async res => {
      await props.me();
      setLoading(false);
      props.setShow(false);
    });
  };
  //Change with existing END
  useEffect(() => {
    //get all profile pic
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    Axios.get('/api/v1/file/my', config).then(items => {
      if (mounted) {
        setAllPics(items.data.data.data);
        setMounted(false);
        setLoading(false);
      }
    });
    //get all profile pic End
  });

  //upload New Pic
  let [newFile, setFile] = useState(null);
  const changeFile = e => {
    setFile(e.target.files[0]);
  };
  const uploadFile = e => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('title', newFile.name);
    formData.append('isProfile', true);
    formData.append('readOnly', true);
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    Axios.post('/api/v1/file', formData, config).then(res => {
      const photoUrl = res.data.data.path.replace('/client/public', '');
      const body = JSON.stringify({
        photo: photoUrl
      });
      Axios.patch('/api/v1/users/updateMe', body, config).then(async res => {
        await props.me();
        props.setShow(false);
        setLoading(false);
      });
    });
  };
  //upload New Pic End

  let pics = null;
  if (allPics) {
    pics = allPics.map(Pic => {
      if (Pic.isProfile) {
        return (
          <Col md="3">
            <Image
              src={Pic.path.replace('/client/public', '')}
              onClick={changeWithExisting}
              thumbnail
            />
          </Col>
        );
      }
      else {
        return (<Col md="3"></Col>)
      }
    });
  }
  if (!loading) {
    return (
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>تغییر عکس پروفایل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Form inline>
                <Form.Group>
                  <Form.File
                    id="NewPicFile"
                    label="برای بارگذاری عکس پروفایل جدید"
                    onChange={changeFile}
                  />
                </Form.Group>
                <Form.Group>
                  <Button variant="outline-info" onClick={uploadFile}>
                    بارگذاری
                  </Button>
                </Form.Group>
              </Form>
            </Row>
            <Row>{pics}</Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
  if (loading) {
    return (
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>تغییر عکس پروفایل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <Spinner
              animation="grow"
              variant="success"
              className={'spinnerChart'}
            />
          </center>
        </Modal.Body>
      </Modal>
    );
  }
};

export default connect(null, { me })(ImageModal);

import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

const ChartMeterList = props => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  defaults.normalized = true;
  defaults.font.size = 11;
  defaults.font.family = 'Iranian Sans';
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  const option = {
    barPercentage: 0.15,
    plugins: {
      legend: {
        position: 'bottom'
      },
      title: {
        display: true,
        text: 'نمودار مصرف کنتورها',
        padding: {
          bottom: 40
        },
        font: {
          size: 18
        }
      }
    },
    scales: {
      y: {
        grid: {
          display: false
        },
        title: {
          display: true,
          text: props.dataset[0].meter.unit.unit
        },
        beginAtZero: true
      },
      x: {
        grid: {
          display: false,
          offset: true,
          
        }
      }
    },
    maintainAspectRatio: true,
    responsive: true
  };
  const data = {
    datasets: [
      {
        backgroundColor: '#fbad1e',
        borderColor: '#fbad1e',
        data: props.dataset.map(el => {
          return { x: el.meter.name, y: el.total };
        })
      }
    ]
  };

  return <Bar data={data} options={option} redraw={true}/>;
};
export default ChartMeterList;

import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

const ChartCompareSeason = props => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const [lastSeason, setLastSeason] = useState('');
  defaults.normalized = true;
  defaults.font.size = 11;
  defaults.font.family = 'Iranian Sans';
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;
  useEffect(() => {
    switch (props.reportSeason) {
      case 'بهار':
        setLastSeason('زمستان');
        break;
      case 'تابستان':
        setLastSeason('بهار');
        break;
      case 'پاییز':
        setLastSeason('تابستان');
        break;
      case 'زمستان':
        setLastSeason('پاییز');
        break;
      default:
        break;
    }
  });

  const option = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true
      },
      title: {
        display: true,
        text: 'نمودار مقایسه ای فصلی',
        padding: {
          bottom: 40
        },
        font: {
          size: 18
        }
      }
    },

    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: props.dataset.currentSeason[0].unit
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'ریال'
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      },
      x: {
        grid: {
          display: true
        }
      }
    }
  };
  const currentSeasonLabel = props.dataset.currentSeason.length > 0 ? props.reportSeason + ' ' + props.reportYear : '';
  const prevSeasonLabel = props.dataset.prevSeason.length > 0 ? lastSeason + ' ' + props.dataset.prevSeason[0].year : '';
  const prevYearSeasonLabel =
    props.dataset.prevYearSeason.length > 0
      ? props.reportSeason + ' ' + props.dataset.prevYearSeason[0].year 
      : '';
  const data = {
    datasets: [
      {
        label: 'مصرف',
        yAxisID: 'y',
        backgroundColor: '#fbad1e',
        borderColor: '#fbad1e',
        data: [
          props.dataset.currentSeason.length > 0 ? props.dataset.currentSeason[0].total : '',
          props.dataset.prevSeason.length > 0 ? props.dataset.prevSeason[0].total : '',
          props.dataset.prevYearSeason.length > 0 ? props.dataset.prevYearSeason[0].total : ''
        ]
      },
      {
        label: 'هزینه',
        yAxisID: 'y1',
        backgroundColor: '#305dfa',
        borderColor: '#305dfa',
        data: [
          props.dataset.currentSeason.length > 0 ? props.dataset.currentSeason[0].cost : '',
          props.dataset.prevSeason.length > 0 ? props.dataset.prevSeason[0].cost : '',
          props.dataset.prevYearSeason.length > 0 ? props.dataset.prevYearSeason[0].cost : ''
        ]
      }
    ],

    labels: [currentSeasonLabel, prevSeasonLabel, prevYearSeasonLabel]
  };

  return <Bar data={data} options={option} />;
};
export default ChartCompareSeason;

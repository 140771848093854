import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import TopMenu from '../../UI/TopMenu/TopMenu';
import Header from '../../UI/Header/Header';

import { Toast } from '../../UI/Toast/Toast';

export const PrivateRouteWithMaster = ({ component: Component, ...rest }) => {
  const [permission, setPermission] = useState(true);
  return (
    <div className="underRoot">
      <Header />
      <TopMenu setPermission={setPermission} />
      {/* <!-- BEGIN: Content--> */}
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <Toast />
          </div>
          <Route
            {...rest}
            render={props =>
              localStorage.getItem('jwt') && localStorage.getItem('user') ? (
                permission ? (
                  <Component {...props} />
                ) : (
                  <Redirect to="/403" />
                )
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        </div>
      </div>
      {/* <!-- END: Content--> */}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import Axios from '../../Axios/Axios';

import { Row, Container, Col, Button, Table, Form, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './Review.css';

const ReviewBuilding = props => {
  const [nav, setNav] = useState({ navID: '', navType: '', navName: '' });
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  const [needLoad, setNeedLoad] = useState(true);
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [showCanvasLeft, setShowCanvasLeft] = useState(true);
  const [mainPad, setMainPad] = useState('');
  const [buildingNav, setBuildingNav] = useState([]);
  const [numberOfBuilding, setNumberOfBuilding] = useState(0);
  const [activeStep, setActiveStep] = useState(1);
  const [allUnits, SetAllUnits] = useState([]);
  const [renderReport, setRenderReport] = useState(true);
  const [selectedUnits, setSelectedUnits] = useState('');
  const [reviewYear, setReviewYear] = useState('');
  const [currentUnit, setCurrentUnit] = useState('');
  const [reviewType, setReviewType] = useState(true);
  const [reviewFixNumber, setReviewFixNumber] = useState(0);
  const [selectedBuilding, SetSelectedBuilding] = useState([]);
  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (renderReport) {
      setBuildingNav(props.setBuildingNav);
      console.log(props.setBuildingNav);
      Axios.get('/api/v1/unit/', config).then(units => {
        SetAllUnits(units.data.data.data);
      });
      setRenderReport(false);
    }
  }, [props.setBuildingNav]);
  const handleShowAllType = () => {
    props.setShowAllTypes(true);
    props.setShowBuildingType(false);
    SetSelectedBuilding([]);
  };
  const createReport = () => {
    props.setShowAllTypes(true);
    props.setShowBuildingType(false);
    SetSelectedBuilding([]);
  };

  const SetStepTwo = () => {
    if (selectedUnits && reviewYear) {
      setActiveStep(2);
    } else {
      toast.error('فیلد سال و حامل نمیتوانند خالی باشند');
    }
  };
  const ToggleReviewType = () => {
    setReviewType(reviewType ? false : true);
  };
  const BuildingReviewReport = () => {
    // console.log(selectedBuilding)

    const Report = JSON.stringify({
      nav: buildingNav,
      unit: selectedUnits,
      year: reviewYear,
      kind: 'ساختمان'
    });

    Axios.post(`/api/v1/consumption/review/`, Report, config).then(report => {
      // console.log( report.data.data.data.data)
      // console.log( report)
      if (report.data.data.data.data.length > 0) {
        const tempArr = [];
        let i = 0;
        // پرتو
        if (reviewType) {
          console.log('pareto');
            setActiveStep(3);
            setLoading(true);
            let sumPercent = 0;
            report.data.data.data.data.forEach(element => {
                 sumPercent = element[0].percent + sumPercent;
                console.log("sumPercent");
                console.log(sumPercent);
                if(sumPercent < 80){
                  console.log("yes")
                  tempArr.push({
                    buildingName: element[0]._id,
                    buildingCost: element[0].cost,
                    buildingTotal: element[0].total,
                    buildingPercent: element[0].percent
                  });
                }else
                {
                  console.log("no")
                }
            });
            SetSelectedBuilding(tempArr);
            setLoading(false);
            console.log('tempArr');
            console.log(tempArr);
          
          // عدد ثابت
        } else {
          console.log('fixed');
          if (reviewFixNumber) {
            setActiveStep(3);
            setLoading(true);
            report.data.data.data.data.forEach(element => {
              if (element[0].total > reviewFixNumber) {
                console.log(element[0].total);
                tempArr.push({
                  buildingName: element[0]._id,
                  buildingCost: element[0].cost,
                  buildingTotal: element[0].total,
                  buildingPercent: element[0].percent
                });
              }
              SetSelectedBuilding(tempArr);
            });
            setLoading(false);
          } else {
            setActiveStep(2);
            toast.error('لطفا عدد ثابت را وارد نمایید');
          }
        }
      } else {
        Axios.get('/api/v1/unit/' + selectedUnits, config).then(units => {
          var str = "مقداری برای حامل ' " + units.data.data.data.name + "'  وارد نشده است.";
          toast.error(str);
        });
      }
    });
  };
  const Print = () => {
    console.log('print');
    //   let printContents = document.getElementById('printablediv').innerHTML;
    //   let originalContents = document.body.innerHTML;
    //   document.body.innerHTML = printContents;
    //   window.print();
    //  document.body.innerHTML = originalContents;
    // window.close();
    return true;
    //  var print_div = document.getElementById("printablediv");
    //  var print_area = window.open();
    //  print_area.document.write(print_div.innerHTML);
    //  print_area.document.close();
    //  print_area.focus();
    //  print_area.print();
    //  print_area.close();
  };
  let tab;
  if (activeStep === 1) {
    tab = (
      <div>
        <form hidden={activeStep !== 1}>
          <h2>1- ورود اطلاعات</h2>
          <Col md={{ span: 4, offset: 4 }} className="mb-3 mt-3">
            <Form.Group as={Row} className="mb-3" controlId="ReviewYear">
              <Form.Label column sm="3">
                سال
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="input"
                  value={reviewYear ? reviewYear : ' '}
                  onChange={e => setReviewYear(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="ReviewYear">
              <Form.Label column sm="3">
                حامل
              </Form.Label>
              <Col sm="9">
                <InputGroup className="mb-1">
                  <select className={'form-control'} onChange={e => setSelectedUnits(e.target.value)}>
                    '<option>حامل را انتخاب نمایید</option>'
                    {allUnits.map(unit => {
                      return (
                        <option value={unit._id}>
                          {unit.name} ({unit.unit})
                        </option>
                      );
                    })}
                  </select>
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
          <Col md={{ span: 4, offset: 4 }} className="mt-5">
            <Button className="float-left" variant="secondary" onClick={handleShowAllType}>
              {' '}
              مرحله قبل
            </Button>
            <Button
              className="float-right"
              onClick={() => {
                SetStepTwo();
              }}
            >
              مرحله بعد
            </Button>
          </Col>
        </form>
        <div class="clearfix"></div>
        <Col md={{ span: 6, offset: 3 }} className="mb-3 mt-5">
          <Table striped bordered hover className="text-center">
            <thead>
              <tr className="greenHeader">
                <p>ساختمان های انتخاب شده</p>
              </tr>
            </thead>
            <tbody>
              {buildingNav
                ? buildingNav.map(el => {
                    return (
                      <tr>
                        <td>{el.navName}</td>
                      </tr>
                    );
                  })
                : ''}
            </tbody>
          </Table>
        </Col>
      </div>
    );
  } else if (activeStep === 2) {
    tab = (
      <form hidden={activeStep !== 2}>
        <h2>2- روش بازنگری</h2>

        <Col md={{ span: 4, offset: 4 }} className="mb-3 mt-3">
          <Row className="toggleTrend mt-2 mb-2">
            <Col md={{ span: 4, offset: 2 }} className="pr-0 pl-0 mb-1 text-right">
              {reviewType ? 'پرتو' : 'عدد ثابت '}
            </Col>
            <Col md={{ span: 4 }} className="text-left">
              <Form.Check id="switchEnabled" type="switch" label=" " checked={reviewType} onChange={ToggleReviewType} />
            </Col>
          </Row>
          {reviewType ? (
            <div className="">در این روش از قانون پرتو استفاده میشود</div>
          ) : (
            <div>
              <p className="mb3">در این روش از عدد ثابت استفده میشود</p>
              <Form.Group as={Row} className="mb-3" controlId="ReviewYear">
                <div className="clearfix"></div>
                <Form.Label column sm="3">
                  عدد
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="number"
                    value={reviewFixNumber ? reviewFixNumber : ''}
                    onChange={e => setReviewFixNumber(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
        </Col>
        <Col md={{ span: 4, offset: 4 }} className="mt-5">
          <Button
            className="float-left"
            variant="secondary"
            onClick={() => {
              setActiveStep(1);
              SetSelectedBuilding([]);
            }}
          >
            {' '}
            مرحله قبل
          </Button>
          <Button
            className="float-right"
            onClick={() => {
              BuildingReviewReport();
            }}
          >
            مرحله بعد
          </Button>
        </Col>
      </form>
    );
  } else if (activeStep === 3) {
    tab = (
      <div>
        <center hidden={!loading}>
          <Spinner animation="grow" variant="success" className={'spinnerChart'} />
        </center>
        <Col hidden={loading} md={{ span: 12 }} className="mt-1 mb-5">
          <Button className="printIcon" onClick={Print}>
            <i class="fa fa-print"></i>
          </Button>

          <Button className="float-left" onClick={createReport}>
            {' '}
            گزارش جدید
          </Button>
        </Col>
        <div className="clearfix"></div>
        <Col hidden={loading} md={{ span: 6, offset: 3 }} className="mt-5" id="printablediv">
          {/* {console.log("selectedBuilding")}
        {console.log(selectedBuilding)} */}

          {selectedBuilding && selectedBuilding.length ? (
            <Table striped hover>
              <thead>
                <tr className="greenHeader">
                  <th>نام ساختمان | سایت </th>
                  <th>مصرف (واحد)</th>
                  <th>هزینه ( واحد)</th>
                  <th>درصد مصرف</th>
                </tr>
              </thead>
              <tbody>
                {selectedBuilding.map(building => {
                  return (
                    <tr>
                      <td>{building.buildingName}</td>
                      <td> {building.buildingTotal.toFixed(2)}</td>
                      <td> {building.buildingCost.toFixed(2)}</td>
                      <td> {building.buildingPercent.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p>ساختمانی بارز نشده است</p>
          )}
          <div className="clearfix"></div>
          <Col md={{ span: 8, offset: 2 }} className="mt-5">
            <Button
              className="float-left"
              variant="secondary"
              onClick={() => {
                setActiveStep(2);
                SetSelectedBuilding([]);
              }}
            >
              {' '}
              مرحله قبل
            </Button>
            <Button
              className="float-right"
              onClick={() => {
                BuildingReviewReport();
              }}
            >
              پرینت گزارش
            </Button>
          </Col>
        </Col>
      </div>
    );
  }

  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-2') : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <Row className={`trendSteps ${activeStep === 1 ? 'activeStep' : ''}`}>
              <div>
                <span className="trendStepsNumber">1</span>
                <span> ورود اطلاعات</span>
              </div>
            </Row>
            <Row className={`trendSteps ${activeStep === 2 ? 'activeStep' : ''}`}>
              <div>
                <span className="trendStepsNumber">2</span>
                <span>روش بازنگری </span>
              </div>
            </Row>
            <Row className={`trendSteps ${activeStep === 3 ? 'activeStep' : ''}`}>
              <div>
                <span className="trendStepsNumber">3</span>
                <span> گزارش بازنگری</span>
              </div>
            </Row>
          </div>
        </Col>
        <Col className="mainContent pr-0">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-0') : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <div
              hidden={showCanvasLeft}
              class="CanvasIcon CanvasLeft"
              onClick={() => {
                setCanvasWidthLeft(3);
                setShowCanvasLeft(true);

                canvasWidthRight == 3 ? setMainPad('pl-0 pr-0') : setMainPad('pl-2 pr-0');
              }}
            >
              <i className="fa fa-caret-right  "></i>
            </div>
            <div>
              <div className="mb-1">
                <Container fluid>{tab}</Container>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ReviewBuilding;

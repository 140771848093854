import React, { useState, useEffect } from 'react';
import RightNav from '../../components/UI/RightNav/RightNav';
import { Row, Container, Col, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';

import './ReviewTypes.css';

const ReviewTypes = props => {
  const [nav, setNav] = useState({ navID: '', navType: '', navName: '' });
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  const [needLoad, setNeedLoad] = useState(true);
  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [showCanvasLeft, setShowCanvasLeft] = useState(true);
  const [mainPad, setMainPad] = useState('');
  const [buildingNav, setBuildingNav] = useState([]);
  const [numberOfBuilding, setNumberOfBuilding] = useState(0);
  // const [showBuildingType, setShowBuildingType] = useState(false);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    // if (needLoad && nav.navID) {
    //   setNeedLoad(false);
    //   Axios.get('/api/v1/MVProject?building=' + nav.navID, config).then(res => {
    //     SetAllMVProjects(res.data.data.data);
    //   });
    // }
  });
  const handleCallback = selectedRow => {
    setNumberOfBuilding(selectedRow.length);
    if (selectedRow.length > 0) {
      setBuildingNav(selectedRow);
      setNav({ navID: selectedRow[0].navID, navType: selectedRow[0].navType, navName: selectedRow[0].navName });
      setNeedLoad(true);
    }
  };
  const handleShowBuildingType = () => {
    props.setShowBuildingType(true);
    // console.log(buildingNav)
    props.setBuildingNav(buildingNav);
  };

  const handleShowEquipmentType = () => {
    props.setShowEquipmentType(true);
    props.setBuildingNav(buildingNav)
  };

  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-2') : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <RightNav
              setIsRenderNavbar={setIsRenderNavbar}
              isRenderNavbar={isRenderNavbar}
              parentCallback={handleCallback}
              type={'multi-select-building'}
            ></RightNav>
          </div>
        </Col>
        <Col className="mainContent pr-0">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-0') : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <div
              hidden={showCanvasLeft}
              class="CanvasIcon CanvasLeft"
              onClick={() => {
                setCanvasWidthLeft(3);
                setShowCanvasLeft(true);

                canvasWidthRight == 3 ? setMainPad('pl-0 pr-0') : setMainPad('pl-2 pr-0');
              }}
            >
              <i className="fa fa-caret-right  "></i>
            </div>
            <div>
              <div className="mb-1">
                <Row className="mt-3">
                  <Col lg="12" className="text-center mb-3">
                    <p>
                      پس از انتخاب ساختمان مورد نظر از منوی سمت راست، روش مورد نظر خود را برای بازنگری انتخاب نمایید.
                    </p>
                  </Col>
                  <Col lg="4" className="text-center">
                    <Button
                      className="min-wid-50"
                      onClick={handleShowBuildingType}
                      disabled={numberOfBuilding <= 1 || numberOfBuilding === 0}
                    >
                      ساختمان / سایت
                    </Button>
                  </Col>
                  <Col lg="4" className="text-center">
                    <Button
                      className="min-wid-50"
                      onClick={handleShowEquipmentType}
                      disabled={numberOfBuilding > 1 || numberOfBuilding === 0}
                    >
                      تجهیز
                    </Button>
                  </Col>
                  <Col lg="4" className="text-center">
                    <Button className="min-wid-50" disabled={numberOfBuilding > 1 || numberOfBuilding === 0}>
                      کاربری
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ReviewTypes;

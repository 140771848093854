import React, { useState, useEffect } from 'react';

import './BaselinePeriod.css';
import BaselinePeriodChart from './BaselinePeriodChart/BaselinePeriodChart';

import { Button, Modal, InputGroup, FormControl, Row, Tab, Tabs, Col } from 'react-bootstrap';
import DatePicker from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import Axios from '../../../../Axios/Axios';
import moment from 'jalali-moment';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const BaselinePeriod = props => {
  const [needLoadMeter, setNeedLoadMeter] = useState(true);
  const [showLink, setShowLink] = useState(false);
  const [allMeter, setAllMeter] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [unitCost, setUnitCost] = useState(props.MVProject.unitCost);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [baselineDescription, setBaselineDescription] = useState(() => {
    if (!props.MVProject.baselineDescription) {
      return null;
    }
    const contentState = convertFromRaw(JSON.parse(props.MVProject.baselineDescription));
    return EditorState.createWithContent(contentState);
  });

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const editorToolBar = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'image',
      'remove',
      'history'
    ],
    fontFamily: {
      options: ['Arial', 'Tahoma', 'Times New Roman', 'Iranian Sans'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined
    }
  };

  const syncData = async (startVal, endVal) => {
    const rawBaselineDescription = baselineDescription
      ? JSON.stringify(convertToRaw(baselineDescription.getCurrentContent()))
      : null;
    const bodyUpdate = JSON.stringify({
      meter: selectedRows[0].navID,
      baselineDescription: rawBaselineDescription,
      baselinePeriodFrom: startVal
        ? moment.from(`${startVal.year}-${startVal.month}-${startVal.day}`, 'fa', 'YYYY-M-D').format('YYYY-MM-DD')
        : null,
      baselinePeriodTo: endVal
        ? moment.from(`${endVal.year}-${endVal.month}-${endVal.day}`, 'fa', 'YYYY-M-D').format('YYYY-MM-DD')
        : null,
      unitCost: unitCost
    });
    const updatedMVP = await Axios.patch('/api/v1/MVProject/' + props.MVProject._id, bodyUpdate, config);

    props.setMVProject(updatedMVP.data.data.data);
    if (!selectedRows || !startVal || !endVal) return 0;
    const body = {
      kind: 'range',
      fromDate: `${startVal.year}-${startVal.month.toString().length > 1 ? startVal.month : '0' + startVal.month}-${
        startVal.day.toString().length > 1 ? startVal.day : '0' + startVal.day
      }`,
      toDate: `${endVal.year}-${endVal.month.toString().length > 1 ? endVal.month : '0' + endVal.month}-${
        endVal.day.toString().length > 1 ? endVal.day : '0' + endVal.day
      }`,
      nav: selectedRows
    };
    Axios.post(`/api/v1/consumption/gstats`, body, config).then(res => {
      if (res.data.data.data.length > 0) {
        if (res.data.data.data[0].length > 0) {
          setChartData(res.data.data.data[0][0].AggregateData);
        }
      }
    });
  };

  const SendItemInfo = (NavID, NavType, NavName) => {
    setSelectedRows([{ navID: NavID, navType: NavType, navName: NavName }]);
  };
  const handleCloseLink = () => {
    setShowLink(false);
  };

  useEffect(() => {
    if (needLoadMeter) {
      setNeedLoadMeter(false);
      if (props.MVProject.meter) {
        setSelectedRows([{ navID: props.MVProject.meter._id, navType: 'Meter', navName: props.MVProject.meter.name }]);
      }
      if (props.MVProject.baselinePeriodFrom) {
        const baseFrom = moment(props.MVProject.baselinePeriodFrom, 'YYYY-MM-DD')
          .locale('fa')
          .format('YYYY/M/D')
          .split('/');
        setStart({ year: baseFrom[0], month: baseFrom[1], day: baseFrom[2] });
      }
      if (props.MVProject.baselinePeriodTo) {
        const baseTo = moment(props.MVProject.baselinePeriodTo, 'YYYY-MM-DD')
          .locale('fa')
          .format('YYYY/M/D')
          .split('/');
        setEnd({ year: baseTo[0], month: baseTo[1], day: baseTo[2] });
      }
      if (props.MVProject.meter && props.MVProject.baselinePeriodFrom && props.MVProject.baselinePeriodTo) {
        const baseFrom = moment(props.MVProject.baselinePeriodFrom, 'YYYY-MM-DD')
          .locale('fa')
          .format('YYYY-MM-DD');

        const baseTo = moment(props.MVProject.baselinePeriodTo, 'YYYY-MM-DD')
          .locale('fa')
          .format('YYYY-MM-DD');

        const body = {
          kind: 'range',
          fromDate: baseFrom,
          toDate: baseTo,
          nav: [{ navID: props.MVProject.meter._id, navType: 'Meter', navName: props.MVProject.meter.name }]
        };
        Axios.post(`/api/v1/consumption/gstats`, body, config).then(res => {
          if (res.data.data.data.length > 0) {
            if (res.data.data.data[0].length > 0) {
              setChartData(res.data.data.data[0][0].AggregateData);
            }
          }
        });
      }
      Axios.get('/api/v1/meter?building=' + props.MVProject.building._id, config).then(meter => {
        setAllMeter(meter.data.data.data);
      });
    }
  });
  return (
    <form hidden={props.activeStep !== 3}>
      <h2>3-دوره زمانی مبنا</h2>
      <span>ابتدا کنتور مصرف انرژی انتخاب و سپس بازه زمانی خط مبنا انتخاب شود.</span>
      <Row className="m-2">
        <Col md="3" className="mt-2">
          <span className="mr-1 font-weight-bolder"> لینک اطلاعات</span>
          <span>
            {props.MVProject.meter && selectedRows.length > 0 ? (
              <span>{`${selectedRows[0].navName} ${
                props.MVProject.meter.unit ? `(${props.MVProject.meter.unit.unit})` : ''
              }`}</span>
            ) : (
              ''
            )}
          </span>
          <Button
            variant="success"
            className="ml-1"
            onClick={() => {
              setShowLink(true);
            }}
          >
            لینک
          </Button>
          <Modal show={showLink} onHide={handleCloseLink}>
            <Modal.Header closeButton>
              <Modal.Title>لینک کنتور یا متغیر</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="list-group list-group-flush" id="allMeterTree">
                <ul className="nav flex-column flex-nowrap overflow-hidden">
                  {allMeter.map(meter => {
                    if (meter.hasChild == true) {
                      return (
                        <li className="nav-item">
                          <div
                            className={`Navbox pl-2 ${
                              selectedRows.some(SelectedRow => SelectedRow.navID === meter._id)
                                ? 'SelectedItem'
                                : 'SimpleItem'
                            }`}
                            onClick={e => SendItemInfo(meter._id, 'Meter', meter.name)}
                          >
                            <a
                              className="nav-link  text-truncate collapsed"
                              href={'#meter' + meter._id}
                              data-toggle="collapse"
                              data-target={'#meter' + meter._id}
                            ></a>
                            <span className="navClick">
                              <i
                                className={`fa ${meter.icon}`}
                                style={{
                                  color: meter.color
                                }}
                              ></i>
                              <span>{meter.name}</span>
                            </span>
                          </div>
                          {allMeter.map(Secmeter => {
                            if (Secmeter.parentMeter) {
                              if (
                                Secmeter.hasChild == false &&
                                Secmeter._id !== meter._id &&
                                Secmeter.parentMeter._id === meter._id
                              ) {
                                return (
                                  <div className="collapse show" id={'meter' + meter._id} aria-expanded="false">
                                    <ul className="flex-column nav">
                                      <li className="nav-item ">
                                        <div
                                          className={`Navbox pl-2 ${
                                            selectedRows.some(SelectedRow => SelectedRow.navID === Secmeter._id)
                                              ? 'SelectedItem'
                                              : 'SimpleItem'
                                          }`}
                                          onClick={e => SendItemInfo(Secmeter._id, 'Meter', Secmeter.name)}
                                        >
                                          <a
                                            className="nav-link  text-truncate collapsed"
                                            href={'#meter' + Secmeter._id}
                                            data-target={'#meter' + Secmeter._id}
                                          ></a>
                                          <span className="navClick">
                                            <i
                                              className={`  pl-1 fa ${Secmeter.icon}`}
                                              style={{
                                                color: Secmeter.color
                                              }}
                                            ></i>
                                            <span>{Secmeter.name}</span>
                                          </span>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                );
                              }
                            }
                          })}
                        </li>
                      );
                    }
                    if (meter.hasChild == false) {
                      if (!meter.parentMeter) {
                        return (
                          <li className="nav-item">
                            <div
                              className={`Navbox  ${
                                selectedRows.some(SelectedRow => SelectedRow.navID === meter._id)
                                  ? 'SelectedItem'
                                  : 'SimpleItem'
                              }`}
                              onClick={e => SendItemInfo(meter._id, 'Meter', meter.name)}
                            >
                              <a
                                className="nav-link  text-truncate collapsed"
                                href={'#meter' + meter._id}
                                data-target={'#meter' + meter._id}
                              ></a>
                              <i className={`  pl-1 fa ${meter.icon}`} style={{ color: meter.color }}></i>
                              <span>{meter.name}</span>
                            </div>
                          </li>
                        );
                      }
                    }
                  })}
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="mr-3 mt-2"
                onClick={() => {
                  syncData(start, end);
                  handleCloseLink();
                }}
              >
                انتخاب
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
        {props.MVProject.meter ? (
          <Col md="3" className="mt-2" onBlur={() => syncData(start, end)}>
            <span className="mr-1 font-weight-bolder">هزینه پایه انرژی (تومان)</span>
            <input
              value={unitCost}
              className="w-25"
              onChange={e => {
                setUnitCost(e.target.value);
              }}
            />
          </Col>
        ) : (
          ''
        )}

        <Col md="3" className="mt-2">
          <span className="mr-1">از تاریخ</span>
          <DatePicker
            //value={start}
            onChange={val => {
              setStart(val);
              syncData(val, end);
            }}
            inputPlaceholder={start ? `${start.year}-${start.month}-${start.day}` : 'تاریخ شروع دوره'}
            shouldHighlightWeekends
            locale="fa"
          />
        </Col>
        <Col md="3" className="mt-2">
          <span className="mr-1">تا تاریخ</span>
          <DatePicker
            // value={end}
            onChange={val => {
              setEnd(val);
              syncData(start, val);
            }}
            inputPlaceholder={end ? `${end.year}-${end.month}-${end.day}` : 'تاریخ پایان دوره'}
            shouldHighlightWeekends
            locale="fa"
          />
        </Col>
      </Row>
      <Row className="m-2">{chartData.length > 0 ? <BaselinePeriodChart dataset={chartData} /> : ''}</Row>
      <Row className="m-2">
        <Col md={{ span: 10, offset: 1 }} onBlur={() => syncData(start, end)}>
          <Editor
            editorState={baselineDescription}
            onEditorStateChange={val => {
              setBaselineDescription(val);
            }}
            toolbar={editorToolBar}
          />
        </Col>
      </Row>
    </form>
  );
};

export default BaselinePeriod;

import React, { Fragment } from 'react';

const CSVDataTable = (props) => {
  let uploadCSVHTML;
  let CSVUploaded = props.CSVData;
  if (CSVUploaded.length > 0) {
    uploadCSVHTML = (
      <table className="table mt-2 GreenTable" >
        <thead className="thead-dark">
          {CSVUploaded[0].map((col,index) => {
            return <th scope="col" key={index}>{col}</th>;
          })}
        </thead>
        <tbody>
          {CSVUploaded.slice(1).map((row,index) => {
            return (
              <tr key={index}>
                {row.map((col,index) => {
                  return <td key={index}>{col}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
  return <Fragment>{uploadCSVHTML}</Fragment>;
};

export default CSVDataTable;

import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

// import checkActive from './active/active';
import './TopMenu.css';

const TopMenu = props => {
  const [reload, setReload] = useState(true);
  const [selectTopMenu, setSelectTopMenu] = useState(0);
  const [allMenu, setAllMenu] = useState(() => {
    if (props.user) {
      if (props.user.permissionLevel) {
        return props.user.permissionLevel.modules;
      }
    }
    return [];
  });
  const [selectSubMenu, setSelectSubMenu] = useState(0);
  const changeTopMenu = val => {
    setSelectTopMenu(val);
    if (val === 0) {
      setSelectSubMenu(0);
    } else {
      setSelectSubMenu(0);
    }
  };
  const changeSubMenu = val => {
    setSelectSubMenu(val);
  };
  useEffect(() => {
    if (reload) {
      setReload(false);
      const location = window.location.pathname;
      let flagLocation = false;
      mainMenu.forEach((el, index) => {
        el.child.forEach((el, ind) => {
          if (el.link === location) {
            flagLocation = true;
            setSelectTopMenu(index);
            setSelectSubMenu(ind);
          }
        });
      });
      if (!flagLocation) {
        props.setPermission(false);
      }
    }
  }, [reload]);
  let mainMenu = [];
  allMenu.forEach(item => {
    if (!item.parent) {
      let tmpItem = { ...item, child: [] };
      allMenu.forEach(subItem => {
        if (subItem.parent === item._id) {
          tmpItem.child.push(subItem);
        }
      });
      mainMenu.push(tmpItem);
    }
  });
  mainMenu.forEach(item => {
    if (item.child.length > 0) {
      item.link = item.child[0].link;
    }
  });
  // const mainMenu = [
  //   {
  //     name: 'داشمبورد',
  //     link: '/PeriodicChart',
  //     icon: 'feather icon-bar-chart-2',
  //     child: [
  //       { name: 'آنالیز روند ', link: '/PeriodicChart', icon: 'far fa-bar-chart' },
  //       { name: 'معیار مصرف', link: '/EnergyLabel', icon: 'fa fa-leaf' },
  //       { name: 'خط مبنا', link: '/trend', icon: 'fontFaLine fa fa-line-chart' },
  //       {
  //         name: 'فرمولهای محاسباتی',
  //         link: '/FormulaBuilder',
  //         icon: 'fa fa-superscript fontFaLine '
  //       },
  //       { name: 'M & V', link: '/MVProjects', icon: 'fontFaLine fa fa-balance-scale' },
  //       { name: 'گزارش ها', link: '/Report', icon: 'far fa-file-alt' },
  //       {
  //         name: 'مصارف لحظه ای',
  //         link: '/Momentary',
  //         icon: 'fas fa-tachometer-alt'
  //       },
  //       { name: 'تقویم انرژی', link: '/Calendar', icon: 'far fa-calendar-alt' },
  //       { name: ' مستندات', link: '/OtherDocuments', icon: 'far fa-folder-open' },
  //       { name: 'ISO 50001', link: '/Documents', icon: ' fontFaLine fas fa-globe' },
  //       { name: 'شرح شغل', link: '/JobDescription', icon: 'fa fa-id-card fontFaLine' },
  //       { name: 'ماشین حساب', link: '/Calculator', icon: 'fontFaLine fas fa-calculator' }
  //     ]
  //   },
  //   {
  //     name: 'ورود اطلاعات',
  //     link: '/uploadMeterData',
  //     icon: 'fa fa-database',
  //     child: [
  //       {
  //         name: 'آپلود اطلاعات',
  //         link: '/uploadMeterData',
  //         icon: 'fa fa-upload'
  //       },
  //       { name: 'اطلاعات کنتورها', link: '/viewMeterData', icon: 'fa fa-eye' }
  //     ]
  //   },
  //   {
  //     name: 'تنظیمات',
  //     link: '/Organization',
  //     icon: 'feather icon-settings',
  //     child: [
  //       {
  //         name: 'ساختار سازمان ، ساختمانها و کنتورها',
  //         link: '/Organization',
  //         icon: 'fa fa-sitemap'
  //       },
  //       { name: 'اطلاعات کاربران', link: '/admin', icon: 'fa fa-users' },
  //       {
  //         name: 'داده های آب و هوا',
  //         link: '/weatherData',
  //         icon: 'fa fa-thermometer-empty'
  //       },
  //       {
  //         name: 'نوع سند',
  //         link: '/DocumentType',
  //         icon: 'fa fa-file-invoice'
  //       },
  //       {
  //         name: 'سمت های سازمانی',
  //         link: '/jobPosition',
  //         icon: 'fa fa-user-shield'
  //       }
  //     ]
  //   }
  // ];
  return (
    <Fragment>
      <div className="horizontal-menu-wrapper">
        <div
          // add class floating-nav to next line for old style
          className="header-navbar navbar-expand-sm navbar navbar-horizontal  navbar-light navbar-without-dd-arrow navbar-shadow menu-border"
          role="navigation"
          data-menu="menu-wrapper"
        >
          <div className="navbar-header ">
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item mr-auto">
                <a className="navbar-brand" href="../../../html/rtl/horizontal-menu-template/index.html">
                  <div className="brand-logo"></div>
                  <h2 className="brand-text mb-0">نرم افزار مدیریت انرژی</h2>
                </a>
              </li>
              <li className="nav-item nav-toggle">
                <a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                  <i className="feather icon-x d-block d-xl-none font-medium-4 primary toggle-icon"></i>
                  <i
                    className="toggle-icon feather icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon primary"
                    data-ticon="icon-disc"
                  ></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-container main-menu-content row" data-menu="menu-container">
            <ul className="nav navbar-nav col-md-4" id="main-menu-navigation" data-menu="menu-navigation">
              {mainMenu.map((el, index) => {
                return (
                  <li className={`dropdown nav-item ${selectTopMenu === index ? 'active' : ''}`} key={index}>
                    <Link to={el.link} className="nav-link" onClick={() => changeTopMenu(index)}>
                      <i className={el.icon}></i>
                      <span>{el.name}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
            <div className="nav navbar-nav col-md-8 left-navbar-submenu">
              {/* Submenu گزارشات */}
              <ul className="nav navbar-nav">
                {mainMenu.length > 0
                  ? mainMenu[selectTopMenu].child.map((el, index) => {
                      return (
                        <li
                          key={index}
                          className={`dropdown nav-item ${selectSubMenu === index ? 'subMenuActive' : ''}`}
                          // data-toggle="tooltip"
                          // data-placement="bottom"
                          // title={el.name}
                          // key={index}
                        >
                          <Link to={el.link} onClick={() => changeSubMenu(index)} className="nav-link MytoolTip">
                            <span className="tooltiptext">{el.name} </span>
                            <i className={el.icon}></i>
                          </Link>
                        </li>
                      );
                    })
                  : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps, {})(TopMenu);

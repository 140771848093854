import React, { useRef, useEffect } from 'react';
import { Chart, defaults } from 'chart.js';

const ProjectModelChart = props => {
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.scales.linear.beginAtZero = true;
  defaults.plugins.legend.display = false;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  const myRef = useRef();

  useEffect(() => {
    let chartTitle = 'Predict vs actual consumption';
    if (props.chartData.length > 0) {
      const tempChart = Chart.getChart(myRef.current);
      if (tempChart) {
        tempChart.destroy();
      }

      const datasets = [
        {
          type: 'line',
          backgroundColor: ['#fdba3a'],
          borderColor: 'rgba(255, 203, 105,0.9)',
          data: props.chartData,
          pointRadius: 5,
          fill: false,
          showLine: false
        }
      ];

      new Chart(myRef.current, {
        data: {
          datasets: datasets
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            y: {
              title: {
                display: true,
                text: 'Actual Consumption (' + props.unit + ')'
              },
              type: 'linear',
              display: true,
              position: 'left',
              grid: {
                display: true
              }
            },
            x: {
              title: {
                display: true,
                text: 'Predict Consumption (' + props.unit + ')'
              },
              type: 'linear',
              display: true,
              position: 'left',
              grid: {
                display: false
              }
            }
          }
        }
      });
    }
  }, [props.chartData]);
  return <canvas ref={myRef}></canvas>;
};

export default ProjectModelChart;

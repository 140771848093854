import { Login_Failed, Login_Success, Logout, Me } from '../actions/types';

const initalState = {
  token: localStorage.getItem('jwt'),
  user: JSON.parse(localStorage.getItem('user'))
};

export default function(state = initalState, action) {
  const { type, payload } = action;
  switch (type) {
    case Login_Success:
      localStorage.setItem('jwt', payload.token);
      localStorage.setItem('user', JSON.stringify(payload.data.user));
      return {
        ...state,
        token: payload.token,
        user: payload.data.user
      };
    case Login_Failed:
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');
      return {
        ...state,
        token: null,
        user: null
      };
    case Logout:
      localStorage.removeItem('user');
      localStorage.removeItem('jwt');
      return {
        ...state,
        token: null,
        user: null
      };
    case Me:
      localStorage.setItem('user', JSON.stringify(payload.data.data));
      return {
        ...state,
        user: payload.data.data
      };
    default:
      return state;
  }
}

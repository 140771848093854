import React, { useState } from 'react';

import Axios from '../../../Axios/Axios';
import { Row, Container, Col, InputGroup, FormControl } from 'react-bootstrap';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ProjectRightNav from './ProjectRightNav/ProjectRightNav';
import TabECM from './TabECM/TabECM';
import BaselinePeriod from './BaselinePeriod/BaselinePeriod';
import ReportingPeriod from './ReportingPeriod/ReportingPeriod';
import RoutineAdjustment from './RoutineAdjustment/RoutineAdjustment';
import NonRoutineAdjustment from './NonRoutineAdjustment/NonRoutineAdjustment';
import ProjectModel from './ProjectModel/ProjectModel';
import Saving from './Saving/Saving';
import PrintView from './PrintView/PrintView';
import './ProjectDetail.css'
const ProjectDetail = props => {
  const MVProject = props.MVProjectID;

  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [showCanvasLeft, setShowCanvasLeft] = useState(true);

  const [activeStep, setActiveStep] = useState(1);
  const [printMode, setPrintMode] = useState(false);
  const [projectName, setProjectName] = useState(MVProject.name);
  const [projectCost, setProjectCost] = useState(MVProject.cost);
  const [projectDescription, setProjectDescription] = useState(() => {
    if (!MVProject.description) {
      return null;
    }
    const contentState = convertFromRaw(JSON.parse(MVProject.description));
    return EditorState.createWithContent(contentState);
  });

  const editorToolBar = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'image',
      'remove',
      'history'
    ],
    fontFamily: {
      options: ['Arial', 'Tahoma', 'Times New Roman', 'Iranian Sans'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined
    }
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  const updateProjectDetail = async () => {
    const rawProjectDesc = projectDescription ? convertToRaw(projectDescription.getCurrentContent()) : null;
    const body = JSON.stringify({
      name: projectName,
      cost: projectCost,
      description: rawProjectDesc ? JSON.stringify(rawProjectDesc) : null
    });
    await Axios.patch('/api/v1/MVProject/' + MVProject._id, body, config);
  };

  let tab;
  if (activeStep === 1) {
    tab = (
      <form hidden={activeStep !== 1}>
        <h2>1- تعریف پروژه</h2>
        <Row className="m-2">
          <Col md={{ span: 4 }}>نام پروژه</Col>
          <Col md={{ span: 4 }}>
            <InputGroup>
              <FormControl
                value={projectName}
                onChange={e => setProjectName(e.target.value)}
                onBlur={updateProjectDetail}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="m-2">
          <Col md={{ span: 4 }}>IPMVP Option</Col>
          <Col md={{ span: 4 }}>{MVProject.IPMVP}</Col>
        </Row>
        <Row className="m-2">
          <Col md={{ span: 4 }}>{MVProject.building.kind === 'Site' ? 'سایت' : 'ساختمان'}</Col>
          <Col md={{ span: 4 }}>{MVProject.building.name}</Col>
        </Row>
        <Row className="m-2">
          <Col md={{ span: 4 }}>هزینه پروژه (تومان)</Col>
          <Col md={{ span: 4 }}>
            <InputGroup>
              <FormControl
                value={projectCost}
                onChange={e => setProjectCost(e.target.value)}
                onBlur={updateProjectDetail}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="m-2">
          <Col onBlur={updateProjectDetail}>
            <Editor
              editorState={projectDescription}
              onEditorStateChange={val => {
                setProjectDescription(val);
              }}
              toolbar={editorToolBar}
            />
          </Col>
        </Row>
      </form>
    );
  } else if (activeStep === 2) {
    tab = <TabECM activeStep={activeStep} MVProject={MVProject} setMVProject={props.setMVProjectID} />;
  } else if (activeStep === 3) {
    tab = <BaselinePeriod activeStep={activeStep} MVProject={MVProject} setMVProject={props.setMVProjectID} />;
  } else if (activeStep === 4) {
    tab = <ReportingPeriod activeStep={activeStep} MVProject={MVProject} setMVProject={props.setMVProjectID} />;
  } else if (activeStep === 5) {
    tab = <RoutineAdjustment activeStep={activeStep} MVProject={MVProject} setMVProject={props.setMVProjectID} />;
  } else if (activeStep === 6) {
    tab = <NonRoutineAdjustment activeStep={activeStep} MVProject={MVProject} setMVProject={props.setMVProjectID} />;
  } else if (activeStep === 7) {
    tab = <ProjectModel activeStep={activeStep} MVProject={MVProject} setMVProject={props.setMVProjectID} />;
  } else if (activeStep === 8) {
    tab = <Saving activeStep={activeStep} MVProject={MVProject} setMVProject={props.setMVProjectID} />;
  }

  return (
    <Container fluid id="MainContent">
      <Row hidden={printMode}>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-2') : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <ProjectRightNav activeStep={activeStep} setActiveStep={setActiveStep} />
          </div>
        </Col>
        <Col className="mainContent pr-0">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3' ? setMainPad('pr-0 pl-0') : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <div
              hidden={showCanvasLeft}
              class="CanvasIcon CanvasLeft"
              onClick={() => {
                setCanvasWidthLeft(3);
                setShowCanvasLeft(true);

                canvasWidthRight == 3 ? setMainPad('pl-0 pr-0') : setMainPad('pl-2 pr-0');
              }}
            >
              <i className="fa fa-caret-right"></i>
            </div>
            <Container fluid>
              <Row
                className="ltr printIcon"
                onClick={() => {
                  setPrintMode(!printMode);
                }}
              >
                <i class="fa fa-print"></i>
              </Row>
              {tab}
            </Container>
          </div>
        </Col>
      </Row>
      <Row hidden={!printMode}>
        <PrintView MVProject={MVProject} setPrintMode={setPrintMode} />
      </Row>
    </Container>
  );
};

export default ProjectDetail;

import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../../../Axios/Axios';
import jalaliMoment from 'jalali-moment';

import {
  Row,
  Container,
  OverlayTrigger,
  Button,
  Tooltip,
  Col,
  Spinner,
  Modal,
  Tabs,
  Tab,
  FormControl,
  InputGroup,
  Form,
  Table
} from 'react-bootstrap';

import ChartCompareMonth from './ChartCompareMonth';
import ChartMonthDaily from './ChartMonthDaily';
import ChartMeterList from './ChartMeterList';
import ChartYearly from './ChartYearly';

import './MonthlyTrend.css';
import { ResultSetDependencies } from 'mathjs';

const MonthlyTrend = props => {
  const [meterData, setMeterData] = useState([]);

  const [pageload, setPageload] = useState(true);
  const [month, setMonth] = useState('');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };
  useEffect(() => {
    switch (props.reportMonth) {
      case '01':
        setMonth('فروردین');
        break;
      case '02':
        setMonth('اردیبهشت');
        break;
      case '03':
        setMonth('خرداد');
        break;
      case '04':
        setMonth('تیر');
        break;
      case '05':
        setMonth('مرداد');
        break;
      case '06':
        setMonth('شهریور');
        break;
      case '07':
        setMonth('مهر');
        break;
      case '08':
        setMonth('آبان');
        break;
      case '09':
        setMonth('آذر');
        break;
      case '10':
        setMonth('دی');
        break;
      case '11':
        setMonth('بهمن');
        break;
      case '12':
        setMonth('اسنفد');
        break;

      default:
        break;
    }
    if (pageload) {
      const body = JSON.stringify({
        kind: 'month',
        year: props.reportYear,
        month: props.reportMonth,
        units: props.currentReport.unit,
        navs: props.currentReport.nav
      });

      Axios.post('/api/v1/reportdata/trend', body, config).then(res => {
        setMeterData(res.data.data);
        props.setPageLoad(true);
      });
    }
  }, [props.currentReport, props.reportMonth, props.reportYear]);

  return (
    <div>
      <div className="prBox">
        <h1 className="text-center">گزارش روند مصرف ماهیانه</h1>
        <h2 className="text-center mb-4">
          <span className="pr-2">
            {' '}
            {month ? month : ''} {props.reportYear}
          </span>
        </h2>
        <div className="reportImage">
          <img src="../../../../ReportTypes/TrendMonthIcon.png" className="img-fluid"></img>
        </div>
        <h3 className="text-center fixed-bottom">
          <div className="pb-1">{props.currentReport.createdBy.name}</div>
          <div className="pb-1">{props.currentReport.name}</div>
          <div className="pb-1">
            {jalaliMoment(props.currentReport.created)
              .locale('fa')
              .format('jYYYY/jM/jD')}
          </div>
        </h3>
      </div>
      <div className="prBox">
        <div>
          <table className="table table-striped GreenTable">
            <thead className="headTable">
              <th scope="col" key="1">
                نام
              </th>
              <th scope="col" key="1">
              کنتور مصرفی | پارامتر
                            </th>
              <th scope="col" key="1">
                نوع
              </th>
            </thead>
            {meterData.map(element => {
              return (
                <tbody>
                  {element.nav ? (
                    <tr>
                      <td className="fontTd">
                        {element.navData[0].organization ? <span>{element.navData[0].organization.name} | </span> : ''}
                        {element.navData[0].building ? <span>{element.navData[0].building.name} | </span> : ''}
                        {element.nav.navName}
                      </td>
                      <td>{element.unit.name}</td>
                      <td>
                        {element.nav.navType === 'Organization' ? 'سازمان' : ''}
                        {element.nav.navType === 'Building' ? 'ساختمان' : ''}
                        {element.nav.navType === 'Site' ? 'صنعت' : ''}
                        {element.nav.navType === 'Meter' ? 'کنتور' : ''}
                        {element.nav.navType === 'Formula' ? 'فرمول' : ''}
                      </td>
                    </tr>
                  ) : (
                    ' '
                  )}
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
      {meterData.map(element => {
        return (
          <Col md={{ span: 12, offset: 0 }} xs={{ span: 12, offset: 0 }}>
            <div className="prBox">
              <h1 className="text-center mb-3">
                {element.navData[0].organization ? <span>{element.navData[0].organization.name} | </span> : ''}
                {element.navData[0].building ? <span>{element.navData[0].building.name} | </span> : ''}
                {element.nav.navName} - {element.nav ? element.unit.name : ''}
              </h1>
              {element.currentMonth.length > 0 ? (
                <Col md={{ span: 8, offset: 2 }} xs={{ span: 12, offset: 0 }}>
                  <Table className="printTable mb-3" bordered>
                    <tbody>
                      <tr>
                        <td className="lbl">
                          مصرف انرژی
                          <span className="unit">
                            {element.unit.type === 'Consumption' && element.currentMonth
                              ? element.currentMonth.length > 0
                                ? '(' + element.currentMonth[0].unit + ')'
                                : ''
                              : ''}
                          </span>
                        </td>
                        <td>
                          {element.unit.type === 'Consumption'
                            ? element.currentMonth.length > 0
                              ? element.currentMonth[0].total.toFixed(0)
                              : ''
                            : 'معتبر نیست'}
                        </td>
                      </tr>
                      <tr>
                        <td className="lbl">هزینه (تومان)</td>
                        <td>
                          {element.unit.type === 'Consumption'
                            ? element.currentMonth.length > 0
                              ? element.currentMonth[0].cost.toFixed(0)
                              : ''
                            : 'معتبر نیست'}
                        </td>
                      </tr>
                      <tr>
                        <td className="lbl">
                          {' '}
                          مقایسه با ماه قبل{' '}
                          {element.prevMonth.length > 0
                            ? '(' + element.prevMonth[0]._id.dateOf + ')'
                            : 'دیتا وجود ندارد'}
                        </td>
                        <td>
                          {element.prevMonth.length > 0 && element.currentMonth.length > 0 ? (
                            Math.sign(
                              (element.currentMonth[0].total - element.prevMonth[0].total) /
                              element.currentMonth[0].total *100
                                
                            ) > 0 ? (
                              <span className="RedText">
                                {(
                                  (element.currentMonth[0].total - element.prevMonth[0].total) /
                                  element.currentMonth[0].total *100
                                  
                                ).toFixed(4)}{' '}
                                +
                              </span>
                            ) : (
                              <span className="greenText">
                                {(
                                  Math.abs(element.currentMonth[0].total - element.prevMonth[0].total) /
                                  element.currentMonth[0].total *100
                                  
                                ).toFixed(4)}{' '}
                                -
                              </span>
                            )
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="lbl">
                          مقایسه با (
                          {element.currentMonth.length > 0 ? element.currentMonth[0]._id.dateOf : 'دیتا وجود ندارد'})
                          سال قبل{' '}
                        </td>
                        <td>
                          {element.prevYearCurrentMonth.length > 0 && element.currentMonth.length > 0 ? (
                            Math.sign(
                              (element.currentMonth[0].total - element.prevYearCurrentMonth[0].total) /
                              element.currentMonth[0].total *100
                                
                            ) > 0 ? (
                              <span className="RedText">
                                {(
                                  (element.currentMonth[0].total - element.prevYearCurrentMonth[0].total) /
                                  element.currentMonth[0].total *100
                                 
                                ).toFixed(4)}{' '}
                                +
                              </span>
                            ) : (
                              <span className="greenText">
                                {(
                                  Math.abs(element.currentMonth[0].total - element.prevYearCurrentMonth[0].total) /
                                  element.currentMonth[0].total *100
                                  
                                ).toFixed(4)}{' '}
                                -
                              </span>
                            )
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                      {element.unit.type === 'Consumption' ? (
                        <tr>
                          <td className="lbl">
                            متوسط مصرف ماهیانه
                            <span className="unit">
                              {element.currentMonth.length > 0 ? '(' + element.currentMonth[0].unit + ')' : ''}
                            </span>
                          </td>

                          <td>{element.prevYear.length > 0 ? (element.prevYear[0].totalAll / 12).toFixed(0) : ''}</td>
                        </tr>
                      ) : (
                        ''
                      )}

                      <tr>
                        <td colSpan={2}>
                          <span>لیست کنتورها:</span>
                          {element.meterMonth.length > 0 ? (
                            <span className="pr-3">
                              {element.meterMonth.map(meterName => {
                                return meterName.meter.name;
                              })}
                            </span>
                          ) : (
                            'کنتوری ثبت نشده است'
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              ) : (
                <div class="text-center mt-2">اطلاعاتی جهت نمایش ثبت نشده است</div>
              )}

              {element.currentMonth.length > 0 ? (
                <div className="printChartReport firtChart">
                  {element.meterMonth.length > 0 && element.unit.type === 'Consumption' && props.reportYear ? (
                    <ChartCompareMonth
                      reportYear={props.reportYear}
                      reportMonth={props.reportMonth}
                      dataset={element}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            {element.currentMonth.length > 0 ? (
              <div className="prBox">
                <div className="printChartReport">
                  {element.currentMonthAllDay.length > 0 ? <ChartMonthDaily dataset={element} /> : ''}
                </div>
                <div className="printChartReport">
                  {element.meterMonth && element.meterMonth.length > 0 ? (
                    <ChartMeterList dataset={element.meterMonth} />
                  ) : (
                    ''
                  )}
                </div>
                <div className="printChartReport">
                  {element.prevYear.length > 0 ? <ChartYearly dataset={element.prevYear[0]} /> : ''}
                </div>
              </div>
            ) : (
              ''
            )}
          </Col>
        );
      })}
    </div>
  );
};

export default MonthlyTrend;

import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Chart, defaults } from 'chart.js';
import { Table, Row, Container } from 'react-bootstrap';
const TrendChart = props => {
  const [alertTrendRow, setAlertTrendRow] = useState([]);
  defaults.normalized = true;
  defaults.font.family = 'Iranian Sans';
  defaults.font.size = 11;
  defaults.plugins.legend.position = 'bottom';
  defaults.plugins.legend.rtl = true;
  defaults.plugins.legend.labels.padding = 30;
  defaults.plugins.legend.labels.color = '#121212';
  defaults.plugins.title.color = '#121212';

  const myRef = useRef();
  const footer = tooltipItems => {
    if (tooltipItems.length < 2) {
      return;
    } else {
      let diffs = parseFloat(tooltipItems[1].formattedValue) - parseFloat(tooltipItems[0].formattedValue);
      let diffSign = '';
      if (diffs < 0) {
        diffs = Math.abs(diffs);
        diffSign = '-';
      }
      return (
        'اختلاف نسبت به خط مبنا: ' +
        diffs.toFixed(2) +
        diffSign +
        ' (%' +
        ((diffs / Math.abs(parseFloat(tooltipItems[1].formattedValue))) * 100).toFixed(2) +
        diffSign +
        ')'
      );
    }
  };
  const getTrendCoeff = () => {
    const alertTrendRow = [];
    if (props.trendCoeff !== null) {
      if (props.trendCoeff * 1 > 0) {
        let trendCoeef = (props.trendCoeff * 1) / 100;
        props.chartData.predictData.forEach((element, index) => {
          if (
            parseFloat(props.chartData.actualData[index].y) > parseFloat(element.y) + parseFloat(element.y) * trendCoeef ||
            parseFloat(props.chartData.actualData[index].y) < parseFloat(element.y) - parseFloat(element.y) * trendCoeef
          ) {
            alertTrendRow.push({
              day: element.x,
              predictData: parseFloat(element.y).toFixed(2),
              actualData: parseFloat(props.chartData.actualData[index].y).toFixed(2),
              coeff: ((parseFloat(props.chartData.actualData[index].y).toFixed(2) - parseFloat(element.y, 2).toFixed(2)) / parseFloat(element.y, 2).toFixed(2)) * 100
            });
          }
        });
      }
    }
    setAlertTrendRow(alertTrendRow);
  };

  useEffect(() => {
    if (props.chartLoad) {
      const tempChart = Chart.getChart(myRef.current);
      if (tempChart) {
        tempChart.destroy();
      }
      let data = {
        datasets: [
          {
            type: 'line',
            backgroundColor: 'rgba(184, 208, 254,0.9)',
            label: `Predict`,
            borderColor: 'rgba(184, 208, 254,0.7)',
            borderWidth: 3,
            radius: 1,
            data: props.chartData.predictData
          },
          {
            type: 'line',
            backgroundColor: 'rgba(253, 186, 58,0.9)',
            label: `Actual`,
            borderColor: 'rgba(253, 186, 58,0.7)',
            borderWidth: 3,
            radius: 1,
            data: props.chartData.actualData
          }
        ]
      };
      const options = {
        interaction: {
          mode: 'x'
        },
        animation: {
          y: {
            easing: 'linear',
            duration: 2000,
            from: 400
          }
        },
        scales: {
          x: {
            type: 'category',
            labels: props.chartData.days,
            grid: {
              display: false
            },
            ticks: {
              maxRotation: 30
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer: footer
            }
          }
        }
      };
      new Chart(myRef.current, {
        data: data,
        options: options
      });
      getTrendCoeff();
    }
  }, [props.chartLoad]);
  return (
    <Fragment>
      <canvas ref={myRef}></canvas>
      {alertTrendRow.length > 0 ? (
        <Container fluid>
          <Row className='mt-3'>
            <center className='col-md-12 mb-1'>
              <strong>
                روزهای دارای اختلاف بیش از حد مجاز اختلاف</strong>
            </center>
            <Table striped>
              <thead>
                <tr>
                  <th>روز</th>
                  <th>عدد Actual</th>
                  <th>عدد Predict</th>
                  <th>درصد اختلاف</th>
                </tr>
              </thead>
              <tbody>
                {alertTrendRow.map(item => {
                  return (
                    <tr>
                      <td>{item.day}</td>
                      <td>{item.actualData}</td>
                      <td>{item.predictData}</td>
                      <td className='ltr'>{item.coeff.toFixed(2)+" %"}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Row>
        </Container>
      ) : null}
    </Fragment>
  );
};

export default TrendChart;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../../actions/auth';
import { Toast } from '../../../components/UI/Toast/Toast';
import './Login.css';

const Login = props => {
  let history = useHistory();

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const { username, password } = formData;

  const changeData = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitData = e => {
    e.preventDefault();
    props.login({ username, password });
  };

  if (props.token) {
    history.push('/');
    window.location.reload();
  }
  return (
    <div style={{ direction: 'ltr' }}>
      <div class="limiter">
        <Toast />
        <div className="bg-full-screen-image blank-page blank-page">
          <div className="app-content content">
            <div className="content-wrapper">
              <div className="content-header row">
                <Toast />
              </div>
              <div className="content-body">
                <section className="row flexbox-container">
                  <div className="col-xl-8 col-11 d-flex justify-content-left">
                    <div className="card bg-authentication rounded-0 mb-0">
                      <div className="row m-0">
                        <div className="col-lg-12 col-12 p-0">
                          <div>
                            <div className="card-header pb-1">
                              <div className="card-title">
                                <img src="../../../app-assets/images/public/LoginLogo.png" className="img-fluid" />
                              </div>
                            </div>

                            <div className="card-content">
                              <div className="card-body pt-1">
                                <div>
                                  <fieldset className="form-label-group form-group position-relative has-icon-left">
                                    <input
                                      type="text"
                                      name="username"
                                      value={username}
                                      onChange={changeData}
                                      className="form-control"
                                      id="user-name"
                                      placeholder="نام کاربری"
                                      required
                                    />
                                    <div className="form-control-position">
                                      <i className="feather icon-user"></i>
                                    </div>
                                    {/* <label className="fontWhite" htmlFor="user-name">
                                      نام کاربری
                                    </label> */}
                                  </fieldset>

                                  <fieldset className="form-label-group position-relative has-icon-left">
                                    <input
                                      type="password"
                                      name="password"
                                      value={password}
                                      onChange={changeData}
                                      className="form-control"
                                      id="user-password"
                                      placeholder="رمز عبور"
                                      required
                                    />
                                    <div className="form-control-position">
                                      <i className="feather icon-lock"></i>
                                    </div>
                                    {/* <label className="fontWhite" htmlFor="user-password">
                                      رمز عبور
                                    </label> */}
                                  </fieldset>
                                  <div className=" col-12 d-flex justify-content-center mt-3">
                                    <button
                                      onClick={submitData}
                                      className="btn btn-primary float-right btn-inline"
                                      type="button"
                                    >
                                      ورود
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="content-footer">
              <div className="copyRight">تمامی حقوق این نرم افزار متعلق به شرکت مهندسین مشاور خانه انرژی می باشد</div>
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token
});
export default connect(mapStateToProps, { login })(Login);

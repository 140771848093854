import React, { useState, useEffect } from 'react';

import RightNav from '../../components/UI/RightNav/RightNav';

import OrganizationItem from './OrganizationItem/OrganizationItem.js';
import BuildingItem from './BuildingItem/BuildingItem.js';
import MeterItem from './MeterItem/MeterItem.js';
import FormulaItem from './FormulaItem/FormulaItem.js';
import {
  Row,
  Container,
  Card,
  Button,
  Col,
  Spinner,
  FormControl,
  Modal,
  InputGroup,
  Form,
} from 'react-bootstrap';
const Organization = props => {
  const [Nav, SetNav] = useState({ navID: '', navType: '' });
  const [isRenderNavbar, setIsRenderNavbar] = useState(true);
  const [ShowPage, SetShowPage] = useState();

  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [canvasWidthLeft, setCanvasWidthLeft] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);
  const [showCanvasLeft, setShowCanvasLeft] = useState(true);

  const handleCallback = (selectedRow, ShowPage) => {
    SetNav({ navID: selectedRow[0].navID, navType: selectedRow[0].navType });
    SetShowPage(ShowPage);
  };
  const ChooseComponent = () => {
    switch (Nav.navType) {
      case 'Formula':
        return (
          <FormulaItem
            setIsRenderNavbar={setIsRenderNavbar}
            ItemID={Nav.navID}
            ShowPageClass={ShowPage}
            SetShowPage={SetShowPage}
          />
        );
      case 'Organization':
        return (
          <OrganizationItem
            setIsRenderNavbar={setIsRenderNavbar}
            ItemID={Nav.navID}
            ShowPageClass={ShowPage}
            SetShowPage={SetShowPage}
          />
        );
      case 'Building':
        return (
          <BuildingItem
            setIsRenderNavbar={setIsRenderNavbar}
            ItemID={Nav.navID}
            ShowPageClass={ShowPage}
            SetShowPage={SetShowPage}
          />
        );
      case 'Meter':
        return (
          <MeterItem
            setIsRenderNavbar={setIsRenderNavbar}
            ItemID={Nav.navID}
            ShowPageClass={ShowPage}
            SetShowPage={SetShowPage}
          />
        );
    }
  };
  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
          <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                canvasWidthLeft === '3'
                  ? setMainPad('pr-0 pl-2')
                  : setMainPad('pr-2 pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <RightNav
          setIsRenderNavbar={setIsRenderNavbar}
          isRenderNavbar={isRenderNavbar}
          parentCallback={handleCallback}
          type={'single-select'}
        ></RightNav>
          </div>
        </Col>
        <Col
          className="mainContent pr-0"
        >
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                canvasWidthLeft === '3'
                  ? setMainPad('pr-0 pl-0')
                  : setMainPad('pr-2 pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>

            <div
              hidden={showCanvasLeft}
              class="CanvasIcon CanvasLeft"
              onClick={() => {
                setCanvasWidthLeft(3);
                setShowCanvasLeft(true);

                canvasWidthRight == 3
                  ? setMainPad('pl-0 pr-0')
                  : setMainPad('pl-2 pr-0');
              }}
            >
              <i className="fa fa-caret-right  "></i>
            </div>

            {ChooseComponent()}
           
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Organization;

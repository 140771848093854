import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Bar, defaults, Chart } from 'react-chartjs-2';

const ChartCompareMonth = props => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  defaults.normalized = true;
  defaults.font.size = 11;
  defaults.font.family = 'Iranian Sans';
  defaults.plugins.legend.display = false;
  defaults.hover.mode = 'dataset';
  defaults.hover.axis = 'xy';
  defaults.responsive = true;
  defaults.elements.line.tension = '0.5';
  defaults.aspectRatio = 3;

  const option = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true
      },
      title: {
        display: true,
        text: 'نمودار مقایسه ای سالیانه',
        padding: {
          bottom: 40
        },
        font: {
          size: 18
        }
      }
    },
    

    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: props.dataset.currentMonth[0].unit
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'ریال'
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      },
      x: {
        grid: {
          display: true
        }
      }
    }
  };
  const currentMonthLabel =
    props.dataset.currentMonth.length > 0 ? props.dataset.currentMonth[0]._id.dateOf + ' ' + props.reportYear : '';
  const prevMonthLabel =
    props.dataset.prevMonth.length > 0 ? props.dataset.prevMonth[0]._id.dateOf + ' ' + props.reportYear : '';
  const prevYearCurrentMonthLabel =
    props.dataset.prevYearCurrentMonth.length > 0
      ? props.dataset.prevYearCurrentMonth[0]._id.dateOf + ' ' + props.dataset.prevYearCurrentMonth[0].year
      : '';
  const data = {
    datasets: [
      {
        label: 'مصرف',
        yAxisID: 'y',
        backgroundColor: '#fbad1e',
        borderColor: '#fbad1e',
        data: [
          props.dataset.currentMonth.length > 0 ? props.dataset.currentMonth[0].total : '',
          props.dataset.prevMonth.length > 0 ? props.dataset.prevMonth[0].total : '',
          props.dataset.prevYearCurrentMonth.length > 0 ? props.dataset.prevYearCurrentMonth[0].total : ''
        ]
      },
      {
        label: 'هزینه',
        yAxisID: 'y1',
        backgroundColor: '#305dfa',
        borderColor: '#305dfa',
        data: [
          props.dataset.currentMonth.length > 0 ? props.dataset.currentMonth[0].cost : '',
          props.dataset.prevMonth.length > 0 ? props.dataset.prevMonth[0].cost : '',
          props.dataset.prevYearCurrentMonth.length > 0 ? props.dataset.prevYearCurrentMonth[0].cost : ''
        ]
      }
    ],

    labels: [currentMonthLabel, prevMonthLabel, prevYearCurrentMonthLabel]
  };

  return <Bar data={data} options={option} redraw={true}/>;
};
export default ChartCompareMonth;

import React, { useState, useEffect } from 'react';

import {
  Row,
  Container,
  Button,
  Col,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import Axios from '../../../Axios/Axios';

const LabelControlPanel = props => {
  const [chartYear, setChartYear] = useState('');
  const [allYear, setAllYear] = useState([]);
  const [needLoadTree, setNeedLoadTree] = useState(1);

  const [allOrganization, setAllOrganization] = useState([]);
  const [allBuilding, setAllBuilding] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [building, setBuilding] = useState(null);
  const [climate, setClimate] = useState(null);
  const [EIdeal, setEIdeal] = useState();

  //calculate
  const btnLabelView = async () => {
    if (chartYear && building && climate) {
      let ideal = changeEIDeal(climate);
      setEIdeal(ideal);
      props.setLoading(true);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      const body = JSON.stringify({
        year: chartYear,
        building: building
      });
      const eActualData = await Axios.post(
        '/api/v1/consumption/energyLabel',
        body,
        config
      );
      const eActual = eActualData.data.data.data.eactual;
      const RValue = eActual / ideal;
      let energyLabelChar = '';
      switch (building.type) {
        case 'مسکونی کوچک':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 1.9) {
            energyLabelChar = 'B';
          } else if (RValue >= 1.9 && RValue < 2.7) {
            energyLabelChar = 'C';
          } else if (RValue >= 2.7 && RValue < 3.4) {
            energyLabelChar = 'D';
          } else if (RValue >= 3.4 && RValue < 4) {
            energyLabelChar = 'E';
          } else if (RValue >= 4 && RValue < 4.5) {
            energyLabelChar = 'F';
          } else if (RValue >= 4.5 && RValue < 5) {
            energyLabelChar = 'G';
          } else if (RValue >= 5) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
        case 'مسکونی بزرگ':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 2) {
            energyLabelChar = 'B';
          } else if (RValue >= 2 && RValue < 2.9) {
            energyLabelChar = 'C';
          } else if (RValue >= 2.9 && RValue < 3.7) {
            energyLabelChar = 'D';
          } else if (RValue >= 3.7 && RValue < 4.4) {
            energyLabelChar = 'E';
          } else if (RValue >= 4.4 && RValue < 5) {
            energyLabelChar = 'F';
          } else if (RValue >= 5 && RValue < 5.4) {
            energyLabelChar = 'G';
          } else if (RValue >= 5.4) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
        case 'اداری دولتی':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 2) {
            energyLabelChar = 'B';
          } else if (RValue >= 2 && RValue < 3) {
            energyLabelChar = 'C';
          } else if (RValue >= 3 && RValue < 4) {
            energyLabelChar = 'D';
          } else if (RValue >= 4 && RValue < 5) {
            energyLabelChar = 'E';
          } else if (RValue >= 5 && RValue < 6) {
            energyLabelChar = 'F';
          } else if (RValue >= 6 && RValue < 7) {
            energyLabelChar = 'G';
          } else if (RValue >= 7) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
        case 'اداری خصوصی':
          if (RValue < 1) {
            energyLabelChar = 'A';
          } else if (RValue >= 1 && RValue < 2.2) {
            energyLabelChar = 'B';
          } else if (RValue >= 2.2 && RValue < 3.2) {
            energyLabelChar = 'C';
          } else if (RValue >= 3.2 && RValue < 4) {
            energyLabelChar = 'D';
          } else if (RValue >= 4 && RValue < 4.6) {
            energyLabelChar = 'E';
          } else if (RValue >= 4.6 && RValue < 5.2) {
            energyLabelChar = 'F';
          } else if (RValue >= 5.2 && RValue < 5.5) {
            energyLabelChar = 'G';
          } else if (RValue >= 5.5) {
            energyLabelChar = 'برچسب تعلق نمیگیرد';
          }
          break;
      }
      props.setLabelData({
        eActual,
        RValue,
        energyLabelChar,
        ideal,
        consumptions: eActualData.data.data.data.consumptions,
        building: building,
        climate: climate,
        year: chartYear
      });
      props.setLoading(false);
    }
  };
  // climate
  const changeClimate = e => {
    setClimate(e.target.value);
    let ideal = changeEIDeal(e.target.value);
    setEIdeal(ideal);
  };
  const changeEIDeal = cl => {
    let BuildingeClimat = cl;
    switch (building.type) {
      case 'مسکونی کوچک':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 111;
            break;
          case 'سرد':
            return 111;
            break;
          case 'معتدل و بارانی':
            return 156;
            break;
          case 'نیمه معتدل و بارانی':
            return 156;
            break;
          case 'نیمه خشک':
            return 83;
            break;
          case 'گرم و خشک':
            return 86;
            break;
          case 'بسیار گرم و خشک':
            return 150;
            break;
          case 'بسیار گرم و مرطوب':
            return 130;
            break;
        }
        break;
      case 'مسکونی بزرگ':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 102;
            break;
          case 'سرد':
            return 102;
            break;
          case 'معتدل و بارانی':
            return 106;
            break;
          case 'نیمه معتدل و بارانی':
            return 106;
            break;
          case 'نیمه خشک':
            return 87;
            break;
          case 'گرم و خشک':
            return 75;
            break;
          case 'بسیار گرم و خشک':
            return 138;
            break;
          case 'بسیار گرم و مرطوب':
            return 118;
            break;
        }
        break;
      case 'اداری دولتی':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 80;
            break;
          case 'سرد':
            return 80;
            break;
          case 'معتدل و بارانی':
            return 64;
            break;
          case 'نیمه معتدل و بارانی':
            return 64;
            break;
          case 'نیمه خشک':
            return 74;
            break;
          case 'گرم و خشک':
            return 64;
            break;
          case 'بسیار گرم و خشک':
            return 86;
            break;
          case 'بسیار گرم و مرطوب':
            return 91;
            break;
        }
        break;
      case 'اداری خصوصی':
        switch (BuildingeClimat) {
          case 'بسیار سرد':
            return 120;
            break;
          case 'سرد':
            return 120;
            break;
          case 'معتدل و بارانی':
            return 152;
            break;
          case 'نیمه معتدل و بارانی':
            return 152;
            break;
          case 'نیمه خشک':
            return 124;
            break;
          case 'گرم و خشک':
            return 117;
            break;
          case 'بسیار گرم و خشک':
            return 121;
            break;
          case 'بسیار گرم و مرطوب':
            return 197;
            break;
        }
        break;
    }
  };
  const changeOrganization = e => {
    setAllBuilding([]);
    setAllYear([]);
    setBuilding('');
    setChartYear('');
    let org = null;
    allOrganization.forEach(element => {
      if (element._id === e.target.value) {
        org = element;
      }
    });
    setOrganization(org);
    setNeedLoadTree(2);
  };
  const changeBuilding = e => {
    setAllYear([]);
    setChartYear('');
    let build = null;
    allBuilding.forEach(element => {
      if (element._id === e.target.value) {
        build = element;
      }
    });
    setBuilding(build);
    setNeedLoadTree(3);
  };

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`
      }
    };
    if (needLoadTree === 1) {
      setNeedLoadTree(0);
      Axios.get('/api/v1/organization', config).then(org => {
        setAllOrganization(org.data.data.data);
        if (org.data.data.data.length > 0) {
          let temp = localStorage.getItem('lastOrganization');
          if (temp) {
            temp = JSON.parse(temp);
            org.data.data.data.forEach(item => {
              if (item._id === temp.navID) {
                setOrganization(item);
              }
            });
          } else {
            setOrganization(org.data.data.data[0]);
          }
          setNeedLoadTree(2);
        }
      });
    }
    //get Building
    if (needLoadTree === 2) {
      setNeedLoadTree(0);
      Axios.get(
        `/api/v1/Building?organization=${organization._id}&kind=Building`,
        config
      ).then(res => {
        setAllBuilding(res.data.data.data);
      });
    }
    //get years
    if (needLoadTree === 3) {
      setNeedLoadTree(0);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      };
      if (building) {
        const body = JSON.stringify({
          kind: 'getYears',
          nav: [{ navID: building._id, navType: 'Building', navName: '' }]
        });
        Axios.post('/api/v1/consumption/gstats', body, config).then(res => {
          setAllYear(res.data.data.data);
        });
      }
    }
  });
  return (
    <Container className="ChartControlPanel pr-0 pl-0 mt-1">
      <Row className={'mb-1'}>
        <Col xs={12}>
          <label>سازمان:</label>
          <select
            className="ControlPanel"
            value={organization ? organization._id : ''}
            onChange={e => {
              changeOrganization(e);
            }}
          >
            <option></option>
            {allOrganization.length > 0
              ? allOrganization.map((el, index) => {
                  return (
                    <option key={index} value={el._id}>
                      {el.name}
                    </option>
                  );
                })
              : ''}
          </select>
        </Col>
      </Row>
      <Row className={'mb-1'}>
        <Col xs={12}>
          <label>ساختمان:</label>
          <select
            className="ControlPanel"
            value={building ? building._id : null}
            onClick={e => {
              changeBuilding(e);
            }}
          >
            <option></option>
            {allBuilding.length > 0
              ? allBuilding.map((el, index) => {
                  return (
                    <option key={index} value={el._id}>
                      {el.name}
                    </option>
                  );
                })
              : ''}
          </select>
        </Col>
      </Row>
      <Row className={'mb-1'}>
        <Col xs={12}>
          <span className={'d-flex'}>
            <label>سال:</label>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip style={{ fontFamily: 'Iranian Sans' }}>
                  فقط از بین سالهایی که برای این ساختمان اطلاعات وجود دارد
                  میتوانید انتخاب کنید
                </Tooltip>
              }
            >
              <i className="fa fa-question-circle"></i>
            </OverlayTrigger>
          </span>
          <select
            className="ControlPanel"
            value={chartYear}
            onChange={e => {
              setChartYear(e.target.value);
            }}
          >
            <option></option>
            {allYear.length > 0
              ? allYear[0].map((el, index) => {
                  return (
                    <option key={index} value={el._id}>
                      {el._id}
                    </option>
                  );
                })
              : ''}
          </select>
        </Col>
      </Row>
      <Row className="mb-2" hidden={!building}>
        <Col xs={12}>
          <span className={'d-flex'}>
            <label>{`اقلیم: ${
              building ? `(${building.city.name})` : ''
            }`}</label>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip style={{ fontFamily: 'Iranian Sans' }}>
                  اقلیم این شهر را در تاریخ انتخابی مشخص نمایید
                </Tooltip>
              }
            >
              <i className="fa fa-question-circle"></i>
            </OverlayTrigger>
          </span>
          <select
            className="ControlPanel"
            value={climate}
            onChange={changeClimate}
          >
            <option></option>
            <option>بسیار سرد</option>
            <option>سرد</option>
            <option>معتدل و بارانی</option>
            <option>نیمه معتدل و بارانی</option>
            <option>نیمه خشک</option>
            <option>گرم و خشک</option>
            <option>بسیار گرم و خشک</option>
            <option>بسیار گرم و مرطوب</option>
          </select>
        </Col>
      </Row>
      <Row className={'mb-1 mt-2'}>
        <Col xs={12}>
          <Button
            className={`btn   'btn-simple'`}
            onClick={() => {
              btnLabelView();
            }}
          >
            مشاهده
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default LabelControlPanel;

import React, { useState, useEffect, Fragment } from 'react';
import { Row, Container, Button, Col, Spinner, Form, Table } from 'react-bootstrap';
import moment from 'jalali-moment';
import { Calendar } from 'react-modern-calendar-datepicker';
import { toast } from 'react-toastify';
import { parse } from 'mathjs';

import Axios from '../../Axios/Axios';
import TrendRightNav from './TrendRightNav/TrendRightNav';
import TrendFormulaBuilder from './TrendFormulaBuilder/TrendFormulaBuilder';
import TrendChart from './TrendChart/TrendChart';

import { linearRegression } from '../../components/Regression/js/linear/linearRegression';
import { addConstant } from '../../components/Regression/js/tools/tools';
import regression from 'regression-extend';
import Note from '../../components/UI/Note/Note';

import './Trend.css';

const Trend = props => {
  const [newTrend, setNewTrend] = useState(true);
  const [needCalculateTrend, setNeedCalculateTrend] = useState(false);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [newTrendName, setNewTrendName] = useState('');
  const [newTrendCoeff, setNewTrendCoeff] = useState(5);
  const [chartData, setChartData] = useState(null);
  const [chartLoad, setChartLoad] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [needGetData, setNeedGetData] = useState(true);
  const [allTrend, setAllTrend] = useState([]);

  const [activeStep, setActiveStep] = useState(1);
  const [loadingRegressionData, setLoadingRegressionData] = useState(false);
  const [chartRangeDate, setChartRangeDate] = useState({
    from: null,
    to: null
  });
  const [chartRangeDateReport, setChartRangeDateReport] = useState({
    from: null,
    to: null
  });

  const [canvasWidthRight, setCanvasWidthRight] = useState('2');
  const [mainPad, setMainPad] = useState('');
  const [showCanvasRight, setShowCanvasRight] = useState(true);

  const [previewRegression, setPreviewRegression] = useState(null);

  const [yAxieFormulaSynon, setYAxieFormulaSynon] = useState([]);
  const [yAxieFormulaText, setYAxieFormulaText] = useState(null);

  const [x1AxieFormulaSynon, setX1AxieFormulaSynon] = useState([]);
  const [x1AxieFormulaText, setX1AxieFormulaText] = useState(null);

  const [x2AxieFormulaSynon, setX2AxieFormulaSynon] = useState([]);
  const [x2AxieFormulaText, setX2AxieFormulaText] = useState(null);

  const [x3AxieFormulaSynon, setX3AxieFormulaSynon] = useState([]);
  const [x3AxieFormulaText, setX3AxieFormulaText] = useState(null);

  const [x4AxieFormulaSynon, setX4AxieFormulaSynon] = useState([]);
  const [x4AxieFormulaText, setX4AxieFormulaText] = useState(null);

  const [x5AxieFormulaSynon, setX5AxieFormulaSynon] = useState([]);
  const [x5AxieFormulaText, setX5AxieFormulaText] = useState(null);

  const [regressionOrder, setRegressionOrder] = useState('1');
  const [regressionParam, setRegressionParam] = useState('2');

  const [showRegressionFormula, setShowRegressionFormula] = useState(true);

  const [notesRefresh, setNotesRefresh] = useState(true);

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`
    }
  };

  useEffect(() => {
    if (needGetData) {
      Axios.get('/api/v1/trend', config).then(res => {
        setAllTrend(res.data.data.data);
        setNeedGetData(false);
      });
    }
    if (needCalculateTrend) {
      setNeedCalculateTrend(false);
      calculateTrend(3, selectedTrend);
    }
  });
  const changeTrendSelect = e => {
    setChartData(null);
    setPreviewRegression(null);
    setShowRegressionFormula(false);
    allTrend.map(item => {
      if (e.target.value === item._id) {
        setSelectedTrend(item);
        setYAxieFormulaSynon(item.yAxieFormulaSynon);
        setYAxieFormulaText(item.yAxieFormula);
        setX1AxieFormulaSynon(item.x1AxieFormulaSynon);
        setX1AxieFormulaText(item.x1AxieFormula);
        setX2AxieFormulaSynon(item.x2AxieFormulaSynon);
        setX2AxieFormulaText(item.x2AxieFormula);
        setX3AxieFormulaSynon(item.x3AxieFormulaSynon);
        setX3AxieFormulaText(item.x3AxieFormula);
        setX4AxieFormulaSynon(item.x4AxieFormulaSynon);
        setX4AxieFormulaText(item.x4AxieFormula);
        setX5AxieFormulaSynon(item.x5AxieFormulaSynon);
        setX5AxieFormulaText(item.x5AxieFormula);
        setRegressionParam(item.regressionParam);
        setRegressionOrder(item.regressionOrder);
        setNeedCalculateTrend(true);
        setNotesRefresh(true);
      }
    });
  };
  const saveTrend = async () => {
    const fromTemp = `${chartRangeDate.from.year}-${chartRangeDate.from.month.toString().length === 2 ? chartRangeDate.from.month : '0' + chartRangeDate.from.month
      }-${chartRangeDate.from.day.toString().length === 2 ? chartRangeDate.from.day : '0' + chartRangeDate.from.day}`;
    const toTemp = `${chartRangeDate.to.year}-${chartRangeDate.to.month.toString().length === 2 ? chartRangeDate.to.month : '0' + chartRangeDate.to.month
      }-${chartRangeDate.to.day.toString().length === 2 ? chartRangeDate.to.day : '0' + chartRangeDate.to.day}`;
    //save trend to model

    let objTrend = {
      name: newTrendName,
      regressionParam: regressionParam,
      regressionOrder: regressionOrder,
      regressionFormula: regressionParam > 1 || regressionOrder == '1' ? previewRegression.regressionEquation : previewRegression.string,
      from: fromTemp,
      to: toTemp,
      yAxieFormulaSynon: yAxieFormulaSynon,
      yAxieFormula: yAxieFormulaText,
      x1AxieFormulaSynon: x1AxieFormulaSynon,
      x1AxieFormula: x1AxieFormulaText,
      x2AxieFormulaSynon: x2AxieFormulaSynon,
      x2AxieFormula: x2AxieFormulaText,
      x3AxieFormulaSynon: x3AxieFormulaSynon,
      x3AxieFormula: x3AxieFormulaText,
      x4AxieFormulaSynon: x4AxieFormulaSynon,
      x4AxieFormula: x4AxieFormulaText,
      x5AxieFormulaSynon: x5AxieFormulaSynon,
      x5AxieFormula: x5AxieFormulaText,
      trendCoeff: newTrendCoeff.toString()
    };
    const bodyTrend = JSON.stringify(objTrend);
    const res = await Axios.post('/api/v1/trend', bodyTrend, config);
    toast.success('خط مبنای مورد نظر شما ذخیره شد.');
    //get trend Params to state
    setSelectedTrend(res.data.data.data);
    setActiveStep(4);
    setNeedGetData(true);
  };
  const calculateTrend = async (i, item) => {
    setActiveStep(i);
    setLoadingRegressionData(true);
    let fromTemp, toTemp;
    let bodyObj;
    if (i == 3) {
      fromTemp = item.from;
      toTemp = item.to;
      bodyObj = {
        kind: 'range',
        fromDate: fromTemp,
        toDate: toTemp,
        paramCount: item.regressionParam,
        yAxieFormulaSynon: item.yAxieFormulaSynon,
        yAxieFormula: item.yAxieFormula,
        x1AxieFormulaSynon: item.x1AxieFormulaSynon,
        x1AxieFormula: item.x1AxieFormula,
        x2AxieFormulaSynon: item.x2AxieFormulaSynon,
        x2AxieFormula: item.x2AxieFormula,
        x3AxieFormulaSynon: item.x3AxieFormulaSynon,
        x3AxieFormula: item.x3AxieFormula,
        x4AxieFormulaSynon: item.x4AxieFormulaSynon,
        x4AxieFormula: item.x4AxieFormula,
        x5AxieFormulaSynon: item.x5AxieFormulaSynon,
        x5AxieFormula: item.x5AxieFormula
      };
    } else {
      fromTemp = `${chartRangeDate.from.year}-${chartRangeDate.from.month.toString().length === 2 ? chartRangeDate.from.month : '0' + chartRangeDate.from.month
        }-${chartRangeDate.from.day.toString().length === 2 ? chartRangeDate.from.day : '0' + chartRangeDate.from.day}`;
      toTemp = `${chartRangeDate.to.year}-${chartRangeDate.to.month.toString().length === 2 ? chartRangeDate.to.month : '0' + chartRangeDate.to.month
        }-${chartRangeDate.to.day.toString().length === 2 ? chartRangeDate.to.day : '0' + chartRangeDate.to.day}`;
      bodyObj = {
        kind: 'range',
        fromDate: fromTemp,
        toDate: toTemp,
        paramCount: regressionParam,
        yAxieFormulaSynon: yAxieFormulaSynon,
        yAxieFormula: yAxieFormulaText,
        x1AxieFormulaSynon: x1AxieFormulaSynon,
        x1AxieFormula: x1AxieFormulaText,
        x2AxieFormulaSynon: x2AxieFormulaSynon,
        x2AxieFormula: x2AxieFormulaText,
        x3AxieFormulaSynon: x3AxieFormulaSynon,
        x3AxieFormula: x3AxieFormulaText,
        x4AxieFormulaSynon: x4AxieFormulaSynon,
        x4AxieFormula: x4AxieFormulaText,
        x5AxieFormulaSynon: x5AxieFormulaSynon,
        x5AxieFormula: x5AxieFormulaText
      };
    }
    const body = JSON.stringify(bodyObj);
    const trend = await Axios.post('/api/v1/util/formula/trend', body, config);
    const days = trend.data.data.days;
    if (days.length === 0) {
      toast.error('برای خط مبنای مورد نظر شما دیتا ثبت نشده است.');
      setLoadingRegressionData(false);
      setActiveStep(1);
      return 0;
    }
    const yData = [];
    let xData = [];
    days.forEach((item, index) => {
      yData.push(trend.data.data.data.yAxie[index].y);
      if (regressionParam === '1') {
        if (regressionOrder === '1') {
          xData.push([trend.data.data.data.x1Axie[index].y, 0]);
        }
        else {
          xData.push([trend.data.data.data.x1Axie[index].y]);
        }
      } else if (regressionParam === '2') {
        xData.push([trend.data.data.data.x1Axie[index].y, trend.data.data.data.x2Axie[index].y]);
      } else if (regressionParam === '3') {
        xData.push([
          trend.data.data.data.x1Axie[index].y,
          trend.data.data.data.x2Axie[index].y,
          trend.data.data.data.x3Axie[index].y
        ]);
      } else if (regressionParam === '4') {
        xData.push([
          trend.data.data.data.x1Axie[index].y,
          trend.data.data.data.x2Axie[index].y,
          trend.data.data.data.x3Axie[index].y,
          trend.data.data.data.x4Axie[index].y
        ]);
      } else if (regressionParam === '5') {
        xData.push([
          trend.data.data.data.x1Axie[index].y,
          trend.data.data.data.x2Axie[index].y,
          trend.data.data.data.x3Axie[index].y,
          trend.data.data.data.x4Axie[index].y,
          trend.data.data.data.x5Axie[index].y
        ]);
      }
    });
    let regressionResult = {};
    if (regressionParam === '1') {
      if (regressionOrder === '1') {
        //add a 1 at beginning of every y for calculating the constant coefficient.
        xData = addConstant(xData);
        //calculate variance inflation factors
        regressionResult = linearRegression(yData, xData, false);
      } else if (regressionOrder === '2') {
        regressionResult = regression.polynomial(xData, yData, { order: 2 });
      } else if (regressionOrder === '3') {
        regressionResult = regression.polynomial(xData, yData, { order: 3 });
      } else if (regressionOrder === '4') {
        regressionResult = regression.polynomial(xData, yData, { order: 4 });
      }
    } else {
      //add a 1 at beginning of every y for calculating the constant coefficient.
      xData = addConstant(xData);
      //calculate variance inflation factors

      regressionResult = linearRegression(yData, xData, false);
    }

    let a = moment.from(fromTemp, 'fa', 'YYYY-MM-DD');
    let b = moment.from(toTemp, 'fa', 'YYYY-MM-DD');
    var diffs = b.diff(a, 'days');
    setPreviewRegression({
      ...regressionResult,
      inDays: diffs + 1,
      outDays: days.length,
      from: fromTemp,
      to: toTemp
    });
    setLoadingRegressionData(false);
  };

  const getPredictData = (paramC, x1, x2, x3, x4, x5, formul, day) => {
    let formulTemp = parse(formul.replace('0x2', ''));
    if (regressionParam > 1 || regressionOrder == '1') {
      return {
        x: day,
        y: formulTemp.evaluate({ x: x1, x1: x1, x2: x2, x3: x3, x4: x4, x5: x5 })
      };
    } else {
      return {
        x: day,
        y: formulTemp.evaluate({ x: x1, 'x^2': x1 })
      };
    }
  };
  const getChartData = async () => {
    setChartLoading(true);
    setChartLoad(false);
    const fromTemp = `${chartRangeDateReport.from.year}-${chartRangeDateReport.from.month.toString().length === 2
      ? chartRangeDateReport.from.month
      : '0' + chartRangeDateReport.from.month
      }-${chartRangeDateReport.from.day.toString().length === 2
        ? chartRangeDateReport.from.day
        : '0' + chartRangeDateReport.from.day
      }`;
    const toTemp = `${chartRangeDateReport.to.year}-${chartRangeDateReport.to.month.toString().length === 2
      ? chartRangeDateReport.to.month
      : '0' + chartRangeDateReport.to.month
      }-${chartRangeDateReport.to.day.toString().length === 2
        ? chartRangeDateReport.to.day
        : '0' + chartRangeDateReport.to.day
      }`;
    let bodyObj = {
      kind: 'range',
      fromDate: selectedTrend.from,
      toDate: selectedTrend.to,
      paramCount: regressionParam,
      yAxieFormulaSynon: yAxieFormulaSynon,
      yAxieFormula: yAxieFormulaText,
      x1AxieFormulaSynon: x1AxieFormulaSynon,
      x1AxieFormula: x1AxieFormulaText,
      x2AxieFormulaSynon: x2AxieFormulaSynon,
      x2AxieFormula: x2AxieFormulaText,
      x3AxieFormulaSynon: x3AxieFormulaSynon,
      x3AxieFormula: x3AxieFormulaText,
      x4AxieFormulaSynon: x4AxieFormulaSynon,
      x4AxieFormula: x4AxieFormulaText,
      x5AxieFormulaSynon: x5AxieFormulaSynon,
      x5AxieFormula: x5AxieFormulaText
    };
    const body = JSON.stringify(bodyObj);
    const prevData = await Axios.post('/api/v1/util/formula/trend', body, config);
    let days = prevData.data.data.days;
    let actualData = prevData.data.data.data.yAxie;

    let bodyObjReport = {
      kind: 'range',
      fromDate: fromTemp,
      toDate: toTemp,
      paramCount: regressionParam,
      yAxieFormulaSynon: yAxieFormulaSynon,
      yAxieFormula: yAxieFormulaText,
      x1AxieFormulaSynon: x1AxieFormulaSynon,
      x1AxieFormula: x1AxieFormulaText,
      x2AxieFormulaSynon: x2AxieFormulaSynon,
      x2AxieFormula: x2AxieFormulaText,
      x3AxieFormulaSynon: x3AxieFormulaSynon,
      x3AxieFormula: x3AxieFormulaText,
      x4AxieFormulaSynon: x4AxieFormulaSynon,
      x4AxieFormula: x4AxieFormulaText,
      x5AxieFormulaSynon: x5AxieFormulaSynon,
      x5AxieFormula: x5AxieFormulaText
    };
    const bodyReport = JSON.stringify(bodyObjReport);
    const reportData = await Axios.post('/api/v1/util/formula/trend', bodyReport, config);
    days = reportData.data.data.days;
    actualData = reportData.data.data.data.yAxie;
    const tempReportData = reportData.data.data.data;
    const predictData = reportData.data.data.days.map((item, index) => {
      return getPredictData(
        regressionParam,
        tempReportData.x1Axie ? tempReportData.x1Axie[index].y : null,
        tempReportData.x2Axie ? tempReportData.x2Axie[index].y : null,
        tempReportData.x3Axie ? tempReportData.x3Axie[index].y : null,
        tempReportData.x4Axie ? tempReportData.x4Axie[index].y : null,
        tempReportData.x5Axie ? tempReportData.x5Axie[index].y : null,
        selectedTrend.regressionFormula,
        item
      );
    });
    setChartData({ days, actualData, predictData });
    setChartLoad(true);
    setChartLoading(false);
  };
  const getRegressionFormula = () => {
    if (previewRegression == null) {
      return;
    }
    if (regressionParam > 1 || regressionOrder == '1') {
      let temp = previewRegression.coefficients.data.map((item, index) => {
        if (item[0] == 0) {
          return (<span key={index}></span>);
        }
        let tempCoeff = item[0];
        if (Math.abs(tempCoeff) < 1) {
          tempCoeff = tempCoeff.toFixed(5);
        } else if (Math.abs(tempCoeff) < 10) {
          tempCoeff = tempCoeff.toFixed(3);
        } else {
          tempCoeff = tempCoeff.toFixed(1);
        }
        if (index === 0) {
          return <span key={index}>{tempCoeff}</span>;
        } else {
          if (tempCoeff.startsWith('-')) {
            return (
              <span key={index}>
                {' - ' + tempCoeff.replace('-', '')}
                <span className="formulX" title={getFormula(index)}>
                  {' X' + index}
                </span>
              </span>
            );
          } else {
            return (
              <span key={index}>
                {' + ' + tempCoeff}
                <span className="formulX" title={getFormula(index)}>
                  {' X' + index}
                </span>
              </span>
            );
          }
        }
      });
      let retArray = [1];
      retArray = retArray.map(item => {
        return (
          <span key="y">
            <span className="formulY" title={getFormula(0)}>
              {'Y '}
            </span>
            =
          </span>
        );
      });
      retArray = retArray.concat(temp);
      return retArray;
    } else {
      let temp = previewRegression.equation.map((item, index) => {
        let tempCoeff = item;
        if (Math.abs(tempCoeff).toFixed(5) === 0) {
          tempCoeff = tempCoeff.toFixed(0);
        }
        if (Math.abs(tempCoeff) < 1) {
          tempCoeff = tempCoeff.toFixed(5);
        } else if (Math.abs(tempCoeff) < 10) {
          tempCoeff = tempCoeff.toFixed(3);
        } else {
          tempCoeff = tempCoeff.toFixed(1);
        }
        if (index === previewRegression.equation.length - 1) {
          if (tempCoeff.startsWith('-')) {
            return <span key={index}> - {tempCoeff.replace('-', '')}</span>;
          } else {
            return <span key={index}> + {tempCoeff}</span>;
          }
        } else {
          if (index === 0) {
            return (
              <span key={index}>
                {tempCoeff}
                <b className="formulX" title={getFormula(1)}>
                  {' X^' + (index + 1) * 1}
                </b>
              </span>
            );
          } else {
            if (tempCoeff.startsWith('-')) {
              return (
                <span key={index}>
                  {' - ' + tempCoeff.replace('-', '')}
                  <b className="formulX" title={getFormula(1)}>
                    {' X^' + (index + 1) * 1}
                  </b>
                </span>
              );
            } else {
              return (
                <span key={index}>
                  {' + ' + tempCoeff}
                  <b className="formulX" title={getFormula(1)}>
                    {' X^' + (index + 1) * 1}
                  </b>
                </span>
              );
            }
          }
        }
      });
      let retArray = [1];
      retArray = retArray.map(item => {
        return (
          <span key="y">
            <b title={getFormula(0)}>{'Y '}= </b>
          </span>
        );
      });
      retArray = retArray.concat(temp);
      return retArray;
    }
  };
  const getFormula = index => {
    if (index === 0) {
      let formul = yAxieFormulaText;
      yAxieFormulaSynon.forEach((item, ind) => {
        formul = formul.replace(`Synonym${ind}`, item.label);
      });
      return formul;
    } else if (index === 1) {
      let formul = x1AxieFormulaText;
      x1AxieFormulaSynon.forEach((item, ind) => {
        formul = formul.replace(`Synonym${ind}`, item.label);
      });
      return formul;
    } else if (index === 2) {
      let formul = x2AxieFormulaText;
      x2AxieFormulaSynon.forEach((item, ind) => {
        formul = formul.replace(`Synonym${ind}`, item.label);
      });
      return formul;
    } else if (index === 3) {
      let formul = x3AxieFormulaText;
      x3AxieFormulaSynon.forEach((item, ind) => {
        formul = formul.replace(`Synonym${ind}`, item.label);
      });
      return formul;
    } else if (index === 4) {
      let formul = x4AxieFormulaText;
      x4AxieFormulaSynon.forEach((item, ind) => {
        formul = formul.replace(`Synonym${ind}`, item.label);
      });
      return formul;
    } else if (index === 5) {
      let formul = x5AxieFormulaText;
      x5AxieFormulaSynon.forEach((item, ind) => {
        formul = formul.replace(`Synonym${ind}`, item.label);
      });
      return formul;
    }
  };

  return (
    <Container fluid id="MainContent">
      <Row>
        <Col
          lg={canvasWidthRight}
          md={canvasWidthRight}
          sm={canvasWidthRight}
          xs={canvasWidthRight}
          hidden={canvasWidthRight === 0}
          className="pr-0 RightPanel"
        >
          <div className="pageBack ">
            <div
              class="ToggleNavIcon ToggleNavRight"
              onClick={() => {
                setCanvasWidthRight(0);
                setMainPad('pl-2');
                setShowCanvasRight(false);
              }}
            >
              <i className="fa fa-caret-right "></i>
            </div>
            <TrendRightNav
              newTrend={newTrend}
              setNewTrend={setNewTrend}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setChartData={setChartData}
              setPreviewRegression={setPreviewRegression}
            />
          </div>
        </Col>
        <Col className="mainContent pr-0">
          <div className="pageBack">
            <div
              class="CanvasIcon CanvasRight"
              hidden={showCanvasRight}
              onClick={() => {
                setCanvasWidthRight(3);
                setShowCanvasRight(true);
                setMainPad(' pl-0');
              }}
            >
              <i className="fa fa-caret-left  "></i>
            </div>
            <Container>
              <form hidden={activeStep !== 1} className={'dpLtr'}>
                <TrendFormulaBuilder
                  setPreviewRegression={setPreviewRegression}
                  regressionParam={regressionParam}
                  setRegressionParam={setRegressionParam}
                  regressionOrder={regressionOrder}
                  setRegressionOrder={setRegressionOrder}
                  yAxieFormulaSynon={yAxieFormulaSynon}
                  setYAxieFormulaSynon={setYAxieFormulaSynon}
                  setYAxieFormulaText={setYAxieFormulaText}
                  x1AxieFormulaSynon={x1AxieFormulaSynon}
                  setX1AxieFormulaSynon={setX1AxieFormulaSynon}
                  setX1AxieFormulaText={setX1AxieFormulaText}
                  x2AxieFormulaSynon={x2AxieFormulaSynon}
                  setX2AxieFormulaSynon={setX2AxieFormulaSynon}
                  setX2AxieFormulaText={setX2AxieFormulaText}
                  x3AxieFormulaSynon={x3AxieFormulaSynon}
                  setX3AxieFormulaSynon={setX3AxieFormulaSynon}
                  setX3AxieFormulaText={setX3AxieFormulaText}
                  x4AxieFormulaSynon={x4AxieFormulaSynon}
                  setX4AxieFormulaSynon={setX4AxieFormulaSynon}
                  setX4AxieFormulaText={setX4AxieFormulaText}
                  x5AxieFormulaSynon={x5AxieFormulaSynon}
                  setX5AxieFormulaSynon={setX5AxieFormulaSynon}
                  setX5AxieFormulaText={setX5AxieFormulaText}
                  chartRangeDate={chartRangeDate}
                  setChartRangeDate={setChartRangeDate}
                  calculateTrend={calculateTrend}
                />
              </form>
              <form hidden={activeStep !== 2}>
                <center hidden={!loadingRegressionData}>
                  <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                </center>
                <Container fluid className="ChartControlPanel" hidden={loadingRegressionData || !previewRegression}>
                  <h3 className={'simpleBoxTitle'}>اطلاعات رگرسیون</h3>
                  <Row className="mb-2">
                    <Col md={{ span: 12 }}>
                      <div className="regressionFormula">{newTrend ? getRegressionFormula() : null}</div>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col md={{ span: 4 }}>
                          <div className="regressionParam">
                            <div className="regParamTitle">R2 Score</div>
                            <div className="regParamText">
                              {previewRegression
                                ? regressionParam > '1' || regressionOrder === '1'
                                  ? previewRegression.rSquared.toFixed(4)
                                  : previewRegression.r2.toFixed(4)
                                : ''}
                            </div>
                          </div>
                        </Col>
                        <Col md={{ span: 4 }}>
                          <div className="regressionParam">
                            <div className="regParamTitle">تعداد روز دوره
                              <i class="fa-solid fa-circle-info"></i>
                            </div>
                            <div className="regParamText">{previewRegression ? previewRegression.inDays : ''}</div>
                          </div>
                        </Col>
                        <Col md={{ span: 4 }}>
                          <div className="regressionParam">
                            <div className="regParamTitle">تعداد روز محاسبه شده</div>
                            <div className="regParamText">{previewRegression ? previewRegression.outDays : ''}</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <h3 className={'simpleBoxTitle'}>آنالیز آماری رگرسیون</h3>
                  <Row className="mb-2 mt-2">
                    {regressionParam > '1' || regressionOrder == '1' ? (
                      <Col md={{ span: 12 }}>
                        <Table responsive striped className="RegTable mb-3">
                          <thead>
                            <tr>
                              <th></th>
                              <th>SS</th>
                              <th>F</th>
                              <th>Significance F</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-bold">Regression</td>
                              <td>{previewRegression ? parseFloat(previewRegression.ssr).toFixed(6) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.fValue).toFixed(6) : ''}</td>

                              <td>
                                {previewRegression ? parseFloat(previewRegression.pValueOfFValue).toFixed(6) : ''}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Residual</td>
                              <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(6) : ''}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Total</td>
                              <td>
                                {previewRegression
                                  ? parseFloat(previewRegression.ssr + parseInt(previewRegression.sse)).toFixed(6)
                                  : ''}
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>

                        <Table responsive striped className="RegTable mb-3">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Coefficients</th>
                              <th>Standard Error</th>
                              <th>t Stat</th>
                              <th>P-value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {previewRegression
                              ? previewRegression.pValues.map((item, index) => {
                                if (!item || isNaN(item)) {
                                }
                                else
                                  return (
                                    <tr>
                                      <td className="font-weight-bold">{index === 0 ? 'Intercept' : 'x' + index}</td>
                                      <td>{parseFloat(previewRegression.coefficients.data[index]).toFixed(6)}</td>
                                      <td>{parseFloat(previewRegression.stdErrorOfCoefficients[index]).toFixed(6)}</td>

                                      <td>{parseFloat(previewRegression.tValues[index]).toFixed(6)}</td>
                                      <td>{parseFloat(previewRegression.pValues[index]).toFixed(6)}</td>
                                    </tr>
                                  );
                              })
                              : ''}
                          </tbody>
                        </Table>
                      </Col>
                    ) : (
                      <Col md={{ span: 12 }}>
                        <Table responsive striped className="RegTable mb-3">
                          <thead>
                            <tr>
                              <th>me</th>
                              <th>sse</th>
                              <th>mse</th>
                              <th>smse</th>
                              <th>se</th>
                              <th>tstat</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{previewRegression ? parseFloat(previewRegression.me).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.mse).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.smse).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.se).toFixed(4) : ''}</td>
                              <td>{previewRegression ? parseFloat(previewRegression.tstat).toFixed(4) : ''}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </Row>

                  <Row className="mt-2 mb-3">
                    <Col md={{ span: 4, offset: 1 }}>
                      <Form.Group className="mb-3 row" controlId="formBasicPassword">
                        <Form.Label className="col-md-4 pt-1">نام خط مبنا</Form.Label>
                        <Form.Control
                          className="col-md-8"
                          type="text"
                          value={newTrendName}
                          onChange={e => {
                            setNewTrendName(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={{ span: 4 }}>
                      <Form.Group className="mb-3 row" controlId="formBasicPassword">
                        <Form.Label className="col-md-6 pt-1">درصد انحراف مجاز</Form.Label>
                        <Form.Control
                          className="col-md-6"
                          type="text"
                          value={newTrendCoeff}
                          onChange={e => {
                            setNewTrendCoeff(e.target.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Button disabled={!previewRegression} onClick={saveTrend}>
                        ذخیره خط مبنا
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </form>
              <form hidden={activeStep !== 3}>
                <Container fluid>
                  <Row>
                    <Col md={{ span: 2, offset: 3 }}>
                      <label className={'pr-2 pl-1'}>انتخاب خط مبنا</label>
                    </Col>
                    <Col md={4}>
                      <select
                        className="TrendSelect"
                        value={selectedTrend ? selectedTrend._id : null}
                        onChange={changeTrendSelect}
                      >
                        <option></option>
                        {allTrend.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                  <center hidden={!loadingRegressionData}>
                    <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                  </center>
                  <Row className={'pt-3 mb-2'} hidden={loadingRegressionData || showRegressionFormula}>
                    <Col md={{ span: 12 }}>
                      <div className="regressionFormula">{getRegressionFormula()}</div>
                    </Col>

                    <Col md={{ span: 12 }}>
                      <Row>
                        <Col md={{ span: 4 }}>
                          <div className="regressionParam">
                            <div className="regParamTitle">R2 Score</div>
                            <div className="regParamText">
                              {previewRegression
                                ? regressionParam > '1' || regressionOrder == '1'
                                  ? previewRegression.rSquared.toFixed(4)
                                  : previewRegression.r2.toFixed(4)
                                : ''}
                            </div>
                          </div>
                        </Col>
                        <Col md={{ span: 4 }}>
                          <div className="regressionParam">
                            <div className="regParamTitle MytoolTip d-inherit" >تعداد روز دوره
                              <span className="tooltiptext mt-1">{previewRegression ? previewRegression.from : ''}  {previewRegression ? previewRegression.to : ''} </span>
                            </div>
                            <div className="regParamText">{previewRegression ? previewRegression.inDays : ''}</div>
                          </div>
                        </Col>
                        <Col md={{ span: 4 }}>
                          <div className="regressionParam">
                            <div className="regParamTitle">تعداد روز محاسبه شده</div>
                            <div className="regParamText">{previewRegression ? previewRegression.outDays : ''}</div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 12 }}>
                      <h3 className={'simpleBoxTitle'}>آنالیز آماری رگرسیون</h3>
                      <Row className="mb-2 mt-2">
                        {regressionParam > '1' || regressionOrder == '1' ? (
                          <Col md={{ span: 12 }}>
                            <Table responsive striped className="RegTable mb-3">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>SS</th>
                                  <th>F</th>
                                  <th>Significance F</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="font-weight-bold">Regression</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.ssr).toFixed(6) : ''}</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.fValue).toFixed(6) : ''}</td>

                                  <td>
                                    {previewRegression ? parseFloat(previewRegression.pValueOfFValue).toFixed(6) : ''}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="font-weight-bold">Residual</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(6) : ''}</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td className="font-weight-bold">Total</td>
                                  <td>
                                    {previewRegression
                                      ? parseFloat(previewRegression.ssr + parseInt(previewRegression.sse)).toFixed(6)
                                      : ''}
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </Table>

                            <Table responsive striped className="RegTable mb-3">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Coefficients</th>
                                  <th>Standard Error</th>
                                  <th>t Stat</th>
                                  <th>P-value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {previewRegression
                                  ? previewRegression.pValues.map((item, index) => {
                                    if (!item || isNaN(item)) {
                                    }
                                    else
                                      return (
                                        <tr>
                                          <td className="font-weight-bold">
                                            {index === 0 ? 'Intercept' : 'x' + index}
                                          </td>
                                          <td>{parseFloat(previewRegression.coefficients.data[index]).toFixed(6)}</td>
                                          <td>
                                            {parseFloat(previewRegression.stdErrorOfCoefficients[index]).toFixed(6)}
                                          </td>

                                          <td>{parseFloat(previewRegression.tValues[index]).toFixed(6)}</td>
                                          <td>{parseFloat(previewRegression.pValues[index]).toFixed(6)}</td>
                                        </tr>
                                      );
                                  })
                                  : ''}
                              </tbody>
                            </Table>
                          </Col>
                        ) : (
                          <Col md={{ span: 12 }}>
                            <Table responsive striped className="RegTable mb-3">
                              <thead>
                                <tr>
                                  <th>me</th>
                                  <th>sse</th>
                                  <th>mse</th>
                                  <th>smse</th>
                                  <th>se</th>
                                  <th>tstat</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{previewRegression ? parseFloat(previewRegression.me).toFixed(4) : ''}</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.sse).toFixed(4) : ''}</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.mse).toFixed(4) : ''}</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.smse).toFixed(4) : ''}</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.se).toFixed(4) : ''}</td>
                                  <td>{previewRegression ? parseFloat(previewRegression.tstat).toFixed(4) : ''}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    {/* Commnents */}
                    <Col md={{ span: !2 }}>
                      <Note
                        itemId={selectedTrend ? selectedTrend._id : null}
                        type="trend"
                        notesRefresh={notesRefresh}
                        itemName={selectedTrend ? selectedTrend.name : null}
                        setNotesRefresh={setNotesRefresh}
                      />
                    </Col>
                  </Row>
                </Container>
              </form>
              <form hidden={activeStep !== 4}>
                <Container className="TrendPage">
                  <Row className={'mb-1 dpRtl pt-2 text-center'}>
                    <Col md={{ span: 6, offset: 3 }}>
                      <h2 className={'pr-2 pl-1 mb-2'}>تعیین دوره گزارش دهی</h2>
                    </Col>
                  </Row>
                  <Row className={'mb-2 dpRtl'}>
                    <Col md={{ span: 6, offset: 3 }}>
                      <Calendar
                        value={chartRangeDateReport}
                        onChange={setChartRangeDateReport}
                        locale="fa"
                        calendarClassName={'calendarWidth'}
                      />
                    </Col>
                  </Row>
                  <Row className={'mb-2'} hidden={!chartRangeDateReport.from || !chartRangeDateReport.to}>
                    <Col className="text-center" md={{ span: 6, offset: 3 }}>
                      <Button onClick={getChartData}>مشاهده گزارش</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                      <center hidden={!chartLoading}>
                        <Spinner animation="grow" variant="success" className={'spinnerChart'} />
                      </center>
                    </Col>
                  </Row>
                  <Row className={'mb-2'} hidden={chartLoading}>
                    <Fragment hidden={!chartLoad}>
                      <TrendChart chartData={chartData} chartLoad={chartLoad} trendCoeff={selectedTrend ? selectedTrend.trendCoeff:null} />
                    </Fragment>
                  </Row>
                </Container>
              </form>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Trend;
